import React, { Component } from "react";
import { string, node, bool } from "prop-types";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import classNames from "classnames";

class NoResults extends Component {
    static propTypes = {
        children: node,
        iconPath: string,
        centerImage: bool,
        description: string,
    };

    static defaultProps = {
        iconPath: "images/util/error.gif",
        children: undefined,
        centerImage: false,
        description: null,
    };

    boxClasses = () => {
        const { centerImage } = this.props;
        return classNames({ "d-flex justify-content-center": centerImage });
    };

    render() {
        const { children, iconPath, description } = this.props;

        return (
            <Box>
                <Box className={this.boxClasses()}>
                    <Image wrapperClassName="img-error-wrapper" src={iconPath} />
                </Box>
                <Box className="text-center mt-7">
                    <Text labelKey="global.noResults" size="3" bold />
                </Box>
                <Box className="text-center mt-5">
                    <Text labelKey={description || "global.noResults.text"} />
                </Box>
                <Box>{children}</Box>
            </Box>
        );
    }
}

export default NoResults;
