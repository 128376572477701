import { createReducer, makeActionCreator } from "util/redux";
import formTypes from "reducers/types/form";

const INITIAL_STATE = {
    credentialGroups: [],
    idTransaction: null,
    idActivity: null,
    hasSignatureEnabled: false,
    fetching: true,
    adminGroupsIds: [],
};

export const selectors = {
    hasSignatureEnabled: ({ details }) => details.signatureLevel !== null,
    getSignatureLevel: ({ details }) => details.signatureLevel,
    hasMassiveEnabled: ({ details }) => details.hasMassiveEnabled,
    getUserEnvStatus: ({ details }) => details.userEnvStatus,
    getAdminGroupsIds: ({ details }) => details.adminGroupsIds,
    isFetching: ({ details }) => (details ? details.fetching : false),
    getCredentialGroups: ({ details }) => details.credentialGroups,
    getIdTransaction: ({ details }) => details.idTransaction,
    getIdActivity: ({ details }) => details.idActivity,
    getUserTokenStatus: ({ details }) => details.userTokenStatus,
    getUserSerialToken: ({ details }) => details.userSerialToken,
    getUserDeviceType: ({ details }) => details.userDeviceType,
    getOldProducts: ({ details }) => details.oldProducts,
    getProducts: ({ details }) => details.products,
};

export default (name) => {
    const types = {
        LOAD_DETAILS_REQUEST: `${name}/LOAD_DETAILS_REQUEST`,
        LOAD_DETAILS_FAILURE: `${name}/LOAD_DETAILS_FAILURE`,
        LOAD_DETAILS_SUCCESS: `${name}/LOAD_DETAILS_SUCCESS`,
        UPDATE_SIGNATURE_REQUEST: `${name}/UPDATE_SIGNATURE_REQUEST`,
        UPDATE_SIGNATURE_REQUEST_SUCCESS: `${name}/UPDATE_SIGNATURE_REQUEST_SUCCESS`,
        LOAD_DATA_FOR_SIGN_UPDATE_SIGNATURE: `${name}/LOAD_DATA_FOR_SIGN_UPDATE_SIGNATURE`,
        UPDATE_SIGNATURE_PREVIEW: `${name}/UPDATE_SIGNATURE_PREVIEW`,
        UPDATE_SIGNATURE_PREVIEW_SUCCESS: `${name}/UPDATE_SIGNATURE_PREVIEW_SUCCESS`,
    };

    return {
        types,
        reducer: createReducer(INITIAL_STATE, {
            [types.LOAD_DETAILS_REQUEST]: (state) => ({ ...state, fetching: true }),
            [types.LOAD_DETAILS_FAILURE]: (state) => ({ ...state, fetching: false }),
            [types.LOAD_DETAILS_SUCCESS]: (state, { data }) => ({
                ...state,
                credentialGroups: data.credentialGroups,
                signatureLevel: data.signatureLevel,
                hasMassiveEnabled: data.hasMassiveEnabled,
                userEnvStatus: data.userEnvStatus,
                adminGroupsIds: data.adminGroupsIds,
                userTokenStatus: data.tokenStatus,
                userSerialToken: data.serialToken,
                userDeviceType: data.deviceType,
                oldProducts: data.oldProducts,
                products: data.products,
                fetching: false,
            }),
            [types.UPDATE_SIGNATURE_REQUEST_SUCCESS]: (state) => ({
                ...state,
                signatureLevel: state.signatureLevel === null ? "A" : null,
            }),
            [types.UPDATE_SIGNATURE_PREVIEW_SUCCESS]: (state, { signatureLevel }) => ({
                ...state,
                signatureLevel,
            }),
            [types.LOAD_DATA_FOR_SIGN_UPDATE_SIGNATURE]: (state, { data }) => ({
                ...state,
                signatureLevel: data.signatureLevel,
                credentialGroups: data.credentialGroups,
                idTransaction: data.idTransaction,
                idActivity: data.idActivity,
            }),
            [formTypes.SEND_FORM_SUCCESS]: () => ({
                ...INITIAL_STATE,
            }),
        }),
        actions: {
            loadDetailsRequest: makeActionCreator(types.LOAD_DETAILS_REQUEST, "id"),
            loadDetailsFailure: makeActionCreator(types.LOAD_DETAILS_FAILURE),
            loadDetailsSuccess: makeActionCreator(types.LOAD_DETAILS_SUCCESS, "data"),
            updateSignatureRequest: makeActionCreator(types.UPDATE_SIGNATURE_REQUEST, "data", "formikBag"),
            updateSignatureRequestSuccess: makeActionCreator(types.UPDATE_SIGNATURE_REQUEST_SUCCESS),
            updateSignaturePreview: makeActionCreator(types.UPDATE_SIGNATURE_PREVIEW, "data", "formikBag"),
            updateSignaturePreviewSuccess: makeActionCreator(types.UPDATE_SIGNATURE_PREVIEW_SUCCESS, "signatureLevel"),
            loadDataForSignUpdateSignature: makeActionCreator(types.LOAD_DATA_FOR_SIGN_UPDATE_SIGNATURE, "data"),
        },
    };
};
