// import { differenceInDays, format, isToday, isYesterday } from "date-fns";
import { format } from "date-fns";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en";
import capitalize from "lodash/capitalize";

// import { get as getText, getLang } from "util/i18n";
import { getLang } from "util/i18n";
import { get as getConfig } from "util/config";

const locale = { es, en };

export const date = (dateToFormat, formatDate = null, lang = null) => {
    const currentLang = getLang();

    const shortDateFormat = getConfig("frontend.shortDateFormat");
    return format(dateToFormat, formatDate || shortDateFormat, { locale: locale[lang || currentLang] });
};

export const naturalDate = (dateToFormat) =>
    /*
     // SE COMENTA BLOQUE DE CODIGO PORQUE LA ESRE PIDE FORMATO "dd/mm/aaaa – hh/mm a"

    const wordBeforeTime = getText("settings.lastLogin.beforeTime");

    if (isToday(dateToFormat)) {
        const wordToday = getText("scheduler.executionToday");

        return capitalize(`${wordToday} ${wordBeforeTime} ${date(dateToFormat, "hh:mm a")}`);
    }
    if (isYesterday(dateToFormat)) {
        const wordYesterday = getText("scheduler.executionYesterday");

        return capitalize(`${wordYesterday} ${wordBeforeTime} ${date(dateToFormat, "hh:mm a")}`);
    }
    if (differenceInDays(new Date(), dateToFormat) < 7) {
        return capitalize(date(dateToFormat, `dddd [${wordBeforeTime}] hh:mm a`));
    }
    */

    capitalize(date(dateToFormat, "DD/MM/YYYY - hh:mm a"));

export const formatNumber = (number, maximumFractionDigits = 6, minimumFractionDigits = 2) => {
    if (number !== null && number !== "") {
        return number.toLocaleString("en-US", {
            maximumFractionDigits,
            minimumFractionDigits,
        });
    }
    return null;
};

export const maskEmail = (email = "") => {
    const [name, domain] = email.split("@");
    let [beforeDot, afterDot] = domain.split(".");
    beforeDot = "***";
    afterDot = `.${afterDot}`;
    const { length: len } = name;
    let maskedName = "";
    let ast = "*";
    if (len > 3) {
        ast = "*".repeat(len - 2);
    }
    maskedName = `${name[0]}${ast}${name[len - 1]}`;
    const maskedEmail = `${maskedName}@${beforeDot}${afterDot}`;
    return maskedEmail.toUpperCase();
};

export const simpleMaskEmail = (email = "", pattern = "*") => {
    if (!email) {
        return "";
    }

    if(!email.includes(pattern)){
        const [name, domain] = email.split("@");
        const { length: len } = name;
        let maskedName = "";
        let ast = pattern;
        if (len > 3) {
            ast = pattern.repeat(len - 2);
        }
        maskedName = `${name[0]}${ast}${name[len - 1]}`;
        return `${maskedName}@${domain}`;
    }else{
        return email;
    }
};

export const maskPhoneNumber = (phoneNumber = "") => {
    if (!phoneNumber) {
        return "";
    }

    // const hasAreaCode = phoneNumber.split("-").length > 1;
    // let [areaCode, number] = phoneNumber.split("-");
    const regex = /^(\d{1,3})(\d{7,15})$/;
    const match = phoneNumber.match(regex);
    let areaCode = "";
    let number = null;
    if (match) {
        areaCode = `+${match[1]}`;
        number = match[2];
    }

    if (!number) {
        number = phoneNumber;
    }

    const { length: len } = number;

    let maskedNumber = "";
    let ast = "*** ***";
    let nonMakedNumber = "";
    if (len > 5) {
        // ast = "*".repeat(len - 4);
        nonMakedNumber = number.substring(len - 4);
    } else {
        ast = "**";
        nonMakedNumber = number.substring(len - 1);
    }

    if(phoneNumber.includes(ast)){
        return phoneNumber;
    }

    maskedNumber = `${areaCode} ${ast} ${nonMakedNumber}`;
    return maskedNumber;
};

export const maskPhoneNumber2 = (phoneNumber = "") => {
    if (!phoneNumber) {
        return "";
    }

    if(phoneNumber.includes("*")){
        return phoneNumber;
    }

    const { length: len } = phoneNumber;
    let maskedNumber = "";
    let nonMakedNumber = "";
    if (len > 5) {
        nonMakedNumber = phoneNumber.substring(len - 4);
    } else {
        nonMakedNumber = phoneNumber.substring(len - 1);
    }
    maskedNumber = `•${nonMakedNumber}`;
    return maskedNumber;
};

export const formatToken = (token, type) => {
    if (!token) {
        return "-";
    }
    const formattedToken = token.replace(/^(\d*)(\d{5})$/, "$1-$2");
    return `${type} (${formattedToken})`;
};

export const formatNameClient = (name) => {
    if (!name) {
        return "";
    }

    if (name.length > 20) {
        return name.substring(0, 20).concat("...");
    }

    return name;
};

export const maskLabelAccountProduct = (label) => {
    if (!label) {
        return "";
    }
    const labels = label?.split(/\s+/);
    if (labels?.length < 3) {
        return label;
    }
    return maskAccountNumber(labels);
}

export const maskAccountNumber = (labels) => {
    if (labels[0]?.length === 10) {
        const nonMaskValues = labels?.slice(1)?.join(" ");
        const account = labels[0];
        const ast = "XXXX";
        const prefixNonMask = account.substring(0, 3);
        const suffixNonMask = account.substring(7);
        return `${prefixNonMask}${ast}${suffixNonMask} ${nonMaskValues}`;
    }
    return labels.join(" ");
}

export default {};
