import React, { Component } from "react";
import { bool, func, shape } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { GET_PAGINATED_DATA_LIST } from "reducers/paginatedTable";
import { loadingSelector } from "reducers/loading";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import HiddenFilters from "pages/transactions/_components/HiddenFilters";
import TransactionHeader from "pages/transactions/_components/TransactionHeader";
import TransactionsItem from "pages/transactions/_components/TransactionItem";
import MainContainer from "pages/_components/MainContainer";
import * as i18n from "util/i18n";
import Box from "pages/_components/Box";
import NoResults from "pages/_components/NoResultsMessage";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import SwitchToggle from "pages/_components/SwitchToggle";
import classNames from "classnames";
import { Collapse } from "react-bootstrap";
import { replace } from "react-router-redux";
import Table from "pages/_components/PaginatedTable/Table";
import FiltersButtons from "./_components/FiltersButtons";

export const EnumTransactionStatus = {
    PENDING: "PENDING",
    PROCESSING: "PROCESSING",
};

class PendingTransactionList extends Component {
    static propTypes = {
        isFetching: bool.isRequired,
        isDesktop: bool.isRequired,
        setFilters: func.isRequired,
        goDesktop: func.isRequired,
        resetFrom: func.isRequired,
        filtersBkP: shape({
            requiresMyApproval: bool,
        }),
        fromTicket: bool.isRequired,
    };

    static defaultProps = {
        filtersBkP: null,
    };

    state = {
        onlyPendings: true,
        onlyProcessing: true,
        ascending: false,
        filtersValue: {
            status: [EnumTransactionStatus.PENDING, EnumTransactionStatus.PROCESSING],
            onlyPendings: true,
            onlyProcessing: true,
            pendingDispatch: true,
            requiresMyApproval: true,
            selectedTransactionType: "all",
            selectedOperationType: "all",
        },
        isCreatedByMe: false,
        hasFiltersApplied: false,
        showFilters: false,
        currentPage: 1,
    };

    checkHasFiltersApplied = (filtersBkP) =>
        (filtersBkP?.selectedFilter && filtersBkP?.selectedFilter !== "all") ||
        (filtersBkP?.selectedOperationType && filtersBkP?.selectedOperationType !== "all") ||
        (filtersBkP?.selectedTransactionType && filtersBkP?.selectedTransactionType !== "all");

    componentWillMount = () => {
        const { filtersBkP, fromTicket, setFilters, resetFrom } = this.props;

        if (fromTicket && filtersBkP) {
            this.setState({
                filtersValue: { ...filtersBkP, onlyPendings: true, pendingDispatch: true },
                hasFiltersApplied: this.checkHasFiltersApplied(filtersBkP),
                requiresMyApproval: filtersBkP?.requiresMyApproval,
                showFilters: true,
            });
        } else {
            setFilters(null);
            this.setState({
                requiresMyApproval: true,
            });
        }
        resetFrom();
    };

    handleOrder = () => {
        const { ascending } = this.state;
        this.setState({ ascending: !ascending });
    };

    resetHandleOrder = () => {
        this.setState({ ascending: false });
    };

    handlerIsCreatedByMe = () => {
        const { isCreatedByMe } = this.state;
        this.setState({ isCreatedByMe: !isCreatedByMe });
    };

    // eslint-disable-next-line react/sort-comp
    changeStatusInFiltersValue(statusArray) {
        const { filtersValue } = this.state;
        const newFilters = {
            ...filtersValue,
            status: statusArray,
        };
        this.setState({ filtersValue: newFilters });
    }

    handleToggle = () => {
        const { setFilters } = this.props;
        const { filtersValue, requiresMyApproval } = this.state;

        const newFilters = {
            ...filtersValue,
            requiresMyApproval: !requiresMyApproval,
        };

        this.setState({ requiresMyApproval: !requiresMyApproval, filtersValue: newFilters });
        setFilters(newFilters);
    };

    handleShowFiltersMobile = () => {
        this.setState({ showFilters: true });
    };

    handleHideFiltersMobile = () => {
        this.setState({ showFilters: false });
    };

    filtersButtons = () => {
        const { showFilters } = this.state;
        return (
            <FiltersButtons
                showFilters={showFilters}
                handleHideFilters={this.handleHideFiltersMobile}
                handleShowFilters={this.handleShowFiltersMobile}
            />
        );
    };

    setCurrentPage = (page) => {
        this.setState({ currentPage: page });
    };

    render() {
        const { isFetching, isDesktop, setFilters } = this.props;
        const {
            onlyPendings,
            onlyProcessing,
            filtersValue,
            isCreatedByMe,
            hasFiltersApplied,
            requiresMyApproval,
            showFilters,
            currentPage,
        } = this.state;

        const setFilterValues = (newFilters) => {
            const { selectedOperationType, selectedTransactionType, selectedFilter } = newFilters;
            if (selectedOperationType === "all" && selectedTransactionType === "all" && selectedFilter === "all") {
                this.setState({ filtersValue: newFilters, hasFiltersApplied: false, requiresMyApproval });
            } else {
                this.setState({ filtersValue: newFilters, hasFiltersApplied: true, requiresMyApproval });
            }
            setFilters(newFilters);
        };

        const renderEmptyList = () => (
            <Box className="m-7">
                <Image src="images/empty.svg" />
                <Box className="text-center mt-5 mb-4">
                    <Text labelKey="transactions.none.title" bold size="3" />
                </Box>
                <Box className="text-center">
                    <Text labelKey="transactions.none.text" />
                </Box>
            </Box>
        );

        const renderSwitch = () => (
            <Box
                className={classNames({
                    "my-3": !isDesktop,
                })}>
                <SwitchToggle
                    switchIsOn={requiresMyApproval}
                    handleToggle={this.handleToggle}
                    labelOnKey="transactions.list.filter.requiresMyApproval.label"
                    labelOffKey="transactions.list.filter.requiresMyApproval.label"
                    textClassName={classNames("credit-card-label ml-3 ", {
                        "size-3": !isDesktop,
                    })}
                    id="switch"
                />
            </Box>
        );



        
        return (
            <>
                <Notification scopeToShow="transactions" />
                <Head onBack={!isDesktop && this.props.goDesktop} title="pendingDispatch.list.title" />
                <MainContainer
                    className={classNames("main-container", {
                        "background-white texture-header px-0": !isDesktop,
                    })}>
                    <Box
                        borderRadius="lg"
                        background="white"
                        className={classNames({
                            "box-shadow-small mt-3 texture-header pt-5": isDesktop,
                            "mt-4": !isDesktop,
                        })}>
                        <Box
                            background="white"
                            className={classNames({
                                "px-7 pt-2 pb-7": isDesktop,
                                "px-5 pb-5": !isDesktop,
                            })}>
                            <Box fullWidth display="flex" className={`border-radius-lg box-shadow-small background-white ${!isDesktop ? 'px-5 py-5 mb-5' : 'px-7 py-7 mb-7'}`}>
                                <Box fullWidth>
                                    <Box>
                                        <Text component="h2" labelKey="transactions.pending.header" defaultValue="Transacciones pendientes de aprobación" bold />
                                    </Box>
                                    <Box>
                                        <Text labelKey="transactions.pending.description" defaultValue="Consulte aquí también las operaciones que están pendiente de aprobación" />
                                    </Box>
                                </Box>
                                <Box>
                                    <Image src="images/transactions_pending_header.svg" color="transparent" />
                                </Box>
                            </Box> 
                            {isDesktop ? (
                                <Box>                                    
                                    <HiddenFilters
                                        // dispatch={dispatch}
                                        isDesktop={isDesktop}
                                        fetching={isFetching}
                                        onlyPendings={onlyPendings}
                                        onlyProcessing={onlyProcessing}
                                        isTransactionList={false}
                                        resetHandleOrder={this.resetHandleOrder}
                                        filterValues={filtersValue}
                                        setFilterValues={setFilterValues}
                                        isCreatedByMe={isCreatedByMe}
                                    />
                                </Box>
                            ) : (
                                <Collapse in={showFilters}>
                                    <Box>
                                        <HiddenFilters
                                            // dispatch={dispatch}
                                            isDesktop={isDesktop}
                                            fetching={isFetching}
                                            onlyPendings={onlyPendings}
                                            onlyProcessing={onlyProcessing}
                                            isTransactionList={false}
                                            resetHandleOrder={this.resetHandleOrder}
                                            filterValues={filtersValue}
                                            setFilterValues={setFilterValues}
                                            isCreatedByMe={isCreatedByMe}
                                        />
                                    </Box>
                                </Collapse>
                            )}
                            <div className="above-the-fold">
                                <Box
                                    className={classNames({
                                        "mt-5": isDesktop,
                                        "pb-5": !isDesktop,
                                    })}>
                                    <Table
                                        idActivity="transactions.list"
                                        notificationScopes={["transactions"]}
                                        name="pendingTransactions"
                                        hasFiltersApplied={hasFiltersApplied}
                                        headerProps={{}}
                                        itemProps={{
                                            isDesktop,
                                            fromPending: true,
                                        }}
                                        itemComponent={TransactionsItem}
                                        headerComponent={TransactionHeader}
                                        filters={filtersValue}
                                        isDesktop={isDesktop}
                                        withoutItemsMessage={renderEmptyList()}
                                        withoutItemsMessageFilter={<NoResults />}
                                        renderDownloadDocs={isDesktop && renderSwitch}
                                        extraActionTwo={!isDesktop && renderSwitch}
                                        showFilters={false}
                                        extraActionMobile={!isDesktop && this.filtersButtons}
                                        currentPage={currentPage}
                                        setCurrentPage={this.setCurrentPage}
                                        autoClearWhenUnmounted
                                    />
                                </Box>
                            </div>
                        </Box>
                    </Box>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetching: loadingSelector(state, [GET_PAGINATED_DATA_LIST]),
    pageNumber: transactionsSelectors.getPageNumber(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    filtersBkP: transactionsSelectors.getFilters(state),
    fromTicket: transactionsSelectors.getFromTicket(state),
});

const mapDispatchToProps = (dispatch) => ({
    setFilters: (filters) => dispatch(transactionsActions.setFilters(filters)),
    resetFrom: () => dispatch(transactionsActions.resetFrom()),
    goDesktop: () => dispatch(replace("/desktop")),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingTransactionList);
