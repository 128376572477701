import React, { useEffect, useState, useMemo, useRef} from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";
import classNames from "classnames";
import { Field } from "formik";
import * as Yup from "yup";
import Box from "pages/_components/Box";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import FormTransition from "pages/forms/_components/FormTransition";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push, replace } from "react-router-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { actions as notificationActions } from "reducers/notification";
import { compose } from "redux";
import * as i18n from "util/i18n";
import fieldARP from "./hooks/field";
import {
    PREFIX,
    useAccountPaySelectorOptions,
    useCreditCardPaySelectorOptions,
    useServicioConvenioData,
    useMetaData,
    initialValuesServiceRegistration,
    validateFormServiceRegistration,
    useDebtData,
    useCreditCardNonRegisteredServicePaymentSelectorOptions,
    useAccountNonRegisteredServicePaymentSelectorOptions,
    useServiceData,
    initialValuesNonRegistered,
    getMetaData,
} from "./hooks/servicePaymentFormHooks";
import { getServiceCategoriesOptions } from "./hooks/servicePaymentsHooks";
import { getCategorySelected } from "./hooks/servicePaymentsHooks";
import Button from "pages/_components/Button";
import Select from "pages/forms/_components/_fields/Select";
import FieldError from "pages/_components/fields/FieldError";
import SideBarModal from "pages/_components/modal/SideBarModal";
import { USD_CURRENCY, SERVICE_REGISTRATION, IDENTIFIERS_MASK } from "constants.js";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import { actions as serviceRegistrationActions, selectors as serviceRegistrationSelectors } from "reducers/serviceRegistration";
import Image from "pages/_components/Image";
import { generatePlaceholder, getMaxLengthFromRegex, cleanRegex, generatePlaceholderFromMask, cleanMask, replaceZerosInMask} from "pages/servicePayments/utils/utils";
import InputMask from 'react-input-mask';
import CatastroInputField from "pages/forms/customForms/_customFields/serviceRegistrationField/CatastroInputField";
import { isMobile } from "react-device-detect";
import ConfirmDialog from "pages/_components/modal/ConfirmDialog";
import Loader from "pages/_components/Loader";
import ExitModal from "pages/_components/modal/ExitModal";
import PayServiceForm from "./_components/PayServiceForm";
import { useGenericProps } from "pages/forms/customForms/hooks/TransferInternalHooks";
import Info from "pages/_components/Info";
import * as config from "util/config";
import TicketData from "pages/_components/TicketData";
import FormattedAmount from "pages/_components/FormattedAmount";
import { renderData, renderDataAmount, renderBills, billsData, billsTableColumns } from "./_components/utilForm";

const ID_FORM = "serviceRegistration";
const ID_ACTIVITY = "serviceRegistration.send";
// const ID_ACTIVITY_NEW_PAY = "paymentService.send";
const ID_ACTIVITY_PRE = "serviceRegistration.pre";
const TITLE_FORM = `${ID_FORM}.title`;
const TITLE_NEW_PAY_FORM = `${ID_FORM}.newPay.title`;

const ID_ACTIVITY_NON_REGISTERED_SERVICE ="nonRegisteredServicePayment.send";
const ID_FORM_PAY = "servicePayments.pay";

const ServiceRegistrationForm = (props) => {
    const {
        mode,
        preDataForm,
        isDesktop,
        dispatch,
        queryBill,
        selectedBiller,
        fetchingForm,
        field,
        currentLang,
        transaction,
        // creditCardList,
        // fetchingList,
        usesJointAccount,
        massivePayService,
        //aliasExists,
        checkAliasExists,
        previewData,
        location,
        fetchingPre,
        fetchingValidations,
        validationData,
        fetchingNonRegisTeredServicePayment,
        payServiceData,
        ...rest
    } = props;

    const [showModalDetail, setShowModalDetail] = useState(false);
    const [payDetail, setPayDetail] = useState({});
    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);
    const [agreements, setAgreements] = useState([]);
    const [identifierType, setIdentifierType] = useState([]);
    const [selectedTypeService, setSelectedTypeService] = useState(SERVICE_REGISTRATION.DEFAULT_VALUE);
    const [selectedServiceCompany, setSelectedServiceCompany] = useState(null);
    const [selectedAgreements, setSelectedAgreements] = useState(null);
    const [selectedIdentifiers, setSelectedIdentifiers] = useState(SERVICE_REGISTRATION.DEFAULT_VALUE);
    const [aliasService, setAliasService] = useState(null);

    const [agreementsOptions, setAgreementsOptions] = useState([]);
    const [identifierTypeOptions, setIdentifierTypeOptions] = useState([]);
    const [requestData, setRequestData] = useState({typeService :''});
    const [changeServiceType, setChangeServiceType] = useState(false);
    const aliasAlreadyExistsMessage = i18n.get("serviceRegistration.alias.error.aliasAlreadyExists");
    const [showModalSeeHelp, setShowModalSeeHelp] = useState(false);
    const [supply, setSupply] = useState("");
    const [supplyCatastro, setSupplyCatastro] = useState("");
    const [maskCatastro, setMaskCatastro] = useState("");
    const [regexCatastro, setRegexCatastro] = useState("");
    const [placeHolderCatastro, setPlaceHolderCatastro] = useState("");
    const [existsAlias, setExistsAlias] = useState(false);
    const [enabledSubmit, setEnabledSubmit] = useState(false);
    const [nonRegistrableService, setNonRegistrableService] = useState(false);
    const nonRegistrableServiceMessage = i18n.get("serviceRegistration.non.registrableService.error");

    const [isNewPay] = useState(location.pathname.includes("/newPay"));
    const [alreadyRegistered, setAlreadyRegistered] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);


    const {
        companies
      } = useServicioConvenioData(preDataForm?.registrationData);

    const serviceOptions = getServiceCategoriesOptions();
    if (usesJointAccount) {
        const daData = transaction.data.debitAccountData;
        if (daData) {
            accountOptions = { options: [{ ...daData, id: daData.idProduct, label: daData.otherLabel }] };
        }
    }
    const [categorySelected, setCategorySelected] = useState({});
    const [isErrorBalance, setIsErrorBalance] = useState(false);
    const isErrorRequest = props.selectedBiller?.hasQuery && props.preDataForm?.billBalance == null;
    const [isSupplyCatastroActive, setIsSupplyCatastroActive] = useState(false);

    const radiButtonOptions = [
        {
            id: "account",
            label: i18n.get("servicePayments.pay.debitPaymentMethod.account"),
        },
        {
            id: "creditCard",
            label: i18n.get("servicePayments.pay.debitPaymentMethod.creditCard"),
        },
    ];

    const companyServiceOptions = useMemo(() =>
        companies.length > 0
            ? companies.map(company => ({
                value: company.companyCode || '',
                label: company.companyLabel || ''
            }))
            : [{ value: '', label: '' }],
        [companies]
    );

    const [genericProps] = useGenericProps(props, ID_ACTIVITY_NON_REGISTERED_SERVICE);

    const creditCardList = preDataForm?.creditCards || [];
    const accountList = preDataForm?.accounts || [];
    const debtDataObject = preDataForm?.debt || {};
    const payServiceObject = preDataForm?.payService || {};
    const rateData = preDataForm?.rate ;

    const allCreditCardOptions = useCreditCardNonRegisteredServicePaymentSelectorOptions(creditCardList);
    const allAccountOptions = useAccountNonRegisteredServicePaymentSelectorOptions(accountList);
    const  debtData = useDebtData(debtDataObject, payServiceObject, allAccountOptions);

    const creditCardOptions = (isNewPay && nonRegistrableService && creditCardList.length > 0)
        ? allCreditCardOptions
        : [];

    const accountOptions = (isNewPay && nonRegistrableService && accountList.length > 0)
        ? allAccountOptions
        : [];

    const debtOptions = (isNewPay && nonRegistrableService && debtData)
        ? debtData
        : {};

    const payServiceOptions = (isNewPay && nonRegistrableService && debtData.payService)
    ? debtData.payService
    : {};

    const valuesPayServiceForm = {
        list: []
    };
    valuesPayServiceForm.list.push(payServiceOptions);

    const initialValuesNonReg = (isNewPay && nonRegistrableService && rateData && accountOptions &&  payServiceOptions ) ?
        initialValuesNonRegistered(rateData, accountOptions, payServiceOptions ) : {}

    useEffect(() => {
        const activities = [ID_ACTIVITY];
        dispatch(formActions.preValidationsForm(activities));
    }, [])

    useEffect(() => {
        if (validationData && !validationData?.hasSignatureScheme) {
            // no tiene comite de firmas asignado
            dispatch(
                notificationActions.showNotification(i18n.get(`form.schemeSignature.notFound`), "error", [
                    "form",
                ]),
            );
        }
    }, [validationData])


    useEffect(() => {
        if (agreements && agreements.length > 0) {
            const options = agreements.map(agreement => ({
                value: agreement.code,
                label: agreement.label
            }));
            //setSelectedAgreements(SERVICE_REGISTRATION.DEFAULT_VALUE);
            setAgreementsOptions(options);
        }
    }, [agreements])


    useEffect(() => {
        if (identifierType && identifierType.length > 0) {
            const options = identifierType
                .filter(identifier => (isNewPay ? true : identifier.registrable === true))// Aplica el filtro solo si `isNewPay` es `false`
                .map(identifier => ({
                    value: identifier.code,
                    label: identifier.label,
                    mask: identifier.mask,
                    registrable: identifier.registrable,
                    regexp: identifier.regexp,
                    helpText: identifier.helpText,
                    additionalData: identifier.additionalData,
                }));
            setIdentifierTypeOptions(options);
            if(options && options.length == 0 && !isNewPay) {
                setSelectedAgreements(false);//Se inactiva Tipo de identificador
                setNonRegistrableService(true);//Se muestra error Servicio no Matriculable
            } else {
                setNonRegistrableService(false);
            }
        }
    }, [identifierType]);

    const cleanDataDebt = (setFieldValue) => {
        setFieldValue("debt", undefined);
        setFieldValue("payService", undefined);
        setFieldValue("list", undefined);
        setNonRegistrableService(false);
    }

    const  onChangeServiceType = (label, value, setFieldValue) => {
        if (requestData.typeService !== value) {

            setRequestData({ typeService: value });
            setChangeServiceType(true)
            setFieldValue("categoryCode", value);
            setFieldValue("category", label);

            setSelectedServiceCompany(null);
            setSelectedAgreements(null);
            setFieldValue('companyId', '' );
            setFieldValue('companyLabel', '');


            setSelectedIdentifiers(null);
            if(supply && supply != null) {
                setSupply(null)
                setSupplyCatastro(null)
            }
            if(aliasService && aliasService != null) {
                setAliasService(null)
            }
            setFieldValue("alias", '');
            cleanDataDebt(setFieldValue);
        }
    };

    const onChangeServiceAgreement = (value) => {
        //if(agreements && agreements.length > 1) {
            if(changeServiceType) {
                setSelectedServiceCompany(null);
                setChangeServiceType(false);
            } else {
                setSelectedServiceCompany(value);
            }
            const company = companies.find(company => String(company.companyCode) === String(value));
            if(company != null) {
                if(company.agreements  &&  company.agreements != null && company.agreements.length > 0 ) {
                    //let uniqueAgreements = removeDuplicateAgreements(company.agreements)
                    //setAgreements(uniqueAgreements);
                    setAgreements(company.agreements);
                } else {
                    setAgreements([]);
                }
            }
        //}

    };


    const onChangeAgreementsTypeIdentifier = (value) => {
        setSelectedIdentifiers(null);
        const agreementsByTypeIdentifier = agreements.find(agreement => agreement.code == value);
        if(agreementsByTypeIdentifier.identifierTypes && agreementsByTypeIdentifier.identifierTypes != null && agreementsByTypeIdentifier.identifierTypes.length > 0) {
            setIdentifierType(agreementsByTypeIdentifier.identifierTypes);
        } else {
            setIdentifierType([{code: '', label: ''}]);
        }
    }

    const onChangeIdentifierByAgreement = (typeSelectedIdentifier, setFieldValue, values) => {

        cleanDataDebt(setFieldValue);
        if(supply && supply != null) {
            setSupply(null)
        }

        if(supplyCatastro && supplyCatastro != null) {
            setSupplyCatastro(null)
        }

        if(aliasService && aliasService != null) {
            setAliasService(null)
        }
        setFieldValue("alias", '');

        if(!typeSelectedIdentifier.registrable && isNewPay) {
            setNonRegistrableService(true);
        }

        if (selectedIdentifiers !== typeSelectedIdentifier) {
            setSelectedIdentifiers(typeSelectedIdentifier);
            setRegexCatastro(typeSelectedIdentifier.regexp);
            setPlaceHolderCatastro(typeSelectedIdentifier.mask);

        }
        const { additionalData } = typeSelectedIdentifier;

        if (additionalData && additionalData.length > 0) {
            const additionalDataObject = {
                additionalData: additionalData
            };
            const additionalDataString = JSON.stringify(additionalDataObject);
            setFieldValue('extendedData', additionalDataString);
            setFieldValue('additionalData', additionalDataString);

        } else {
            setFieldValue('extendedData', '{}');
            setFieldValue('additionalData', '');
        }

        setFieldValue('identificationType',typeSelectedIdentifier.value );
        setFieldValue('identificationLabel', typeSelectedIdentifier.label);
        setFieldValue("imgFullName", null);
        const imgName = config.get(`servicePayments.${values.categoryCode}.${values.companyId}.${values.agreementId}.${typeSelectedIdentifier.value}`);
        if(imgName && imgName!= null) {
            const channel = isDesktop ? "web": "mobile";
            setFieldValue("imgFullName",`${channel}/${imgName}`);
        }

        if (
            typeSelectedIdentifier.value === IDENTIFIERS_MASK.CATASTRO.value
            //  &&
            // (!regexCatastro || !maskCatastro)
        ) {
            setIsSupplyCatastroActive(true);
            setRegexCatastro(typeSelectedIdentifier.regexp);
            let catastroMask = typeSelectedIdentifier.mask
                ? typeSelectedIdentifier.mask
                : IDENTIFIERS_MASK.mask;

            let catastroZeroMask = catastroMask;
            catastroMask=replaceZerosInMask(cleanMask(catastroMask));
            setMaskCatastro(catastroMask);

            let placeHolderCatastro = catastroZeroMask;//generatePlaceholderFromMask(catastroMask);
            setPlaceHolderCatastro(placeHolderCatastro);
        } else {
            setIsSupplyCatastroActive(false);
        }
    };

    const removeDuplicateAgreements = (agreements) => {
        const uniqueAgreements = agreements.filter((agreement, index, self) =>
          index === self.findIndex((a) => a.code === agreement.code)
        );
        return uniqueAgreements;
      };



   useEffect(() => {
        if(requestData && requestData.typeService != '') {
            if (mode === "edit") {
                setSelectedTypeService(requestData.typeService);
                dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData }));
            }
        }
    }, [mode, requestData])


    useEffect(() => {
        // Visualizar datos dinamicos en view
        if (
            transaction?.data?.biller?.billerId &&
            rest.match.params?.idTransaction &&
            transaction.idTransaction === rest.match.params?.idTransaction
        ) {
            // Telered -> consulta de reglas
            if (transaction?.data?.biller?.svcProviderId === "Telered") {
                dispatch(payServiceActions.listRulesBillerRequest(transaction?.data?.biller?.billerId));
            } else {
                // ARP -> definicion de reglas por defecto
                dispatch(payServiceActions.listRulesBillerSuccess(fieldARP));
            }
            // metodo de pago
            // setDebitPaymentMethod(transaction?.data?.debitPaymentMethod);

            // datos de categoria
            const category = getCategorySelected(transaction?.data?.biller.category);
            if (category.length > 0) {
                setCategorySelected(category[0]);
            }
        }
    }, [rest.match.params]);

    useEffect(() => {
        setIsErrorBalance(isErrorRequest);
    }, [isErrorRequest]);


    useEffect(() => {
        // no tiene productos para debito
        if(isNewPay && nonRegistrableService) {
            if (preDataForm?.code === "API040E") {
                dispatch(push("/servicePayments"));

                dispatch(
                    notificationActions.showNotification(i18n.get(`returnCode.${preDataForm?.code}`), "warning", [
                        "servicePayments",
                    ]),
                );
            }
        }
    }, [preDataForm]);

    const obtenerLongitudMaxima = (mascara, regexp) => {
        const result = {
            min: undefined,
            max: undefined,
        }

        let maxMask = 0;

        if (mascara) {
            const soloDigitos = mascara.replace(/[^0-9A-Za-z]/g, '');
            maxMask = soloDigitos.length;
            result.max = soloDigitos.length;
            // return result;
        }

        if (regexp) {
            let maxAlternativeLength = 0;
            // let minLength = 0;

            // Quitar los delimitadores de principio "^" y final "$" para analizar mejor
            const regexBody = regexp.replace(/^\^|\$$/g, '');
            const alternatives = regexBody.split('|');


            let partMinLength = 0;
            // Evaluar cada alternativa y calcular la longitud maxima
            alternatives.forEach((alternative) => {
                let partLength = 0;

                // Capturar y sumar cualquier rango de repeticion {x,y} o {y}
                const rangeMatches = [...alternative.matchAll(/\{(\d+),?(\d+)?\}/g)];
                if (rangeMatches.length > 0) {
                    rangeMatches.forEach((match) => {
                        const max = match[2] ? parseInt(match[2], 10) : parseInt(match[1], 10);
                        const min = match[2] ? parseInt(match[1], 10) : 0;
                        partMinLength += min;
                        partLength += max;
                    });
                } else {
                    // Contar secuencias literales sin repeticionn
                    const literalChars = [...alternative.matchAll(/[A-Za-z0-9]/g)];
                    partLength += literalChars.length;
                }

                // Verificar si esta alternativa es la de mayor longitud
                if (partLength > maxAlternativeLength) {
                    maxAlternativeLength = partLength;
                }
            });

            result.max = maxMask > maxAlternativeLength ? maxMask : maxAlternativeLength;
            result.min = partMinLength;
        }



        return result;
    };




    const  { max : maxLength, min: minLength } = obtenerLongitudMaxima(placeHolderCatastro, regexCatastro);
    const validationSchema = () => Yup.object().shape({
        supply: isSupplyCatastroActive
            ? Yup.string()
            : Yup.string()
            .nullable()
                .transform((value) => (value ? value.trim() : ''))
                // Validacion 1: Campo obligatorio
                .required(i18n.get("serviceRegistration.supply.emptyOrInvalid.error", "", {
                    identifier: selectedIdentifiers.label
                }))
                // Validacion 2: Longitud maxima
                .test(
                    'max-length',
                    minLength ? i18n.get("serviceRegistration.supply.rangeIdentifierSize.error", "", {
                        minLength,
                        maxLength,
                    }) : i18n.get("serviceRegistration.supply.maximumIdentifierSize.error", "", {
                        maxLength,
                    }),
                    function (value) {
                        if (!value) return true;
                        return value.length <= maxLength;
                    }
                )
                // Validacion 3: Caracteres especiales prohibidos (solo si el campo tiene algun valor)
                .test(
                    'no-special-chars',
                    i18n.get("serviceRegistration.supply.specialCharactersProhibited.error", "", {
                        identifier: selectedIdentifiers.label
                    }),
                    function (value) {
                        if (!value) return true;
                        return /^[a-zA-Z0-9]*$/.test(value);
                    }
                )
                // Validacion 4: Tipo de dato y formato basado en regexCatastro dinamico
                .test(
                    'regex-and-length',
                    i18n.get("serviceRegistration.supply.invalidIdentificationField.error", "", {
                        identifier: selectedIdentifiers.label
                    }),
                    function (value) {
                        if (!value) return true;
                        if (value.length > maxLength) return true;
                        const regexTest = new RegExp(regexCatastro).test(value);
                        if (!regexTest) {
                            return this.createError({
                                message: i18n.get("serviceRegistration.supply.invalidIdentificationField.error", null, {
                                    identifier: selectedIdentifiers.label
                                }),
                            });
                        }

                        return true;
                    }
                ),
        supplyCatastro: isSupplyCatastroActive
            ? Yup.string()
                .nullable()
                .transform((value) => value ? value.trim() : '')
                .required(i18n.get("serviceRegistration.supply.emptyOrInvalid.error", "", {
                    identifier: selectedIdentifiers.label
                }))
                .test(
                    'mask-complete',
                    i18n.get("serviceRegistration.supply.invalidIdentificationField.error", "", {
                        identifier: selectedIdentifiers.label
                    }),
                    function (value) {
                        if (!value) return false;
                        const isComplete = !value.includes('_') && value.length === maskCatastro.length;
                        return isComplete;
                    }
                )
            : Yup.string(),
        alias: nonRegistrableService ? Yup.string() : Yup.string()
            .required(i18n.get("serviceRegistration.alias.empty.error"))
            .trim()
            .min(1, i18n.get("serviceRegistration.alias.empty.error"))
            .matches(/^[a-zA-Z0-9]*$/, i18n.get("serviceRegistration.alias.invalid.error")),
        list: Yup.array().of(
            Yup.object().shape({
                debitPaymentMethod: Yup.string().required(i18n.get("global.field.required")),
                debitProduct: Yup.string().required(i18n.get("global.field.required")),
                creditCardPaymentType: Yup.string().when("debitPaymentMethod", {
                    is: "creditCard",
                    then: Yup.string().required(i18n.get("global.field.required")),
                    otherwise: Yup.string(),
                }),
            }),
        ),
    });

    const hasUserInteracted = useRef(false);
    const hasUserIdentifierInteracted = useRef(false);
    const hasUserAgreementInteracted = useRef(false);

    const handleFocus = () => {
        hasUserInteracted.current = true;
    };

    const handleBlur = () => {
        hasUserInteracted.current = false;
    };

    const handleIdentifierFocus = () => {
        hasUserIdentifierInteracted.current = true;
    };

    const handleIdentifierBlur = () => {
        hasUserIdentifierInteracted.current = false;
    };

    const handleAgreementFocus = () => {
        hasUserAgreementInteracted.current = true;
    };

    const handleAgreementBlur = () => {
        hasUserAgreementInteracted.current = false;
    };

    const goToServices = () => {
        dispatch(push("/servicePayments"));
    }

    const handlerCancelAction = () => {
        if (isNewPay) {
            setShowExitModal(true);
        } else {
            goToServices();
        }
    };

    const handlerPreviewCancelAction = () => {
        if (isNewPay) {
            setShowExitModal(true);
        } else {
            goToServices();
        }
    };

    const handleAcceptModal = () => {
        dispatch(push("/desktop"));
    };

    const handleHideModal = () => {
        setShowExitModal(false);
    };

    const renderTicket = () => {

        return (
            <>
            </>
        );
    };

    const fieldInputError = (error) =>
        error ? (
            <Box className="mt-2">
                <FieldError error={error} />
            </Box>
        ) : null;


    const renderHelpImage = (values) => {
        return (
            <>
                <Box display="flex" column alignY="between" className="scrollable-content px-0" fullWidth fullHeight>
                    <Box display="flex" column alignX="center" className="mb-auto">

                        <Box className="my-9">
                            <Image key="src" src={`images/serviceRegistration/${values.imgFullName}`} />
                        </Box>
                    </Box>
                </Box>

                <Box className="text-center mt-8">
                    <Button
                        bsStyle="outline"
                        label="global.return"
                        className="btn-width-lg"
                        onClick={() => {
                            setShowModalSeeHelp(false);
                        }}
                    />
                </Box>
            </>
        );
    };


    const handleChangeSupply = (value, setFieldValue, errors, setErrors) => {

        if (errors?.supply) {
            delete errors.supply;
        }
        if (errors?.supplyCatastro) {
            delete errors.supplyCatastro;
        }

        if(isSupplyCatastroActive) {
            setSupplyCatastro(value);
            setFieldValue("supplyCatastro", value);
            setFieldValue('identificationValue', value);
            setFieldValue("supply", '');
        } else {
            setSupply(value);
            setFieldValue("supply", value);
            setFieldValue("supplyCatastro", '');
            setFieldValue('identificationValue', value);
        }
    };

    const handleChangeAlias = (value, setFieldValue) => {
        setAliasService(value)
        setFieldValue("alias", value);
    }

    const onFinishCheckDebt = ({service, debt}) => {
        const servicePay = { ...service, debt, hasDue: true, amountPay: debt.total };
        dispatch(payServiceActions.setServicePayment(servicePay, undefined, 1, ""));
        dispatch(push("/servicePayments/form"));

    }

    const payRegisteredService = (category, identification) => {
        dispatch(payServiceActions.clean());
        dispatch(payServiceActions.newPayService(category, identification, onFinishCheckDebt));
        dispatch(replace(`/servicePayments/registered/${category}`));
    };

    const handleCancelserviceRegistration = (setErrors) => {
        setAlreadyRegistered(false);
        setErrors({})
    }

    const handleValidateNonRegistrableService =async  (values, setValues, setErrors, errors) => {
        if (mode !== "edit") {
            return;
        }

        // Elimina los errores relacionados con `list`
        if (errors?.supply) {
            delete errors.supply;
        }
        if (errors?.supplyCatastro) {
            delete errors.supplyCatastro;
        }
        
        const errorsList = validateForm(values);

        if (Object.keys(errorsList).length > 0) {
            setErrors(errorsList); // Muestra los errores generados por validateForm
            setShowSuccessMessage(false);
            return;
        }

        setErrors({});
        setShowSuccessMessage(true);
        const requestData = {  ...values };
        dispatch(payServiceActions.nonRegisteredServicePaymentRequest(requestData, values, setValues ));
    };

    const renderFieldsNonRegisteredService = (errors, setFieldValue, values, setErrors, setValues) => {
        return (
        <>
            <Col xs={isDesktop ? 10 : 8} md={isDesktop ? 10 : 8}>
                <Box className="form-group">
                    <Box display="flex" alignY="center" className="data-label-special-mb">
                        <Text
                            component="label"
                            htmlFor="react-select-accountBy-input"
                            className="data-label no-ellipsis"
                            size="5"
                            bold
                            defaultValue={selectedIdentifiers && selectedIdentifiers.helpText}
                        />
                    </Box>

                    <Box>
                        { (maskCatastro && maskCatastro != null && isSupplyCatastroActive) ?

                            <CatastroInputField
                                name="supplyCatastro"
                                mask={maskCatastro}
                                value={supplyCatastro}
                                onChange={(e) => handleChangeSupply(e.target.value, setFieldValue, errors, setErrors )}
                                error={errors.supplyCatastro ? errors.supplyCatastro : errors.identificationValue}
                                isRequired={true}
                                disabled={isNewPay && nonRegistrableService ? fetchingNonRegisTeredServicePayment : false}
                            />

                        :
                            <>
                                <TextSimpleField
                                    name="supply"
                                    value={supply}
                                    id="supply"
                                    bold
                                    error={errors.supply  ? errors.supply : errors.identificationValue}
                                    placeholder={selectedIdentifiers?.mask ?  selectedIdentifiers?.mask  : ""}
                                    onChange = {(e) => handleChangeSupply(e.target.value, setFieldValue, errors, setErrors)}
                                    disabled={isNewPay && nonRegistrableService ? fetchingNonRegisTeredServicePayment : false}
                                />
                            </>
                        }
                    </Box>
                </Box>
            </Col>
            <Col xs={isDesktop ? 2 : 4} md={isDesktop ? 2 : 4}>
                <Box className="form-group">
                    <Box display="flex" alignY="center">
                        <Button
                            bsStyle="primary"
                            label="nonRegisteredServicePayment.form.validate.label"
                            loading={fetchingNonRegisTeredServicePayment}
                            block
                            onClick={() => handleValidateNonRegistrableService(values, setValues, setErrors, errors)}/>
                    </Box>
                </Box>
            </Col>

            {values && values.debt && showSuccessMessage && !fetchingNonRegisTeredServicePayment && (
                <>
                    <Box
                        fullWidth
                        background="white"
                        borderRadius="lg"
                        className={ isMobile ? "form-service-payments pt-7 mb-5" : "form-service-payments pt-7 mb-3"}>
                        <Box background="background-disabled" className="p-5" borderRadius="lg">
                            <Col xs={12} md={9}>
                                <Row
                                    className={classNames({
                                        "row-template-1fr gap-0": !isDesktop,
                                    })}>
                                    <Col xs={12} md={3}>
                                        <Box
                                            className={classNames({
                                                "py-4": isDesktop,
                                            })}>
                                            <Info
                                                labelKey="nonRegisteredServicePayment.pay.name.label"
                                                text={values.debt.clientName}
                                                classNameValue="size-6"
                                                flex={!isDesktop}
                                            />
                                        </Box>

                                    </Col>
                                    <Col xs={12} md={3}>

                                        <Box
                                            className={classNames({
                                                "py-4": isDesktop,
                                            })}>
                                            <Info
                                                labelKey="nonRegisteredServicePayment.pay.debtAmount.label"
                                                classNameValue="size-6"
                                                flex={!isDesktop}/>
                                                    <FormattedAmount bold size="6" quantity={values.debt && values.debt.total} noCurrency />
                                        </Box>

                                    </Col>
                                    <Col xs={12} md={3}>

                                        <Box
                                            className={classNames({
                                                "py-4": isDesktop,
                                            })}>
                                                <Info
                                                    labelKey={"nonRegisteredServicePayment.form.commission.label"}
                                                    classNameValue="size-6"
                                                    flex={!isDesktop}
                                                />
                                                <FormattedAmount bold size="6" quantity={values.list && values.list[0].commission} noCurrency />
                                        </Box>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Box
                                            className={classNames({
                                                "py-4": isDesktop,
                                            })}>
                                        </Box>
                                    </Col>
                                </Row>
                            </Col>
                        </Box>
                    </Box>
                </>
            )}
        </>
    )

    }

    const renderPayServiceForm = (errors, setFieldValue, values, setErrors ) => {

        return (
            <Box
                    borderRadius="lg"
                    className={classNames("mt-7", {
                        "box-shadow-small p-7": isDesktop,
                    })}>
                        <Box className="mt-5">
                            <Text bold labelKey={`${ID_FORM_PAY}.data.pay`} />
                        </Box>
                        <PayServiceForm
                            index={0}
                            values={values}
                            errors={errors}
                            setErrors={setErrors}
                            genericProps={genericProps}
                            ID_FORM={ID_FORM_PAY}
                            accountOptions={values.accounts}
                            creditCardOptions={values.creditCards}
                            setFieldValue={setFieldValue}
                            isDesktop={isDesktop}
                            showFieldAmount
                        />
                    </Box>
        );
    }

    const initialIdActivity = (isNewPay, nonRegistrableService) => {
        if(isNewPay && nonRegistrableService){
            return ID_ACTIVITY_NON_REGISTERED_SERVICE;
        } else {
            return ID_ACTIVITY;
        }
    }

    const renderConfirmation = ({
        category,
        companyLabel,
        agreementLabel,
        identificationLabel,
        identificationValue,
        amountPay,
        commission,
        description,
        debitPaymentMethod,
        debitProductLabel,
        creditCardPaymentTypeLabel,
        debt: { clientName },
    },
    idForm
    ) => {
        return (
        <>
            <TicketData.Main label={`${idForm}.amountPay`} value={amountPay} currency={USD_CURRENCY} type="amount" />

            <Box>
                <Box className="mt-3 mb-4">
                    <Text size="6" bold labelKey="paymentService.pay.send.service.title" />
                </Box>
                <TicketData.Data
                    label={`${idForm}.category`}
                    value={i18n.get(`servicePayment.category.${category}`)}
                />
                <TicketData.Data label={`${PREFIX}.service`} value={companyLabel} />
                <TicketData.Data label={`${idForm}.convenio`} value={agreementLabel} />
                <TicketData.Data label={`${idForm}.identificationType`} value={identificationLabel} />
                <TicketData.Data label={identificationLabel} value={identificationValue} />
                <TicketData.Data label={`${idForm}.titular`} value={clientName} />

                <Box className="mt-5 mb-4">
                    <Text size="6" bold labelKey={`${idForm}.data.pay`} />
                </Box>

                {debitPaymentMethod === "account" ? (
                    <TicketData.Data label={`${idForm}.debitAccount`} value={debitProductLabel} />
                ) : (
                    <>
                        <TicketData.Data label={`${idForm}.debitCreditCard`} value={debitProductLabel} />
                        <TicketData.Data
                            label={`${idForm}.creditCard.paymentType`}
                            value={creditCardPaymentTypeLabel}
                        />
                    </>
                )}
                <TicketData.Data label={`${idForm}.description`} value={description || "-"} />
                <TicketData.Data label={`${idForm}.rate`} value={commission} />
            </Box>
        </>
        )
    };

    const validateForm = (values) => {
        const errors = {};
        const messageRequired = i18n.get('serviceRegistration.supply.emptyOrInvalid.error', "", {
            identifier: selectedIdentifiers.label
        });

        // Validacion condicional para supply
        if (!isSupplyCatastroActive) {
            if (!values.supply || values.supply.trim() === '') {
                errors.supply = messageRequired;
                return errors;
            } else {
                const trimmedValue = values.supply.trim();
                // Longitud maxima
                if (trimmedValue.length > maxLength) {
                    errors.supply = i18n.get('serviceRegistration.supply.maximumIdentifierSize.error', '', {
                        maxLength,
                    });
                    return errors;
                }

                // Validación de longitud exacta
                if (trimmedValue.length !== maxLength) {
                    errors.supply = i18n.get('serviceRegistration.supply.invalidIdentificationField.error', '', {
                        identifier: selectedIdentifiers.label
                    });

                    return errors;
                }

                // Caracteres especiales prohibidos
                if (!/^[a-zA-Z0-9]*$/.test(trimmedValue)) {
                    errors.supply = i18n.get('serviceRegistration.supply.specialCharactersProhibited.error', "", {
                        identifier: selectedIdentifiers.label
                    });
                    return errors;
                }

                // // Validacion de regex dinamica
                if (regexCatastro && !new RegExp(regexCatastro).test(trimmedValue)) {
                    errors.supply = i18n.get("serviceRegistration.supply.invalidIdentificationField.error", "", {
                        identifier: selectedIdentifiers.label
                    });
                    return errors;
                }
            }
        }

        // Validacion condicional para supplyCatastro
        if (isSupplyCatastroActive) {
            if (!values.supplyCatastro || values.supplyCatastro.trim() === '') {
                errors.supplyCatastro = messageRequired;
                return errors;
            } else {
                const value = values.supplyCatastro;

                // Verificar si el valor coincide con el formato de la mascara
                if (value.includes('_') || value.length !== maskCatastro.length) {
                    errors.supplyCatastro = messageRequired;
                }
                return errors;
            }
        }
        return errors;
    };

    const getComissionValue = (debt) => {
        const commissionData = debt.additionalData?.find(item => item.code === "valorComision");
        return commissionData ? parseFloat(commissionData.value) : 0;

    }

    const hasBills = (values) => {
        return (
            values.list &&
            values.list[0] &&
            values.list[0].debt &&
            values.list[0].debt.bills &&
            values.list[0].debt.bills.length > 0
        );
    };

    const renderFields = (setFieldValue, values, setValues, renderScheduler, errors, setErrors) => {

        if (mode !== "view" && fetchingValidations) {
            return <PageLoading loading classicStyle={false} />;
        }

        if (mode === "edit") {
            if(isNewPay && nonRegistrableService) {
                if(showSuccessMessage && !fetchingNonRegisTeredServicePayment && values.payService) {
                    setEnabledSubmit(true);
                }
                else{
                    setEnabledSubmit(false);
                }
                
            } else {
                if(validateFormServiceRegistration(values)) {
                    setEnabledSubmit(true);
                } else {
                    setEnabledSubmit(false);
                }
            }

        }

        if (isNewPay && mode === "edit" && errors && errors.alreadyRegistered) {
            //alerta de servicio ya matriculado
            setAlreadyRegistered(true);
        }
        return (
            <>
                {mode === "edit" && (
                    <>
                        <Box
                            fullWidth
                            background="white"
                            borderRadius="lg"
                            className={ isMobile ? "form-service-payments pt-7 mb-5" : "box-shadow-small form-service-payments p-7 mb-7"}>
                            <Box {...isDesktop && { borderRadius: "lg", className: "box-shadow-small p-6" }} >

                            <Box>
                                <Text bold labelKey={`${ID_FORM}.info`} />
                            </Box>
                            <Col xs={12} md={9}>
                                <Box>
                                    <Row alignY="flex-end" gapX={7} gapY={3}>
                                        <Col xs={12} md={6}>
                                            <Box className="form-group form-group--select">
                                                <Box display="flex" alignY="center" className="data-label-special-mb">
                                                    <Text
                                                        component="label"
                                                        htmlFor="react-select-accountBy-input"
                                                        labelKey="serviceRegistration.serviceType.label"
                                                        className="data-label"
                                                        size="5"
                                                        bold
                                                    />
                                                </Box>
                                                <Box className="input-group">
                                                    <Select
                                                        id="accountBy"
                                                        name="accountBy"
                                                        label="transactions.list.filter.accountBy.label"
                                                        onChange={(option) => {
                                                            //setSelectedTypeService(option.value);
                                                            onChangeServiceType(option.label, option.value, setFieldValue);
                                                        }}
                                                        options={serviceOptions}
                                                        value={selectedTypeService}
                                                        placeholder={i18n.get("serviceRegistration.placeholder.select.label")}
                                                        disabled={isNewPay && nonRegistrableService ? fetchingNonRegisTeredServicePayment : false}
                                                    />
                                                </Box>
                                            </Box>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Box className="form-group form-group--select">
                                                <Box display="flex" alignY="center" className="data-label-special-mb">
                                                    <Text
                                                        component="label"
                                                        htmlFor="react-select-companyId-input"
                                                        labelKey="serviceRegistration.serviceCompany.label"
                                                        className="data-label"
                                                        size="5"
                                                        bold
                                                    />
                                                </Box>
                                                <Box className="input-group">
                                                    <Select
                                                        key={JSON.stringify(companyServiceOptions || {})}
                                                        id="companyId"
                                                        name="companyId"
                                                        label="transactions.list.filter.accountBy.label"
                                                        onFocus={handleFocus}
                                                        onBlur={handleBlur}
                                                        onChange={(option) => {
                                                            if (option.label !== "" && option.value !== "" && hasUserInteracted.current) {
                                                                onChangeServiceAgreement(option.value);
                                                                setSelectedServiceCompany(option.value);
                                                                setFieldValue('companyId',option.value )
                                                                setFieldValue('companyLabel', option.label);
                                                                setSelectedIdentifiers(null);
                                                                setSelectedAgreements(null);
                                                                setFieldValue("alias", '');
                                                                setFieldValue('agreementId', '' );
                                                                setFieldValue('agreementLabel', '');
                                                                cleanDataDebt(setFieldValue);
                                                            }
                                                        }}
                                                        options={companyServiceOptions}
                                                        value={selectedServiceCompany || null}
                                                        placeholder={i18n.get("serviceRegistration.placeholder.select.label")}
                                                        disabled={isNewPay && nonRegistrableService ? fetchingNonRegisTeredServicePayment : false}
                                                    />
                                                </Box>
                                            </Box>
                                        </Col>
                                    </Row>
                                    <Box className="position-relative">
                                        {fetchingPre && <Loader bgTransparent />}
                                    </Box>
                                    {selectedServiceCompany != null ?
                                    <Row alignY="flex-end" gapX={7} gapY={3}>
                                    <Col xs={12} md={6}>
                                        <Box className="form-group form-group--select">
                                            <Box display="flex" alignY="center" className="data-label-special-mb">
                                                <Text
                                                    component="label"
                                                    htmlFor="react-select-accountBy-input"
                                                    labelKey="serviceRegistration.agreement.label"
                                                    className="data-label"
                                                    size="5"
                                                    bold
                                                />
                                            </Box>
                                            <Box className="input-group">
                                                <Select
                                                    key={JSON.stringify(agreementsOptions || {})}
                                                    id="accountBy"
                                                    name="accountBy"
                                                    label="transactions.list.filter.accountBy.label"
                                                    onFocus={handleAgreementFocus}
                                                    onBlur={handleAgreementBlur}
                                                    onChange={(option) => {
                                                        //setSelectedAgreements(option.value);
                                                        if (option.label !== "" && option.value !== "" && hasUserAgreementInteracted.current) {
                                                            onChangeAgreementsTypeIdentifier(option.value);
                                                            setFieldValue('agreementId',option.value )
                                                            setFieldValue('agreementLabel', option.label);
                                                            setSelectedAgreements(option.value);
                                                            setFieldValue('identificationType', '');
                                                            setFieldValue('identificationLabel', '');
                                                            setSelectedIdentifiers(null);
                                                            setErrors({});
                                                            cleanDataDebt(setFieldValue);
                                                        }
                                                    }}
                                                    options={agreementsOptions}
                                                    value={selectedAgreements}
                                                    placeholder={i18n.get("serviceRegistration.placeholder.select.label")}
                                                    noDataText="serviceRegistration.identifierType.nonRegistrableService"
                                                    disabled={isNewPay && nonRegistrableService ? fetchingNonRegisTeredServicePayment : false}
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Box className="form-group form-group--select">
                                            <Box display="flex" alignY="center" className="data-label-special-mb">
                                                <Text
                                                    component="label"
                                                    htmlFor="react-select-filterType-input"
                                                    labelKey="serviceRegistration.identifierType.label"
                                                    className="data-label"
                                                    size="5"
                                                    bold
                                                />
                                            </Box>
                                                <Box className="input-group">
                                                    <Select
                                                        key={JSON.stringify(identifierTypeOptions || {})}
                                                        id="filterType"
                                                        name="filterType"
                                                        onFocus={handleIdentifierFocus}
                                                        onBlur={handleIdentifierBlur}
                                                        label="transactions.list.filter.accountBy.label"
                                                        onChange={(option) => {
                                                            if (option) {
                                                                if( option.label && option.value && hasUserIdentifierInteracted.current) {
                                                                    onChangeIdentifierByAgreement(option, setFieldValue, values);
                                                                }
                                                            }
                                                        }}
                                                        options={identifierTypeOptions}
                                                        value={selectedIdentifiers}
                                                        placeholder={i18n.get("serviceRegistration.placeholder.select.label")}
                                                        disabled={
                                                                !selectedAgreements || 
                                                                (!isNewPay && nonRegistrableService) || 
                                                                (isNewPay && nonRegistrableService && fetchingNonRegisTeredServicePayment)
                                                        }
                                                    />
                                                    {/* Matriculacion - Servicio no Matriculable */}
                                                    {(!isNewPay && nonRegistrableService) && fieldInputError(nonRegistrableServiceMessage)}
                                                </Box>
                                        </Box>
                                    </Col>
                                </Row>
                                    : null }

                                    {(isNewPay && nonRegistrableService) && (
                                        <Row alignY="flex-end" gapX={7} gapY={3}>
                                            {renderFieldsNonRegisteredService(errors, setFieldValue, values, setErrors, setValues)}
                                        </Row>
                                    )}

                                </Box>
                            </Col>
                            </Box>

                            {(selectedIdentifiers && selectedIdentifiers.value != null && !(isNewPay && nonRegistrableService)) && (
                                <Box {...(isDesktop && { borderRadius: "lg", className: "box-shadow-small p-6 pb-7" })}>
                                    {(isNewPay && !nonRegistrableService) && (
                                        <Box background="background-disabled" borderRadius="lg" className="mt-6">
                                            <Box display="flex" className="p-5">
                                                <Box>
                                                    <Image key="src" src="images/advice-icon.svg" />
                                                </Box>
                                                <Box className="ml-4">
                                                    <Box className="mt-2">
                                                        <Text labelKey="serviceRegistration.newPay.enrollable.title" bold />
                                                    </Box>
                                                    <Box className="mt-2">
                                                        <Text
                                                            size="6"
                                                            color="text-grey-color"
                                                            labelKey="serviceRegistration.newPay.enrollable.text"
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                    <Col xs={12} md={9}>
                                        <Box>
                                            <Row alignY="flex-end" gapX={7} gapY={3}>
                                                {(!isNewPay || !nonRegistrableService) && (
                                                    <>
                                                        <Col xs={12} md={6}>
                                                            <Box className="form-group">
                                                                <Box display="flex" alignY="center" className="data-label-special-mb">
                                                                    <Text
                                                                        component="label"
                                                                        htmlFor="react-select-accountBy-input"
                                                                        className="data-label"
                                                                        size="5"
                                                                        bold
                                                                        defaultValue={selectedIdentifiers.label}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    {maskCatastro && maskCatastro != null && isSupplyCatastroActive ? (
                                                                        <CatastroInputField
                                                                            name="supplyCatastro"
                                                                            mask={maskCatastro}
                                                                            value={supplyCatastro}
                                                                            onChange={(e) => handleChangeSupply(e.target.value, setFieldValue, errors, setErrors)}
                                                                            error={errors.supplyCatastro ? errors.supplyCatastro : errors.identificationValue}
                                                                            isRequired={true}
                                                                        />
                                                                    ) : (
                                                                        <TextSimpleField
                                                                            name="supply"
                                                                            value={supply}
                                                                            id="supply"
                                                                            bold
                                                                            error={errors.supply ? errors.supply : errors.identificationValue}
                                                                            placeholder={
                                                                                selectedIdentifiers?.mask ? selectedIdentifiers?.mask : ""
                                                                            }
                                                                            onChange={(e) => handleChangeSupply(e.target.value, setFieldValue, errors, setErrors)}
                                                                        />
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            <Box className="form-group">
                                                                <Box display="flex" alignY="center" className="data-label-special-mb">
                                                                    <Text
                                                                        component="label"
                                                                        htmlFor="react-select-accountBy-input"
                                                                        className="data-label"
                                                                        size="5"
                                                                        bold
                                                                        labelKey="serviceRegistration.alias.input.label"
                                                                    />
                                                                </Box>
                                                                <Box className="input-group">
                                                                    <TextSimpleField
                                                                        name="alias"
                                                                        id="alias"
                                                                        bold
                                                                        placeholder={i18n.get("serviceRegistration.alias.placeholder.label")}
                                                                        error={errors?.alias}
                                                                        onChange={(e) => handleChangeAlias(e.target.value, setFieldValue)}
                                                                        value={aliasService}
                                                                        maxLength="20"
                                                                    />
                                                                    {existsAlias && fieldInputError(aliasAlreadyExistsMessage)}
                                                                </Box>
                                                            </Box>
                                                        </Col>
                                                    </>
                                                )}
                                            </Row>
                                        </Box>
                                    </Col>

                                    {values && values.imgFullName && (
                                        <Col xs={12} md={6}>
                                            <Box display="flex" className="mt-3" fullWidth>
                                                <Box display="flex" alignX="center" className="mr-2">
                                                    <Image src="images/icons/info.svg" />
                                                </Box>
                                                <Box>
                                                    <Text
                                                        color="text-secondary"
                                                        defaultValue={i18n.get("serviceRegistration.information.findCode.label")}
                                                        className="mt-8 mb-8"
                                                        size="6"
                                                    />
                                                    <Button
                                                        primaryLink
                                                        bsStyle="link"
                                                        defaultLabelText={i18n.get("serviceRegistration.information.findCode.redirectLink")}
                                                        className="px-2"
                                                        onClick={() => {
                                                            setShowModalSeeHelp(true);
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <SideBarModal
                                                show={showModalSeeHelp}
                                                dialogClassName="modal-detail-pay-service"
                                                onClose={() => {
                                                    setShowModalSeeHelp(false);
                                                }}
                                            >
                                                <Box className="px-9">
                                                    {showModalSeeHelp && <>{renderHelpImage(values)}</>}
                                                </Box>
                                            </SideBarModal>
                                        </Col>
                                    )}
                                </Box>
                            )}

                            <ConfirmDialog
                                key={alreadyRegistered ? "dialog-open" : "dialog-closed"}
                                showDialog={alreadyRegistered}
                                title={i18n.get("serviceRegistration.newPay.modal.title")}
                                description=""
                                onConfirm={() => payRegisteredService(values.categoryCode, values.identificationValue)}
                                onCancel={() => handleCancelserviceRegistration(setErrors)}
                                confirmLabel="servicePayments.registeredServices.list.btn.pay"
                                continueLabel="global.cancel"
                            />
                            {isNewPay && nonRegistrableService  && values && values.payService && showSuccessMessage && !fetchingNonRegisTeredServicePayment && (
                                <>
                                    {hasBills(values) && (
                                        <>{renderBills(values.list[0], isDesktop)}</> 
                                    )}

                                    <Row alignY="flex-end" gapX={7} gapY={3}>
                                        {renderPayServiceForm(errors, setFieldValue, values, setErrors)}
                                    </Row>
                                </>
                            )}
                        </Box>
                        
                    </>

                )}
                {mode === "preview" || mode === "view" ? (

                    (isNewPay && nonRegistrableService) ? (
                        renderConfirmation(values.list[0], "servicePayments.pay")
                    ) : (
                    <>
                        <Box background="background-disabled" className="p-5 mb-5" borderRadius="lg">
                            <Row>
                                <Col xs={6} className="d-flex align-items-center">
                                    <Text labelKey={`serviceRegistration.summary.header.serviceType`} />
                                </Col>
                                <Col xs={6}>
                                    <Box display="flex" fullWidth className="size-0 text-semibold justify-content-end">
                                        {values && values.category}
                                    </Box>
                                </Col>
                            </Row>
                        </Box>
                        <Box>
                            <Box className="mb-6">
                                <Text size="8" bold labelKey={`${ID_FORM}.serviceData.label`} />
                            </Box>

                            <Box  fullWidth className="pt-3 pb-5 mt-n-min">
                                <Row  className="p-5 mb-5">
                                    <Col xs={6} className="d-flex align-items-center">
                                        <Text labelKey={`${ID_FORM}.serviceCompany.label`} />
                                    </Col>
                                    <Col xs={6}>
                                        <Box display="flex" fullWidth className="size-0 text-semibold justify-content-end">
                                            {values && values.companyLabel}
                                        </Box>
                                    </Col>
                                </Row>
                                <Row className="p-5 mb-5">
                                    <Col xs={6} className="d-flex align-items-center">
                                        <Text labelKey={`${ID_FORM}.agreement.label`} />
                                    </Col>
                                    <Col xs={6} className="d-flex justify-content-end">
                                        <Box className={isMobile ? "size-0 text-semibold" : "text-no-wrap size-0 text-semibold"}>
                                            {values && values.agreementLabel}
                                        </Box>
                                    </Col>
                                </Row>
                                <Row  className="p-5 mb-5">
                                    <Col xs={6} className="d-flex align-items-center">
                                        <Text labelKey={`${ID_FORM}.identifierType.label`} />
                                    </Col>
                                    <Col xs={6}>
                                        <Box display="flex" fullWidth className="size-0 text-semibold justify-content-end">
                                            {values && values.identificationLabel}
                                        </Box>
                                    </Col>
                                </Row>
                                <Row className="p-5 mb-5">
                                    <Col xs={6} className="d-flex align-items-center">
                                        <Text labelKey={`${ID_FORM}.supplyNumber.label`} />
                                    </Col>
                                    <Col xs={6}>
                                        <Box display="flex" fullWidth className="size-0 text-semibold justify-content-end">
                                            {values && values.identificationValue}
                                        </Box>
                                    </Col>
                                </Row>
                                <Row  className="p-5 mb-5">
                                    <Col xs={6} className="d-flex align-items-center">
                                        <Text labelKey={`${ID_FORM}.alias.label`} />
                                    </Col>
                                    <Col xs={6}>
                                        <Box display="flex" fullWidth className="size-0 text-semibold justify-content-end">
                                            {values && values.alias}
                                        </Box>
                                    </Col>
                                </Row>

                                </Box>
                            </Box>

                        <SideBarModal
                            show={showModalDetail}
                            dialogClassName="modal-detail-pay-service"
                            onClose={() => {
                                setShowModalDetail(false);
                            }}
                            // title={`${ID_FORM}.detail.title`}
                        >
                            <Box className="px-9">{showModalDetail && <>{renderHelpImage(values)}</>}</Box>
                        </SideBarModal>
                    </>
                    )

                ) : null}
                        <ExitModal
                            modalShow={showExitModal}
                            acceptFunction={handleAcceptModal}
                            cancelFunction={handleHideModal}
                            headingText={i18n.get("confirm.exit.title")}
                            text={i18n.get("confirm.exit.info")}
                        />
            </>
        );
    };

    const formProps = {
        title: isNewPay ? TITLE_NEW_PAY_FORM : TITLE_FORM,
        metadata: isNewPay && nonRegistrableService ? getMetaData(payServiceData, ID_ACTIVITY_NON_REGISTERED_SERVICE): metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        ticketConfirmation: true,
        cancelAction: handlerCancelAction,
        isCustom: true,
        idActivity: initialIdActivity(isNewPay, nonRegistrableService),
        showSchedulerMessage: false,
        // validate: validateForm,
        titleFormConfirmation: "OTP CODE",
        data: initialValuesServiceRegistration(props, isNewPay),
        submitButtonLabel: "forms.servicePayment.confirmation.button",
        validationSchema,
        textBack: "serviceRegistration.returnToPaymentsServices",
        textBackConfirmation: "global.return",
        submitDisabled: !enabledSubmit,
        showFormAcions: true,
        errors: {},
        handleCancelPreview: handlerPreviewCancelAction,
        pathBackEditMode: "/servicePayments",
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    fetching: payServiceSelectors.isFetching(state),
    field: payServiceSelectors.getField(state),
    massivePayService: payServiceSelectors.getMassivePayService(state),
    selectedBiller: payServiceSelectors.getSelectedBiller(state),
    queryBill: payServiceSelectors.getQueryBill(state),
    fetchingForm: formSelectors.getFetching(state),
    fetchingPre: formSelectors.getFetchingPre(state),
    preDataForm: formSelectors.getPreData(state),
    currentLang: i18nSelectors.getLang(state),
    transaction: formSelectors.getTransaction(state),
    mode: formSelectors.getMode(state),
    previewData: formSelectors.getPreviewData(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
    fetchingValidations: formSelectors.getFetchingValidations(state),
    validationData: formSelectors.getValidationData(state),
    fetchingNonRegisTeredServicePayment: payServiceSelectors.isFetchingNonRegisTeredServicePayment(state),
    payServiceData: payServiceSelectors.getPayServiceData(state),
});


ServiceRegistrationForm.propTypes = {
    history: shape({}).isRequired,
    isDesktop: bool,
    mode: string,
    currentLang: string,
    preDataForm: shape({}),
    previewData: shape({}),
    validationData: shape({}),
    fromBackoffice: bool,
    subtitle: string,
    subtitlePrefix: string,
    fetching: bool,
    fetchingPre: bool,
    field: arrayOf(shape({})),
    selectedBiller: shape({}),
    queryBill: shape({}),
    fetchingForm: bool,
    dispatch: func.isRequired,
    transaction: shape({}),
    usesJointAccount: bool,
    aliasExists: bool,
    checkAliasExists: func.isRequired,
};

ServiceRegistrationForm.defaultProps = {
    fromBackoffice: false,
    mode: "edit",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    subtitle: true,
    subtitlePrefix: true,
    fetching: false,
    fetchingPre: false,
    isDesktop: false,
    field: [],
    selectedBiller: null,
    queryBill: null,
    fetchingForm: false,
    transaction: null,
    fetchingList: false,
    usesJointAccount: false,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(ServiceRegistrationForm));
