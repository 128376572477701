import { call, put, takeLatest } from "redux-saga/effects";
import { routerActions } from "react-router-redux/actions";

import * as form from "middleware/form";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { credentialsToUnderscoreFormat } from "util/form.js";
import { types as secondFactorTypes } from "reducers/secondFactor";
import { actions as sessionActions } from "reducers/session";

const loadChannelsRequest = (middleware, actions) =>
    function* loadChannelsRequestSaga({ id }) {
        const response = yield call(middleware.loadChannelsRequest, id);

        if (response.type === "W") {
            yield put(actions.loadChannelsFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
            );
        } else {
            yield put(actions.loadChannelsSuccess({ ...response.data.data }));
        }
    };

const mapChannelsFormData = ({ idUser, caps, capsProd }) => ({
    idUser,
    ...Object.entries(caps).reduce(
        ({ enabledChannels, maxAmounts, capFrequencies }, [channel, { amount, frequency }]) => ({
            enabledChannels: [...enabledChannels, channel],
            maxAmounts: [...maxAmounts, amount],
            capFrequencies: [...capFrequencies, frequency],
        }),
        {
            enabledChannels: [],
            maxAmounts: [],
            capFrequencies: [],
        },
    ),
    capsProd,
});

const updateChannelsPreview = (middleware, actions) =>
    function* updateChannelsPreviewSaga({ data, setSubmitting }) {
        const response = yield call(middleware.updateChannelsPreview, mapChannelsFormData(data));

        setSubmitting(false);

        if (response.type === "W") {
            const errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

            yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.medium.modify.channels.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.onSuccess(data.idUser));
            yield put(actions.updateChannelsPreviewSuccess(data.caps, credentialGroups, null, null, data.capsProd));
        }
    };

const updateChannelsRequest = (middleware, actions) =>
    function* updateChannelsRequestSaga({ data, formikBag }) {
        const { secondFactor, ...restOfParams } = data;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(secondFactor);
        const response = yield call(middleware.updateChannelsRequest, {
            ...credentialsWithUnderscore,
            ...mapChannelsFormData(restOfParams),
            capsInfo: data.caps,
            fullName: data.fullName,
            documentType: data.documentType,
            documentNumber: data.documentNumber,
        });
        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);

        if (response.type === "W") {
            const { code, data } = response.data;
            let showNotification = true;
            if (code === "COR020W" && data.secondFactor) {
                yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
                showNotification = false;
            }
            if (code === "API707W") {
                yield put(sessionActions.logoutUserBlocked());
                return;
            }
            if (code === "API708W") {
                yield put(
                    notificationActions.showNotification(i18n.get("secondFactor.credential.otp.expired", "Código OTP expirado, solicite un nuevo código OTP"), "warning", [
                        "administration",
                    ]),
                );
                return;
            }
            const errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

            setErrors(response.data.data);
            if (showNotification) {
                yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
            }
        } else {
            if (response.data.code === "COR023I") {
                const message = i18n.get("administration.limits.channels.update.pending.messages", data.message);
                yield put(notificationActions.showNotification(message, "warning", ["administration"]));
            } else {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("administration.limits.channels.update.success"),
                        "success",
                        ["administration"],
                    ),
                );
            }

            yield put(routerActions.go(-2));
            yield put(actions.updateChannelsRequestSuccess());
        }
    };

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.LOAD_CHANNELS_REQUEST, loadChannelsRequest(middleware, actions)),
    takeLatest(types.UPDATE_CHANNELS_PREVIEW, updateChannelsPreview(middleware, actions)),
    takeLatest(types.UPDATE_CHANNELS_REQUEST, updateChannelsRequest(middleware, actions)),
];

export default sagasCreator;
