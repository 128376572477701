import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import { arrayOf, bool, func, node, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectors as softTokenSelector } from "reducers/softToken";
import { selectors as secondFactorSelectors } from "reducers/secondFactor";
import { isMobileNativeFunc } from "util/device";
import { ENT000, generateOTP } from "util/softToken.util";
import { USER_TOKEN_STATUS_ACTIVE, USER_TOKEN_STATUS_LOCKED_ENTRUST } from "util/userToken.util";
import * as config from "util/config";
import * as i18n from "util/i18n";
import { Field } from "formik";
import PinInput from "pages/_components/fields/PinInput";
import { SECOND_FACTOR_CONST, SECOND_FACTOR_METHOD_CONST } from "util/secondFactorUtils";
import * as secondFactorUtils from "util/secondFactorUtils";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import PopOver from "pages/_components/PopOver";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import { replace } from "react-router-redux";

const FORM_ID = "secondFactor.credential";

const DIGITAL_KEY_LENGTH = config.getInteger("credential.softHardtoken.length", 8);

const SoftHardTokenValidation = ({
    isDesktop,
    credentialType,
    credentialMethod,
    credentialStatus,
    dispatch,
    tokenStatus,
    onChangeToken,
    isFetching,
    onValidSubmit,
    withEchangeToken,
    attempts,
    userHasToken,
    showTitle,
    buttons,
}) => {
    const maxAttempts = config.getInteger("core.attempts.secondFactor.maxAttempts");

    const [popoverData, setPopoverData] = useState({
        buttonTitle: null,
        image: "",
        textRegular: null,
        textBold: null,
        textSecondRegular: null,
        textExtraInfo: null,
    });

    const isSoftHardToken = credentialType === SECOND_FACTOR_CONST.SOFT_HARD_TOKEN;

    const isDigitalTokenMethod = credentialMethod === SECOND_FACTOR_METHOD_CONST.DIGITAL_TOKEN;
    const isSoftTokenMethod = credentialMethod === SECOND_FACTOR_METHOD_CONST.SOFT_TOKEN;
    const isHardTokenMethod = credentialMethod === SECOND_FACTOR_METHOD_CONST.HARD_TOKEN;

    useEffect(() => {
        console.log("front: ", tokenStatus, userHasToken);
        if (userHasToken && tokenStatus === USER_TOKEN_STATUS_ACTIVE && isDigitalTokenMethod) {
            console.log("ok paso a getOtpCode");
            getOtpCode();
        } else if ( credentialStatus === USER_TOKEN_STATUS_LOCKED_ENTRUST) {
            dispatch(replace("/settings/authenticatorHandler"));
        } else if (isMobileNativeFunc() && isDigitalTokenMethod && (userHasToken === "false" || userHasToken === false) && withEchangeToken === null) {
            // seed en otro dispositivo se envia a pantalla de activar token
            dispatch(replace("/deviceRequired"));
        }
    }, [tokenStatus, userHasToken]);

    const getOtpCode = async () => {
        const currentOtpResponse = await generateOTP();
        if (!currentOtpResponse) {
            return;
        }
        const { code, data } = JSON.parse(currentOtpResponse);
        if (!code || !data || code !== ENT000) {
            return;
        }
        const valueToken = data;
        const valueTokenFormat = secondFactorUtils.getSecondFactorFormat(credentialType, valueToken);
        onChangeToken(valueTokenFormat);
    };

    useEffect(() => {
        const newPopoverData = {};
        newPopoverData.buttonTitle = i18n.get("softToken.credentials.popover.title");
        if (isSoftTokenMethod) {
            newPopoverData.image = "images/iconPopOvers/SOFTOKEN.svg";
            newPopoverData.textRegular = i18n.get("softToken.credentials.popover.text");
            newPopoverData.textBold = i18n.get("softToken.credentials.popover.app.entrust.label");
        }
        if (isDigitalTokenMethod) {
            newPopoverData.image = "images/iconPopOvers/SOFTOKEN.svg";
            newPopoverData.textRegular = i18n.get("softToken.credentials.popover.text");
            newPopoverData.textBold = i18n.get("softToken.credentials.popover.app.empresas.label");
        }
        if (isHardTokenMethod) {
            newPopoverData.image = "images/iconPopOvers/TOKEN.svg";
            newPopoverData.textRegular = i18n.get("hardToken.credentials.popover.text");
            newPopoverData.textBold = i18n.get("hardToken.credentials.popover.token.device.label");
            newPopoverData.textSecondRegular = i18n.get("hardToken.credentials.popover.token.device.extra.label");
        }
        setPopoverData(newPopoverData);
    }, [credentialType, credentialMethod]);

    const renderCredentials = () => (
        <Box className="second-factor-validation" display="flex" column fullWidth>
            {showTitle && (
                <Box className="title-with-icon">
                    <Image src="images/iconTitles/security_device.svg" />
                    <Text component="h3" defaultValue={i18n.get("", "Verificación")} />
                </Box>
            )}

            <Text
                component="h4"
                color="heading"
                bold
                labelKey=""
                defaultValue={`${i18n.get("softToken.credentials.title")} ${isHardTokenMethod ? i18n.get("hardToken.credentials.alias.label") : ""}`}
            />
            <Box display="block" fullWidth className="mb-7">
                {isSoftTokenMethod && (
                    <Text defaultValue={i18n.get("softToken.credentials.text")} />
                )}
                {isDigitalTokenMethod && (
                    <Text defaultValue={i18n.get("softToken.credentials.text")} />
                )}
                {isHardTokenMethod && (
                    <Text defaultValue={i18n.get("hardToken.credentials.text")} />
                )}

                {isSoftTokenMethod && <Text bold defaultValue={i18n.get("softToken.credentials.app.entrust.label")} />}
                {isDigitalTokenMethod && <Text bold defaultValue={i18n.get("softToken.credentials.app.empresas.label")} />}
                {isHardTokenMethod && <Text bold defaultValue={i18n.get("hardToken.credentials.label")} />}
            </Box>
            <Field
                idForm={FORM_ID}
                autoComplete="off"
                name="secondFactor"
                component={PinInput}
                maxLength={1}
                labelNoMarginTop
                noMarginBottom
                type="number"
                inputLenght={DIGITAL_KEY_LENGTH}
                // defaultLabelText={i18n.get("", "Clave virtual")}
                autoFocus={isDesktop}
                credentialType={credentialType}
                maxAttempts={maxAttempts}
                attempts={attempts}
                className={classNames({
                    "": isMobile
                })}
                handleChangeProp={(value) => {
                    if (value) {
                        const submit = value?.length === DIGITAL_KEY_LENGTH;
                        onValidSubmit({ submit });
                        if (submit) {
                            const valueTokenFormat = secondFactorUtils.getSecondFactorFormat(credentialType, value);
                            onChangeToken(valueTokenFormat);
                        }
                    }
                }}
            />

            {buttons && buttons}

            <PopOver
                image={popoverData.image}
                content={
                    <>
                        <Box display="block" fullWidth>
                            <Text defaultValue={i18n.get("", popoverData.textRegular)} />
                            <Text bold defaultValue={i18n.get("", popoverData.textBold)} />
                            {popoverData.textSecondRegular && (
                                <Text defaultValue={i18n.get("", popoverData.textSecondRegular)} />
                            )}
                        </Box>
                        {popoverData.textExtraInfo && (
                            <Box display="block" fullWidth className="mt-3">
                                <Text
                                    bold
                                    size="7"
                                    color="strong-primary"
                                    defaultValue={i18n.get("", popoverData.textExtraInfo)}
                                />
                            </Box>
                        )}
                    </>
                }
                position="top-left">
                <Button
                    primaryLink
                    bsStyle="link"
                    defaultLabelText={i18n.get("", popoverData.buttonTitle)}
                    className="px-0"
                />
            </PopOver>
        </Box>
    );
    // eslint-disable-next-line no-confusing-arrow
    const renderCredentialToken = () =>
        tokenStatus && (isSoftTokenMethod || isHardTokenMethod) ? renderCredentials() : <></>;

    const renderDesktop = () => renderCredentials();
    const renderMobile = () => renderCredentialToken();

    const loadingToken = () => (
        <div className="widget-preloader no-background min-height-2rem">
            <div>
                <span />
                <span />
                <span />
            </div>
        </div>
    );

    return (
        <>
            {isFetching && !isMobileNativeFunc() && loadingToken()}
            {!isMobileNativeFunc() && !isFetching && renderDesktop()}
            {isMobileNativeFunc() && renderMobile()}
        </>
    );
};

const mapStateToProps = (state) => ({
    tokenStatus: softTokenSelector.getStatusToken(state),
    isFetching: softTokenSelector.isFetching(state),
    attempts: secondFactorSelectors.getAttempts(state),
    userHasToken: secondFactorSelectors.getUserHasToken(state),
});

SoftHardTokenValidation.propTypes = {
    credentials: arrayOf(string).isRequired,
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    tokenStatus: string,
    onChangeToken: func,
    onValidSubmit: func,
    propsForm: shape({}),
    isFetching: bool.isRequired,
    relativeStyleError: bool,
    warningIcon: string,
    credentialType: string,
    credentialTypeMethod: string,
    withEchangeToken: string,
    showTitle: bool,
    buttons: node,
};

SoftHardTokenValidation.defaultProps = {
    tokenStatus: undefined,
    onChangeToken: () => {},
    onValidSubmit: () => {},
    propsForm: {},
    relativeStyleError: true,
    warningIcon: "tooltip.svg",
    credentialType: null,
    credentialTypeMethod: null,
    withEchangeToken: null,
    showTitle: true,
    buttons: null,
};

export default connect(mapStateToProps)(resizableRoute(SoftHardTokenValidation));
