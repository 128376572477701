import { format } from "date-fns";
import * as API from "middleware/api";
import * as configUtils from "util/config";
import moment from "moment";

export const detailsRequest = (id) => API.executeWithAccessToken("/creditCards.read", { idCreditCard: id });

export const changeCardStatus = (id, blocked) =>
    API.executeWithAccessToken("/creditCards.blockunblock", { idCreditCard: id, blocked });

export const movementsRequest = (id, filters = {}) => {
    let { dateFrom, dateTo } = filters;
    const { minAmount, maxAmount, pageNumber, offset, quantity, concept } = filters;
    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    const params = {
        idCreditCard: id,
        currency: configUtils.get("core.masterCurrency"),
        dateFrom,
        dateTo,
        maxAmount,
        minAmount,
        pageNumber,
        offset,
        quantity,
        concept,
    };

    return API.executeWithAccessToken("creditCards.listStatements", params);
};

export const downloadMovements = (idCreditCard, filters, fileFormat, creditCard) => {
    let { dateFrom, dateTo } = filters;
    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/creditCards.downloadMovements", {
        idCreditCard,
        format: fileFormat,
        currency: configUtils.get("core.masterCurrency"),
        ...filters,
        dateFrom,
        dateTo,
        dateLabelDownload: moment().format("dddd D MMMM YYYY"),
        hourLabelDownload: moment().format("HH:mm A"),
        creditCard,
    });
};

export const downloadDeferred = (idCreditCard, filters, fileFormat, creditCard) => {
    let { dateFrom, dateTo } = filters;
    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/creditCards.downloadDeferred", {
        idCreditCard,
        format: fileFormat,
        currency: configUtils.get("core.masterCurrency"),
        ...filters,
        dateFrom,
        dateTo,
        dateLabelDownload: moment().format("dddd D MMMM YYYY"),
        hourLabelDownload: moment().format("HH:mm A"),
        creditCard,
    });
};

export const downloadAdditionals = (idCreditCard, filters, fileFormat, creditCard) => {
    let { dateFrom, dateTo } = filters;
    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/creditCards.downloadAdditionals", {
        idCreditCard,
        format: fileFormat,
        currency: configUtils.get("core.masterCurrency"),
        ...filters,
        dateFrom,
        dateTo,
        dateLabelDownload: moment().format("dddd D MMMM YYYY"),
        hourLabelDownload: moment().format("HH:mm A"),
        creditCard,
    });
};

export const getMovementsTransit = (productId) =>
    API.executeWithAccessToken("/creditCards.movement.transit.get", {
        productId,
    });

export const getFinancingPlans = (productId) =>
    API.executeWithAccessToken("/creditCards.financingPlans", {
        productId,
    });

export const downloadMovementsTransit = (productId, contentType) =>
    API.executeWithAccessToken("/creditCards.downloadMovements.transit", {
        productId,
        contentType,
        dateLabelDownload: moment().format("dddd D MMMM YYYY"),
        hourLabelDownload: moment().format("HH:mm A"),
    });

export const creditCardAdditionalRequest = (productId, typeOperation) =>
    API.executeWithAccessToken("/creditCards.listAdditional", {
        idCreditCard: productId,
        typeOperation,
    });

export const creditCardCorporateRequest = () => API.executeWithAccessToken("/creditCards.listCorporate");

export const creditCardPointsRequest = (productId) =>
    API.executeWithAccessToken("/creditCards.inquiryPoints", {
        productId,
    });

export const downloadCreditCardPoints = (idCreditCard) =>
    API.executeWithAccessToken("/download.inquiryPoints", {
        idCreditCard,
    });

export const creditCardCorporateMovementsRequest = (cardStyle, movementType, filters, internalNumber, owner) => {
    let { dateFrom, dateTo } = filters;
    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/creditCard.corporate.movements", {
        cardStyle,
        movementType,
        internalNumber,
        dateFrom,
        dateTo,
        owner,
    });
};

export const downloadCorporateMovementsRequest = (
    numberMask,
    nameOnCard,
    cardStyle,
    movementType,
    filters,
    internalNumber,
    owner,
    fileFormat,
) => {
    let { dateFrom, dateTo } = filters;
    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;
    return API.executeWithAccessToken("/creditCard.corporate.movements.download", {
        numberMask,
        nameOnCard,
        cardStyle,
        movementType,
        internalNumber,
        dateFrom,
        dateTo,
        owner,
        format: fileFormat,
    });
};

export const creditCardCorporateMovementDetailRequest = (cardStyle, internalNumber, owner, movementType, movementId) =>
    API.executeWithAccessToken("/creditCard.corporate.movement.detail", {
        cardStyle,
        internalNumber,
        owner,
        movementType,
        movementId,
    });

export const downloadCorporateMovementDetailRequest = (
    numberMask,
    internalNumber,
    owner,
    movementType,
    movementId,
    dateFrom,
    dateTo,
) =>
    API.executeWithAccessToken("/creditCard.corporate.movement.detail.download", {
        numberMask,
        internalNumber,
        owner,
        movementType,
        movementId,
        dateFrom,
        dateTo,
    });