import React, { useState, useEffect } from "react";
import { shape, bool, func, string, node } from "prop-types";
import { connect } from "react-redux";

import { isMobileNative } from "util/device";

import OtpValidation from "pages/secondFactor/OtpValidation";
import SoftHardTokenValidation from "pages/secondFactor/SoftHardTokenValidation";
import * as secureStorageUtils from "util/secureStorage";

import {
    types as secondFactorTypes,
    actions as secondFactorActions,
    selectors as secondFactorSelectors,
} from "reducers/secondFactor";
import { SECOND_FACTOR_CONST } from "util/secondFactorUtils";
import SecurityQuestionsValidation from "./SecurityQuestionsValidation";

const SecondFactor = ({
    onSuccess,
    onChangeToken,
    onValidSubmit,
    validateForm,
    buttonLabel,
    textInfo,
    onlyEmailValidation,
    onlySMSValidation,
    onCancel,
    isSendEmailOTP,
    optionalEmailOTP,
    cleanAttemps,
    activity,
    useOwnScopeNotification,
    showInModal,
    withBackground,
    isDisabledButton,
    isDisabledTextField,
    onHover,
    withEchangeToken,
    idActivity,
    secondFactorPreview,
    isFetching,
    credentials,
    showTitle,
    scopeToShow,
    buttons,
    isSubmitting,
}) => {
    const [credentialType, setCredentialType] = useState("loading");
    const [credentialMethod, setCredentialMethod] = useState("");
    const [credentialStatus, setCredentialStatus] = useState("");

    const [isCredentialRequested, setIsCredentialRequested] = useState(false);

    useEffect(() => {
        if (credentials && idActivity === "session.login.legacy.step3") {
            console.log(
                "SecondFactor: No volver a consultar credenciales para este activity: ",
                credentials,
                idActivity,
            );
            setIsCredentialRequested(true);
        } else {
            console.log("SecondFactor: Consultar credenciales para este activity: ", credentials, idActivity);
            secondFactorPreview();
            setIsCredentialRequested(true);
        }
    }, [idActivity]);

    useEffect(() => {
        if (credentials && isCredentialRequested) {
            console.log("SecondFactor: Credenciales para este activity: ", credentials, idActivity);
            const credential = credentials[0];
            setCredentialType(credential.credentialType);
            setCredentialMethod(credential.credentialMethod);
            setCredentialStatus(credential.status);
        }
    }, [credentials, isCredentialRequested]);

    const loadingToken = () => (
        <div className="widget-preloader no-background min-height-2rem">
            <div>
                <span />
                <span />
                <span />
            </div>
        </div>
    );

    const renderContent = () => {
        switch (credentialType) {
            case "loading":
                return loadingToken();
            case SECOND_FACTOR_CONST.SOFT_HARD_TOKEN:
                return (
                    <SoftHardTokenValidation
                        showTitle={showTitle}
                        credentialType={credentialType}
                        credentialMethod={credentialMethod}
                        credentialStatus={credentialStatus}
                        withEchangeToken={withEchangeToken}
                        onChangeToken={(tokenCode) => {
                            onChangeToken(tokenCode);
                        }}
                        onValidSubmit={(validData) => {
                            onValidSubmit(validData);
                        }}
                        buttons={buttons}
                    />
                );
            case SECOND_FACTOR_CONST.SECURITY_QUESTIONS:
                return (
                    <SecurityQuestionsValidation
                        showTitle={showTitle}
                        withEchangeToken={withEchangeToken}
                        credentialType={credentialType}
                        credentialMethod={credentialMethod}
                        scopeToShow={scopeToShow}
                        onValidSubmit={(validData) => {
                            onValidSubmit(validData);
                        }}
                        buttons={buttons}
                    />
                );
            case SECOND_FACTOR_CONST.OTP:
                return (
                    <OtpValidation
                        showTitle={showTitle}
                        withEchangeToken={withEchangeToken}
                        idActivity={idActivity}
                        credentialType={credentialType}
                        credentialMethod={credentialMethod}
                        scopeToShow={scopeToShow}
                        onChangeToken={(tokenCode) => {
                            onChangeToken(tokenCode);
                        }}
                        onValidSubmit={(validData) => {
                            onValidSubmit(validData);
                        }}
                        buttons={buttons}
                        isSubmitting={isSubmitting}
                    />
                );
            default:
                return (
                    <>
                        <div>Credential Not found</div>
                    </>
                );
        }
    };

    return renderContent();
};

SecondFactor.propTypes = {
    onSuccess: func.isRequired,
    onChangeToken: func,
    onValidSubmit: func,
    onCancel: func,
    validateForm: func.isRequired,
    buttonLabel: string,
    textInfo: string,
    onlySMSValidation: bool,
    isSendEmailOTP: bool,
    optionalEmailOTP: bool,
    useOwnScopeNotification: bool,
    showInModal: bool,
    withBackground: bool,
    isDisabledButton: bool,
    isDisabledTextField: bool,
    onHover: bool,
    withEchangeToken: string,
    idActivity: string,
    isFetching: bool,
    showTitle: bool,
    scopeToShow: string,
    buttons: node,
    isSubmitting: bool,
};

SecondFactor.defaultProps = {
    buttonLabel: "global.confirm",
    textInfo: null,
    onlySMSValidation: false,
    onCancel: undefined,
    isSendEmailOTP: false,
    optionalEmailOTP: true,
    useOwnScopeNotification: false,
    showInModal: false,
    withBackground: false,
    isDisabledButton: false,
    isDisabledTextField: false,
    onHover: false,
    withEchangeToken: null,
    idActivity: null,
    isFetching: false,
    showTitle: true,
    scopeToShow: undefined,
    buttons: null,
    isSubmitting: false,
};

const mapStateToProps = (state) => ({
    credentials: secondFactorSelectors.getCredentials(state),
    isFetching: secondFactorSelectors.isFetching(state),
});

const mapDispatchToProps = (dispatch, { idActivity, withEchangeToken, scopeToShow }) => ({
    cleanAttemps: () => {
        // dispatch(secondFactorActions.cleanAttemps());
    },
    secondFactorPreview: () => {
        dispatch(
            secondFactorActions.secondFactorPreviewRequest({
                idActivity,
                exchangeToken: withEchangeToken,
                scopeToShow,
            }),
        );
    },
    secondFactorVerificationCode: () => {
        dispatch(
            secondFactorActions.secondFactorVerificationCodeRequest({ exchangeToken: withEchangeToken, idActivity }),
        );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SecondFactor);
