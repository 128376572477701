import React, { useEffect, useState } from "react";
import Box from "pages/_components/Box";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import FormTransition from "pages/forms/_components/FormTransition";
import { useGenericProps, useMetaData } from "pages/forms/customForms/hooks/TransferInternalHooks";
import { arrayOf, bool, func, shape, string } from "prop-types";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { actions as notificationActions } from "reducers/notification";
import { compose } from "redux";
import * as i18n from "util/i18n";
import {
    PREFIX,
    initialValues,
    useAccountPaySelectorOptions,
    useCreditCardPaySelectorOptions,
    validateForm,
    validationSchema,
} from "./hooks/servicePaymentFormHooks";
import { renderData, renderDataAmount, renderBills } from "./_components/utilForm";
import PayServiceForm from "./_components/PayServiceForm";
import TicketData from "pages/_components/TicketData";
import { USD_CURRENCY } from "constants.js";
import classNames from "classnames";
import ExitModal from "pages/_components/modal/ExitModal";

const ID_FORM = "servicePayments.pay";
const ID_ACTIVITY = "paymentService.send";
const ID_ACTIVITY_PRE = "paymentService.pre";
const TITLE_FORM = `${ID_FORM}.title`;

const ServicePaymentForm = (props) => {
    const {
        mode,
        preDataForm,
        isDesktop,
        dispatch,
        fetchingForm,
        field,
        currentLang,
        transaction,
        fetchingList,
        payService,
        fetchingValidations,
        validationData,
        ...rest
    } = props;

    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);
    const [genericProps] = useGenericProps(props, ID_ACTIVITY);
    const [enabledSubmit, setEnabledSubmit] = useState(false);
    const [firstPre, setFirstPre] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);

    const creditCardOptions = useCreditCardPaySelectorOptions(preDataForm?.creditCards);
    const accountOptions = useAccountPaySelectorOptions(preDataForm?.accounts);

    useEffect(() => {
        const activities = ["paymentService.pay.send"];
        dispatch(formActions.preValidationsForm(activities));
    }, []);

    useEffect(() => {
        if (validationData && !validationData?.hasSignatureScheme) {
            // no tiene comite de firmas asignado
            dispatch(
                notificationActions.showNotification(i18n.get(`form.schemeSignature.notFound`), "error", ["form"]),
            );
        }
    }, [validationData]);

    useEffect(() => () => dispatch(payServiceActions.cleanCachePayService()), []);

    useEffect(() => {
        if (payService) {
            if (mode === "edit" && !firstPre) {
                const requestData = { service: payService };

                dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData }));
                setFirstPre(true);
            }
        } else if (!transaction?.data) {
            dispatch(push("/servicePayments"));
        }
    }, [mode]);

    useEffect(() => {
        // no tiene productos para debito
        if (preDataForm?.code === "API040E") {
            dispatch(push("/servicePayments"));

            dispatch(
                notificationActions.showNotification(i18n.get(`returnCode.${preDataForm?.code}`), "warning", [
                    "servicePayments",
                ]),
            );
        }
    }, [preDataForm]);

    const handlerCancelAction = () => {
        setShowExitModal(true);
    };

    const handleAcceptModal = () => {
        dispatch(push("/desktop"));
    };

    const handleHideModal = () => {
        setShowExitModal(false);
    };

    const handlerPreviewCancelAction = () => {
        setShowExitModal(true);
    };

    const renderServiceData = ({
        category,
        companyLabel,
        identificationLabel,
        identificationValue,
        alias,
        commission,
        debt: { clientName, total },
    }) => {
        return (
            <Box fullWidth background="background-disabled" borderRadius="lg" className="p-5">
                <Box className="mb-5" fullWidth>
                    <Text labelKey="servicePayments.massive.pay.detail.service.title" bold />
                </Box>
                <Row
                    className={classNames({
                        "row-template-1fr": !isDesktop,
                    })}>
                    <Col xs={3}>
                        {renderData(`${ID_FORM}.category`, i18n.get(`servicePayment.category.${category}`))}
                    </Col>
                    <Col xs={3}>{renderData(`${PREFIX}.service`, companyLabel)}</Col>
                    <Col xs={3}>{renderData(`${PREFIX}.alias`, alias)}</Col>
                    <Col xs={3}>{renderData(`${ID_FORM}.identificationType`, identificationLabel)}</Col>
                    <Col xs={3}>
                        <>
                            <Box className="my-2 size-6 color-text-grey">
                                <Text color="text-grey" size="6">
                                    {identificationLabel}
                                </Text>
                            </Box>
                            <Box className="text-bold">{identificationValue}</Box>
                        </>
                    </Col>
                    <Col xs={3}>{renderData(`${ID_FORM}.titular`, clientName)}</Col>
                    <Col xs={3}>{renderDataAmount(`${ID_FORM}.amount`, total, "")}</Col>
                    <Col xs={3}>{renderDataAmount(`${ID_FORM}.commission`, commission, "")}</Col>
                </Row>
            </Box>
        );
    };

    const renderConfirmation = ({
        category,
        companyLabel,
        alias,
        identificationLabel,
        identificationValue,
        amountPay,
        commission,
        description,
        debitPaymentMethod,
        debitProductLabel,
        creditCardPaymentTypeLabel,
        debt: { clientName },
    }) => (
        <>
            <TicketData.Main label={`${ID_FORM}.amountPay`} value={amountPay} currency={USD_CURRENCY} type="amount" />

            <Box>
                <Box className="mt-3 mb-4">
                    <Text size="6" bold labelKey="paymentService.pay.send.service.title" />
                </Box>
                <TicketData.Data
                    label={`${ID_FORM}.category`}
                    value={i18n.get(`servicePayment.category.${category}`)}
                />
                <TicketData.Data label={`${PREFIX}.service`} value={companyLabel} />
                <TicketData.Data label={`${PREFIX}.alias`} value={alias} />
                <TicketData.Data label={`${ID_FORM}.identificationType`} value={identificationLabel} />
                <TicketData.Data label={identificationLabel} value={identificationValue} />
                <TicketData.Data label={`${ID_FORM}.titular`} value={clientName} />

                <Box className="mt-5 mb-4">
                    <Text size="6" bold labelKey={`${ID_FORM}.data.pay`} />
                </Box>

                <TicketData.Data
                    label={`${ID_FORM}.debitPaymentMethod`}
                    value={i18n.get(`${ID_FORM}.debitPaymentMethod.${debitPaymentMethod}`)}
                />
                {debitPaymentMethod === "account" ? (
                    <TicketData.Data label={`${ID_FORM}.debitAccount`} value={debitProductLabel} />
                ) : (
                    <>
                        <TicketData.Data label={`${ID_FORM}.debitCreditCard`} value={debitProductLabel} />
                        <TicketData.Data
                            label={`${ID_FORM}.creditCard.paymentType`}
                            value={creditCardPaymentTypeLabel}
                        />
                    </>
                )}
                <TicketData.Data label={`${ID_FORM}.description`} value={description || "-"} />
                <TicketData.Data label={`${ID_FORM}.rate`} value={commission} />
            </Box>
        </>
    );

    const renderTicket = () => <></>;

    const renderFields = (setFieldValue, values, _setValues, _scheduler, errors, setErrors) => {
        if (
            (mode !== "view" && (!preDataForm || Object.keys(preDataForm).length === 0 || fetchingForm)) ||
            !payService ||
            fetchingValidations
        ) {
            return <PageLoading loading classicStyle={false} />;
        }
        if (mode === "edit" && validateForm(values)) {
            setEnabledSubmit(true);
        } else {
            setEnabledSubmit(false);
        }

        return (
            <>
                {mode === "edit" && (
                    <Box
                        fullWidth
                        background="white"
                        borderRadius="lg"
                        className={classNames("form-service-payments ", {
                            "box-shadow-small mb-7 p-7": isDesktop,
                            "mt-6 mb-4": !isDesktop,
                        })}>
                        {renderServiceData(values.list[0])}

                        <>{renderBills(values.list[0], isDesktop)}</>

                        <Box
                            borderRadius="lg"
                            className={classNames("mt-7", {
                                "box-shadow-small p-7": isDesktop,
                            })}>
                            <Box className="mt-5">
                                <Text bold labelKey={`${ID_FORM}.data.pay`} />
                            </Box>
                            <PayServiceForm
                                index={0}
                                // radiButtonOptions={radiButtonOptions}
                                values={values}
                                errors={errors}
                                setErrors={setErrors}
                                genericProps={genericProps}
                                ID_FORM={ID_FORM}
                                accountOptions={accountOptions}
                                creditCardOptions={creditCardOptions}
                                setFieldValue={setFieldValue}
                                isDesktop={isDesktop}
                                showFieldAmount
                            />
                        </Box>
                    </Box>
                )}
                {(mode === "preview" || mode === "view") && <>{renderConfirmation(values.list[0])}</>}

                <ExitModal
                    modalShow={showExitModal}
                    acceptFunction={handleAcceptModal}
                    cancelFunction={handleHideModal}
                    headingText={i18n.get("confirm.exit.title")}
                    text={i18n.get("confirm.exit.info")}
                />
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        ticketConfirmation: true,
        cancelAction: handlerCancelAction,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        showSchedulerMessage: false,
        // validate: validateForm,
        titleFormConfirmation: "OTP CODE",
        data: initialValues(props),
        validationSchema,
        textBack: "global.return",
        textBackConfirmation: "global.return",
        submitDisabled: !enabledSubmit,
        submitButtonLabel: "forms.servicePayment.confirmation.button",
        handleCancelPreview: handlerPreviewCancelAction,
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    fetchingForm: formSelectors.getFetching(state),
    fetching: payServiceSelectors.isFetching(state),
    payService: payServiceSelectors.getPayService(state),
    preDataForm: formSelectors.getPreData(state),
    currentLang: i18nSelectors.getLang(state),
    mode: formSelectors.getMode(state),
    previewData: formSelectors.getPreviewData(state),
    fetchingValidations: formSelectors.getFetchingValidations(state),
    validationData: formSelectors.getValidationData(state),
});

ServicePaymentForm.propTypes = {
    history: shape({}).isRequired,
    isDesktop: bool,
    mode: string,
    currentLang: string,
    preDataForm: shape({}),
    validationData: shape({}),
    previewData: shape({}),
    fromBackoffice: bool,
    subtitle: string,
    subtitlePrefix: string,
    fetching: bool,
    fetchingValidations: bool,
    field: arrayOf(shape({})),
    fetchingForm: bool,
    dispatch: func.isRequired,
    transaction: shape({}),
    fetchingList: bool,
    isFastRecharge: bool,
    isLinkFastRecharge: bool,
    isFetchingFastRecharge: bool,
    listFastRecharge: arrayOf(shape({})),
};

ServicePaymentForm.defaultProps = {
    fromBackoffice: false,
    mode: "edit",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    subtitle: true,
    subtitlePrefix: true,
    fetching: false,
    fetchingValidations: true,
    isDesktop: false,
    field: [],
    fetchingForm: false,
    transaction: null,
    fetchingList: false,
    isFastRecharge: false,
    isLinkFastRecharge: false,
    isFetchingFastRecharge: false,
    listFastRecharge: [],
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(ServicePaymentForm));
