import RemarkableProductInfo from "pages/_components/RemarkableProductInfo";
import { bool, node, shape, func } from "prop-types";
import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { CREDIT_CARD_TYPE_CARD, dictionaryMiniatureCard } from "util/creditCards";
import { string } from "yup";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import classNames from "classnames";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import Info from "pages/_components/Info";

import * as i18nUtils from "util/i18n";
import { isMobile } from "react-device-detect";
import FormattedDate from "pages/_components/FormattedDate";
import Button from "pages/_components/Button";

class DetailHeadInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            moreDetails: false,
        };
    }

    moreDetailsHandler = () => {
        this.setState((prevState) => ({
            moreDetails: !prevState.moreDetails,
        }));
    };

    renderImage = (cardStyle) => (
        <Box display="flex" alignY="center" className="product-detail-img-wrapper">
            <Image
                src={`images/${dictionaryMiniatureCard(cardStyle)}-miniature-creditCard.png`}
                wrapperClassName="product-cards-detail-img"
            />
        </Box>
    );

    renderCreditCardTitle = (shortLabel, nameOnCard, showText = true) => (
        <Box fullWidth column={isMobile} alignX={isMobile && "center"}>
            <Box display="flex">
                <Box fullWidth>
                    <Text size="1" component="h1" align="left" bold>
                        {shortLabel}
                    </Text>
                </Box>
                {isMobile && (
                    <Box alignY="center" display="flex">
                        <Text className="ml-3 product-status-text" labelKey={this.props.productStatus} />
                    </Box>
                )}
            </Box>
            <Text component="h4" align="left" bold>
                {nameOnCard}
            </Text>
            {showText && <Text align="left" labelKey="creditCards.detail.text" color="text-disabled-color" />}
        </Box>
    );

    renderData2 = (isAdditionalCreditCard, closingDate, minimumPayment, minimumPaymentCurrency) => (
        <>
            {isAdditionalCreditCard || !isMobile ? (
                <>
                    {isMobile ? (
                        <Box className="mb-4 mt-1">
                            <Text labelKey="creditCard.details.info.closingDate" />
                            &nbsp;&nbsp;
                            <FormattedDate bold color="heading" date={closingDate} />
                        </Box>
                    ) : (
                        <Box className="fit-content-width text-left">
                            <I18n id="creditCard.details.info.closingDate" />
                            <Info className="data-amount-account" date={closingDate} />
                        </Box>
                    )}
                </>
            ) : (
                <>
                    {isMobile ? (
                        <Box className="mb-4 mt-1">
                            <Text labelKey="creditCard.details.info.closingDate" />
                            &nbsp;&nbsp;
                            <FormattedDate bold color="heading" date={closingDate} />
                        </Box>
                    ) : (
                        <Box className="fit-content-width text-left">
                            <I18n id="creditCard.details.head.info.minimum.balance.pay" />
                            <FormattedAmount
                                noAmountMargin
                                className={
                                    isMobile ? "data-amount-account big-size my-3" : "data-amount-account big-size"
                                }
                                currency={minimumPaymentCurrency}
                                quantity={minimumPayment}
                            />
                        </Box>
                    )}
                </>
            )}
        </>
    );

    renderBalances = (isAditional, closingDate, expirationDate, minimumPayment, minimumPaymentCurrency, totalDebt) => (
        <Box
            borderRadius="lg"
            background="background-primary"
            display="flex"
            className={isMobile ? "p-5 m-5" : "p-8 children-border-right"}
            column={isMobile}
            alignX={isMobile && "center"}>
            <Box fullWidth={!isMobile} className={isMobile && "order-2"}>
                {this.renderData2(isAditional, closingDate, minimumPayment, minimumPaymentCurrency)}
            </Box>
            <Box fullWidth={!isMobile} className={isMobile ? "order-3" : "text-webkit-center"}>
                {isMobile ? (
                    <Box>
                        <Text labelKey="creditCard.details.info.dueDate" />
                        &nbsp;&nbsp;
                        <FormattedDate bold color="heading" date={expirationDate} />
                    </Box>
                ) : (
                    <Box className="fit-content-width text-left">
                        <I18n id="creditCard.details.info.dueDate" />
                        <Info className="data-amount-account" date={expirationDate} />
                    </Box>
                )}
            </Box>
            <Box fullWidth={!isMobile} className={isMobile ? "order-1" : "text-webkit-right"}>
                <Box className={isMobile ? "fit-content-width text-center" : "fit-content-width text-left"}>
                    {isAditional || !isMobile ? (
                        <>
                            <I18n id="creditCard.details.head.info.minimum.balance.pay" />
                            <FormattedAmount
                                noAmountMargin
                                className={
                                    isMobile ? "data-amount-account big-size my-3" : "data-amount-account big-size"
                                }
                                currency={minimumPaymentCurrency}
                                quantity={minimumPayment}
                            />
                        </>
                    ) : (
                        <>
                            <I18n id="creditCard.details.head.info.minimum.balance.pay" />
                            <FormattedAmount
                                noAmountMargin
                                className={
                                    isMobile ? "data-amount-account big-size my-3" : "data-amount-account big-size"
                                }
                                currency={minimumPaymentCurrency}
                                quantity={minimumPayment}
                            />
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );

    renderSecondaryButton = (secondaryBtn, idProduct, isDesktop)  =>
        !isDesktop &&
        secondaryBtn && (
            <Box display="flex">
                {secondaryBtn.map((item) => (
                    <Button
                        key={item.label}
                        label={item.label}
                        bsStyle={item.bsStyle}
                        {...(item.onClick && { onClick: item.onClick })}
                        {...(item.href && { href: item.href + idProduct })}
                        {...(item.disabled && { disabled: true })}
                        {...(item.loading && { loading: item.loading })}
                        block
                    />
                ))}
            </Box>
        );

    renderMoreDetails = (
        currency,
        productType,
        officialName,
        deliveryAddress,
        openingDate,
        lastTransactionDate,
        internationalRemittances,
        blockedBalance,
        detainedBalance12,
        detainedBalance24,
        creditLineAmount,
        occasionalOverdrafts,
        isDesktop,
        account,
        availableBalance,
        currentDue,
        balanceDueDate,
        closingDate,
        balance,
        purchasesInstallments,
        consolidatedAmount,
        initialBalance,
        finalBalance,
        assignedQuota,
        availableQuota,
        usedQuota,
        isAditional,
    ) => (
        <Box borderRadius="lg" background="background-disabled" className="mt-5 mb-5">
            <Box
                borderRadius="lg"
                display="flex"
                className={classNames({
                    "pt-5 pb-5 pl-8 pr-8": isDesktop,
                    "p-5": !isDesktop,
                })}
                onClick={this.moreDetailsHandler}
                pointer
                fullWidth>
                <Box fullWidth>
                    <Text align="left" labelKey="global.moreDetails" bold />
                </Box>
                <Box alignY="center">
                    <Image src={`images/util/arrow-${this.state.moreDetails ? "up" : "down"}.svg`} />
                </Box>
            </Box>

            <Collapse in={this.state.moreDetails}>
                {!isAditional ? (
                    <Box
                        className={classNames({
                            "pl-8 pr-8": isDesktop,
                            "pl-5 pr-5": !isDesktop,
                        })}>
                        <Box display={isDesktop && "flex"} className={`${isDesktop ? "mt-7" : "mt-5"}`}>
                            <Info
                                labelText={`${i18nUtils.get("creditCard.details.info.initialBalance")} ${currency}`}
                                amount={initialBalance}
                                flex={!isDesktop}
                            />
                            <Info
                                labelText={`${i18nUtils.get("creditCard.details.info.consumption")} ${currency}`}
                                amount={currentDue}
                                flex={!isDesktop}
                            />
                            <Info
                                labelText={`${i18nUtils.get("creditCard.details.info.payments")} ${currency}`}
                                amount={purchasesInstallments}
                                flex={!isDesktop}
                            />
                            <Info
                                labelText={`${i18nUtils.get("creditCard.details.info.finalBalance")} ${currency}`}
                                amount={finalBalance}
                                flex={!isDesktop}
                            />
                        </Box>
                        <Box
                            display={isDesktop && "flex"}
                            className={classNames({
                                "mt-7": isDesktop,
                            })}>
                            <Info labelKey="creditCard.details.info.closingDate" date={closingDate} flex={!isDesktop} />
                            <Info
                                labelText={`${i18nUtils.get("creditCard.details.info.duePayments")} ${currency}`}
                                amount={balanceDueDate}
                                flex={!isDesktop}
                            />
                            <Info
                                labelText={`${i18nUtils.get("creditCard.details.info.assignedQuota")} ${currency}`}
                                amount={assignedQuota}
                                flex={!isDesktop}
                            />
                            <Info
                                labelText={`${i18nUtils.get("creditCard.details.info.usedQuota")} ${currency}`}
                                amount={usedQuota}
                                flex={!isDesktop}
                            />
                        </Box>
                        <Box
                            display={isDesktop && "flex"}
                            className={classNames("pb-7", {
                                "mt-7": isDesktop,
                            })}>
                            <Info
                                labelText={`${i18nUtils.get("creditCard.details.info.availableQuota")} ${currency}`}
                                amount={availableQuota}
                                flex={!isDesktop}
                            />
                            <Info labelKey="creditCard.details.info.accountNumber" text={account} flex={!isDesktop} />
                            {isDesktop && (
                                <>
                                    <Info />
                                    <Info />
                                </>
                            )}
                        </Box>
                    </Box>
                ) : (
                    <Box
                        className={classNames({
                            "pl-8 pr-8": isDesktop,
                            "pl-5 pr-5": !isDesktop,
                            "justify-content-between": isDesktop,
                        })}>
                        <Box
                            display={isDesktop && "flex"}
                            className={classNames("pb-7", {
                                "mt-7": isDesktop,
                            })}>
                            <Info labelKey="creditCard.details.info.accountNumber" text={account} flex={!isDesktop} />
                            <Info
                                labelText={`${i18nUtils.get("creditCard.details.info.duePayments")} ${currency}`}
                                amount={balanceDueDate}
                                flex={!isDesktop}
                            />
                            <Info
                                labelText={`${i18nUtils.get("creditCard.details.info.assignedQuota")} ${currency}`}
                                amount={assignedQuota}
                                flex={!isDesktop}
                            />
                            <Info
                                labelText={`${i18nUtils.get("creditCard.details.info.availableQuota")} ${currency}`}
                                amount={availableQuota}
                                flex={!isDesktop}
                            />
                        </Box>
                    </Box>
                )}
            </Collapse>
        </Box>
    );

    render() {
        const {
            creditCard,
            children,
            isDesktop,
            isAdditionalCreditCard,
            handlePointsClick,
            showEnableWalletRequest,
            cardWasDigitized,
            artifactType,
        } = this.props;

        const {
            blocked,
            cardStyle,
            description,
            franchise,
            holder,
            idProduct,
            isIssuerAvaibleForPoints,
            isIssuerAvaibleForCashback,
            isPrepaid,
            isCorporative,
            productionStatus,
            productType,
            shortLabel,
            statusCode,
            totalPoints,
            cashBackAmount,
            cashBackAmountCurrency,
            number,
            nameOnCard,
            currency,
            availableCredit,
            countableBalance,
            avaibleBalance,
            closingDate,
            expirationDate,
            minimumPayment,
            minimumPaymentCurrency,
            officialName,
            deliveryAddress,
            openingDate,
            lastTransactionDate,
            internationalRemittances,
            blockedBalance,
            detainedBalance12,
            detainedBalance24,
            creditLineAmount,
            occasionalOverdrafts,
            account,
            availableBalance,
            currentDue,
            balanceDueDate,
            purchasesInstallments,
            balance,
            consolidatedAmount,
            initialBalance,
            finalBalance,
            assignedQuota,
            availableQuota,
            usedQuota,
            isAditional,
            totalDebt,
        } = creditCard;

        const filterOptionList = (optionList, keyType) => {
            const filter = optionList?.filter((item) => item && item[keyType] && item[keyType] === true);
            return filter;
        };

        const getCreditCardArray = (product) => {
            if (isPrepaid) {
                const optionList = [
                    {
                        currency: product.availableBalanceCurrency,
                        quantity: product.availableBalance,
                        title: "creditCard.details.info.availableBalance.prePaid",
                        tooltip: "creditCard.details.info.availableBalance.prePaid.tooltip",
                        showInfAdditional: true,
                        showInfMain: true,
                    },
                    {
                        date: product.lastPaymentDate,
                        title: "creditCard.details.info.expirationDate.prePaid",
                        tooltip: "creditCard.details.info.expirationDate.prePaid.tooltip",
                        showInfAdditional: false,
                        showInfMain: true,
                    },
                ];
                return filterOptionList(
                    optionList,
                    isAdditionalCreditCard && !isCorporative ? "showInfAdditional" : "showInfMain",
                );
            }

            const optionList = [
                {
                    title: `creditCard.detail.condition.credit.limit`,
                    quantity: product.creditLimit,
                    currency: product.creditLimitCurrency,
                    tooltip: product.hasOwnLimit
                        ? `creditCard.detail.condition.credit.limit.own.tooltip`
                        : `creditCard.detail.condition.credit.limit.share.tooltip`,
                    showInfAdditional: true,
                    showInfMain: false,
                },

                {
                    currency: product.stmtBalanceCurrency,
                    quantity: product.stmtBalance,
                    title: "creditCard.details.info.detainedBalance",
                    tooltip: "creditCard.details.info.detainedBalance.tooltip",
                    showInfAdditional: false,
                    showInfMain: true,
                },
                {
                    currency: product.balanceCurrency,
                    quantity: product.balance,
                    title: "creditCard.details.info.currentDue",
                    tooltip: "creditCard.details.info.currentDue.tooltip",
                    showInfAdditional: false,
                    showInfMain: true,
                },
                {
                    currency: product.minimumPaymentCurrency,
                    quantity: product.minimumPayment,
                    title: "creditCard.details.info.minimumPayment",
                    showInfAdditional: false,
                    showInfMain: true,
                },
                {
                    currency: product.availableBalanceCurrency,
                    quantity: product.availableBalance,
                    title: "creditCard.details.info.availableBalance",
                    showInfAdditional: true,
                    showInfMain: true,
                },
                {
                    date: product.closingDate,
                    title: "creditCard.details.info.closingDate",
                    showInfAdditional: false,
                    showInfMain: true,
                },
                {
                    title: "creditCard.details.info.expirationDate",
                    showInfAdditional: false,
                    showInfMain: true,
                },
            ];

            if (product.paymentDeadLineText) {
                optionList[6].message = product.paymentDeadLineText;
            } else {
                optionList[6].date = product.paymentDeadLine;
            }

            return filterOptionList(
                optionList,
                isAdditionalCreditCard && !isCorporative ? "showInfAdditional" : "showInfMain",
            );
        };

        const settings = {
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: 1,
            arrows: false,
        };

        const remarkable = () => (
            <RemarkableProductInfo
                isDesktop={isDesktop}
                productType={productType}
                blocked={blocked}
                holder={holder}
                icon={franchise.toLowerCase()}
                iDCc={idProduct}
                franchise={franchise}
                points={totalPoints}
                isPrepaid={isPrepaid}
                isCorporative={isCorporative}
                statusCode={statusCode}
                shortLabel={shortLabel}
                description={description}
                productionStatus={productionStatus}
                artifactType={artifactType}
                isAdditionalCreditCard={isAdditionalCreditCard}
                handlePointsClick={handlePointsClick}
                isIssuerAvaibleForPoints={isIssuerAvaibleForPoints}
                isIssuerAvaibleForCashback={isIssuerAvaibleForCashback}
                numberMask={creditCard?.numberMask || ""}
                {...(cardStyle && { cardStyle })}
                isSwitchVisible={creditCard?.isSwitchVisible}
                cashback={`${cashBackAmountCurrency} ${cashBackAmount}`}
                showEnableWalletRequest={showEnableWalletRequest}
                cardWasDigitized={cardWasDigitized}
            />
        );

        return (
            <div className={classNames("detail-head-info head-card background-white")}>
                <>
                    <Box
                        display="flex"
                        column={isMobile}
                        alignX={isMobile && "center"}
                        className={classNames({
                            "p-7 mb-3": isDesktop,
                            "p-5": !isDesktop,
                        })}>
                        {!isDesktop && this.renderImage(cardStyle)}
                        {this.renderCreditCardTitle(shortLabel, nameOnCard)}
                        {isDesktop && this.renderImage(cardStyle)}
                    </Box>

                    <Box fullWidth={isMobile}>
                        {this.renderBalances(
                            isAditional,
                            closingDate,
                            expirationDate,
                            minimumPayment,
                            minimumPaymentCurrency,
                            totalDebt,
                        )}
                    </Box>

                    <Box fullWidth={isMobile} className="px-5">
                        {this.renderSecondaryButton(this.props.secondaryBtn, idProduct, isDesktop)}
                    </Box>

                    <Box
                        className={classNames("background-white pb-1", {
                            "px-5": !isDesktop,
                        })}>
                        {this.renderMoreDetails(
                            minimumPaymentCurrency,
                            productType,
                            officialName,
                            deliveryAddress,
                            openingDate,
                            lastTransactionDate,
                            internationalRemittances,
                            blockedBalance,
                            detainedBalance12,
                            detainedBalance24,
                            creditLineAmount,
                            occasionalOverdrafts,
                            isDesktop,
                            account,
                            availableBalance,
                            currentDue,
                            balanceDueDate,
                            closingDate,
                            purchasesInstallments,
                            balance,
                            consolidatedAmount,
                            initialBalance,
                            finalBalance,
                            assignedQuota,
                            availableQuota,
                            usedQuota,
                            isAditional,
                        )}
                    </Box>
                    {children}
                </>
            </div>
        );
    }
}

DetailHeadInfo.propTypes = {
    children: node,
    creditCard: shape({}),
    isDesktop: bool.isRequired,
    isAdditionalCreditCard: bool,
    artifactType: string,
    handlePointsClick: func.isRequired,
    showEnableWalletRequest: func,
    cardWasDigitized: bool,
};

DetailHeadInfo.defaultProps = {
    creditCard: null,
    children: null,
    isAdditionalCreditCard: false,
    artifactType: CREDIT_CARD_TYPE_CARD,
    showEnableWalletRequest: undefined,
    cardWasDigitized: undefined,
};

export default DetailHeadInfo;
