import React from "react";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import FieldError from "pages/_components/fields/FieldError";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import * as i18n from "util/i18n";
import RadioButton from "pages/_components/fields/Radio";
import classNames from "classnames"
import Table from "rc-table";

export const renderData = (labelKey, data, size = "6") => (
    <>
        <Box className="mt-2 mb-3 size-6 color-text-grey">
            <Text color="text-grey" size={size} labelKey={labelKey} />
        </Box>
        <Box className="text-bold size-6">{data}</Box>
    </>
);

export const renderDataDate = (labelKey, data, size = "6") => (
    <>
        <Box className="mt-2 mb-3 size-6 color-text-grey">
            <Text color="text-grey" size={size} labelKey={labelKey} />
        </Box>
        <Box className="text-bold size-6">{data ? <FormattedDate date={data} anotherFormat="DD/MM/YYYY" /> : "-"}</Box>
    </>
);

export const renderDataAmount = (labelKey, data, className = "justify-content-end", size = "6") => (
    <>
        <Box className="mt-2 mb-3 size-6 color-text-grey">
            <Text color="text-grey" size={size} labelKey={labelKey} />
        </Box>
        <Box className="text-bold size-6">
            <FormattedAmount quantity={data} className={className} noCurrency />
        </Box>
    </>
);

export const fieldInputError = (error) =>
    error ? (
        <Box className="mt-2">
            <FieldError error={error} />
        </Box>
    ) : null;

export const renderDataModal = ({ xs, label, labelKey, data, date, amount }) => (
    <Row className="mt-3 mb-4">
        <Col xs={xs || 6}>
            {label && (
                <Text size="6" light>
                    {label}
                </Text>
            )}
            {labelKey && <Text size="6" light labelKey={labelKey} />}
        </Col>
        <Col xs={xs ? 12 - xs : 6} className="text-right text-bold size-6">
            {data && (
                <Text size="6" bold>
                    {data}
                </Text>
            )}
            {date && <FormattedDate date={date} anotherFormat="DD/MM/YYYY" />}
            {amount && <FormattedAmount quantity={amount} className="justify-content-end" noCurrency />}
        </Col>
    </Row>
);

const billsTableColumns = (isDesktop) => {
    if (isDesktop) {
        return [
            {
                key: "radio",
                dataIndex: "radio",
                title: "",
                width: 7,
            },
            {
                key: "concept",
                dataIndex: "concept",
                // align: "center",
                title: i18n.get("servicePayments.pay.debts.concept"),
                width: 160,
            },
            {
                key: "dueYear",
                dataIndex: "dueYear",
                title: i18n.get("servicePayments.pay.debts.dueYear"),
                width: 105,
            },
            {
                key: "amount",
                align: "right",
                dataIndex: "amount",
                title: i18n.get("servicePayments.pay.debts.amount"),
                width: 105,
            },
        ];
    } else {
        return [
            {
                key: "dataRow",
                dataIndex: "dataRow",
                title: "",
                width: 100,
            },
        ];
    }
};

const billsData = (bills, isDesktop) =>
    bills?.map(({ concept, date, amount }, index) => {
        // let data = { key: index };
        if (isDesktop) {
            return {
                dataRow: <></>,
                radio: (
                    <>
                        <RadioButton
                            name={`radio${index}`}
                            id={`radio${index}`}
                            key={`radio${index}`}
                            checked={index === 0}
                            className="radio-bill-service"
                        />
                    </>
                ),
                concept: <Text size="6">{concept || "-"}</Text>,
                dueYear: <FormattedDate size="6" date={date} anotherFormat="YYYY" />,
                amount: (
                    <Box display="flex" alignX="end">
                        <FormattedAmount size="6" quantity={amount} noCurrency />
                    </Box>
                ),
            };
        } else {
            return {
                dataRow: (
                    <Box display="flex" className="align-items-start" fullWidth>
                        <RadioButton
                            name={`radio${index}`}
                            id={`radio${index}`}
                            key={`radio${index}`}
                            checked={index === 0}
                            className="radio-bill-service"
                        />
                        <Row className="ml-3">
                            <Col xs={6}>
                                <Box display="flex">
                                    <Box>
                                        <Box>
                                            <Text
                                                color="text-grey-color"
                                                size="6"
                                                labelKey="servicePayments.pay.debts.concept"
                                            />
                                        </Box>
                                        <Text size="6">{concept || "-"}</Text>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={6}>
                                <Box display="flex" alignX="end">
                                    <Box>
                                        <Box>
                                            <Text
                                                color="text-grey-color"
                                                size="6"
                                                labelKey="servicePayments.pay.debts.dueYear"
                                            />
                                        </Box>
                                        <Box className="text-right">
                                            <FormattedDate size="6" date={date} anotherFormat="YYYY" />
                                        </Box>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={6}>
                                <Box>
                                    <Box>
                                        <Text
                                            color="text-grey-color"
                                            size="6"
                                            labelKey="servicePayments.pay.debts.amount"
                                        />
                                    </Box>
                                    <Box>
                                        <FormattedAmount size="6" quantity={amount} noCurrency />
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                ),
            };
        }
    });

export const renderBills = ({ debt: { bills } }, isDesktop) => {
        if (!bills || bills.length === 0) {
            return null;
        }
        return (
            <Box
                className={classNames({
                    "box-shadow-small p-7 mt-7 border-radius-lg": isDesktop,
                })}>
                <Box
                    className={classNames({
                        "my-5": isDesktop,
                        "mt-5 mb-2": !isDesktop,
                    })}>
                    <Text bold labelKey={`servicePayments.pay.data.debts`} />
                </Box>

                <Table
                    className={classNames("table-default overflowNone", {
                        "table-td-border-radius": !isDesktop,
                    })}
                    columns={billsTableColumns(isDesktop)}
                    data={billsData(bills, isDesktop)}
                    rowKey={(record) => record.key}
                    rowClassName="bill-row"
                />
            </Box>
        );
    };