import * as Yup from "yup";
import * as i18n from "util/i18n";

const validationSchema = Yup.lazy((caps) => {


    if (!caps || typeof caps !== "object") {
        console.error("caps no es un objeto válido:", caps);
        return Yup.object();
    }

    return Yup.object(
        Object.keys(caps || {}).reduce((acc, key) => {
            acc[key] = Yup.object().shape({
                amount: Yup.number()
                    .typeError(i18n.get("cybo.environmentsManagement.users.capsForUserProduct"))
                    .required(i18n.get("cybo.environmentsManagement.users.capsForUserProduct")),
            });
            return acc;
        }, {})
    );
});

export default validationSchema;
