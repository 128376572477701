
export const normalizedProducts = (items) =>
    items.map((p) => ({ idProduct: p.idProduct, label: p.label, type: p.productType }));

export const filterFeatures = (groups, cashProducts) => {
    const cashServices = Object.keys(cashProducts);
    return groups
        .map((f) => ({
            ...f,
            childrenList:
                f.childrenList
                    .filter((c) => c.childrenList && c.childrenList.length > 0 && cashServices.includes(c.idItem)),
        }))
        .filter((f) => f.childrenList && f.childrenList.length > 0);
};



export const allPermissions = (groups) => {
    let result = [];

    groups.forEach(item => {
        // Extraer valores de permissionList si existen
        if (item.permissionList && item.permissionList.length > 0) {
            const permissions = item.permissionList.map(permission => ({
                idPermission: permission.idPermission,
                cashService: permission.advancedIdSubcategory,
                allowProductSelection: permission.advancedAllowProductSelection,
                productTypes: permission.productTypes,
            }));
            result = result.concat(permissions);
        }

        // Recursivamente procesar los childrenList
        if (item.childrenList && item.childrenList.length > 0) {
            result = result.concat(allPermissions(item.childrenList));
        }
    });

    return result;
}


export const allowedProductsFeature = (groups, cashProducts) => {
    const permissions = allPermissions(groups);
    const result = {};
    permissions.forEach(p => {
        result[p.idPermission] = p.allowProductSelection ? cashProducts[p.cashService] || [] : ['NONE'];
    });

    return result;

}

export const preSelectionRole = ( groups, cashProducts, productsEnv, aditionalProducts ) => {
    const products = [...productsEnv, ...(aditionalProducts || [])];
    const permissions = allPermissions(groups);
            const preSelected = {};
            permissions.forEach(({idPermission, cashService, productTypes, allowProductSelection}) => {
                if (cashProducts[cashService]) {
                    const productsServiceCash = cashProducts[cashService];
                    let productsPermission = [];
                    const productTypesList = productTypes ? productTypes.split(","): [];
                    if (productTypesList.length > 0 && !allowProductSelection) {
                        productsPermission = productTypesList.map(item => `ALL_${item}`);
                    } else if (productTypesList.length > 0 && allowProductSelection) {

                         productsPermission = products.filter(item =>
                            productsServiceCash.includes(item.idProduct) && productTypesList.includes(item.productType)
                          ).map(item => item.idProduct);

                    } else {
                        productsPermission = ["NONE"]
                    }
                    preSelected[idPermission] = productsPermission;
                }
            });

    return preSelected;
}
