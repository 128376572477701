export const types = {
    LIST_OTHER_BANK_REQUEST: "creditCardOtherBank/LIST_OTHER_BANK_REQUEST",
    LIST_OTHER_BANK_FAILURE: "creditCardOtherBank/LIST_OTHER_BANK_FAILURE",
    LIST_OTHER_BANK_SUCCESS: "creditCardOtherBank/LIST_OTHER_BANK_SUCCESS",

    SAVE_CREDIT_CARD_DATA: "creditCardOtherBank/SAVE_CREDIT_CARD_DATA",

    LIST_BANKS_REQUEST: "creditCardOtherBank/LIST_BANKS_REQUEST",
    LIST_BANKS_FAILURE: "creditCardOtherBank/LIST_BANKS_FAILURE",
    LIST_BANKS_SUCCESS: "creditCardOtherBank/LIST_BANKS_SUCCESS",
};

export const INITIAL_STATE = {
    idSelectedCard: null,
    selectedCardData: null,
    fetching: false,
    list: [],
    listBanks: [],
    currentPage: 0,
    totalPages: 0,
};

export default (state = INITIAL_STATE, action = {}) => {
    const { newList, listBanks, currentPage, totalPages, creditCardData } = action;

    switch (action.type) {
        case types.SAVE_CREDIT_CARD_DATA:
            return {
                ...state,
                selectedCardData: creditCardData,
            };
        case types.LIST_OTHER_BANK_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LIST_OTHER_BANK_SUCCESS:
            return {
                ...state,
                fetching: false,
                list: newList,
                currentPage,
                totalPages,
            };
        case types.LIST_OTHER_BANK_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_BANKS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LIST_BANKS_SUCCESS:
            return {
                ...state,
                fetching: false,
                listBanks,
            };
        case types.LIST_BANKS_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        default:
            return state;
    }
};

export const actions = {
    getCreditCards: (pageNumber, searchWord) => ({
        type: types.LIST_OTHER_BANK_REQUEST,
        pageNumber,
        searchWord,
    }),
    getBanks: (codeBranch) => ({
        type: types.LIST_BANKS_REQUEST,
        codeBranch,
    }),
    saveCreditCard: (creditCardData) => ({
        type: types.SAVE_CREDIT_CARD_DATA,
        creditCardData,
    }),
};

export const selectors = {
    getFetching: ({ creditCardOtherBank }) => creditCardOtherBank.fetching,
    getCreditCardsList: ({ creditCardOtherBank }) => creditCardOtherBank.list,
    getListBank: ({ creditCardOtherBank }) => creditCardOtherBank.listBanks,
    getCreditCardData: ({ creditCardOtherBank }) => creditCardOtherBank.selectedCardData,
    getIdSelectedCard: (id) => id,
};
