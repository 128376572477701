import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { bool, number } from "prop-types";
import TextField from "pages/_components/fields/TextField";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "transactions.list.filters.order";

class NoOrderFilter extends Component {
    static propTypes = {
        fetching: bool,
        maxLength: number,
    };

    static defaultProps = {
        fetching: false,
        maxLength: 16,
    };

    handleChange(e) {
        const { value } = e.target;
        if (/^\d*$/.test(value)) {
            e.target.value = value;
        } else {
            e.preventDefault();
        }
    }

    render() {
        const { fetching, filterValues, maxLength } = this.props;
        return (
            <Field
                component={TextField}
                idForm={FORM_ID}
                name="order"
                type="number"
                maxLength={maxLength}
                onInput={this.handleChange}
            />
        );
    }
}

export default NoOrderFilter;
