import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { channelsSelectors } from "reducers/administration";
import { actions as formActions } from "reducers/form";
import { channelsActions } from "reducers/administration/advanced";

import ChannelsConfirm from "pages/administration/_components/ChannelsConfirm";

const mapStateToProps = (state) => ({
    caps: channelsSelectors.getCaps(state),
    topAmount: channelsSelectors.getTopAmount(state),
    user: channelsSelectors.getUser(state),
    credentialGroups: channelsSelectors.getCredentialGroups(state),
    idTransaction: channelsSelectors.getIdTransaction(state),
    idActivity: channelsSelectors.getIdActivity(state),
    capsProd: channelsSelectors.getCapsProd(state),
    products: channelsSelectors.getProducts(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(channelsActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsConfirm);
