/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import moment from "moment";
import { PRODUCT_TYPE, TYPE_FILTER } from "constants.js";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Table from "rc-table";
import Text from "pages/_components/Text/Text";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Select from "pages/forms/_components/_fields/Select";
import { arrayOf, bool, func, number, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as checksActions, selectors as checksSelectors } from "reducers/checks";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import * as configUtil from "util/config";
import * as i18n from "util/i18n";
import * as numberUtils from "util/number";
import PaginatorButtonsBar from "pages/_components/PaginatorButtonsBar";
import DateField from "pages/_components/fields/DateField";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import Image from "pages/_components/Image";
import NoResults from "pages/_components/NoResultsMessage";
import { Collapse } from "react-bootstrap";
import Tooltip from "pages/_components/Tooltip";
import { format } from "date-fns";
import ChecksItem from "./_components/ChecksItem";

const { decimalSeparator, thousandSeparator } = numberUtils.numberFormat();

const Checks = (props) => {
    const {
        dispatch,
        isDesktop,
        isFetching,
        checks,
        accounts,
        offset,
        totalCount,
        pageNumber,
        fetchingAccounts,
        checkFilters,
    } = props;

    const checksPerPage = configUtil.getInteger("checksPerPage") || 10;
    const maxRangeDays = configUtil.getInteger("checks.range.maxdays");
    const minDateRange = configUtil.getInteger("checks.range.months.min");
    const [open, setOpen] = useState(checkFilters?.filterStatus ?? true);
    const blueStatus = ["C", "G", "H"];
    const redStatus = ["A", "D", "E", "F", "O", "R", "S", "T", "V", "W", "Y"];
    const grayStatus = ["N", "X"];

    const typeFilterOptionsSelect = [
        {
            value: TYPE_FILTER.DATE,
            label: i18n.get("checks.types.filter.date"),
        },
        /* {
            value: TYPE_FILTER.AMOUNT,
            label: i18n.get("checks.types.filter.amount"),
        }, */
        {
            value: TYPE_FILTER.NUMBER,
            label: i18n.get("checks.types.filter.number"),
        },
    ];

    const accountsOptions = accounts
        ?.filter(({ productType }) => productType === PRODUCT_TYPE.ACCOUNT_CC)
        .map(({ number, numberMask, ownerName, productTypeBackend, currency }) => ({
            value: number,
            label: `${numberMask} - ${ownerName} - ${productTypeBackend} - ${currency}`,
        }));

    const [amountValueStart, setAmountValueStart] = useState("");
    const [amountValueEnd, setAmountValueEnd] = useState("");
    const [numberValue, setNumberValue] = useState("");
    const [downloading, setDownloading] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState("");
    const [selectedTypeFilter, setSelectedTypeFilter] = useState(TYPE_FILTER.DATE);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [errorPeriod, setErrorPeriod] = useState(false);
    const [errorRange, setErrorRange] = useState(false);
    const [afterSearch, setAfterSearch] = useState(false);
    const [numberChanged, setNumberChanged] = useState(false);
    const [searchFilters, setSearchFilter] = useState({});

    const handleClickDownload = (format) => {
        if (isValidForm()) {
            setDownloading(true);
            dispatch(checksActions.downloadChecks({ ...searchFilters, nextIndex: 0 }, format, onFinishDownload));
        }
    };

    const documents = [
        {
            label: "accounts.pdfFile",
            onClick: () => handleClickDownload("pdf"),
        },
        {
            label: "accounts.xlsFile",
            onClick: () => handleClickDownload("xls"),
        },
        {
            label: "accounts.txtFile",
            onClick: () => handleClickDownload("txt"),
        },
    ];

    const setFilterValue = (setter, value, defaultValue) => {
        setter(value ?? defaultValue);
    };

    useEffect(() => {
        setFilterValue(setSelectedTypeFilter, checkFilters?.selectedTypeFilter, TYPE_FILTER.DATE);
        setFilterValue(setSelectedAccount, checkFilters?.selectedAccount, "");
        setFilterValue(setAmountValueStart, checkFilters?.amountValueStart, "");
        setFilterValue(setAmountValueEnd, checkFilters?.amountValueEnd, "");
        setFilterValue(setNumberValue, checkFilters?.numberValue, "");
        setFilterValue(setStartDate, checkFilters?.startDate ? moment(checkFilters.startDate) : null, null);
        setFilterValue(setEndDate, checkFilters?.endDate ? moment(checkFilters.endDate) : null, null);
        if (checkFilters) {
            setSearchFilter({
                ...checkFilters,
                idAccount: checkFilters.selectedAccount,
                typeFilter: checkFilters.selectedTypeFilter,
                checkNumber: checkFilters.numberValue,
            });
        } else {
            dispatch(accountsActions.listAccounts());
            dispatch(checksActions.loadInitChecks());
        }
    }, []);

    const assignSearchFilters = (startDate, endDate, nextIndex) => {
        dispatch(
            checksActions.setSearchFilters({
                startDate,
                endDate,
                selectedAccount,
                selectedTypeFilter,
                amountValueStart,
                amountValueEnd,
                numberValue,
                nextIndex,
            }),
        );
    };

    const handleClickFilter = () => {
        const dateRange = {
            startDate: startDate ? format(startDate, "YYYY-MM-DD") : null,
            endDate: endDate ? format(endDate, "YYYY-MM-DD") : null,
        };
        const validForm =
            selectedTypeFilter === TYPE_FILTER.DATE
                ? !errorPeriod && !errorRange
                : selectedTypeFilter === TYPE_FILTER.NUMBER && !!numberValue;
        if (validForm) {
            setAfterSearch(true);
            dispatch(
                checksActions.listAllChecksRequest(
                    selectedAccount,
                    selectedTypeFilter,
                    dateRange.startDate,
                    dateRange.endDate,
                    amountValueStart,
                    amountValueEnd,
                    numberValue,
                ),
            );
            assignSearchFilters(dateRange.startDate, dateRange.endDate, 0);
        }
        const filters = {
            ...dateRange,
            idAccount: selectedAccount,
            typeFilter: selectedTypeFilter,
            amountValueStart,
            amountValueEnd,
            nextIndex: 0,
            checkNumber: numberValue,
        };
        setSearchFilter(filters);
    };

    const handleStartDate = (date) => {
        if (endDate) {
            if (date.isAfter(endDate)) {
                setErrorPeriod(true);
            } else {
                setErrorPeriod(false);
            }
            if (endDate.diff(date, "days") > maxRangeDays) {
                setErrorRange(true);
            } else {
                setErrorRange(false);
            }
        }
        setStartDate(date);
    };

    const handleEndDate = (date) => {
        if (startDate) {
            if (startDate.isAfter(date)) {
                setErrorPeriod(true);
            } else {
                setErrorPeriod(false);
            }
            if (date.diff(startDate, "days") > maxRangeDays) {
                setErrorRange(true);
            } else {
                setErrorRange(false);
            }
        }
        setEndDate(date);
    };

    const handleBack = () => {
        dispatch(push(`/desktop`));
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleClickFilter();
        }
    };

    const onRowClick = (record) => {
        dispatch(checksActions.setSearchFilters({ ...checkFilters, filterStatus: open }));
        dispatch(push(`/checks/${record.key}`));
    };

    const isValidForm = () => {
        if (selectedTypeFilter === TYPE_FILTER.DATE) {
            return !!startDate && !!endDate && endDate.isSameOrAfter(startDate) && !errorRange && !!selectedAccount;
        }
        if (selectedTypeFilter === TYPE_FILTER.NUMBER) {
            return !!selectedAccount && numberValue !== "";
        }
        return false;
    };

    const renderHeaderList = () => {
        const result = [
            {
                key: "numberOfCheck",
                dataIndex: "numberOfCheck",
                title: i18n.get("checks.table.item.number"),
                width: 75,
            },
            {
                key: "date",
                dataIndex: "registrationDate",
                title: i18n.get("checks.table.item.date"),
                width: 75,
            },
            {
                key: "user",
                dataIndex: "user",
                title: i18n.get("checks.table.item.user"),
                width: 75,
            },
            {
                key: "status",
                dataIndex: "status",
                title: i18n.get("checks.table.item.status"),
                width: 75,
            },
            {
                key: "amount",
                dataIndex: "amount",
                title: i18n.get("checks.table.item.amount"),
                width: 75,
                align: "right",
            },
        ];
        return result;
    };

    const renderFilterDate = () => (
        <>
            <Col xs={12} md={3}>
                <Text
                    component="label"
                    htmlFor="react-select-accountBy-input"
                    labelKey="checkbooks.filter.dateFrom.label"
                    className="data-label"
                    size="5"
                    bold
                />
                <DateField
                    idForm="checks.filters"
                    name="startDate"
                    field={{ name: "startDate" }}
                    hideLabel
                    labelNoMarginTop
                    handleChange={handleStartDate}
                    minDate={moment().add(-minDateRange, "months")}
                    maxDate={moment()}
                    popperPlacement="bottom"
                    valueSelectedDate={startDate}
                    popperModifiers={{
                        flip: {
                            behavior: ["bottom"],
                        },
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                />
            </Col>
            <Col xs={12} md={3}>
                <Text
                    component="label"
                    htmlFor="react-select-accountBy-input"
                    labelKey="checkbooks.filter.dateTo.label"
                    className="data-label"
                    size="5"
                    bold
                />
                <DateField
                    idForm="checks.filters"
                    name="endDate"
                    field={{ name: "endDate" }}
                    hideLabel
                    labelNoMarginTop
                    handleChange={handleEndDate}
                    minDate={startDate || moment().add(-minDateRange, "months")}
                    maxDate={moment()}
                    popperPlacement="bottom"
                    valueSelectedDate={endDate}
                    popperModifiers={{
                        flip: {
                            behavior: ["bottom"],
                        },
                    }}
                    error={
                        (errorPeriod && i18n.get("checkbooks.filter.period.msg.errorperiod")) ||
                        (errorRange &&
                            i18n.get("checkbooks.filter.period.msg.errorrange", "", {
                                days: maxRangeDays,
                            }))
                    }
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                />
            </Col>
        </>
    );
    const renderFilterAmount = () => (
        <>
            <Col xs={6} md={3}>
                <Box className="form-group">
                    <FieldLabel labelKey="checkbooks.filter.amountFrom.label" labelNoMarginTop />
                    <Box className="input-group">
                        <NumberFormat
                            id="amountFilter"
                            name="amountFilter"
                            className="form-control"
                            type="text"
                            onChange={(event) =>
                                setAmountValueStart(
                                    numberUtils.createDecimalNumber(event.target.value, decimalSeparator),
                                )
                            }
                            maxLength="20"
                            value={amountValueStart}
                            placeholder="0.00"
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            fixedDecimalScale
                            autoComplete="off"
                            inputMode="decimal"
                            onKeyDown={handleKeyDown}
                        />
                    </Box>
                </Box>
            </Col>
            <Col xs={6} md={3}>
                <Box className="form-group">
                    <FieldLabel labelKey="checkbooks.filter.amountTo.label" labelNoMarginTop />
                    <Box className="input-group">
                        <NumberFormat
                            id="amountFilterEnd"
                            name="amountFilterEnd"
                            className="form-control"
                            type="text"
                            onChange={(event) =>
                                setAmountValueEnd(numberUtils.createDecimalNumber(event.target.value, decimalSeparator))
                            }
                            maxLength="20"
                            value={amountValueEnd}
                            placeholder="0.00"
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            fixedDecimalScale
                            autoComplete="off"
                            inputMode="decimal"
                            onKeyDown={handleKeyDown}
                        />
                    </Box>
                </Box>
            </Col>
        </>
    );
    const renderFilterNumber = () => (
        <>
            <Col xs={12} md={6}>
                <Box className="form-group">
                    <TextSimpleField
                        labelKey="checkbooks.filter.number.label"
                        name="numberValue"
                        pattern="^[0-9]*$"
                        id="numberValue"
                        type="number"
                        maxLength="15"
                        value={numberValue}
                        onChange={(e) => {
                            setNumberChanged(true);
                            if (e.target.value.length <= 15) {
                                setNumberValue(e.target.value);
                            }
                        }}
                        error={
                            numberChanged && !numberValue && i18n.get("fields.defaultForm.defaultField.requiredError")
                        }
                    />
                </Box>
            </Col>
        </>
    );

    const renderFilterDesktop = () => (
        <Box>
            <Col xs={12} md={9}>
                <Box>
                    <Row alignY="flex-end" gapX={7} gapY={3}>
                        <Col xs={12} md={6}>
                            <Box className="form-group form-group--select">
                                <Box display="flex" alignY="center" className="data-label-special-mb">
                                    <Text
                                        component="label"
                                        htmlFor="react-select-accountBy-input"
                                        labelKey="checkbooks.list.filter.accountBy.label"
                                        className="data-label"
                                        size="5"
                                        bold
                                    />
                                </Box>
                                <Box className="input-group">
                                    <Select
                                        id="accountBy"
                                        name="accountBy"
                                        label="transactions.list.filter.accountBy.label"
                                        onChange={(option) => {
                                            setSelectedAccount(option.value);
                                        }}
                                        options={accountsOptions}
                                        value={selectedAccount}
                                    />
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={12} md={6}>
                            <Box className="form-group form-group--select">
                                <Box display="flex" alignY="center" className="data-label-special-mb">
                                    <Text
                                        component="label"
                                        htmlFor="react-select-filterType-input"
                                        labelKey="checkbooks.list.filter.filterType.label"
                                        className="data-label"
                                        size="5"
                                        bold
                                    />
                                </Box>
                                <Box className="input-group">
                                    <Select
                                        id="filterType"
                                        name="filterType"
                                        label="transactions.list.filter.accountBy.label"
                                        onChange={(option) => {
                                            setSelectedTypeFilter(option.value);
                                            setNumberChanged(false);
                                            setNumberValue("");
                                        }}
                                        options={typeFilterOptionsSelect}
                                        value={selectedTypeFilter}
                                    />
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </Box>
                <Box className="mt-5">
                    <Row alignY="flex-end" gapX={7} gapY={3}>
                        {selectedTypeFilter === TYPE_FILTER.DATE && renderFilterDate()}
                        {selectedTypeFilter === TYPE_FILTER.AMOUNT && renderFilterAmount()}
                        {selectedTypeFilter === TYPE_FILTER.NUMBER && renderFilterNumber()}
                        <Col xs={12} md={3}>
                            <Button
                                label="global.search"
                                bsStyle="primary"
                                className={classNames("mt-auto full-width", { "px-9": isDesktop, "px-8": !isDesktop })}
                                disabled={!isValidForm()}
                                onClick={handleClickFilter}
                            />
                        </Col>
                    </Row>
                </Box>
            </Col>
        </Box>
    );

    // const renderFilterMobile = () => (
    //     <>
    //         <Row alignY="flex-end" gapY="7">
    //             <Col xs={6}>
    //                 <Box className="form-group">
    //                     <FieldLabel labelKey="checkbooks.filter.amount.label" labelNoMarginTop />
    //                     <Box className="input-group">
    //                         <NumberFormat
    //                             id="amountFilter"
    //                             name="amountFilter"
    //                             className="form-control"
    //                             type="text"
    //                             onChange={(event) =>
    //                                 setAmountValueStart(
    //                                     numberUtils.createDecimalNumber(event.target.value, decimalSeparator),
    //                                 )
    //                             }
    //                             maxLength="20"
    //                             value={amountValueStart}
    //                             placeholder="0.00"
    //                             thousandSeparator={thousandSeparator}
    //                             decimalSeparator={decimalSeparator}
    //                             fixedDecimalScale
    //                             autoComplete="off"
    //                             inputMode="decimal"
    //                             onKeyDown={handleKeyDown}
    //                         />
    //                     </Box>
    //                 </Box>
    //             </Col>
    //             <Col xs={6}>
    //                 <Box className="form-group">
    //                     <FieldLabel labelKey="checkbooks.filter.number.label" labelNoMarginTop />
    //                     <Box className="input-group">
    //                         <input
    //                             className="form-control"
    //                             type="number"
    //                             max="9999999"
    //                             min="0"
    //                             value={numberValue}
    //                             onChange={(e) => setNumberValue(e.target.value)}
    //                             name="checkNumberInput"
    //                             placeholder=""
    //                             onKeyDown={handleKeyDown}
    //                         />
    //                     </Box>
    //                 </Box>
    //             </Col>
    //         </Row>
    //     </>
    // );
    const renderEmptyList = () => (
        <Box className="m-7">
            <Image src="images/empty.svg" />
            <Box className="text-center">
                <Text labelKey="checks.none.text" />
            </Box>
        </Box>
    );
    const onFinishDownload = () => {
        setDownloading(false);
    };

    // const handleMoreDataClick = () => {
    //     dispatch(
    //         checksActions.loadMoreRequest({
    //             pageNumber,
    //             selectedAccount,
    //             selectedTypeFilter,
    //             startDate,
    //             endDate,
    //             amountValueStart,
    //             amountValueEnd,
    //             numberValue,
    //         }),
    //     );
    // };

    const paginate = (nextIndex) => {
        const filters = {
            idAccount: selectedAccount,
            typeFilter: selectedTypeFilter,
            startDate,
            endDate,
            amountValueStart,
            amountValueEnd,
            numberValue,
            nextIndex,
        };
        dispatch(checksActions.loadMoreRequest(filters));
        assignSearchFilters(startDate, endDate, nextIndex);
    };

    const goToFirstPage = () => {
        paginate(0);
    };

    const goToPreviousPage = () => {
        paginate((pageNumber - 1) * checksPerPage - checksPerPage);
    };

    const goToNextPage = () => {
        paginate(offset);
    };

    const goToLastPage = () => {
        if (totalCount % checksPerPage === 0) {
            paginate(totalCount - checksPerPage);
        } else {
            paginate(Math.floor(totalCount / checksPerPage) * checksPerPage);
        }
    };

    const getChecksData = () =>
        checks.map((check) => ({
            key: check.numberOfCheck,
            numberOfCheck: (
                <div className="data-wrapper data-wrapper-flex">
                    <span className="data-desc">{check.numberOfCheck}</span>
                </div>
            ),
            registrationDate: (
                <div className="data-wrapper data-wrapper-flex">
                    <FormattedDate date={check.registrationDate} anotherFormat="DD/MM/YYYY" />
                </div>
            ),
            user: (
                <div className="data-wrapper data-wrapper-flex">
                    <span className="data-desc">{check.user}</span>
                </div>
            ),
            status: (
                <div
                    className={classNames(
                        "fit-content-width",
                        { "check-status check-status-text": check.status === "P" },
                        { "check-status check-status-info-text": blueStatus.includes(check.status) },
                        { "check-status check-status-err-text": redStatus.includes(check.status) },
                        { "check-status check-status-war-text": grayStatus.includes(check.status) },
                    )}>
                    <span className="data-desc">{i18n.get(`checks.status.description.${check.status}`)}</span>
                </div>
            ),
            amount: (
                <div className="data-wrapper data-wrapper-flex justify-content-flex-end" align="right">
                    <FormattedAmount quantity={check.checkAmount} noCurrency />
                </div>
            ),
        }));

    const getResultCounter = () => (
        <Text
            labelKey="global.pagination.results"
            color="text"
            size="5"
            rows={
                totalCount < 1
                    ? totalCount
                    : pageNumber === 1
                    ? checks?.length
                    : (pageNumber - 1) * checksPerPage + checks?.length
            }
            totalRows={totalCount}
        />
    );

    const getTooltipInfo = () => (
        <Tooltip
            image="images/icons/circle-info.svg"
            text="checks.tooltip.downloads.info"
            position="top-right"
            className="align-self-center"
            showProgressBar={false}
        />
    );

    return (
        <>
            <Notification scopeToShow="checks" />
            <Head
                title="checks.header.search.title"
                // {...(checks && checks.length > 0 && { exportList: documents })}
                // exportList={documents}
                {...(!isDesktop && checks && checks.length > 0 && { exportList: documents })}
                {...(isDesktop && { exportList: documents })}
                disabledExportList={!(checks && checks.length > 0)}
                isFetchingExport={downloading}
                exportListBsStyle={isDesktop ? `download ${!isValidForm() && "disabled"} ` : "only-icon"}
                backLinkTo="/checksHome"
                textBack="consult.checks.returnToChecks"
            />

            <MainContainer
                showLoader={isFetching || fetchingAccounts}
                className="px-0 border-radius-lg box-shadow-small texture-header background-white">
                <Box background="white" className="mt-5 px-7 pt-2 pb-7">
                    <Collapse in={isDesktop ? true : open}>{renderFilterDesktop()}</Collapse>
                    <Box
                        component="article"
                        background="white"
                        borderRadius="default"
                        className={classNames("pt-5 mb-5")}
                        toBorderInMobile>
                        {totalCount > 0 && (
                            <>
                                {!isDesktop ? (
                                    <Box display="flex" alignX="between" alignY="center" className="pr-5 pl-5 mb-3">
                                        {getResultCounter()}
                                        <Box>
                                            <Button
                                                label={
                                                    open
                                                        ? "global.productFilters.btnFilter.opened.label"
                                                        : "transactions.button.showFilters"
                                                }
                                                bsStyle="link"
                                                image="images/icons/filter.svg"
                                                className="mt-auto"
                                                onClick={() => {
                                                    setOpen(!open);
                                                }}
                                            />
                                            {getTooltipInfo()}
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box display="flex" alignX="between">
                                        {getResultCounter()}
                                        {getTooltipInfo()}
                                    </Box>
                                )}
                                {!isDesktop ? (
                                    <Box className="pagination-component pagination-checks-content">
                                        <Box className="children-alternate-bg-color">
                                            {checks.map((check) => (
                                                <ChecksItem
                                                    blueStatus={blueStatus}
                                                    redStatus={redStatus}
                                                    grayStatus={grayStatus}
                                                    check={check}
                                                    onRow={(record) => {
                                                        onRowClick({ ...record, key: record?.numberOfCheck });
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                ) : (
                                    <Table
                                        className="table-default"
                                        columns={renderHeaderList()}
                                        data={getChecksData()}
                                        rowKey={(record) => record.key}
                                        emptyText={i18n.get("checks.list.noRecords")}
                                        onRow={(record) => ({
                                            onClick: () => onRowClick(record),
                                        })}
                                    />
                                )}

                                <Box className="pagination-component mt-5">
                                    <PaginatorButtonsBar
                                        page={totalCount === 0 ? 0 : pageNumber}
                                        totalpages={Math.ceil(totalCount / checksPerPage)}
                                        goToFirstPage={goToFirstPage}
                                        goToPreviousPage={goToPreviousPage}
                                        goToNextPage={() => goToNextPage()}
                                        goToLastPage={goToLastPage}
                                    />
                                </Box>
                            </>
                        )}
                        {!isDesktop && (
                            <Box display="flex" alignX="between" alignY="center" className="pr-5 pl-5 mb-3">
                                {totalCount <= 0 && (
                                    <>
                                        {getResultCounter()}
                                        <Button
                                            label={
                                                open
                                                    ? "global.productFilters.btnFilter.opened.label"
                                                    : "transactions.button.showFilters"
                                            }
                                            bsStyle="link"
                                            image="images/icons/filter.svg"
                                            className="mt-auto"
                                            onClick={() => {
                                                setOpen(!open);
                                            }}
                                        />
                                    </>
                                )}
                            </Box>
                        )}
                        {totalCount === 0 &&
                            (!afterSearch ? (
                                renderEmptyList()
                            ) : (
                                <Box className="mt-10">
                                    <NoResults
                                        description={
                                            selectedTypeFilter === TYPE_FILTER.DATE
                                                ? "checks.list.empty.dates"
                                                : "checks.list.empty.checkNumber"
                                        }
                                    />
                                </Box>
                            ))}
                    </Box>
                </Box>
            </MainContainer>
        </>
    );
};

Checks.propTypes = {
    dispatch: func.isRequired,
    match: shape({}).isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    checks: shape({}).isRequired,
    accounts: arrayOf(shape({})).isRequired,
    offset: number.isRequired,
    totalCount: number.isRequired,
    pageNumber: number.isRequired,
    fetchingAccounts: bool.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: checksSelectors.getFetching(state),
    checks: checksSelectors.checks(state),
    accounts: accountsSelectors.getAccounts(state),
    offset: checksSelectors.getOffset(state),
    totalPages: checksSelectors.getTotalPages(state),
    totalCount: checksSelectors.getTotalCount(state),
    pageNumber: checksSelectors.getPageNumber(state),
    fetchingAccounts: accountsSelectors.getFetching(state),
    checkFilters: checksSelectors.getSearchFilters(state),
});

export default connect(mapStateToProps)(resizableRoute(Checks));
