export default {
    DOWNLOAD_TICKET_FAILURE: "forms/DOWNLOAD_TICKET_FAILURE",
    DOWNLOAD_TICKET_REQUEST: "forms/DOWNLOAD_TICKET_REQUEST",
    DOWNLOAD_TICKET_SUCCESS: "forms/DOWNLOAD_TICKET_SUCCESS",

    DOWNLOAD_IMG_TICKET_FAILURE: "forms/DOWNLOAD_IMG_TICKET_FAILURE",
    DOWNLOAD_IMG_TICKET_REQUEST: "forms/DOWNLOAD_IMG_TICKET_REQUEST",
    DOWNLOAD_IMG_TICKET_SUCCESS: "forms/DOWNLOAD_IMG_TICKET_SUCCESS",

    SHARE_IMG_TICKET: "forms/SHARE_IMG_TICKET",

    SHARE_TICKET: "forms/SHARE_TICKET",
    SHARE_TICKET_SUCCESS: "forms/SHARE_TICKET_SUCCESS",
    SHARE_TICKET_FAILURE: "forms/SHARE_TICKET_FAILURE",

    READ_FORM_SUCCESS: "forms/READ_FORM_SUCCESS",
    READ_FORM_FAILURE: "forms/READ_FORM_FAILURE",

    PREVIEW_FORM_REQUEST: "forms/PREVIEW_FORM_REQUEST",
    PREVIEW_FORM_SUCCESS: "forms/PREVIEW_FORM_SUCCESS",
    ADD_EXTRA_PARAMS: "forms/ADD_EXTRA_PARAMS",

    SEND_FORM_REQUEST: "forms/SEND_FORM_REQUEST",
    SEND_FORM_CREDENTIAL_FAILURE: "forms/SEND_FORM_CREDENTIAL_FAILURE",
    SEND_FORM_DATA_FAILURE: "forms/SEND_FORM_DATA_FAILURE",
    SEND_FORM_DATA_INSUFFICIENT_FOUND: "forms/SEND_FORM_DATA_INSUFFICIENT_FOUND",
    SEND_FORM_SUCCESS: "forms/SEND_FORM_SUCCESS",

    SAVE_DRAFT_REQUEST: "forms/SAVE_DRAFT_REQUEST",
    SAVE_DRAFT_SUCCESS: "forms/SAVE_DRAFT_SUCCESS",
    SAVE_DRAFT_FAILURE: "forms/SAVE_DRAFT_FAILURE",

    READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS: "forms/READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS",
    READ_TRANSACTION_REQUEST: "forms/READ_TRANSACTION_REQUEST",
    READ_TRANSACTION_SUCCESS: "forms/READ_TRANSACTION_SUCCESS",
    READ_TRANSACTION_FAILURE: "forms/READ_TRANSACTION_FAILURE",

    READ_TRANSACTION_TICKET_SUCCESS: "forms/READ_TRANSACTION_TICKET_SUCCESS",

    CANCEL_TRANSACTION_PRE_ERROR: "forms/CANCEL_TRANSACTION_PRE_ERROR",
    CANCEL_TRANSACTION_PRE_REQUEST: "forms/CANCEL_TRANSACTION_PRE_REQUEST",
    CANCEL_TRANSACTION_PRE_SUCCESS: "forms/CANCEL_TRANSACTION_PRE_SUCCESS",

    CANCEL_TRANSACTION_REQUEST: "forms/CANCEL_TRANSACTION_REQUEST",
    CANCEL_TRANSACTION_SUCCESS: "forms/CANCEL_TRANSACTION_SUCCESS",

    MODIFY_TRANSACTION_REQUEST: "forms/MODIFY_TRANSACTION_REQUEST",
    MODIFY_TRANSACTION_FAILURE: "forms/MODIFY_TRANSACTION_FAILURE",

    SIGN_TRANSACTION_PREVIEW_REQUEST: "forms/SIGN_TRANSACTION_PREVIEW_REQUEST",
    SIGN_TRANSACTION_PREVIEW_SUCCESS: "forms/SIGN_TRANSACTION_PREVIEW_SUCCESS",
    SIGN_TRANSACTION_PREVIEW_FAILURE: "forms/SIGN_TRANSACTION_PREVIEW_FAILURE",
    SIGN_TRANSACTION_REQUEST: "forms/SIGN_TRANSACTION_REQUEST",
    CLOSE_TRANSACTION_PREVIEW: "forms/CLOSE_TRANSACTION_PREVIEW",

    LIST_CREDENTIALS_GROUPS_REQUEST: "forms/LIST_CREDENTIALS_GROUPS_REQUEST",
    LIST_CREDENTIALS_GROUPS_SUCCESS: "forms/LIST_CREDENTIALS_GROUPS_SUCCESS",

    SET_DATA: "forms/SET_DATA",

    FORM_CLOSED: "forms/FORM_CLOSED",

    SET_MODE: "forms/SET_MODE",

    PRE_CUSTOM_FORM_REQUEST: "forms/PRE_CUSTOM_FORM_REQUEST",
    PRE_CUSTOM_FORM_SUCCESS: "forms/PRE_CUSTOM_FORM_SUCCESS",
    PRE_CUSTOM_FORM_FAILURE: "forms/PRE_CUSTOM_FORM_FAILURE",

    PRE_VALIDATIONS_FORM_REQUEST: "forms/PRE_VALIDATIONS_FORM_REQUEST",
    PRE_VALIDATIONS_FORM_SUCCESS: "forms/PRE_VALIDATIONS_FORM_SUCCESS",
    PRE_VALIDATIONS_FORM_FAILURE: "forms/PRE_VALIDATIONS_FORM_FAILURE",

    PRE_FORM_REQUEST: "forms/PRE_FORM_REQUEST",
    PRE_FORM_SUCCESS: "forms/PRE_FORM_SUCCESS",
    PRE_FORM_FAILURE: "forms/PRE_FORM_FAILURE",
    GO_BACK_CLEAN_TRANSACTION: "forms/GO_BACK_CLEAN_TRANSACTION",
    CHANGE_TRANSACTION_HIDE: "forms/CHANGE_TRANSACTION_HIDE",

    CHANGE_TYPE_LOAN: "forms/CHANGE_TYPE_LOAN",
    CHANGE_RADIO_BUTTON_OPTION: "forms/CHANGE_RADIO_BUTTON_OPTION",

    SET_FORM_FETCHING: "forms/SET_FORM_FETCHING",

    BANK_DESCRIPTION_REQUEST: "forms/BANK_DESCRIPTION_REQUEST",
    BANK_DESCRIPTION_SUCCESS: "forms/BANK_DESCRIPTION_SUCCESS",
    BANK_DESCRIPTION_FAILURE: "forms/BANK_DESCRIPTION_FAILURE",
    PREVIEW_FORM_CONFIRM: "forms/PREVIEW_FORM_CONFIRM",
    VIEW_FORM_WALLY: "forms/VIEW_FORM_WALLY",
    CONFIRM_MOVEMENT_PENDING_WALLY: "forms/CONFIRM_MOVEMENT_PENDING_WALLY",
    CONFIRM_SUSPEND_CHECK: "forms/CONFIRM_SUSPEND_CHECK",
    DELETE_CREDIT_CARD_OTHER_BANK: "forms/DELETE_CREDIT_CARD_OTHER_BANK",
    CONFIRM_PAYMENT_FAST_RECHARGE: "forms/CONFIRM_PAYMENT_FAST_RECHARGE",
    REDIRECT_SERVICE_REGISTRATION: "forms/REDIRECT_SERVICE_REGISTRATION",
    CLEAN_TRANSACTION: "forms/CLEAN_TRANSACTION",
};
