import React from "react";
import { node, string } from "prop-types";
import Box from "./Box";
import Text from "./Text";
import Image from "./Image";

const Help = ({ titleKey, textKey, defaultValueTitle, defaultValueText, iconSrc, children }) => (
    <Box className="p-4" background="background-disabled" borderRadius="lg">
        <Box display="flex">
            <Box>
                <Image src={iconSrc || "images/icons/icon_notice.svg"} />
            </Box>
            <Box className="ml-4">
                {titleKey && (
                    <Box>
                        <Text labelKey={titleKey} bold defaultValue={defaultValueTitle}/>
                    </Box>
                )}
                {textKey && (
                    <Box className="mt-3">
                        <Text color="text-grey" size="6" labelKey={textKey} defaultValue={defaultValueText}/>
                    </Box>
                )}
            </Box>
            {children && (
                <Box>
                    {children}
                </Box>
            )}             
        </Box>
    </Box>
);

Help.propTypes = {
    titleKey: string,
    textKey: string,
    defaultValueTitle: string,
    defaultValueText: string,
    iconSrc: string,
    children: node.isRequired,
};

Help.defaultProps = {
    titleKey: undefined,
    textKey: undefined,
    defaultValueTitle: undefined,
    defaultValueText: undefined,
    iconSrc: undefined,
};

export default Help;
