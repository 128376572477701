import React from "react";
import { bool, string } from "prop-types";
import { MainContainerContext } from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import CustomDatePicker from "pages/_components/fields/datepicker/CustomDatePicker";
import Image from "pages/_components/Image";
import Box from "pages/_components/Box";
import { isMobile } from "react-device-detect";
import Text from "pages/_components/Text/Text";
import Button from "pages/_components/Button/Button";

class DatePickerWithCustomInput extends React.Component {
    startRef = React.createRef();

    static propTypes = {
        isMobile: bool.isRequired,
        popperPlacement: string,
    };

    static defaultProps = {
        popperPlacement: null,
    };

    onKeyDown = (e) => {
        if (e.key === "Tab") {
            // Cierra el DatePicker si presiona Tab
            this.startRef.current.setOpen(false);
        } else if (!/[0-9/]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
            // Permite solo números, "/", Backspace, y teclas de navegación
            e.preventDefault();
        }
    };

    renderCustomHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => {
        console.log("Date in renderCustomHeader: ", date); // Verificar el valor de `date`

        return (
            <div className="custom-header">
                {isMobile && (
                    <div>
                        <Image src="images/bottom_sheet_handle.svg" height="5" wrapperWidth="10" wrapperHeight="4" />
                    </div>
                )}
                <Box display="flex" alignX="center" alignY="center">
                    {/* Doble flecha para retroceder un año */}
                    <Button onClick={() => changeYear(date.year() - 1)}>
                        <Image
                            src="images/util/caret-left-double.svg"
                            height="4"
                            wrapperWidth="4"
                            wrapperHeight="4"
                            className="fill-primary"
                        />
                    </Button>
                    <Button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        <Image
                            src="images/util/caret-left.svg"
                            height="4"
                            wrapperWidth="4"
                            wrapperHeight="4"
                            className="fill-primary"
                        />
                    </Button>
                    <Text size={isMobile ? "3" : "5"} className="text-capitalize mx-4">
                        {date.format("MMMM")} {date.year()}
                    </Text>
                    <Button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        <Image
                            src="images/util/caret-right.svg"
                            height="4"
                            wrapperWidth="4"
                            wrapperHeight="4"
                            className="fill-primary"
                        />
                    </Button>
                    {/* Doble flecha para avanzar un año */}
                    <Button onClick={() => changeYear(date.year() + 1)}>
                        <Image
                            src="images/util/caret-right-double.svg"
                            height="4"
                            wrapperWidth="6"
                            wrapperHeight="4"
                            className="fill-primary"
                        />
                    </Button>
                </Box>
            </div>
        );
    };

    render() {
        const { isMobile, popperPlacement } = this.props;
        if (isMobile) {
            return (
                <MainContainerContext.Consumer>
                    {(ref) => (
                        <CustomDatePicker
                            {...this.props}
                            //onKeyDown={(e) => e.preventDefault()}
                            isMobile={isMobile}
                            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                            peekNextMonth
                            // showMonthDropdown
                            // showYearDropdown
                            // dropdownMode="select"
                            withPortal
                            autoComplete="off"
                            popperPlacement={popperPlacement || "bottom-start"}
                            popperModifiers={{
                                flip: {
                                    behavior: ["bottom-start"], // don't allow it to flip to be above
                                },
                            }}
                            viewContentRef={ref}
                            renderCustomHeader={this.renderCustomHeader}
                            onFocus={(e) => e.target.blur()} // Evita que aparezca el cursor al enfocarse
                        />
                    )}
                </MainContainerContext.Consumer>
            );
        }

        return (
            <CustomDatePicker
                {...this.props}
                isMobile={isMobile}
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                autoComplete="off"
                ref={this.startRef}
                onKeyDown={this.onKeyDown}
                popperPlacement={popperPlacement || "bottom-start"}
                popperModifiers={{
                    flip: {
                        behavior: ["bottom-start"], // don't allow it to flip to be above
                    },
                }}
                renderCustomHeader={this.renderCustomHeader}
            />
        );
    }
}

export default resizableRoute(DatePickerWithCustomInput);
