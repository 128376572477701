import { arrayOf, func, node, shape, string } from "prop-types";
import React, { createContext, useContext, useState } from "react";
import { connect } from "react-redux";
import { actions as transactionActions } from "../reducers/transactions";

export const PDF_CONTENT_TYPE = "application/pdf";
export const XLS_CONTENT_TYPE = "application/vnd.ms-excel";

export const EnumTransactionFilterType = {
    TRANSACTION: "TRANSACTION",
    PENDING_TRANSACTION: "PENDING_TRANSACTION",
};

export const URL_LIST_TRANSACTION = "/transactions.download";
export const URL_LIST_TRANSACTION_HISTORY = "/transactions.history.download";

export const TransactionFilterContext = createContext({});
const TransactionFilterProvider = (props) => {
    const {
        children,
        dispatch,
        defaultStatusList,
        transactionFilterType,
        url = URL_LIST_TRANSACTION,
        defaultFilters,
        lastRegistryNumber,
    } = props;

    const [operationType, setOperationType] = useState(undefined);
    const [dateFrom, setDateFrom] = useState(undefined);
    const [dateTo, setDateTo] = useState(undefined);
    const [channelType, setChannelType] = useState(undefined);
    const [status, setStatus] = useState(defaultStatusList);

    const downloadFilterTransaction = (contentType, filters) => {
        let dateFromFilter = filters?.dateFrom;
        if (!dateFromFilter) {
            dateFromFilter = defaultFilters ? defaultFilters.dateFrom : null;
        }
        let dateToFilter = filters?.dateTo;
        if (!dateToFilter) {
            dateToFilter = defaultFilters ? defaultFilters.dateTo : null;
        }

        dispatch(
            transactionActions.downloadListRequest(
                {
                    operationType: filters?.selectedOperationType || null,
                    dateFrom: dateFromFilter,
                    dateTo: dateToFilter,
                    channelType: filters?.channelType ? filters?.channelType : null,
                    status: filters?.status ? filters?.status : null,
                    contentType,
                    selectedTransactionType: filters?.selectedTransactionType || null,
                    user: filters?.user || null,
                    order: filters?.order || null,
                    maxAmount: filters?.maxAmount || null,
                    minAmount: filters?.minAmount || null,
                    onlyPendings: filters?.onlyPendings || false,
                },
                url || URL_LIST_TRANSACTION,
                lastRegistryNumber,
            ),
        );
    };

    const clearFilters = () => {
        setChannelType(undefined);
        setDateFrom(undefined);
        setDateTo(undefined);
        if (!(transactionFilterType && transactionFilterType === EnumTransactionFilterType.PENDING_TRANSACTION)) {
            setStatus(undefined);
        }
    };

    const contextData = {
        operationType,
        setOperationType,
        dateFrom,
        setDateFrom,
        dateTo,
        setDateTo,
        channelType,
        setChannelType,
        status,
        setStatus,
        downloadFilterTransaction,
        clearFilters,
    };

    return (
        <TransactionFilterContext.Provider value={contextData} {...props}>
            {children}
        </TransactionFilterContext.Provider>
    );
};
TransactionFilterProvider.propTypes = {
    children: node.isRequired,
    dispatch: func.isRequired,
    defaultStatusList: arrayOf(string),
    transactionFilterType: string,
    url: string,
    defaultFilters: shape({}),
    lastRegistryNumber: string,
};
TransactionFilterProvider.defaultProps = {
    defaultStatusList: undefined,
    transactionFilterType: undefined,
    url: URL_LIST_TRANSACTION,
    defaultFilters: undefined,
    lastRegistryNumber: "0",
};
export default connect()(TransactionFilterProvider);

export const useTransactionFilterProvider = () => {
    const context = useContext(TransactionFilterContext);
    if (!context) {
        throw new Error("useTransactionFilterProvider only can be used inside TransactionFilterContext");
    }
    return context;
};
