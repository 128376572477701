import * as I18n from "util/i18n";

import { push, replace, routerActions } from "react-router-redux";
import { selectors as configSelectors, types as configTypes } from "reducers/config";
import { selectors as i18nSelectors, types as i18nTypes } from "reducers/i18n";
import { all, call, fork, put, select, spawn } from "redux-saga/effects";

import { MAX_FAILED_TIMES } from "constants.js";
import { actions as notificationActions } from "reducers/notification";
import { actions as sessionActions } from "reducers/session";
import globalTypes from "reducers/types/global";
import { delay } from "redux-saga";
import accounts from "sagas/accounts";
import administrationAdvanced from "sagas/administration/advanced";
import administrationTicket from "sagas/administration/common/administrationTicket";
import administrationGroups from "sagas/administration/groups";
import administrationMedium from "sagas/administration/medium";
import bankReference from "sagas/bankReference";
import restrictions from "sagas/administration/restrictions";
import administrationSimple from "sagas/administration/simple";
import administrationUsers from "sagas/administration/users";
import administrationUsersInvite from "sagas/administration/usersInvite";
import authenticateHandler from "sagas/authenticateHandler";
import bankSelector from "sagas/bankSelector";
import campaigns from "sagas/campaigns";
import changeStatusProductSagas from "sagas/changeStatusProductSagas";
import chatbot from "sagas/chatbot";
import checks from "sagas/checks";
import communication from "sagas/communication";
import communicationTrays from "sagas/communicationTrays";
import communications from "sagas/communications";
import config from "sagas/config";
import creditCard from "sagas/creditCard";
import creditCardOtherBank from "sagas/creditCardOtherBank";
import creditCardMovementDetails from "sagas/creditCardMovementDetails";
import creditCardRequest from "sagas/creditCardRequest";
import creditCards from "sagas/creditCards";
import creditLines from "sagas/creditLines";
import debitCards from "sagas/debitCards";
import deposits from "sagas/deposits";
import desktop from "sagas/desktop";
import enrollment from "sagas/enrollment";
import files from "sagas/files";
import finances from "sagas/finances";
import fingerprint from "sagas/fingerprint";
import fixedTermDeposit from "sagas/fixedTermDeposit/fixedTermDeposit.saga";
import form from "sagas/form";
import formFields from "sagas/formFields";
import frequentDestination from "sagas/frequentDestination";
import environment from "sagas/generalConditions";
import i18n from "sagas/i18n";
import loans from "sagas/loans";
import loansPayment from "sagas/loansPayment";
import login from "sagas/login";
import changeExpiredPassword from "sagas/changeExpiredPassword";
import massPayments from "sagas/massPayments";
import onboarding from "sagas/onboarding";
import payService from "sagas/payService";
import pointsOfInterest from "sagas/pointsOfInterest";
import products from "sagas/products";
import pushNotifications from "sagas/pushNotifications";
import recoveryPassword from "sagas/recoveryPassword";
import recoveryUserSagas from "sagas/recoveryUserSagas";
import servicePayments from "sagas/servicePayments";
import session from "sagas/session";
import settings from "sagas/settings";
import softToken from "sagas/softToken/softToken";
import softTokenDisclaimer from "sagas/softTokenDisclaimer/softTokenDisclaimer.saga";
import status from "sagas/status";
import template from "sagas/template";
import requestAndComplaints from "sagas/requestAndComplaints";
import tour from "sagas/tour";
import transactionLines from "sagas/transactionLines";
import transactions from "sagas/transactions";
import wally from "sagas/wally";
import updateUserData from "sagas/updateUserData/updateUserData.saga";
import widgets from "sagas/widgets";
import { isMobileNativeFunc } from "util/device";
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";
import { crashLogData } from "util/crashReport/crashReport.util";
import wallet from "sagas/wallet/wallet.saga";
import { applePayRemovePassButton } from "util/wallet/wallet.util";
import productRequest from "sagas/productRequest";
import transferSupport from "sagas/transferSupport/transferSupport.saga";
import unlockUser from "sagas/unlockUser";
import paginatedTable from "sagas/paginatedTable";
import secondFactor from "sagas/secondFactor";
import documents from "sagas/documents";

const REDIRECT_USER_BLOCKED = "/desktop";
const SCOPE_USER_BLOCKED = "desktop";

const sagas = [
    ...accounts,
    ...bankReference,
    ...deposits,
    ...campaigns,
    ...chatbot,
    ...checks,
    ...creditCards,
    ...creditCardOtherBank,
    ...debitCards,
    ...creditCard,
    ...creditCardMovementDetails,
    ...communications,
    ...communication,
    ...communicationTrays,
    ...config,
    ...desktop,
    ...enrollment,
    ...fingerprint,
    ...form,
    ...i18n,
    ...loans,
    ...loansPayment,
    ...onboarding,
    ...products,
    ...pushNotifications,
    ...recoveryPassword,
    ...changeExpiredPassword,
    ...recoveryUserSagas,
    ...changeStatusProductSagas,
    ...status,
    ...session,
    ...settings,
    ...template,
    ...widgets,
    ...transactions,
    ...bankSelector,
    ...login,
    ...administrationAdvanced,
    ...administrationGroups,
    ...administrationMedium,
    ...administrationSimple,
    ...administrationTicket,
    ...administrationUsers,
    ...administrationUsersInvite,
    ...files,
    ...formFields,
    ...pointsOfInterest,
    ...creditCardRequest,
    ...massPayments,
    ...transactionLines,
    ...restrictions,
    ...requestAndComplaints,
    ...frequentDestination,
    ...servicePayments,
    ...environment,
    ...wally,
    ...softToken,
    ...authenticateHandler,
    ...payService,
    ...softTokenDisclaimer,
    ...tour,
    ...fixedTermDeposit,
    ...finances,
    ...wallet,
    ...updateUserData,
    ...productRequest,
    ...creditLines,
    ...transferSupport,
    ...unlockUser,
    ...paginatedTable,
    ...secondFactor,
    ...documents,
];

export default function* rootSaga() {
    yield all(
        sagas.map((saga) =>
            spawn(function* listenErrors() {
                let isSyncError = false;
                const resetSyncError = () => {
                    isSyncError = false;
                };
                let httpError = false;
                while (true) {
                    httpError = false;
                    isSyncError = true;
                    try {
                        setTimeout(resetSyncError);

                        yield call(function* execSaga() {
                            yield saga;
                        });
                        // eslint-disable-next-line no-console
                        console.error(
                            "Unexpected root saga termination. " +
                                "The root sagas are supposed to be sagas that live during the whole app lifetime!",
                            saga,
                        );
                    } catch (error) {
                        httpError = typeof error.httpError !== "undefined";
                        if (!httpError && isSyncError) {
                            throw new Error(`${saga.name} was terminated because it threw an exception on startup.`);
                        }
                        yield call(crashLogData, error);
                        yield call(handleError, error);
                    }

                    if (!httpError) {
                        // Para evitar que fallas infinitas bloqueen el browser...
                        // eslint-disable-next-line no-console
                        console.error(saga.name, " will be restarted after 1 second");
                        yield call(delay, 1000);
                    }
                }
            }),
        ),
    );
}

export function* hideModalRedirectError() {
    yield put(changeStatusProductActions.modalHide());
    /**
     * TODO: add more modals if is necessary
     */
}

function* showAlertToken(message) {
    yield call(hideModalRedirectError);
    yield put(
        notificationActions.showNotification(
            I18n.get(message || `transaction.invalid.otp.required.${isMobileNativeFunc() ? "mobile" : "desktop"}`),
            "error",
            ["desktop"],
            true,
            1000,
        ),
    );

    yield put(routerActions.replace("/desktop"));
}

function* showAlertRemoteTimeout(message) {
    yield call(hideModalRedirectError);
    yield put(
        notificationActions.showNotification(
            I18n.get(message || "transaction.invalid.timeout.remote.error.message"),
            "error",
            ["desktop"],
            true,
            1000,
        ),
    );

    yield put(routerActions.replace("/desktop"));
}

export function* handleError(error) {
    /**
     * hide apple pay button if a error uncontrolled was throwing
     */

    yield fork(applePayRemovePassButton);
    let errorControlled = false;

    if (error.data) {
        switch (error.data.code) {
            // Add known error codes as new cases for avoid general error message

            // User is blocked
            case "COR019E":
                errorControlled = true;
                yield put(
                    notificationActions.showNotification(I18n.get("entrust.token.blocked.message"), "error", [
                        SCOPE_USER_BLOCKED,
                    ]),
                );
                yield put(routerActions.replace(REDIRECT_USER_BLOCKED));

                break;
            case "API040E":
                errorControlled = true;
                yield put(replace("/desktop"));
                yield put(notificationActions.showNotification(I18n.get("returnCode.API040E"), "warning", ["desktop"]));
                break;
            case "API900E":
                errorControlled = true;
                yield put(replace("/desktop"));
                yield put(notificationActions.showNotification(I18n.get("returnCode.API900E"), "warning", ["desktop"]));
                break;
            // Attempt to sign an expired transaction
            case "COR108E":
                errorControlled = true;
                yield put(
                    notificationActions.showNotification(I18n.get("transaction.expired.signAttempt"), "error", [
                        "transactions",
                    ]),
                );
                yield put(replace("/transactions/list"));
                break;
            case "API575E": {
                errorControlled = true;
                yield call(
                    showAlertToken,
                    `transaction.invalid.otp.required.${isMobileNativeFunc() ? "mobile" : "desktop"}`,
                );
                break;
            }
            case "API583E": {
                errorControlled = true;
                yield call(showAlertToken, "returnCode.API583E");
                break;
            }
            case "API041E": {
                errorControlled = true;
                if (isMobileNativeFunc()) {
                    yield put(
                        push({
                            pathname: "/invalidMobileVersion",
                            message: error?.data?.message,
                        }),
                    );
                }

                break;
            }
            case "API587E": {
                errorControlled = true;
                yield put(
                    push({
                        pathname: "/websiteunderconstruction",
                        message: "forms.servicePayment.outOf.service.label",
                    }),
                );
                break;
            }
            case "BAK589E": {
                errorControlled = true;
                yield put(
                    push({
                        pathname: "/error",
                        code: error.data.code,
                        idTransaction: error.data.idTransaction,
                    }),
                );

                break;
            }
            case "BAK590E": {
                errorControlled = true;
                yield call(showAlertRemoteTimeout, "returnCode.BAK590E");

                break;
            }
            case "COR047E":
            case "COR029E":
            case "COR121E": {
                errorControlled = true;
                yield put(sessionActions.logoutExternal());
                yield put({ type: globalTypes.CLEAN_UP });
                // yield put(replace("/"));
                yield put(notificationActions.showNotification(error.data.message, "warning", ["externalLayout"]));
                break;
            }
            case "COR005E": {
                errorControlled = true;
                yield put(replace("/desktop"));
                yield put(notificationActions.showNotification(error.data.message, "warning", ["desktop"]));
                break;
            }
            case "COR123E": {
                errorControlled = true;
                yield put(replace("/userBlockedAdmin"));
                break;
            }
            default:
                break;
        }

        if (!errorControlled) {
            // eslint-disable-next-line no-console
            console.error("[API Error Handler]:1", error.data, error.status);
            yield put(
                push({
                    pathname: "/error",
                    code: error.data.code,
                    message: error.data.message,
                    idTransaction: error.data.idTransaction,
                }),
            );
        }
    } else if (error.response && error.response.status === 401) {
        // eslint-disable-next-line no-console
        console.error("[API Error Handler]:2", error.response);
        // The request was made and the server responded, but with a status code outside of 2xx
        yield put({ type: globalTypes.CLEAN_UP });

        switch (error.response.data.code) {
            case "API004W":
                yield put(
                    notificationActions.showNotification(I18n.get("session.expired"), "error", ["externalLayout"]),
                );
                break;

            case "API010W":
                yield put(
                    notificationActions.showNotification(I18n.get("session.loggedinOtherDevice"), "error", [
                        "externalLayout",
                    ]),
                );
                break;
            case "API007E":
                yield put(replace("/"));
                yield put(
                    notificationActions.showNotification(I18n.get("transaction.exchangeToken.expired"), "warning", [
                        "externalLayout",
                    ]),
                );
                break;
            case undefined: // The 401 sent by the Apigateway / Authorization server doesn't send a data code
                // yield put(replace("/"));
                // yield put(
                //     notificationActions.showNotification(I18n.get("session.expired"), "warning", ["externalLayout"]),
                // );
                break;

            default:
                break;
        }
    } else if (error.request) {
        // eslint-disable-next-line no-console
        console.error("[API Error Handler]:3", error.request);
        // The request was made but no response was received

        const timesConfigFailed = yield select(configSelectors.getTimesFailed);
        const timesI18nFailed = yield select(i18nSelectors.getTimesFailed);

        if (timesConfigFailed >= MAX_FAILED_TIMES || timesI18nFailed >= MAX_FAILED_TIMES) {
            yield put(push({ pathname: "/serverError" }));
        } else {
            const lang = yield select(i18nSelectors.getLang);
            yield put({ type: configTypes.RESET_SAGAS_UPDATE, lang });
            yield put({ type: i18nTypes.RESET_SAGAS_UPDATE, lang });
            yield put(
                push({
                    pathname: "/error",
                    code: "CLI999E",
                    idTransaction: "",
                }),
            );
        }
    } else {
        // eslint-disable-next-line no-console
        console.error("[API Error Handler]:4", error.message);
        // Something happened in setting up the request that triggered an Error
        yield put(
            push({
                pathname: "/error",
                code: "CLI999E",
                idTransaction: "",
            }),
        );
    }
}
