import classNames from "classnames";
import moment from "moment";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import DatePicker from "pages/_components/fields/datepicker";
import { bool, func, instanceOf, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";

import * as i18n from "util/i18n";

class DateField extends Component {
    static propTypes = {
        cssDisabledToday: bool,
        customLabel: string,
        dateFormat: string,
        disabled: bool,
        endDate: oneOfType([instanceOf(moment), instanceOf(Date)]),
        error: string,
        field: shape({}),
        form: shape({}),
        handleChange: func,
        handleResetDateFilters: func,
        hideLabel: bool,
        hidePlaceholder: bool,
        historic: bool,
        idField: string,
        idForm: string,
        labelNoMarginTop: bool,
        maxDate: oneOfType([instanceOf(moment), instanceOf(Date)]),
        minDate: oneOfType([instanceOf(moment), instanceOf(Date)]),
        resetFilters: bool,
        showDisabledMonthNavigation: bool,
        showMonthDropdown: bool,
        showYearDropdown: bool,
        startDate: instanceOf(Date),
        valueSelectedDate: instanceOf(Date),
        placeholder: string,
        popperPlacement: string,
    };

    static defaultProps = {
        cssDisabledToday: false,
        customLabel: "",
        dateFormat: null,
        disabled: false,
        endDate: null,
        error: null,
        field: {},
        form: {},
        handleChange: () => {},
        handleResetDateFilters: null,
        hideLabel: false,
        hidePlaceholder: false,
        historic: false,
        idField: "",
        idForm: "",
        labelNoMarginTop: false,
        maxDate: moment(),
        minDate: moment().add(-6, "months"),
        resetFilters: false,
        showDisabledMonthNavigation: true,
        showMonthDropdown: false,
        showYearDropdown: false,
        startDate: null,
        valueSelectedDate: null,
        placeholder: null,
        popperPlacement: null,
    };

    state = {
        isFocused: false,
        selectedDate: null,
    };

    componentDidUpdate() {
        const { resetFilters, handleResetDateFilters } = this.props;

        if (resetFilters) {
            if (handleResetDateFilters) {
                handleResetDateFilters(false);
            }
            this.handleReset();
        }
    }

    handleReset = () => {
        const {
            field,
            form: { setFieldValue },
        } = this.props;

        this.setState({ selectedDate: null });
        setFieldValue(field.name, null);
    };

    validateDate = (selectedDate, name) => {
        const { maxDate, minDate, historic } = this.props;

        let maxDateAsMoment = null;
        if (maxDate !== null && maxDate) {
            if (maxDate._isAMomentObject) {
                maxDateAsMoment = maxDate;
            } else {
                maxDateAsMoment = moment(maxDate);
            }
        }

        let minDateAsMoment = null;
        if (minDate !== null && minDate) {
            if (minDate._isAMomentObject) {
                minDateAsMoment = minDate.startOf("day");
            } else {
                minDateAsMoment = moment(minDate).startOf("day");
            }
        }

        let minDateAux = minDateAsMoment;
        let maxDateAux = maxDateAsMoment;
        if (!historic) {
            if (selectedDate >= minDateAsMoment) {
                minDateAux = selectedDate;
            }

            if (selectedDate <= maxDateAsMoment) {
                maxDateAux = selectedDate;
            }
        }

        if (
            name !== null &&
            name &&
            ((selectedDate <= maxDateAsMoment && selectedDate > minDateAsMoment) ||
                (selectedDate < maxDateAsMoment && selectedDate >= minDateAsMoment))
        ) {
            if (name === "dateFrom") {
                minDateAux = selectedDate;
            }

            if (name === "dateTo") {
                maxDateAux = selectedDate;
            }
        } else {
            maxDateAux = selectedDate;
        }

        if (maxDateAux < minDateAux) {
            return false;
        }

        const diffMinDate = selectedDate.diff(minDateAux);
        const diffMaxDate = selectedDate.diff(maxDateAux);

        if (diffMinDate < -86400000 || diffMaxDate > 0) {
            return false;
        }

        return true;
    };

    handleChange = (selectedDate) => {
        const { field, form, handleChange } = this.props;

        if (!this.validateDate(selectedDate, field.name)) {
            return;
        }

        if (handleChange) {
            handleChange(selectedDate);
        }

        this.setState({ selectedDate });

        if (Object.keys(form).length > 0) {
            form.setFieldValue(field.name, selectedDate ? selectedDate.toDate() : null);
            form.setFieldTouched(field.name, true);
        }
    };

    render() {
        const { isFocused, selectedDate } = this.state;
        const {
            cssDisabledToday,
            customLabel,
            dateFormat,
            endDate,
            error,
            field,
            form: { touched, errors },
            handleResetDateFilters,
            hideLabel,
            hidePlaceholder,
            idField,
            idForm,
            labelNoMarginTop,
            maxDate,
            minDate,
            resetFilters,
            showDisabledMonthNavigation,
            startDate,
            valueSelectedDate,
            placeholder,
            popperPlacement,
            ...rest
        } = this.props;

        const hasError = error || (touched && touched[field.name] && errors && errors[field.name]);

        return (
            <div
                className={classNames("form-group", "form-group--datepicker", {
                    "has-error": hasError,
                    "has-focus": isFocused,
                })}>
                {!hideLabel && !customLabel && (
                    <FieldLabel
                        labelKey={`${idForm}.${field.name}.label`}
                        idField={field.name}
                        labelNoMarginTop={labelNoMarginTop}
                    />
                )}
                {!hideLabel && customLabel && (
                    <FieldLabel idField={field.name} labelText={customLabel} labelNoMarginTop={labelNoMarginTop} />
                )}
                <div className="input-group">
                    <div
                        className={classNames("form-control", {
                            "today-disabled": cssDisabledToday,
                            "is-disabled": this.props.disabled,
                        })}>
                        <DatePicker
                            dateFormat={dateFormat === null ? i18n.get("datepicker.format") : dateFormat}
                            className="form-control"
                            endDate={endDate ? moment(endDate) : undefined}
                            maxDate={maxDate ? moment(maxDate) : undefined}
                            minDate={minDate ? moment(minDate) : undefined}
                            placeholderText={hidePlaceholder ? "" : placeholder || i18n.get(`${idForm}.${field.name}.placeholder`)}
                            selected={selectedDate || (field.value && moment(field.value)) || valueSelectedDate}
                            showDisabledMonthNavigation={showDisabledMonthNavigation}
                            startDate={startDate ? moment(startDate) : undefined}
                            onChange={this.handleChange}
                            id={field.name}
                            {...rest}
                            popperPlacement={popperPlacement}
                        />
                    </div>
                </div>
                {hasError && <FieldError error={error || errors[field.name]} />}
            </div>
        );
    }
}

export default DateField;
