import { call, put, takeLatest } from "redux-saga/effects";

import { types, actions } from "reducers/administration/common/group";
import * as administrationGroups from "middleware/administration/advanced";
import { actions as notificationActions } from "reducers/notification";
import { permissionsActions } from "reducers/administration/advanced";
import * as i18n from "util/i18n";

const sagas = [takeLatest(types.LOAD_GROUP_REQUEST, loadGroupRequest)];

export default sagas;

function* loadGroupRequest({ id }) {
    const response = yield call(administrationGroups.loadGroupRequest, id);

    if (response.type === "W") {
        yield put(actions.loadGroupFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
        );
    } else {
        yield put(actions.loadGroupSuccess(response.data.data));
        yield put(
            permissionsActions.loadPermissionsSuccess({
                ...response.data.data,
                // TODO rename groups on every reducer to uiPermissions
                groups: response.data.data.uiPermissions,
                permissions: response.data.data.permissions,
                envData: response.data.data.envData,
            }),
        );
    }
}
