import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { goBack } from "react-router-redux";
import { Formik, Form, Field } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import Text from "pages/_components/Text/Text";
import AlertInformation from "pages/_components/AlertInformation";
import Notification from "pages/_components/Notification";
import Selector from "pages/_components/fields/formik/Selector";
import MainContainer from "pages/_components/MainContainer";
import { bool, func } from "prop-types";
import { Collapse } from "react-bootstrap";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import Col from "react-bootstrap/lib/Col";

import * as i18n from "util/i18n";
import { actions as documentsActions, selectors as documentsSelectors } from "reducers/documents";
import DateField from "pages/_components/fields/DateField";
import FieldError from "pages/_components/fields/FieldError";
import * as configUtil from "util/config";
import moment from "moment";
import TableTaxDocuments from "../documents/_components/TableTaxDocuments";

const FORM_ID = "documents.tax.filter";

const DocumentsTax = (props) => {
    const [open, setOpen] = useState(true);
    const [afterSearch, setAfterSearch] = useState(false);
    const [valuesSubmit, setValuesSubmit] = useState(null);
    const maxRangeDays = configUtil.getInteger("documents.range.maxdays");
    const rowsPerPage = configUtil.getInteger("documents.rowsPerPage") || 10;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [errorPeriod, setErrorPeriod] = useState(false);
    const [errorRange, setErrorRange] = useState(false);
    const [filters, setFilters] = useState({});

    const { isDesktop, dispatch, isFetching } = props;

    const typeDocumentOptions = [
        {
            value: "00",
            label: "Todos",
        },
        {
            value: "01",
            label: "Factura",
        },
        {
            value: "07",
            label: "Comprobante de retención",
        },
        {
            value: "04",
            label: "Nota de crédito",
        },
    ];

    useEffect(() => {
        dispatch(documentsActions.clean());
    }, []);

    const onBack = () => {
        dispatch(goBack());
    };

    const renderFilterDesktop = () => {
        const validateFields = ({ values }) => {
            if (!valuesSubmit) {
                return !values.typeDocument || !startDate || !endDate ;
            }
            return (
                !values.typeDocument ||
                !startDate ||
                !endDate
                // (afterSearch && documents?.length === 0)
                // errorRange ||
                // errorPeriod ||
                // afterSearch ||
                // JSON.stringify(values) !== JSON.stringify(valuesSubmit)
            );
        };

        const validationSchema = Yup.object().shape({ 
            typeDocument: Yup.string().required(i18n.get(`${FORM_ID}.typeDocument.required`)),
        });

        const handleSubmit = (values) => {
            setAfterSearch(true);
            setValuesSubmit(values);

            const DocumentsTaxFilters = {
                typeDocument: values.typeDocument,
                startDate,
                endDate,
            };
            setFilters(DocumentsTaxFilters);
            dispatch(documentsActions.listTaxDocumentsRequest(DocumentsTaxFilters, 0));
        };

        const handleStartDate = (date) => {
            setAfterSearch(false);
            if (endDate) {
                if (date.isAfter(endDate)) {
                    setErrorPeriod(true);
                } else {
                    setErrorPeriod(false);
                }
                if (endDate.diff(date, "days") > maxRangeDays) {
                    setErrorRange(true);
                } else {
                    setErrorRange(false);
                }
            }
            setStartDate(date);
        };
        const handleEndDate = (date) => {
            setAfterSearch(false);
            if (startDate) {
                if (startDate.isAfter(date)) {
                    setErrorPeriod(true);
                } else {
                    setErrorPeriod(false);
                }
                if (date.diff(startDate, "days") > maxRangeDays) {
                    setErrorRange(true);
                } else {
                    setErrorRange(false);
                }
            }
            setEndDate(date);
        };
        const renderErrors = () => (
            <>
                {errorRange && (
                    <Box className="pt-2 pb-5 form-errors-document">
                        {errorRange && <FieldError error={i18n.get("documents.tax.filter.period.msg.error.range")} />}
                    </Box>
                )}
            </>
        );

        return (
            <Formik
                enableReinitialize
                initialValues={{
                    typeDocument: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}>
                {(formik) => (
                    <Form>
                        <Row gapX="7">
                            <Col lg={3}>
                                <Field
                                    component={Selector}
                                    idForm={FORM_ID}
                                    name="typeDocument"
                                    options={typeDocumentOptions}
                                    renderAs="combo"
                                    radioClassNames="radio-like-buttons"
                                    labelClassName="text-bold"
                                    handleChange={() => {
                                        setAfterSearch(false);
                                    }}
                                />
                            </Col>
                            <Col lg={3} style={{ "align-self": "end" }}>
                                <Text
                                    component="label"
                                    htmlFor="react-select-accountBy-input"
                                    labelKey="checkbooks.filter.dateFrom.label"
                                    className="data-label"
                                    size="5"
                                    bold
                                />
                                <DateField
                                    idForm={FORM_ID}
                                    name="startDate"
                                    field={{ name: "startDate" }}
                                    placeholderText="dd/mm/aaaa"
                                    hideLabel
                                    labelNoMarginTop
                                    handleChange={handleStartDate}
                                    minDate={null}
                                    maxDate={endDate || moment()}
                                    popperPlacement="bottom"
                                    valueSelectedDate={startDate}
                                    popperModifiers={{
                                        flip: {
                                            behavior: ["bottom"],
                                        },
                                    }}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    className={errorRange ? "error-date-field" : "custom-date-field"}
                                />
                            </Col>
                            <Col lg={3} style={{ "align-self": "end" }}>
                                <Text
                                    component="label"
                                    htmlFor="react-select-accountBy-input"
                                    labelKey="checkbooks.filter.dateTo.label"
                                    className="data-label"
                                    size="5"
                                    bold
                                />
                                <DateField
                                    idForm={FORM_ID}
                                    name="endDate"
                                    field={{ name: "endDate" }}
                                    placeholderText="dd/mm/aaaa"
                                    hideLabel
                                    labelNoMarginTop
                                    handleChange={handleEndDate}
                                    minDate={startDate || moment()}
                                    maxDate={moment()}
                                    popperPlacement="bottom"
                                    valueSelectedDate={endDate}
                                    popperModifiers={{
                                        flip: {
                                            behavior: ["bottom"],
                                        },
                                    }}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    className={errorRange ? "error-date-field" : "custom-date-field"}
                                />
                            </Col>

                            <Col xs={12} md={3} lg={3} style={{ "align-self": "end" }}>
                                <Button
                                    label="global.search"
                                    bsStyle="primary"
                                    type="submit"
                                    btnUppercase={false}
                                    className={classNames("mt-auto full-width", {
                                        "px-9": isDesktop,
                                        "px-8": !isDesktop,
                                    })}
                                    disabled={validateFields(formik) || errorRange}
                                    loading={isFetching}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}></Col>
                            <Col lg={3}>
                                <div>{renderErrors()}</div>
                            </Col>
                            <Col lg={3}></Col>
                            <Col lg={3}></Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        );
    };

    return (
        <>
            <Notification scopeToShow="documentsTax.thirdParties" />
            <Head
                onBack={onBack}
                titleText={i18n.get("documents.tax.title")}
                textBack="documents.tax.header.returnToDocuments"
            />
            <MainContainer className="px-0 border-radius-lg box-shadow-small texture-header background-white mt-5">
                <Box
                    background="white"
                    fullWidth
                    display="flex"
                    className="mt-5 px-7 pt-7">
                    <Box className="full-width">
                        <AlertInformation
                            withIcon
                            background="info"
                            title="documents.tax.alert.msg.info"
                            textClassName="align-justify"
                        />
                    </Box>
                </Box>

                <Box background="white" className="px-7 pt-2 pb-7">
                    <Collapse in={isDesktop ? true : open}>{renderFilterDesktop()}</Collapse>
                    <Box
                        component="article"
                        background="white"
                        borderRadius="default"
                        className={classNames("pt-5 mb-5")}
                        toBorderInMobile>
                        <TableTaxDocuments
                            afterSearch={afterSearch}
                            filters={filters}
                            rowsPerPage={rowsPerPage}
                        />
                    </Box>
                </Box>
            </MainContainer>
        </>
    );
};

DocumentsTax.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: documentsSelectors.getFetching(state),
    // documents: documentsSelectors.getTaxDocuments(state),
});

export default connect(mapStateToProps)(DocumentsTax);
