import { call, put, takeLatest, select } from "redux-saga/effects";
import { authenticateHandlerTypes, actions as authenticateHandlerActions, selectors as authenticateHandlerSelectors } from "reducers/authenticateHandler";
import * as softtokenApi from "middleware/softToken/softToken";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { replace } from "react-router-redux";
import { deleteIdentity } from "util/softToken.util";
import { types as secondFactorTypes, actions as secondFactorActions } from "reducers/secondFactor";
import { selectors as softTokenSelectors, actions as softTokenActions } from "reducers/softToken";
import { actions as fingerprintActions } from "reducers/fingerprint";
import { actions as sessionActions } from "reducers/session";
import { USER_TOKEN_STATUS_INACTIVE, USER_TOKEN_STATUS_ACTIVE } from "util/userToken.util";
import * as secureStorageUtils from "util/secureStorage";
import { API_TRANSACTIONAL_BAD_REQUEST } from "util/responses";

const sagas = [
    takeLatest(authenticateHandlerTypes.GET_TOKEN_STATUS_REQUEST, getTokenStatusRequest),
    takeLatest(authenticateHandlerTypes.GET_TOKEN_INFO_REQUEST, getTokenInfoRequest),
    takeLatest(authenticateHandlerTypes.GET_PASSWORD_STATUS_REQUEST, getAuthenticatorStatusRequest),
    takeLatest(authenticateHandlerTypes.DESACTIVATE_TOKEN_REQUEST, desactivateSoftToken),
    takeLatest(authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_REQUEST, unlockAuthenticator),
    takeLatest(authenticateHandlerTypes.DELETE_TOKEN_PIN_REQUEST, deleteTokenPinRequest),
    takeLatest(authenticateHandlerTypes.VALIDATE_TOKEN_PIN_REQUEST, validateTokenPinRequest),
    takeLatest(authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_REQUEST, getTokenInfoStatusRequest),
    takeLatest(authenticateHandlerTypes.DESACTIVATE_TOKEN_PREVIEW_REQUEST, desactivateSoftTokenPreview),
];

export default sagas;

function* getTokenStatusRequest() {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.validateStatusToken, deviceUuid);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        const { tokenStatus, pinCode, pinTries, serialToken, deviceUuid } = response.data.data;
        yield put(authenticateHandlerActions.getTokenStatusSuccess(tokenStatus, pinCode, pinTries, serialToken, deviceUuid));
    }
}

function* getTokenInfoRequest() {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.getTokenInfo, deviceUuid);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        const { pinCode, pinTries, tokenStatus } = response.data.data;
        yield put(authenticateHandlerActions.getTokenInfoSuccess(pinCode, pinTries, tokenStatus));
    }
}

function* deleteTokenPinRequest() {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.deleteTokenPinRequest, deviceUuid);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("token.pin.delete.message"), "success", [
                "settings/authenticatorHandler",
                "/desktop",
            ]),
        );
        yield put(authenticateHandlerActions.deleteTokenPinSucces());
    }
}

function* validateTokenPinRequest(pinCode) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.validateTokenPinRequest, deviceUuid, pinCode?.pinCode);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["externalLayout"]),
        );
        yield put(authenticateHandlerActions.hideValidationPinModal());
    } else {
        const { isPinCodeValid, pinTries } = response.data.data;
        yield put(authenticateHandlerActions.validateTokenPinSucces(isPinCodeValid, pinTries));

        if (!isPinCodeValid) {
            yield put(
                notificationActions.showNotification(i18n.get("token.pin.pinCode.noValid"), "error", [
                    "externalLayout",
                ]),
            );
        }
    }
}

function* getAuthenticatorStatusRequest({ authenticatorType }) {
    const response = yield call(softtokenApi.validateStatusAuthenticator, authenticatorType);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        const { authenticatorStatus } = response.data.data;
        yield put(authenticateHandlerActions.getPasswordStatusSuccess(authenticatorStatus));
    }
}

function* desactivateSoftTokenPreview({ redirect, isFromMigrateScreen, secondFactor, formikBag, onSuccess }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.desactivateUserTokenPreview, deviceUuid, secondFactor);
    if (response.type === "W") {
        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        const { code, data } = response.data;
        if (data.secondFactor) {
            formikBag.setErrors(data);
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            return;
        }

        if (code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }

        if (code === "API708W") {
            yield put(
                notificationActions.showNotification(
                    i18n.get(
                        "secondFactor.credential.otp.expired",
                        "Código OTP expirado, solicite un nuevo código OTP",
                    ),
                    "warning",
                    ["softToken.deactivation"],
                ),
            );
            return;
        }

        yield put({ type: authenticateHandlerTypes.DESACTIVATE_TOKEN_PREVIEW_FAILED });
        yield put(
            notificationActions.showNotification(i18n.get("token.desactivate.error.message"), "error", [
                "softToken.deactivation",
            ]),
        );
    } else {
        yield put({ type: authenticateHandlerTypes.DESACTIVATE_TOKEN_PREVIEW_SUCCESS });
        if (onSuccess) {
            onSuccess();
        }
    }
}

function* desactivateSoftToken({ redirect, isFromMigrateScreen, secondFactor, formikBag, onSuccess }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.desactivateUserToken, deviceUuid, secondFactor);
    if (response.type === "W") {
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
        const { code, data } = response.data;
        if (data.secondFactor) {
            formikBag.setErrors(data);
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            return;
        }

        if (code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }
        if (code === "API708W") {
            yield put(
                notificationActions.showNotification(
                    i18n.get(
                        "secondFactor.credential.otp.expired",
                        "Código OTP expirado, solicite un nuevo código OTP",
                    ),
                    "warning",
                    ["softToken.deactivation"],
                ),
            );
            return;
        }
        if (code === API_TRANSACTIONAL_BAD_REQUEST) {
            yield put(notificationActions.showNotification(data.message, "error", ["softToken.deactivation"]));
            return;
        }
        yield put({ type: authenticateHandlerTypes.DESACTIVATE_TOKEN_PREVIEW_FAILED });
        yield put(
            notificationActions.showNotification(i18n.get("token.desactivate.error.message"), "error", [
                "softToken.deactivation",
            ]),
        );
    } else {
        const userTokenDeviceUuid = yield select(authenticateHandlerSelectors.getDeviceUuid);
        const userTokenPinCode = yield select(authenticateHandlerSelectors.getPinCode);

        if (window?.app?.getDeviceUUID()) {
            yield call(deleteIdentity);
        }

        try {
            const pinCode = yield call(secureStorageUtils.get, "softTokenPinCode");
            if (pinCode) {
                console.log("DeleteToken +pinCode: ", pinCode);
                yield put(softTokenActions.removeDevicePin());
            }
        } catch (error) {
            /* do something with the error */
            console.log("Ha ocurrido un error en la eliminacion de pin: ", error);
        }

        try {
            const securityMethod = yield call(secureStorageUtils.get, "softTokenSecurityMethod");
            if (securityMethod && securityMethod === "FINGERPRINT") {
                console.log("DeleteToken +fingerprint: ", securityMethod);
                yield put(fingerprintActions.fingerprintDeleteAllDevices());
            }
        } catch (error) {
            if(deviceUuid===userTokenDeviceUuid && !userTokenPinCode){
                yield put(fingerprintActions.fingerprintDeleteAllDevices());
            }
            /* do something with the error */
            console.log("Ha ocurrido un error en la eliminacion biometria: ", error);
        }

        try {
            console.log("DeleteToken +removeSecurityMethod: ");
            yield put(softTokenActions.removeSecurityMethod());
        } catch (error) {
            /* do something with the error */
            console.log("Ha ocurrido un error en la eliminacion del securityMethod: ", error);
        }

        yield put({ type: authenticateHandlerTypes.DESACTIVATE_TOKEN_SUCCESS });
        yield put({ type: authenticateHandlerTypes.GET_TOKEN_STATUS_REQUEST });
        yield put(
            notificationActions.showNotification(i18n.get("token.desactivate.success.message"), "success", [
                "softToken.deactivation",
            ]),
        );
        if (isFromMigrateScreen) {
            if (redirect) {
                yield put(replace(redirect ?? "/desktop"));
            }
        }

        try {
            // yield put(secondFactorActions.secondFactorStatusTokenRequest({ exchangeToken: undefined }));
            yield put(secondFactorActions.secondFactorStatusTokenSuccess(false));
            yield put(softTokenActions.getStatusTokenSuccess(USER_TOKEN_STATUS_INACTIVE));
        } catch (error) {
            /* Posibles errores al actualizar el status del softToken activo */
        }

        if (onSuccess) {
            onSuccess();
        }
    }
}

function* unlockAuthenticator({ secondFactor, formikBag, onSuccess }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.unlockUserAuthenticator, deviceUuid, secondFactor);
    if (response.type === "W") {
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
        const { code, data } = response.data;
        if (code === "COR020W" && data.secondFactor) {
            formikBag.setErrors(data);
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            return;
        }

        if (code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }
        if (code === "API708W") {
            yield put(
                notificationActions.showNotification(
                    i18n.get(
                        "secondFactor.credential.otp.expired",
                        "Código OTP expirado, solicite un nuevo código OTP",
                    ),
                    "warning",
                    ["unlockDevice"],
                ),
            );
            return;
        }
        if (code === API_TRANSACTIONAL_BAD_REQUEST) {
            yield put(notificationActions.showNotification(response.data.data.message, "error", ["unlockDevice"]));
            return;
        }
        yield put({ type: authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_FAILED });
        const errorMessage = response.data.message || i18n.get("authenticator.password.unlock.error.message");
        yield put(notificationActions.showNotification(errorMessage, "error", ["unlockDevice"]));
    } else {
        const { isTokenUnlocked, email } = response.data.data;
        if (isTokenUnlocked) {
            yield put({ type: authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_SUCCESS, email });
            yield put(softTokenActions.getStatusTokenSuccess(USER_TOKEN_STATUS_ACTIVE));
            onSuccess();
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("authenticator.password.unlock.error.message"), "error", [
                    "unlockDevice",
                ]),
            );
        }
    }
    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* getTokenInfoStatusRequest({ onFinish }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.getTokenInfo, deviceUuid);

    if (typeof onFinish === "function") {
        onFinish();
    }

    if (!response?.type || !response?.data?.data) {
        yield put(authenticateHandlerActions.getTokenInfoStatusFailure());
        yield;
        return;
    }

    if (response.type === "W") {
        yield put(authenticateHandlerActions.getTokenInfoStatusFailure());
        yield;
        return;
    }
    const { tokenStatus } = response.data.data;
    yield put(authenticateHandlerActions.getTokenInfoStatusSuccess(tokenStatus));
}
