import React, { Component } from "react";
import Row from "pages/_components/Row";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import { Col } from "react-bootstrap";
import { isDesktop, isMobile } from "react-device-detect";
import classNames from "classnames";

class MainData extends Component {
    renderData = () => {
        const { value, currency, type } = this.props;
        switch (type) {
            case "amount":
                return (
                    <>
                        <FormattedAmount quantity={value} className="size-1 text-semibold display-inline" noCurrency />
                        &nbsp;&nbsp;
                        <Text semibold size="1" color="text-grey-color" defaultValue={currency} />
                    </>
                );
            case "date":
                return (
                    <>
                        <FormattedDate quantity={value} className={classNames(" text-semibold display-inline", {
                            "size-1": isDesktop,
                            "size-2": !isDesktop
                        })} noCurrency />
                    </>
                );

            default:
                return (
                    <Text semibold size="4">
                        {value}
                    </Text>
                );
        }
    };

    render() {
        const { label, defaultLabelValue } = this.props;
        return (
            <Box background="background-disabled" className="p-5 mt-3 mb-7" borderRadius="lg">
                <Row
                    className={classNames({
                        "row-template-1fr gapY-1": !isDesktop,
                    })}>
                    <Col
                        xs={6}
                        className={classNames("d-flex align-items-center", {
                            "justify-content-center": !isDesktop,
                        })}>
                        <Text size="6" labelKey={label} defaultValue={defaultLabelValue || label} />
                    </Col>
                    <Col xs={6}>
                        <Box
                            display="flex"
                            fullWidth
                            className={classNames({
                                "justify-content-end text-right": isDesktop,
                                "justify-content-center": !isDesktop,
                            })}>
                            {this.renderData()}
                        </Box>
                    </Col>
                </Row>
            </Box>
        );
    }
}

class AdditionalData extends Component {
    render() {
        const { label, value, defaultLabelValue } = this.props;
        return (
            <Row
                className={classNames("mt-3 mb-4", {
                    "row-template-1fr gapY-1": !isDesktop,
                })}>
                <Col xs={5}>
                    <Text size="6" labelKey={label} defaultValue={defaultLabelValue || label} />
                </Col>
                <Col
                    xs={7}
                    className={classNames({
                        "text-right": isDesktop,
                    })}>
                    <Text
                        size={isDesktop ? 6 : 5}
                        defaultValue={value}
                        bold
                        className={
                            isMobile
                                ? "text-break-all"
                                : (value.length > 40
                                ? "text-break-all"
                                : "text-break-all text-no-wrap")
                        }
                    />
                </Col>
            </Row>
        );
    }
}
class TicketData extends Component {
    static Main = MainData;
    static Data = AdditionalData;
}

export default TicketData;
