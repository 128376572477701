import * as API from "middleware/api";

export const getListBank = (codeBranch) =>
    API.executeWithAccessToken("/creditCard.listBanks", {
        codeBranch,
    });

export const getListCreditCardOtherBanks = (pageNumber, searchWord) =>
    API.executeWithAccessToken("/creditCard.otherBanks.list", {
        pageNumber,
        searchWord,
    });

export const enrollCreditCardsOtherBanks = (bank, cardType, cardNumber, alias) =>
    API.executeWithAccessToken("/creditCard.otherBanks.enroll.preview", {
        bank,
        cardType,
        _cardNumber: cardNumber,
        alias,
    });
