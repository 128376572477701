import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Select from "pages/_components/fields/Select";
import AmountField from "pages/_components/fields/formik/AmountField";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import * as Yup from "yup";
import * as i18n from "util/i18n";
import SwitchField from "pages/_components/fields/SwitchField";
import Info from "pages/_components/Info";
import Tooltip from "../../_components/Tooltip/Tooltip";
import CapsTabs from "./caps/CapsTabs";
import CapAmountListField from "../../_components/fields/formik/CapAmountListField";
import CapsInfo from "./caps/CapsInfo";
import * as config from "util/config";
import capsValidation from "../../_components/fields/formik/caps/validationSchema";

class Channels extends Component {
    static propTypes = {
        actions: shape({ loadChannelsRequest: func.isRequired }).isRequired,
        routerActions: shape({ goBack: func.isRequired }).isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        enabledChannelsFrequencies: arrayOf(
            shape({
                value: string.isRequired,
                label: string.isRequired,
            }),
        ),
        enabledChannels: arrayOf(string).isRequired,
        currencies: arrayOf(
            shape({
                id: string.isRequired,
                value: string,
                label: string.isRequired,
            }),
        ).isRequired,
        nonRemovableChannels: arrayOf(string).isRequired,
        caps: shape({}).isRequired,
        topAmount: shape({
            maximum: number,
            amount: number,
            frequency: string,
        }),
        fetching: bool.isRequired,
        user: shape({}).isRequired,
        signatureLevel: string.isRequired,
        userEnvStatus: string.isRequired,
    };

    static defaultProps = {
        enabledChannelsFrequencies: null,
        topAmount: null,
    };

    componentDidMount() {
        const { actions, match } = this.props;
        actions.loadChannelsRequest(match.params.id);
    }

    handleBack = () => {
        const { routerActions } = this.props;
        routerActions.goBack();
    };

    getIdProduct = (propId) => {
        const ids = propId?.split("_");
        return ids[1] || "";
    };

    handleSubmit = (values, { setSubmitting }) => {
        const { match, actions, topAmount } = this.props;
        const enabledChannels = [...values.enabledChannels];
        values.channels.all = { ...values.channels.all, amount: topAmount?.maximum };
        actions.updateChannelsPreview(
            {
                caps: Object.entries(values.channels).reduce((enabled, [channel, data]) => {
                    if (!enabledChannels.includes(channel)) {
                        return enabled;
                    }

                    return { ...enabled, [channel]: { ...data, frequency: "transaction" } };
                }, {}),
                capsProd: Object.entries(values.caps).reduce((acc, [channel, data]) => {
                    if (![...enabledChannels, 'phonegap'].includes(channel)) {
                        return [...acc, ...[{[this.getIdProduct(channel)]: data}]];   
                    }
                    return acc;
                }, []),
                idUser: match.params.id,
            },
            setSubmitting,
        );
    };

    validationSchema = () => {
        const { topAmount } = this.props;

        return Yup.lazy((values) =>
            Yup.object().shape({
                caps: capsValidation,
                channels: Yup.object().shape({
                    frontend: Yup.object().shape({
                        amount: !values.enabledChannels.includes("frontend")
                            ? Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable()
                            : Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable()
                                  .max(
                                      topAmount.maximum,
                                      i18n.get("administration.limits.channels.topAmount.maxAmount.error"),
                                  )
                                  .min(1, i18n.get("administration.limits.channels.frontend.required"))
                                  .required(i18n.get("administration.limits.channels.frontend.required")),
                    }),
                    phonegap: Yup.object().shape({
                        amount: !values.enabledChannels.includes("phonegap")
                            ? Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable()
                            : Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable()
                                  .max(
                                      topAmount.maximum,
                                      i18n.get("administration.limits.channels.topAmount.maxAmount.error"),
                                  )
                                  .min(1, i18n.get("administration.limits.channels.phonegap.required"))
                                  .required(i18n.get("administration.limits.channels.phonegap.required")),
                    }),
                }),
            }),
        );
    };

    mergeCaps = (products, oldProducts, currentValues) => {
        const merged = { ...currentValues };
        const productsFor = products ? [...products].filter(p => (p.type === "TC" || p.type === "CC" || p.type === "CA")) : []; 
        productsFor.forEach((product) => {
            const key = `cap_${product.id}`;
            if (!merged[key]) {
                merged[key] = {
                    amount: oldProducts[key]?.amount || 0,
                };
            }
        });

        return merged;
    };

    isValidValues = (values) => {
        const { enabledChannels, caps } = values;
        const validChannels = [];
        enabledChannels.forEach((channel) => {
            if (values?.channels[channel]?.amount > 0) {
                validChannels.push(channel);
            }
        });
        if (caps) {
            if(Object.entries(caps)?.
                filter(([idProd, data]) => idProd !== "frontend" && idProd != "phonegap")?.
                some(([idProd, { amount }]) => (String(amount) == ""))) {
                    return false;
            }
        }
        if (validChannels.length === enabledChannels.length) {
            return true;
        }
        return false;
    };

    isDisabledField = (values, channel) => !values.enabledChannels.includes(channel);

    renderForm = ({ isSubmitting, values, setFieldValue }) => {
        const {
            enabledChannelsFrequencies,
            enabledChannels,
            nonRemovableChannels,
            user,
            topAmount,
            signatureLevel,
            userEnvStatus,
            activeEnvironment,
        } = this.props;


        const tabItems = [
            { id: "accounts", products:["CA","CC"] },
        { id: "TC",products:["TC"] },
    ];

        const renderItem = (item) => (
            <Box
                key={item.id}
                keyLabel={`administration.capForUserProduct.tab.${item.id}`}>
                {
                    <CapAmountListField products={values?.products} fieldName="caps" currentProductTab={item?.products||[]}/>
                }
            </Box>
        );

        return (
            <Form>
                <Box
                    component="section"
                    display="flex"
                    column
                    background="component-background"
                    borderRadius="default"
                    className="p-7 box-shadow-small background-white border-radius-lg"
                    fullHeight>
                    <Box
                        display="flex"
                        fullWidth
                        className="p-7 box-shadow-small background-white border-radius-lg mb-3"
                        background="white"
                        borderRadius="lg">
                        <Box display="flex" className="mt-7" gapX="3">
                            <Row>
                                <Col xs={4}>
                                    <Box display="flex" column>
                                        <Text
                                            component="p"
                                            labelKey="administration.user.label"
                                            className="m-0 pb-3"
                                            color="heading-color"
                                            size="7"
                                            regular
                                        />
                                        <Text
                                            component="label"
                                            className="my-0 mr-5"
                                            color="heading-color"
                                            bold
                                            size="6">
                                            {user?.fullName}
                                        </Text>
                                    </Box>
                                </Col>
                                <Col xs={4}>
                                    <Box display="flex" column>
                                        <Text
                                            component="label"
                                            className="m-0 pb-3"
                                            labelKey="administration.user.details.documentNumber"
                                            color="heading-color"
                                            size="7"
                                            regular
                                        />
                                        <Text color="heading-color" ellipsis bold size="6">
                                            {user?.documentType} {user?.documentNumber}
                                        </Text>
                                    </Box>
                                </Col>
                                <Col xs={2}>
                                    <Box display="flex" column>
                                        <Text
                                            component="label"
                                            className="m-0 pb-3"
                                            labelKey="administration.user.details.signature"
                                            color="heading-color"
                                            size="7"
                                            regular
                                        />
                                        <Text
                                            component="label"
                                            className="my-0 mr-5"
                                            color="heading-color"
                                            bold
                                            size="6">
                                            {signatureLevel}
                                        </Text>
                                    </Box>
                                </Col>

                                <Col xs={1}>
                                    <Info
                                        className={`info-user-list info-user-${userEnvStatus}`}
                                        text={i18n.get(`global.${userEnvStatus}`)}
                                    />
                                </Col>
                            </Row>
                        </Box>
                    </Box>
                    <Box
                        component="ul"
                        display="flex"
                        column
                        gap="3"
                        fullHeight
                        noList
                        className="p-7 box-shadow-small background-white border-radius-lg mb-3">
                        <Box display="flex" fullWidth>
                            <Text bold size="4" labelKey={"administration.channels.title"} />
                        </Box>
                        {enabledChannels
                            .filter((channel) => channel !== "all")
                            .map((channel, i) => (
                                <Box
                                    component="li"
                                    display="flex"
                                    alignX="between"
                                    alignY="center"
                                    background="component-background"
                                    borderRadius="default"
                                    className="py-5"
                                    fullHeight
                                    noList
                                    key={channel}>
                                    <Row alignY="center">
                                        <Col xs={4}>
                                            <Field name={`channels.${channel}`}>
                                                {({ field, form }) => (
                                                    <div className="display-flex gap-3 align-items-center">
                                                        <SwitchField
                                                            {...field}
                                                            idForm="administration"
                                                            name={field.name}
                                                            value={values.enabledChannels.includes(channel)}
                                                            onChange={() =>
                                                                form.setFieldValue(
                                                                    "enabledChannels",
                                                                    values.enabledChannels.includes(channel)
                                                                        ? values.enabledChannels.filter(
                                                                              (value) => value !== channel,
                                                                          )
                                                                        : values.enabledChannels.concat(channel),
                                                                )
                                                            }
                                                            disabled={nonRemovableChannels.includes(channel)}
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                        </Col>
                                        <Col xs={8}>
                                            <Row alignX="center">
                                                <Col xs={6}>
                                                    <Select
                                                        name={`channels.${channel}.frequency`}
                                                        className={classNames("form-group ellipsis-text", {
                                                            "select-open-top": i === enabledChannels.length - 1,
                                                        })}
                                                        value={"transaction"}
                                                        label={i18n.get(`channels.${channel}.frequency`)}
                                                        options={enabledChannelsFrequencies}
                                                        disabled
                                                    />
                                                </Col>
                                                <Col xs={6}>
                                                    <Field
                                                        name={`channels.${channel}`}
                                                        component={AmountField}
                                                        maxLength={19}
                                                        data={{
                                                            options: [{ id: 1, label: "USD" }],
                                                        }}
                                                        clearable={false}
                                                        labelText={i18n.get(`channels.${channel}.amount`)}
                                                        bold={false}
                                                        disabled={this.isDisabledField(values, channel)}
                                                        hideCurrency
                                                        labelNoMarginTop
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Box>
                            ))}
                        <Box
                            display="flex"
                            alignX={"between"}
                            column={true}
                            className="mb-6 mt-6">
                            <Box display="flex">
                                <Text
                                    className="m-0"
                                    labelKey="administration.channels.all"
                                    size="6"
                                />
                                <FormattedAmount
                                    quantity={topAmount?.maximum || config.get(`default_cap_user_${activeEnvironment.type}`)}
                                    currency={"USD"}
                                    size="6"
                                    bold={true}
                                    color={"heading-color"}
                                />

                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        fullWidth
                        className=" p-7 box-shadow-small background-white border-radius-lg mb-3"
                        background="white"
                        borderRadius="lg">


                        <Col xs={12}>
                            <CapsInfo/>
                            <CapsTabs
                                onSelect={(idx) => {
                                    setFieldValue("selectedTabIndex",idx)}
                                }
                                selectedIndex={values?.selectedTabIndex}>
                                {tabItems.map((item) => renderItem(item))}
                            </CapsTabs>
                        </Col>
                    </Box>
                </Box>

                <Box className="main-container p-7">
                    <Row>
                        <Col xs={3} xsOffset={3}>
                            <Button bsStyle="outline" label="global.cancel" onClick={this.handleBack} block />
                        </Col>
                        <Col xs={3}>
                            <Button
                                type="submit"
                                bsStyle="primary"
                                label="global.continue"
                                loading={isSubmitting}
                                disabled={
                                    !values.enabledChannels ||
                                    !values.enabledChannels.length > 0 ||
                                    !this.isValidValues(values)
                                }
                                block
                            />
                        </Col>
                    </Row>
                </Box>

            </Form>
        );
    };
    mergeCaps(products, oldProducts, caps) {
        const mergedCaps = { ...caps };
        for (const product of products) {
            const oldProductKey = `cap_${product.id}`;
            if (oldProducts[oldProductKey]) {
                mergedCaps[product.id] = {
                    ...oldProducts[oldProductKey],
                    ...caps[product.id],
                };
            }
        }
        return mergedCaps;
    }
    getInitialValues() {
        const { caps, enabledChannels, maximum, amount, products, oldProducts } = this.props;

        const mergedCaps = this.mergeCaps(products, oldProducts, caps);

        return {
            caps: mergedCaps,
            selectedTabIndex: 0,
            products,
            oldProducts,
            channels: enabledChannels.reduce(
                (values, channel) => ({
                    ...values,
                    [channel]: caps[channel] || { frequency: "transaction" },
                }),
                {
                    topAmount: {
                        amount: maximum || amount,
                        frequency: "transaction",
                    },
                }
            ),
            enabledChannels: Object.keys(caps),
        };
    }

    render() {
        const { fetching, enabledChannels, caps, topAmount } = this.props;
        const isLoading = fetching && !Object.keys(caps).length;

        const initialValues = this.getInitialValues();

        return (
            <>
                <Head onBack={this.handleBack} textBack="administration.users.returnToDetail" hasCenterContent>
                    <Text component="h2" size="1" color="heading-color" classNames="m-0" bold>
                        <I18n id="administration.limits.title" />
                    </Text>
                </Head>

                <MainContainer showLoader={isLoading || !enabledChannels.length}>
                    <Formik
                        validateOnBlur={false}
                        validateOnChange={false}
                        onSubmit={this.handleSubmit}
                        initialValues={initialValues}
                        validationSchema={this.validationSchema}
                    >
                        {(formik) => {
                            return this.renderForm(formik);
                        }}
                    </Formik>
                </MainContainer>
            </>
        );
    }
}

export default Channels;
