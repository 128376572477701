import RemarkableProductInfo from "pages/_components/RemarkableProductInfo";
import { bool, node, shape, func } from "prop-types";
import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { CREDIT_CARD_TYPE_CARD, dictionaryMiniatureCard } from "util/creditCards";
import { string } from "yup";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import classNames from "classnames";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import Info from "pages/_components/Info";

import * as i18nUtils from "util/i18n";
import { isMobile } from "react-device-detect";
import FormattedDate from "pages/_components/FormattedDate";
import Button from "pages/_components/Button";
import { actions as creditCardActions } from "reducers/creditCard";
import { push } from "react-router-redux/actions";

class DetailHeadInfoCorporate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            moreDetails: false,
        };
    }

    moreDetailsHandler = () => {
        this.setState((prevState) => ({
            moreDetails: !prevState.moreDetails,
        }));
    };

    handleClick = () => {
        const { dispatch, creditCard } = this.props;
        dispatch(creditCardActions.updateMovementConsumeToPay([]));
        dispatch(push("/CreditCardsCorporatePayment?creditCard=" + creditCard.internalNumber));
    };

    renderImage = (cardStyle) => (
        <Box display="flex" alignY="end" className="product-detail-img-wrapper">
            <Image
                className="img-header-card fill-transparent"
                src={`images/${cardStyle}-miniature-creditCardCorp.svg`}
                wrapperClassName="product-cards-detail-img display-flex justify-content-end"
            />
        </Box>
    );

    renderCreditCardTitle = (shortLabel, nameOnCard, showText = true) => (
        <Box fullWidth column={isMobile} alignX={isMobile && "center"}>
            <Box display="flex">
                <Box fullWidth>
                    <Text size="1" component="h1" align="left" bold>
                        {shortLabel}
                    </Text>
                </Box>
                {isMobile && (
                    <Box alignY="center" display="flex">
                        <Text className="ml-3 product-status-can-text">Principal</Text>
                    </Box>
                )}
            </Box>
            <Text component="h4" align="left" bold>
                {nameOnCard}
            </Text>
            {showText && <Text align="left" labelKey="creditCards.detail.text" color="text-disabled-color" />}
        </Box>
    );

    renderBalances = (officialMail, availableBalance, lastPaymentAmmount) => (
        <Box
            borderRadius="lg"
            background="background-primary"
            display="flex"
            className={isMobile ? "p-5 m-5" : "p-8 children-border-right"}
            column={isMobile}
            alignX={isMobile && "center"}>
            <Box fullWidth={!isMobile} className={isMobile && "order-3 text-center"}>
                {isMobile ? (
                    <Box className="mb-4 mt-1">
                        <Text labelKey="accounts.details.info.official" />
                        &nbsp;&nbsp;
                        <Text className="info-data-value" ellipsis bold>
                            {officialMail}
                        </Text>{" "}
                    </Box>
                ) : (
                    <Box className="fit-content-width text-left">
                        <I18n id="accounts.details.info.official" />
                        <Text className="info-data-value" color="primary" underlined ellipsis bold>
                            {officialMail}
                        </Text>
                    </Box>
                )}
            </Box>
            <Box fullWidth={!isMobile} className={isMobile ? "order-2 text-center" : "text-webkit-center"}>
                {isMobile ? (
                    <Box display="flex" alignY="center">
                        <I18n id="accounts.availableBalance" />
                        <FormattedAmount
                            noAmountMargin
                            className={isMobile ? "data-amount-account my-3 ml-3" : "data-amount-account big-size"}
                            currency="USD"
                            quantity={availableBalance}
                        />
                    </Box>
                ) : (
                    <Box className="fit-content-width text-left">
                        <I18n id="accounts.availableBalance" />
                        <FormattedAmount
                            noAmountMargin
                            className={isMobile ? "data-amount-account big-size my-3" : "data-amount-account big-size"}
                            currency="USD"
                            quantity={availableBalance}
                        />
                    </Box>
                )}
            </Box>
            <Box fullWidth={!isMobile} className={isMobile ? "order-1" : "text-webkit-right"}>
                <Box className={isMobile ? "fit-content-width text-center" : "fit-content-width text-left"}>
                    {!isMobile ? (
                        <>
                            <I18n id="creditCards.corporate.balance.pay" />
                            <FormattedAmount
                                noAmountMargin
                                className={
                                    isMobile ? "data-amount-account big-size my-3" : "data-amount-account big-size"
                                }
                                currency="USD"
                                quantity={lastPaymentAmmount}
                            />
                        </>
                    ) : (
                        <>
                            <I18n id="creditCards.corporate.balance.pay" />
                            <FormattedAmount
                                noAmountMargin
                                className={
                                    isMobile ? "data-amount-account big-size my-3" : "data-amount-account big-size"
                                }
                                ccurrency="USD"
                                quantity={lastPaymentAmmount}
                            />
                            
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );

    render() {
        const {
            creditCard,
            children,
            isDesktop,
            isAdditionalCreditCard,
            handlePointsClick,
            showEnableWalletRequest,
            cardWasDigitized,
            artifactType,
        } = this.props;

        const {
            cardStyle,
            isPrepaid,
            isCorporative,
            nameOnCard,
            numberMask,
            officialMail,
            availableBalance,
            lastPaymentAmmount,
        } = creditCard;

        return (
            <div className={classNames("detail-head-info head-card background-white")}>
                <>
                    <Box
                        display="flex"
                        column={isMobile}
                        alignX={isMobile && "center"}
                        className={classNames({
                            "p-7 mb-3": isDesktop,
                            "p-5": !isDesktop,
                        })}>
                        {!isDesktop && this.renderImage(cardStyle)}
                        {this.renderCreditCardTitle(numberMask, nameOnCard)}
                        {isDesktop && this.renderImage(cardStyle)}
                    </Box>

                    <Box fullWidth={isMobile}>
                        {this.renderBalances(officialMail, availableBalance, lastPaymentAmmount)}
                    </Box>

                    {isMobile && (
                        <Box display="flex" column fullWidth className="p-5">
                            <Button
                                label="creditCard.buttons.payCard"
                                bsStyle="primary"
                                block
                                // href={"/CreditCardsCorporatePayment?creditCard=" + creditCard.internalNumber}
                                onClick={this.handleClick}
                            />
                        </Box>
                    )}
                    {children}
                </>
            </div>
        );
    }
}

DetailHeadInfoCorporate.propTypes = {
    children: node,
    creditCard: shape({}),
    isDesktop: bool.isRequired,
    isAdditionalCreditCard: bool,
    artifactType: string,
    handlePointsClick: func.isRequired,
    showEnableWalletRequest: func,
    cardWasDigitized: bool,
};

DetailHeadInfoCorporate.defaultProps = {
    creditCard: null,
    children: null,
    isAdditionalCreditCard: false,
    artifactType: CREDIT_CARD_TYPE_CARD,
    showEnableWalletRequest: undefined,
    cardWasDigitized: undefined,
};

export default DetailHeadInfoCorporate;
