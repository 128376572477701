import { types, actions } from "reducers/checks";
import { call, put, takeLatest } from "redux-saga/effects";
import * as checksMiddleware from "middleware/checks";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { isMobileNativeFunc } from "util/device";
import { openLink } from "util/browser";
import b64toBlob from "b64-to-blob";
import { downloadCSVFile, downloadMobileFile, downloadPdf, downloadXls } from "util/download";
import { push } from "react-router-redux";

const sagas = [
    takeLatest(types.CHECKBOOKS_LIST_REQUEST, checkbooksListRequest),
    takeLatest(types.CHECKS_LIST_REQUEST, checksListRequest),
    takeLatest(types.ALL_CHECKS_LIST_REQUEST, allChecksListRequest),
    takeLatest(types.URL_CHECK_REQUEST, getUrlCheckRequest),
    takeLatest(types.LOAD_MORE_REQUEST, loadMoreData),
    takeLatest(types.DOWNLOAD_CHECKS_REQUEST, downloadChecks),
    takeLatest(types.IMAGE_CHECK_REQUEST, imageCheck),
    takeLatest(types.REMITTANCE_CHECKS_LIST_REQUEST, remittanceChecksListRequest),
    takeLatest(types.DOWNLOAD_REMITTANCE_CHECKS_REQUEST, downloadRemittanceChecks),
    takeLatest(types.SET_CHECK_REQUEST_EMISSION_COST_REQUEST, checksEmissionCost),
    takeLatest(types.SET_CHECK_REQUEST_LIST_OFFICE_REQUEST, getOfficesList),
    takeLatest(types.SET_CHECK_REQUEST_VALIDATE_CI_REQUEST, validateCI),
];

export default sagas;

function* checkbooksListRequest({ idAccount }) {
    const response = yield call(checksMiddleware.listCheckbooks, idAccount);
    if (response && response.status === 200) {
        const { checkbooks, accountNumber } = response.data.data;

        yield put(actions.listCheckbooksSuccess(checkbooks, accountNumber));
    } else {
        yield put(actions.listCheckbooksFailure());
    }
}

function* checksListRequest({ idAccount, idCheckbook }) {
    const response = yield call(checksMiddleware.listChecks, idAccount, idCheckbook);
    if (response && response.status === 200) {
        const { checks, accountNumber, initialCheckNumber, finalCheckNumber, currentCheckbook } = response.data.data;

        yield put(
            actions.listChecksSuccess(checks, accountNumber, initialCheckNumber, finalCheckNumber, currentCheckbook),
        );
    } else {
        yield put(actions.listChecksFailure());
    }
}

function* allChecksListRequest({
    idAccount,
    typeFilter,
    startDate,
    endDate,
    amountStart,
    amountEnd,
    checkNumber,
    nextIndex,
}) {
    try {
        const response = yield call(
            checksMiddleware.listAllChecks,
            idAccount,
            typeFilter,
            startDate,
            endDate,
            amountStart,
            amountEnd,
            checkNumber,
            nextIndex,
        );
        if (response && response?.type === "I") {
            const { checks, idAccount, totalCount, offset, pageNumber, totalPages } = response.data.data;
            yield put(actions.listAllChecksSuccess(checks, idAccount, totalCount, offset, pageNumber, totalPages));
        } else {
            throw new Error();
        }
    } catch (error) {
        yield put(actions.listAllChecksFailure());
        yield goToError(i18n.get("checks.header.search.title"));
    }
}

function* showGenericErrorTrackingUrl() {
    yield put(
        notificationActions.showNotification(
            i18n.get("checks.getUrl.error.message"),
            "error",
            ["checks", "account/details"],
            false,
        ),
    );
    yield put({
        type: types.URL_CHECK_CLEAR,
    });
}

function* getUrlCheckRequest({ idAccount, checkNumber }) {
    const response = yield call(checksMiddleware.getCheckUrl, idAccount, checkNumber);
    if (!response?.type || !response?.data?.data) {
        yield call(showGenericErrorTrackingUrl);
        yield;
        return;
    }
    const { type, data } = response.data;
    if (type === "W") {
        yield call(showGenericErrorTrackingUrl);
        yield;
        return;
    }
    const { fileUrl } = data;
    if (!fileUrl || fileUrl === "") {
        yield call(showGenericErrorTrackingUrl);
        yield;
        return;
    }
    yield put({
        type: types.URL_CHECK_CLEAR,
    });
    if (isMobileNativeFunc()) {
        yield call(openLink, fileUrl);
        yield;
        return;
    }
    yield call(window.open, fileUrl, "_blank", "noreferrer");
}

function* loadMoreData({ filters }) {
    try {
        const { idAccount, typeFilter, startDate, endDate, amountStart, amountEnd, checkNumber, nextIndex } = filters;
        const response = yield call(
            checksMiddleware.listAllChecks,
            idAccount,
            typeFilter,
            startDate,
            endDate,
            amountStart,
            amountEnd,
            checkNumber,
            nextIndex,
        );
        if (response && response?.type === "I") {
            const { checks, idAccount, totalCount, offset, pageNumber, totalPages } = response.data.data;

            yield put(actions.listAllChecksSuccess(checks, idAccount, totalCount, offset, pageNumber, totalPages));
        } else {
            throw new Error();
        }
    } catch (error) {
        yield put(actions.listAllChecksFailure());
        yield goToError(i18n.get("checks.header.search.title"));
    }
}

function* downloadChecks({ filters, format, onFinish }) {
    const response = yield call(checksMiddleware.downloadChecks, filters, format);
    if (response.type === "W") {
        yield put({ type: types.DOWNLOAD_CHECKS_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checks"]));
    } else {
        yield put({ type: types.DOWNLOAD_CHECKS_SUCCESS });
        if (onFinish) {
            onFinish();
        }
        const { content, fileName, contentType } = response.data.data;

        if (isMobileNativeFunc()) {
            const fileBlob = b64toBlob(content, contentType);
            downloadMobileFile(fileBlob, fileName, contentType);
            yield;
            return;
        }

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else if (format === "xls") {
            downloadXls(fileName, content);
        } else if (format === "txt") {
            downloadCSVFile(fileName, content);
        } else {
            yield put({ type: types.DOWNLOAD_CHECKS_FAILURE });
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checks"]));
        }
    }
}

function* imageCheck({ idAccount, checkNumber, dateCheck }) {
    const response = yield call(checksMiddleware.imageCheck, idAccount, checkNumber, dateCheck);
    if (response && response.status === 200) {
        yield put({
            type: types.IMAGE_CHECK_SUCCESS,
            ...response.data.data,
        });
    }
}

function* goToError(title) {
    yield put(
        push({
            pathname: "/error-page",
            title,
        }),
    );
}

function* remittanceChecksListRequest({
    idAccount,
    status,
    startDate,
}) {
    try {
        const response = yield call(
            checksMiddleware.listRemittanceChecks,
            idAccount,
            status,
            startDate,
        );

        if (response.type === "W") {
            yield put(actions.listRemittanceChecksFailure());
            if (response.data?.data?.code === "BAK001W") {
                yield goToError(i18n.get("checks.header.search.title"));
            }
        } else if (response && response?.type === "I") {
            const { remittanceChecks, idAccount, totalChecks, totalAmountChecks, totalCount } = response.data.data;
            yield put(
                actions.listRemittanceChecksSuccess(
                    remittanceChecks,
                    idAccount,
                    totalChecks,
                    totalAmountChecks,
                    totalCount,
                ),
            );
        } else {
            throw new Error();
        }
    }catch(error){
        yield put(actions.listRemittanceChecksFailure());
        yield goToError(i18n.get("checks.header.search.title"));
    }
}

function* downloadRemittanceChecks({ filters, format, onFinish }) {
    const response = yield call(checksMiddleware.downloadRemittanceChecks, filters, format);
    if (response.type === "W") {
        yield put({ type: types.DOWNLOAD_REMITTANCE_CHECKS_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checks"]));
    } else {
        yield put({ type: types.DOWNLOAD_REMITTANCE_CHECKS_SUCCESS });
        if (onFinish) {
            onFinish();
        }
        const { content, fileName, contentType } = response.data.data;

        if (isMobileNativeFunc()) {
            const fileBlob = b64toBlob(content, contentType);
            downloadMobileFile(fileBlob, fileName, contentType);
            yield;
            return;
        }

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else if (format === "xls") {
            downloadXls(fileName, content);
        } else if (format === "txt") {
            downloadCSVFile(fileName, content);
        } else {
            yield put({ type: types.DOWNLOAD_REMITTANCE_CHECKS_FAILURE });
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checks"]));
        }
    }
}

function* checksEmissionCost({ accountType, accountNumber, checkTypeCode, checkTypeDesc }) {
    const response = yield call(checksMiddleware.getEmissionCost, {
        accountType,
        accountNumber,
        checkTypeCode,
        checkTypeDesc,
    });
    if (response && response.status === 200) {
        const { checkSequential, checkTotalCost } = response.data.data;
        if (checkSequential && checkTotalCost) {
            yield put(actions.getEmissionCostSuccess(checkSequential, checkTotalCost));
        } else {
            yield put({ type: types.SET_CHECK_REQUEST_EMISSION_COST_FAILURE });
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checks"]));
        }
    }
}

function* getOfficesList({ cityCode }) {
    const response = yield call(checksMiddleware.getOfficesList, { cityCode });
    if (response && response.status === 200) {
        const { offices } = response.data.data;
        if (offices) {
            yield put(actions.getOfficesListSuccess(offices));
        } else {
            yield put({ type: types.SET_CHECK_REQUEST_LIST_OFFICE_FAILURE });
            // yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checks"]));
        }
    }
}

function* validateCI({ docType, identification }) {
    const response = yield call(checksMiddleware.validateCI, { docType, identification });
    if (response && response.status === 200) {
        const { fullName } = response.data.data;
        if (fullName !== undefined && fullName != "") {
            yield put(actions.getCiValidatedSuccess(fullName));
        } else {
            yield put(actions.getCiValidatedSuccess(""));
            yield put(
                notificationActions.showNotification(i18n.get("check.request.alert.validateCi"), "warning", [
                    "checkRequest",
                ]),
            );
            // yield put({ type: types.SET_CHECK_REQUEST_VALIDATE_CI_FAILURE });
            // yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checks"]));
        }
    }
}
