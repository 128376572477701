import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import Row from "react-bootstrap/lib/Row";
import Box from "pages/_components/Box";
import Col from "react-bootstrap/lib/Col";
import AmountField from "./AmountField";
import * as i18n from "util/i18n";
import Text from "pages/_components/Text";
import { maskLabelAccountProduct } from "util/format";

const CapAmountField = ({ product, fieldName, formId }) => (
    <Box className="border-radius-lg mb-5 mt-5" background="background-disabled">
        <Row className="p-3">
            {/* Primer Box a la izquierda */}
            <Col xs={7} className="desktop-main p-7 align-items-center display-flex">
                <Box>
                    <Row>
                        <Text bold color="heading">
                            {i18n.get(`administration.capForUserProduct.type.${product.type}`)}
                        </Text>
                    </Row> 
                    <Row>
                        <Box className="data-desc">{product?.type === "TC" ?  product.label : maskLabelAccountProduct(product.label)}</Box>
                    </Row>
                </Box>
            </Col>

            {/* Segundo Box a la derecha */}
            <Col xs={5} className="p-5">
                <Text bold color="heading">
                    {i18n.get("administration.capForUserProduct.amount.label")}
                </Text>
                <Field
                    name={fieldName}
                    idForm={formId}
                    hideLabel
                    component={AmountField}
                    maxLength={13 + 2 + 4} // 13 caracteres, 2 decimales, 6 separadores
                    placeholder=""
                    clearable={false}
                    bold={false}
                    hideCurrency
                    fixedDecimalScale
                    classNameContainer="mt-3"
                    // labelText={i18n.get("administration.capForUserProduct.amount.label")}
                />
            </Col>
        </Row>
    </Box>
);

CapAmountField.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    fieldName: PropTypes.string.isRequired,
    formId: PropTypes.string.isRequired,
};

export default CapAmountField;
