import { bool, func, oneOfType, shape } from "prop-types";
import React, { Component } from "react";
import I18n from "pages/_components/I18n";
// import AdditionalHeader from "./AdditionalHeader";
import Box from "pages/_components/Box";
import NoResults from "pages/_components/NoResultsMessage";
import Row from "pages/_components/Row";
import Col from "react-bootstrap/lib/Col";
import { isDesktop } from "react-device-detect";
import * as i18nUtils from "util/i18n";
import Link from "react-router-dom/Link";
import Info from "pages/_components/Info";
import classNames from "classnames";
import moment from "moment";
import Text from "pages/_components/Text";
import Movement from "./Movement";
import FormattedAmount from "pages/_components/FormattedAmount";
import GridTable from "pages/_components/GridTable/GridTable";

class CreditCardCorporateConsumeToPay extends Component {
    static propTypes = {
        movementsConsumeToPay: oneOfType([shape({})]).isRequired,
        showProductIcon: bool,
        setFavoriteAccount: func,
        completeFavorites: bool,
    };

    static defaultProps = {
        showProductIcon: true,
        setFavoriteAccount: () => {},
        completeFavorites: false,
    };

    renderEmptyResults = () => (
        <Box className="my-9">
            <NoResults />
        </Box>
    );

    renderList = () => {
        const { movementsConsumeToPay, isRequestAvailable } = this.props;

        if (!movementsConsumeToPay?.length) {
            return null;
        }
        return (
            <>
                {movementsConsumeToPay?.length === 0 && <>{this.renderEmptyResults()}</>}
                {movementsConsumeToPay.length > 0 && (
                    // children-bg-color
                    <Box className="children-bg-color paginate-table children-alternate-bg-color full-height full-width">
                        {isDesktop && (
                            <Box display="flex" className="movs-header-cols" borderRadius="md" fullWidth>
                                <Box display="flex" fullWidth alignY="center" className="p-5 size-6 pr-10 mr-1">
                                    <I18n id="creditCard.corporate.movement.detail.consumePending.consumeCode" />
                                </Box>
                                <Box display="flex" fullWidth alignY="center" className="p-5 size-6 pr-10 mr-1">
                                    <I18n id="tableHeader.expiracyDate" />
                                </Box>
                                <Box
                                    display="flex"
                                    fullWidth
                                    alignY="center"
                                    className="p-5 size-6 justify-content-flex-end">
                                    <I18n id="forms.creditcard.pay.own.paymentData.amount.label" />
                                </Box>
                            </Box>
                        )}
                        {movementsConsumeToPay.map((statement) => (
                            <>
                                {isDesktop ? (
                                    <Col xs={12} md={6}>
                                        <Box
                                            display="flex"
                                            alignX="between"
                                            alignY="center"
                                            className="border-radius-lg mb-4">
                                            <Box fullWidth>
                                                <Box
                                                    display={isDesktop ? "flex" : "grid"}
                                                    alignY="center"
                                                    gapX="5"
                                                    fullWidth
                                                    className="item-account-list p-5 border-radius-lg justify-content-between">
                                                    <Info
                                                        className="info-account-list"
                                                        labelText={statement.idStatement}
                                                    />
                                                    <Info
                                                        className={classNames("info-account-list", {
                                                            "text-center": isDesktop,
                                                        })}
                                                        labelText={moment(statement.expirationDate).format(
                                                            "DD/MM/YYYY",
                                                        )}
                                                    />
                                                    <Info
                                                        className="info-account-list text-right"
                                                        labelText={statement.amount}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Col>
                                ) : (
                                    <Box
                                        display="flex"
                                        alignX="between"
                                        alignY="center"
                                        className="border-radius-lg mb-4">
                                        <Box display="flex">
                                            <Box
                                                display="flex"
                                                alignY="center"
                                                column
                                                gapX="5"
                                                fullWidth
                                                className="item-account-list p-5 justify-content-start">
                                                <Box display="flex" fullWidth alignY="start" column className="mb-3">
                                                    <Info
                                                        className={"info-account-list color-text-grey"}
                                                        labelKey="creditCard.corporate.movement.detail.consume.consumeCode"
                                                    />
                                                    <Info
                                                        className="info-account-list"
                                                        labelText={statement.idStatement}
                                                    />
                                                </Box>

                                                <Box display="flex" fullWidth alignY="start" column className="mb-3">
                                                    <Info
                                                        className="info-account-list"
                                                        labelKey="creditCard.corporate.movement.detail.creditNote.chargeAmount"
                                                    />
                                                    <Info className="info-account-list" labelText={statement.amount} />
                                                </Box>
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignY="center"
                                                column
                                                gapX="5"
                                                fullWidth
                                                className="item-account-list p-5 justify-content-start">                                            <Box>

                                                <Info
                                                    className="info-account-list"
                                                    labelKey="tableHeader.expiracyDate"
                                                />
                                                <Info
                                                    className="info-account-list"
                                                    labelText={moment(statement.expirationDate).format("DD/MM/YYYY")}
                                                />
                                            </Box>                                            </Box>

                                        </Box>
                                    </Box>
                                )}
                            </>
                        ))}
                    </Box>
                )}
            </>
        );
    };

    render() {
        // return <>{this.renderList()}</>;
        return <Box className="mt-3">{this.renderList()}</Box>;
    }
}

export default CreditCardCorporateConsumeToPay;
