import React, { useState } from "react";
import { bool, func, shape, string } from "prop-types";
import { bindActionCreators } from "redux";
import Head from "pages/_components/Head";

import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";
import TicketContent from "pages/_components/TicketContent";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { selectors } from "reducers/administration/common/administrationTicket";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as formActions } from "reducers/form";
import ExitModal from "pages/_components/modal/ExitModal";
import * as i18n from "util/i18n";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import AdditionalDataTicket from "./_components/tickets/AdditionalDataTicket";
import MainDataTicket from "./_components/tickets/MainDataTicket";
import AdministrationFormConfirmation from "./_components/AdministrationFormConfirmation";
import { getCustomTitleTicket } from "./utils/customTitleTicket";

const SignTransaction = (props) => {
    const { transactionData, fetching, routerAction, actions, fromPending, isRejecting, setFromTicket, products } = props;

    const [showExitModal, setShowExitModal] = useState(false);

    const handleBack = () => {
        routerAction.goBack();
        // handleCancel();
    };

    const handleSubmit = (credentials, formikBag) => {
        const { idActivity, idTransaction } = transactionData;

        const paramsSign = { idForm: null, idActivity, idTransaction };

        if (isRejecting) {
            actions.cancelTransaction({ ...paramsSign, credentials, formikBag });
        } else {
            actions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    const handleCancel = () => {
        setFromTicket();
        if (fromPending) {
            routerAction.push("/pendingTransaction/list", { fromTicket: true });
        } else {
            routerAction.push("/transactions/list", { fromTicket: true });
        }
    };

    const handleAcceptModal = () => {
        handleCancel();
        setShowExitModal(false);
    };

    const handleHideModal = () => {
        setShowExitModal(false);
    };

    const handlePreBack = () => {
        setShowExitModal(true);
    };

    return (
        <>
            <Notification scopeToShow="administration" />
            <Head
                titleText={getCustomTitleTicket(transactionData) || transactionData.activityName}
                onBack={handleBack}
                textBack={`forms.transaction.ticket.${fromPending ? "returnToPending" : "returnToList"}`}
            />
            <MainContainer
                showLoader={fetching}
                className={classNames({
                    "background-white": !isDesktop,
                })}>
                <AdministrationTicket {...props} showButtons={false}>
                    <TicketContent
                        head={<MainDataTicket transactionData={transactionData} products={products} />}
                        isRejecting={isRejecting}
                        transactionData={transactionData}>
                        <AdditionalDataTicket {...props} />
                    </TicketContent>
                </AdministrationTicket>
                <AdministrationFormConfirmation
                    credentialGroups={[{ credentials: ["accessToken", "otp"], idCredentialGroup: "accessToken-otp" }]}
                    onSubmit={handleSubmit}
                    onClickCancel={handlePreBack}
                    isDesktop={isDesktop}
                    idActivity={transactionData?.idActivity}
                />
                <ExitModal
                    modalShow={showExitModal}
                    acceptFunction={handleAcceptModal}
                    cancelFunction={handleHideModal}
                    headingText={i18n.get("confirm.exit.title")}
                    text={i18n.get("confirm.exit.info")}
                />
            </MainContainer>
        </>
    );
};

SignTransaction.propTypes = {
    fetching: bool.isRequired,
    transactionData: shape({
        data: shape({}),
        idActivity: string,
        idTransaction: string,
    }).isRequired,
    routerAction: shape({
        goBack: func.isRequired,
    }).isRequired,
    actions: shape({
        signTransaction: func.isRequired,
    }).isRequired,
    fromPending: bool.isRequired,
    isRejecting: bool,
    setFromTicket: func.isRequired,
};

SignTransaction.defaultProps = {
    isRejecting: false,
};

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
    groups: selectors.getUIpermission(state),
    fetching: selectors.isFetching(state),
    fromPending: transactionsSelectors.getFromPending(state),
    products: selectors.getProductsTransaction(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(formActions, dispatch),
    routerAction: bindActionCreators(routerActions, dispatch),
    setFromTicket: () => dispatch(transactionsActions.setFromTicket()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignTransaction);
