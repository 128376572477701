import { call, put, takeLatest } from "redux-saga/effects";
import { replace } from "react-router-redux";
import { types } from "reducers/loans";
import { NO_TRANSITION } from "constants.js";
import * as loans from "middleware/loans";
import { actions as notificationActions } from "reducers/notification";

import { downloadPdf, downloadXls, downloadCSVFile, downloadMobileFile } from "util/download";
import * as i18n from "util/i18n";
import { isMobileNativeFunc } from "util/device";
import b64toBlob from "b64-to-blob";

const sagas = [
    takeLatest(types.LIST_LOANS_REQUEST, listLoansRequest),
    takeLatest(types.LOAN_DETAILS_REQUEST, loanDetails),
    takeLatest(types.LOAN_READ_REQUEST, loanRead),
    takeLatest(types.DOWNLOAD_PAYMENT_REQUEST, downloadPayment),
    takeLatest(types.LIST_LOAN_STATEMENTS_REQUEST, listStatements),
    takeLatest(types.DOWNLOAD_LOANS_REQUEST, downloadLoans),
    takeLatest(types.DOWNLOAD_STATEMENT_LOANS_REQUEST, downloadStamentLoans),
];

export default sagas;

const defaultFilters = {
    status: "allFees",
};

function* listLoansRequest() {
    const response = yield call(loans.listLoans);
    if (response && response.status === 200) {
        let loansList = response.data.data.amortizedLoans;
        const { requestLoanPermission } = response.data.data;
        const { amortizedLoans, propertiesLoans } = response.data.data;
        const { completeFavorites } = response.data.data;
        loansList = amortizedLoans !== null ? amortizedLoans.concat(propertiesLoans) : propertiesLoans;
        if (loansList.length === 1) {
            yield put(
                replace({
                    pathname: `/loans/${loansList[0].idProduct}`,
                    state: { transition: NO_TRANSITION },
                }),
            );
        } else {
            yield put({
                type: types.LIST_LOANS_SUCCESS,
                loansList,
                requestLoanPermission,
                completeFavorites,
            });
        }
    }
}

function* loanDetails({ selectedLoanId }) {
    const response = yield call(loans.listStatements, selectedLoanId);
    if (response && response.status === 200) {
        yield put({
            type: types.LOAN_DETAILS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* loanRead({ loanId, familyLoan, typeLoan, currentBalance }) {
    const response = yield call(loans.readLoan, loanId, familyLoan, typeLoan);
    if (response && response.status === 200) {
        yield put({
            type: types.LOAN_READ_SUCCESS,
            ...response.data.data,
            loan: { ...response.data.data.loan, currentBalance },
        });
    }
}

function* downloadPayment({ idLoan, format }) {
    const { type, data } = yield call(loans.downloadPayment, idLoan, defaultFilters, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_PAYMENT_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["loan/details"]));
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else if (format === "xls") {
            downloadXls(fileName, content);
        } else if (format === "csv") {
            downloadCSVFile(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_PAYMENT_SUCCESS });
    }
}

function* listStatements({ loan, onFinish }) {
    const response = yield call(loans.listStatements, loan);

    if (response) {
        if (response.type === "W") {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["loan/details"]),
            );
        } else if (response.status === 200) {
            const { statements, feePayment, amountPayment, lastPaymentDay } = response.data.data;
            yield put({
                type: types.LIST_LOAN_STATEMENTS_SUCCESS,
                statements,
                feePayment,
                amountPayment,
                lastPaymentDay,
            });
        }
    }

    onFinish();
}

function* downloadLoans({ format, productType, groupCode, numOperation, onFinish }) {
    const response = yield call(loans.downloadLoanList, format, productType, groupCode, numOperation);
    if (response.type === "W") {
        yield put({ type: types.DOWNLOAD_LOANS_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["loans"]));
    } else {
        yield put({ type: types.DOWNLOAD_LOANS_SUCCESS });
        if (onFinish) {
            onFinish();
        }
        const { content, fileName, contentType } = response.data.data;

        if (isMobileNativeFunc()) {
            const fileBlob = b64toBlob(content, contentType);
            downloadMobileFile(fileBlob, fileName, contentType);
            yield;
            return;
        }

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else if (format === "xls") {
            downloadXls(fileName, content);
        } else if (format === "txt") {
            downloadCSVFile(fileName, content);
        } else {
            yield put({ type: types.DOWNLOAD_LOANS_FAILURE });
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["loans"]));
        }
    }
}

function* downloadStamentLoans({ format, idLoan, groupCode, typeProduct, numOperation, description, onFinish }) {
    const response = yield call(
        loans.downloadStatementLoanList,
        format,
        idLoan,
        groupCode,
        typeProduct,
        numOperation,
        description,
    );
    if (response.type === "W") {
        yield put({ type: types.DOWNLOAD_STATEMENT_LOANS_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["details/loan"]));
    } else {
        yield put({ type: types.DOWNLOAD_STATEMENT_LOANS_SUCCESS });
        if (onFinish) {
            onFinish();
        }
        const { content, fileName, contentType } = response.data.data;

        if (isMobileNativeFunc()) {
            const fileBlob = b64toBlob(content, contentType);
            downloadMobileFile(fileBlob, fileName, contentType);
            yield;
            return;
        }

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else if (format === "xls") {
            downloadXls(fileName, content);
        } else if (format === "txt") {
            downloadCSVFile(fileName, content);
        } else {
            yield put({ type: types.DOWNLOAD_STATEMENT_LOANS_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["details/loan"]),
            );
        }
    }
}
