import classNames from "classnames";
import Box from "pages/_components/Box";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FieldWarning from "pages/_components/fields/FieldWarning";
import { resizableRoute } from "pages/_components/Resizable";
import Tooltip from "pages/_components/Tooltip";
import withFocus from "pages/_components/withFocus";
import { bool, element, func, node, number, object, oneOf, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { compose } from "redux";
import { ReactComponent as Show } from "styles/images/search.svg";
import { getNestedObject } from "util/general";
import * as i18n from "util/i18n";
import isEmpty from "lodash/isEmpty";
import loadash from "lodash";

class TextField extends Component {
    static propTypes = {
        autoCapitalize: string,
        autoComplete: string,
        autoFocus: bool,
        isRequired: bool,
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([number, string]),
        }).isRequired,
        form: shape({
            errors: shape({}),
            touched: shape({}),
        }).isRequired,
        handleOnChange: func,
        hideLabel: bool,
        hidePlaceholder: bool,
        inputFunctions: element,
        serarchStyle: bool,
        inputRef: oneOfType([func, object]),
        isDesktop: bool.isRequired,
        isTablet: bool.isRequired,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
        maxLength: number,
        mobileOS: string.isRequired,
        mode: oneOf(["view", "edit"]),
        nestedErrorsObject: bool,
        optional: string,
        pattern: string,
        renderAs: string,
        type: string,
        warningMessage: string,
        labelText: string,
        placeholderText: string,
        isFocused: bool,
        toggleIsFocused: func,
        idForm: string,
        classNameForViewMode: string,
        className: string,
        copyEnabled: bool,
        idField: string,
        minLength: number,
        validationregularexpresion: string,
        idvalidation: string,
        noMarginBottom: bool,
        tooltip: node,
        tooltipInfo: node,
        tooltipLightSyled: bool,
        tooltipText: string,
        tooltipPosition: string,
        labelNoMarginTop: bool,
        labelClassName: string,
        showLabel: bool,
        lang: string,
        idActivity: string,
        onChangeDelay: func,
        relativeStyleError: bool,
        showError: bool,
        warningIcon: string,
        initValue: string,
    };

    static defaultProps = {
        autoCapitalize: "sentences",
        autoComplete: "on",
        autoFocus: false,
        isRequired: false,
        handleOnChange: null,
        hideLabel: false,
        hidePlaceholder: false,
        inputFunctions: null,
        serarchStyle: false,
        inputRef: React.createRef(),
        maxLength: 50,
        mode: "edit",
        nestedErrorsObject: false,
        optional: "",
        pattern: null,
        renderAs: "input",
        type: "text",
        warningMessage: "",
        labelText: null,
        placeholderText: null,
        isFocused: false,
        toggleIsFocused: null,
        idForm: "",
        classNameForViewMode: "",
        className: null,
        copyEnabled: true,
        idField: "",
        minLength: 0,
        validationregularexpresion: "",
        idvalidation: "",
        noMarginBottom: false,
        tooltip: null,
        tooltipInfo: null,
        tooltipLightSyled: false,
        tooltipText: null,
        tooltipPosition: null,
        labelNoMarginTop: false,
        labelClassName: null,
        showLabel: true,
        lang: undefined,
        idActivity: undefined,
        onChangeDelay: undefined,
        relativeStyleError: false,
        warningIcon: "warningIcon.svg",
        initValue: undefined,
        showError: false,
    };

    state = {
        hasWarning: false,
    };

    constructor(...args) {
        super(...args);
        const { onChangeDelay } = this.props;
        if (onChangeDelay) {
            this.debounceCheckInput = loadash.debounce(this.checkInput.bind(this), 700);
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        const { isDesktop, isFocused, mobileOS } = this.props;

        if (isFocused && !isDesktop && mobileOS === "Android") {
            /* eslint-disable-next-line react/no-find-dom-node */
            ReactDOM.findDOMNode(this).scrollIntoView({ block: "center", behavior: "smooth" });
        }
    };

    handleBlur = (event) => {
        const { field, toggleIsFocused } = this.props;

        this.setState({
            hasWarning: false,
        });
        field.onBlur(event);
        toggleIsFocused();
    };

    handleOnChange = (event) => {
        const { field, handleOnChange, maxLength, pattern, validationregularexpresion, onChangeDelay } = this.props;
        const { onChange } = field;

        const regex = new RegExp(validationregularexpresion);
        /* eslint no-control-regex: "off" */
        const reg = /[^\x00-\xFF]/g;

        if (!reg.test(event.target.value)) {
            if (!pattern || event.target.validity.valid) {
                if (event.target.value.length <= maxLength) {
                    if (validationregularexpresion !== "" && !regex.test(event.target.value)) {
                        return;
                    }
                    if (handleOnChange) {
                        handleOnChange(event);
                    }

                    onChange(event);
                    this.validate(event.target.value);
                    this.setState({
                        hasWarning: false,
                    });

                    if (onChangeDelay) {
                        this.debounceCheckInput();
                    }

                    return;
                }
            }
        }
        this.setState({
            hasWarning: true,
        });
    };

    handleOnCopy = (event) => {
        const { copyEnabled } = this.props;
        if (!copyEnabled) {
            event.preventDefault();
        }
    };

    renderLabel = () => {
        const { idForm, field, labelText, optional } = this.props;
        const text = labelText || `${idForm}.${field.name}.label`;
        return <FieldLabel labelKey={text} optional={optional} />;
    };

    renderPlaceholder = () => {
        const { idForm, field, hidePlaceholder, placeholderText } = this.props;
        if (hidePlaceholder) {
            return "";
        }
        if (placeholderText !== null) {
            return placeholderText;
        }
        return i18n.get(`${idForm}.${field.name}.placeholder`);
    };

    customMinLengthValidation = ({ target }) => {
        const { minLength, idvalidation, validationregularexpresion } = this.props;

        const { value } = target;

        if (minLength) {
            if (value && value.length < minLength) {
                target.setCustomValidity(
                    i18n.get("generic.text.field.minLength.warning.message", null, {
                        MINLENGTH: minLength,
                        CURRENTLENGTH: value.length,
                    }),
                );
            } else {
                target.setCustomValidity("");
            }
        }

        if (idvalidation && validationregularexpresion) {
            const rexp = new RegExp(validationregularexpresion);
            if (!rexp.test(value)) {
                switch (idvalidation) {
                    case "email":
                        target.setCustomValidity(i18n.get("generic.text.field.email.validationError", null));
                        break;
                    default:
                        break;
                }
            } else {
                target.setCustomValidity("");
            }
        }
    };

    setValue = (value) => {
        const {
            form: { setFieldValue },
            field: { name },
        } = this.props;

        if (this.isValidValue(value)) {
            setFieldValue(name, value);
            this.validate(value);
        }
    };

    isEmptyValue = (value) => !value;

    i18n = (type) => {
        const { lang, idActivity, idField } = this.props;

        // eslint-disable-next-line react/destructuring-assignment
        const map = this.props[`${type}Map`] || {};

        if (isEmpty(map)) {
            return i18n.getI18Component(type, lang, idActivity, idField);
        }
        return map[lang];
    };

    validate = (value) => {
        const { isRequired } = this.props;
        if (isRequired && this.isEmptyValue(value)) {
            this.setError(this.i18n("requiredError"));
            return false;
        }

        this.setError(null);

        return true;
    };

    setError = (msg) => {
        const {
            form: { errors, setErrors },
            field: { name },
        } = this.props;

        if (msg) {
            setErrors({ ...errors, [name]: msg });
        } else {
            const { [name]: error, ...rest } = errors;
            setErrors(rest);
        }
    };

    checkInput() {
        const { onChangeDelay, field } = this.props;
        if (onChangeDelay) {
            onChangeDelay(field?.value || "");
        }
    }

    render() {
        const {
            autoComplete,
            field,
            form: { touched, errors },
            inputFunctions,
            inputRef,
            hideLabel,
            hidePlaceholder,
            idForm,
            handleOnChange,
            isDesktop,
            isTablet,
            isMobile,
            isMobileNative,
            mobileOS,
            toggleIsFocused,
            isFocused,
            renderAs: Element,
            autoCapitalize,
            mode,
            optional,
            nestedErrorsObject,
            warningMessage,
            labelText,
            placeholderText,
            classNameForViewMode,
            copyEnabled,
            idField,
            className,
            noMarginBottom,
            labelNoMarginTop,
            labelClassName,
            tooltip,
            tooltipInfo,
            tooltipLightSyled,
            tooltipText,
            tooltipPosition,
            serarchStyle,
            showLabel = true,
            relativeStyleError,
            warningIcon,
            initValue,
            onChangeDelay,
            isRequired,
            idActivity,
            showError,
            ...props
        } = this.props;
        const { hasWarning } = this.state;
        const hasError = nestedErrorsObject
            ? getNestedObject(touched, field.name.split(".")) && getNestedObject(errors, field.name.split("."))
            : touched[field.name] && errors[field.name];

        if (mode !== "edit" && !field.value) {
            return null;
        }

        const fieldLabel = labelText || `${idForm}.${field.name}.label`;
        const id = idField || `${idForm}.${field.name}`;

        return (
            <div
                className={classNames("form-group", className, {
                    "has-error": hasError || showError,
                    "has-focus": isFocused,
                    "form-group-margin-bottom": !noMarginBottom,
                })}>
                {showLabel && (
                    <FieldLabel
                        labelNoMarginTop={labelNoMarginTop}
                        labelClassName={labelClassName}
                        hideLabel={hideLabel}
                        {...(labelText ? { labelText } : { labelKey: fieldLabel })}
                        optional={optional}
                        idField={id}
                        tooltipLightSyled={tooltipLightSyled}
                        tooltipText={tooltipText}
                        tooltipInfo={tooltipInfo}
                        tooltipPosition={tooltipPosition}
                    />
                )}

                {mode === "edit" ? (
                    <div className="input-group">
                        {serarchStyle && (
                            <Box
                                className="pl-5"
                                display="flex"
                                alignX="center"
                                alignY="center"
                                position="absolute"
                                fullHeight>
                                <Show className="svg-image" />
                            </Box>
                        )}
                        <Element
                            id={id}
                            className={classNames("form-control", {
                                "pl-9 position-relative": serarchStyle,
                            })}
                            placeholder={this.renderPlaceholder()}
                            {...field}
                            {...props}
                            value={(field.value === undefined ? initValue : field.value) || ""}
                            onFocus={toggleIsFocused}
                            onBlur={this.handleBlur}
                            ref={inputRef}
                            onChange={this.handleOnChange}
                            autoCapitalize={autoCapitalize}
                            onCopy={this.handleOnCopy}
                            onPaste={this.handleOnCopy}
                            onCut={this.handleOnCopy}
                            onInvalid={this.customMinLengthValidation}
                            autoComplete={autoComplete}
                        />
                        {inputFunctions}
                    </div>
                ) : (
                    <div className={classNameForViewMode}>{field.value}</div>
                )}

                {(hasError || showError) && (
                    <FieldError
                        relativeStyleError={relativeStyleError}
                        error={nestedErrorsObject ? getNestedObject(errors, field.name.split(".")) : errors[field.name]}
                    />
                )}

                {hasWarning && <FieldWarning warning={warningMessage} icon={warningIcon} />}

                {tooltip && isFocused && (
                    <Tooltip
                        tooltipLightSyled={tooltipLightSyled}
                        position={tooltipPosition || (isTablet || isMobile ? "top-right" : "left-centered")}>
                        {tooltip}
                    </Tooltip>
                )}
            </div>
        );
    }
}

export default compose(resizableRoute, withFocus)(TextField);
