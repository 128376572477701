import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LOAD_ADMINISTRATION_TICKET_REQUEST: "ADMINISTRATION/LOAD_ADMINISTRATION_TICKET_REQUEST",
    LOAD_ADMINISTRATION_TICKET_FAILURE: "ADMINISTRATION/LOAD_ADMINISTRATION_TICKET_FAILURE",
    LOAD_ADMINISTRATION_TICKET_SUCCESS: "ADMINISTRATION/LOAD_ADMINISTRATION_TICKET_SUCCESS",

    LOAD_ADMINISTRATION_SIGN_PREVIEW_SUCCESS: "ADMINISTRATION/LOAD_ADMINISTRATION_SIGN_PREVIEW_SUCCESS",
};

export const INITIAL_STATE = {
    data: {},
    fetching: true,
    idTransaction: null,
    idActivity: null,
    credentialGroups: [],
    products: [],
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_ADMINISTRATION_TICKET_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.LOAD_ADMINISTRATION_TICKET_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LOAD_ADMINISTRATION_TICKET_SUCCESS]: (state, { data, emailUserCreator, uipermission, products }) => ({
        ...state,
        fetching: false,
        data,
        emailUserCreator,
        uipermission,
        products,
    }),
    [types.LOAD_ADMINISTRATION_SIGN_PREVIEW_SUCCESS]: (
        state,
        { params, idTransaction, idActivity, credentialGroups },
    ) => ({
        ...state,
        params,
        idTransaction,
        idActivity,
        credentialGroups,
    }),
});

export const actions = {
    loadAdministrationTicketRequest: makeActionCreator(types.LOAD_ADMINISTRATION_TICKET_REQUEST, "idTransaction"),
    loadAdministrationTicketFailure: makeActionCreator(types.LOAD_ADMINISTRATION_TICKET_FAILURE),
    loadAdministrationTicketSuccess: makeActionCreator(
        types.LOAD_ADMINISTRATION_TICKET_SUCCESS,
        "data",
        "emailUserCreator",
        "uipermission",
        "products",
    ),
};

export const selectors = {
    isFetching: ({ administrationTicket }) => administrationTicket.fetching,
    getData: ({ administrationTicket }) => administrationTicket.data,
    getUIpermission: ({ administrationTicket }) => administrationTicket.uipermission,
    getIdTransaction: ({ administrationTicket }) => administrationTicket.idTransaction,
    getIdActivity: ({ administrationTicket }) => administrationTicket.idActivity,
    getCredentialGroups: ({ administrationTicket }) => administrationTicket.credentialGroups,
    getEmailUserCreator: ({ administrationTicket }) => administrationTicket.emailUserCreator,
    getProductsTransaction: ({ administrationTicket }) => administrationTicket.products,
};
