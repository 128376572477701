import Box from "pages/_components/Box";
import FormattedDate from "pages/_components/FormattedDate";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { arrayOf, bool, shape, string } from "prop-types";
import React from "react";

const Signatures = ({ title, list, usesJointAccount, isDesktop }) =>
    list.map((elem) => (
        <>
            {usesJointAccount ? (
                <Box
                    display="flex"
                    alignX="between"
                    alignY="center"
                    key={`${elem.firstName}${elem.lastName}${elem.fullName}`}
                    className="p-3"
                    border="bottom-border-base-color-not-last"
                    fullWidth>
                    <Box display="flex">
                        <Text color="heading" size="7" bold labelKey={title} addColon />
                        <Text color="heading" size="7" className="mr-2">
                            {elem.fullName || `${elem.lastName}, ${elem.firstName}`}
                        </Text>
                        <Text color="heading" size="7">
                            {elem.signatureLevel && ` (${elem.signatureLevel})`}
                        </Text>
                    </Box>
                    <Box display="flex" alignY="center">
                        {elem.date && <FormattedDate color="heading" size="small" date={elem.date} />}
                        {elem.date && elem.isValid && (
                            <Image src="images/icons/ticketSignatureCheck.svg" className="ml-3" />
                        )}
                        {elem.date && !elem.isValid && (
                            <Image src="images/icons/ticketSignatureCanceled.svg" className="ml-3" />
                        )}
                        {!elem.date && <Box borderRadius="circle" border="border-base-color" className="p-3 ml-3" />}
                    </Box>
                </Box>
            ) : (
                <Box display="block" alignY="baseline" key={elem.fullName}>
                    <div className="data-wrapper data-confirm mb-2">
                        <span className="data-label">
                            {elem.fullName}&nbsp;&nbsp;&nbsp;
                            {!isDesktop && (
                                <>
                                    {elem.dateAsString ? (
                                        <>{elem.dateAsString}</>
                                    ) : (
                                        <FormattedDate date={elem.date} anotherFormat="DD/MM/YYYY HH:mm:ss" />
                                    )}
                                </>
                            )}
                        </span>
                        {isDesktop && (
                            <span className="data-desc">
                                {elem.dateAsString ? (
                                    <>{elem.dateAsString}</>
                                ) : (
                                    <FormattedDate date={elem.date} anotherFormat="DD/MM/YYYY HH:mm:ss" />
                                )}
                            </span>
                        )}
                    </div>
                </Box>
            )}
        </>
    ));

Signatures.propTypes = {
    firstName: string,
    lastName: string,
    date: string,
    fullName: string,
    signatureLevel: string,
    title: string.isRequired,
    list: arrayOf(shape({})).isRequired,
    usesJointAccount: bool,
};

Signatures.defaultProps = {
    firstName: null,
    lastName: null,
    date: null,
    fullName: null,
    signatureLevel: null,
    usesJointAccount: false,
};

export default Signatures;
