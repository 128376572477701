import { Field } from "formik";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape, string, arrayOf } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import Selector from "pages/_components/fields/formik/Selector";
import { dictionaryMiniatureCard } from "util/creditCards";
import Info from "pages/_components/Info";
import { format } from "date-fns";
import { formatNumber } from "util/format";
import TextField from "pages/_components/fields/TextField";
import AmountField from "pages/_components/fields/formik/AmountField";
import { push } from "react-router-redux";
import TicketData from "pages/_components/TicketData";
import { actions as transactionsActions } from "reducers/transactions";
import { isDesktop } from "react-device-detect";
import classNames from "classnames";
import FormTransition from "../_components/FormTransition";
import {
    useCreditCardPaymentSelectorOptions,
    PARTIAL_PAYMENT,
    useDebitAccountSelectorOptions,
    usePaymentTypeOptions,
    useCreditCardIdParam,
} from "./hooks/PayCreditCardHooks";
import { useMetaData } from "./hooks/TransferInternalHooks";
import ExitModal from "pages/_components/modal/ExitModal";

const ID_FORM = "creditcard.pay.own";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
const TITLE_FORM = `forms.${ID_FORM}.title`;

function isValidForm(values) {
    const isQuantityValid = values?.amount?.quantity > 0;

    return values?.creditCard
        && values?.amountPaymentOptions
        && values?.debitAccount
        && values?.amount
        && isQuantityValid
        && values?.concept;
}

const LocalCreditCardPaymentForm = (props) => {
    const {
        mode,
        dispatch,
        preDataForm,
        transaction,
        creditCardDetail,
        fetchingDetail,
        fetchingList,
        location,
        fetching,
        formProducts,
    } = props;

    const accountsOptions = useDebitAccountSelectorOptions(formProducts || []);

    const creditCardsOptions = useCreditCardPaymentSelectorOptions(preDataForm);
    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);

    const [creditCardIdParam] = useCreditCardIdParam(location);
    const [creditCardIdSelected, setCreditCardIdSelected] = useState(null);

    const [creditCardDetailSelected, setCreditCardDetailSelected] = useState({});
    const amountPaymentOptions = usePaymentTypeOptions(creditCardDetailSelected);
    const [enabledSubmit, setEnabledSubmit] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);
    const [firstPre, setFirstPre] = useState(false);

    const titleForm = TITLE_FORM;

    useEffect(() => {
        if (mode === "edit" && !firstPre) {
            dispatch(
                formActions.preForm(
                    { idActivity: ID_ACTIVITY_PRE },
                    { message: "No tiene Tarjetas de Crédito asociadas", location: "/desktop" },
                ),
            );
            dispatch(formActions.preCustomForm(["creditcard.payment"]));
            setFirstPre(true);
            dispatch(transactionsActions.cleanActionSecondary());
        }

        if (mode === "view") {
            if (transaction?.idTransactionStatus === "FINISHED") {
                //dispatch(transactionsActions.setActionSecondaryByPath("global.goToHome", null, "/desktop"));
                //dispatch(transactionsActions.setHiddenBackButton(true));
                //dispatch(push(`/transaction/${transaction?.idTransaction}`));
                //return;
            }
        }
    }, [dispatch, mode]);

    useEffect(() => {
        const id = creditCardIdParam || creditCardIdSelected;
        if (id) {
            dispatch(creditCardActions.readCreditCard(id));
        }
    }, [creditCardIdParam, creditCardIdSelected]);

    useEffect(() => {
        if (
            creditCardDetail &&
            (creditCardDetail.idProduct === creditCardIdParam || creditCardDetail.idProduct === creditCardIdSelected)
        ) {
            setCreditCardDetailSelected(creditCardDetail);
        }
    }, [creditCardDetail]);

    useEffect(
        () => () => {
            dispatch(creditCardActions.detailStopFetching());
        },
        [dispatch],
    );

    const renderTicket = () => <></>;

    const renderCreditCardInfoTitle = () => (
        <LabelText labelKey="forms.creditcard.pay.own.cardData.title" defaultValue="Datos de Tarjeta" isDesktop={isDesktop} />
    );

    const renderCreditCardSelector = (setFieldValue) => (
        <Field
            component={Selector}
            idForm={ID_FORM}
            name="creditCard"
            options={creditCardsOptions}
            value={creditCardDetailSelected?.idProduct || ""}
            renderAs="combo"
            labelClassName={ isDesktop? "size-5 text-bold": "size-3 text-semibold" }
            handleChange={(creditCard) => {
                setCreditCardIdSelected(creditCard);
                setFieldValue("amountPaymentOptions", undefined);
            }}
            labelText={i18n.get("forms.creditcard.pay.own.cardData.cardSelector.label", "Tarjeta de crédito")}
            placeholder={i18n.get("forms.creditcard.pay.own.cardData.cardSelector.placeholder", "Seleccionar tarjeta")}
        />
    );

    const renderCreditCardImage = () => (
        <Image
            src={`images/${dictionaryMiniatureCard(creditCardDetailSelected?.cardStyle)}-icon-creditCard.svg`}
            className="img-header-card fill-transparent"
        />
    );

    const renderCreditCardLabel = () => (
        <Info
            labelText={creditCardDetailSelected?.shortLabel || ""}
            text={creditCardDetailSelected?.nameOnCard || ""}
            size={isDesktop? 5 : 3}
            classNameLabel={isDesktop? "size-5": "size-3"}
        />
    );

    const renderCreditCardExpirationDate = () => (
        <Info
            labelKey="forms.creditcard.pay.own.ticketData.expirationDate.label"
            text={format(creditCardDetailSelected?.expirationDate, i18n.get("datepicker.format")) || ""}
            size={isDesktop? 5 : 3}
            classNameLabel={isDesktop? "size-5": "size-3"}
        />
    );

    const renderCreditCardMinimumPayment = () => (
        <Info labelText={isDesktop? "Mínimo a Pagar USD": "Mínimo a Pagar"} classNameLabel={isDesktop? "size-5": "size-3"}
        text={!isDesktop? '$ '.concat(formatNumber(creditCardDetailSelected?.minimumPayment || 0)): formatNumber(creditCardDetailSelected?.minimumPayment || 0)} size={isDesktop? 4 : 3} />
    );

    const renderCreditCardTotalPayment = () => (
        <Info labelText={isDesktop? "Total a Pagar USD": "Total a Pagar"}  classNameLabel={isDesktop? "size-5": "size-3"} classNameValue={ isDesktop? "display-flex justify-content-end": "display-flex justify-content-start"}
        text={!isDesktop? '$ '.concat(formatNumber(creditCardDetailSelected?.finalBalance || 0)): formatNumber(creditCardDetailSelected?.finalBalance || 0)} size={isDesktop? 4 : 3} />
    );

    const renderPaymentOptionsTitle = () => (
        <LabelText labelKey="forms.creditcard.pay.own.paymentData.title" defaultValue="Datos de Pago" isDesktop={isDesktop} />
    );

    const renderPaymentOptionsPaymentTypeLabel = () => (
        <LabelText labelKey="forms.creditcard.pay.own.paymentData.paymentType.label"  defaultValue="Seleccionar Tipo de pago" isDesktop={isDesktop} />
    );

    const renderPaymentOptionsPaymentTypeSelector = (setFieldValue) => (
        <Field
            component={Selector}
            idForm={ID_FORM}
            name="amountPaymentOptions"
            options={amountPaymentOptions}
            renderAs="radio"
            radioClassNames={classNames("radio-like-buttons radio-no-icon ", {"size-5 text-bold":isDesktop, "flex-direction-column size-3 text-semibold": !isDesktop }, )}
            handleChange={(option) => {
                setFieldValue("amountPaymentOptions", option);
                setFieldValue("creditCard", creditCardDetailSelected?.idProduct);
                let amount = null;
                if (option === "cashAmount" ) {
                    amount = creditCardDetailSelected?.finalBalance;
                }
                if (option === "minimumPayment" ) {
                    amount = creditCardDetailSelected?.minimumPayment;
                }

                if (amount != null) {
                    const newAmount = option !== PARTIAL_PAYMENT ? amount : "";
                    setFieldValue("amount", { currency: "USD", quantity: newAmount });
                } else {
                    setFieldValue("amount", { currency: "USD", quantity: "" });
                }
                setFieldValue("nameOnCard", creditCardDetailSelected?.label);
                setFieldValue("cardHolder", creditCardDetailSelected?.nameOnCard);
                setFieldValue("expirationDate", creditCardDetailSelected?.expirationDate);
            }}
        />
    );


    const renderPaymentOptionsDebitAccountSelector = (setFieldValue) => (
        <Field
            component={Selector}
            idForm={ID_FORM}
            name="debitAccount"
            options={accountsOptions}
            renderAs="combo"
            labelClassName={ isDesktop? "size-5 text-bold": "size-3 text-semibold" }
            handleChange={(debitAccount) => {
                setFieldValue("debitAccount", debitAccount);
                const acc = formProducts?.find((e) => e.idProduct === debitAccount);
                setFieldValue(
                    "debitAccountLabel",
                    `${acc.numberMask} - ${acc.client?.name} - ${acc.productTypeBackend} - ${acc.currency}`,
                );
            }}
            labelText={i18n.get("forms.creditcard.pay.own.paymentData.account.label", "Cuenta Ordenante")}
            placeholder={i18n.get("forms.creditcard.pay.own.paymentData.account.placeholder", "Seleccionar cuenta")}
        />
    );

    const renderPaymentOptionsConceptTextField = () => (
        <Field
            name="concept"
            idForm={ID_FORM}
            component={TextField}
            pattern="^[a-zA-Z0-9 ]*$"
            maxLength={100}
            labelClassName={isDesktop ? "size-5 text-bold" : "size-3 text-semibold"}
            spellCheck="false"
            labelText={i18n.get("forms.creditcard.pay.own.paymentData.concept.label", "Concepto")}
            placeholder={i18n.get("forms.creditcard.pay.own.paymentData.concept.placeholder", "Ingresar un concepto")}
        />
    );

    const renderPaymentOptionsAmountField = (setFieldValue, values) => (
        <Field
            name="amount"
            component={AmountField}
            maxLength={15}
            data={{
                options: [{ id: 1, label: "USD" }],
            }}
            clearable={false}
            labelText={i18n.get("forms.creditcard.pay.own.paymentData.amount.label", "Monto USD")}
            hideCurrency
            fixedDecimalScale
            onInputChange={(value) => {
                setFieldValue("amount", { currency: "USD", quantity: value });
            }}
            bold={false}
            amount={values?.amount?.quantity}
            placeholder={i18n.get("forms.creditcard.pay.own.paymentData.amount.placeholder", "Ingresar")}
            labelNoMarginTop="true"
        />
    );

    const renderTicketConfirmation = (values) => (
        <>
            <TicketData.Main
                label="forms.creditcard.pay.own.ticketData.amount.label"
                value={values.amount?.quantity}
                currency="USD"
                type="amount"
            />

                <Box className="mt-3 mb-4">
                    <Text
                        size={5}
                        bold
                        labelKey="forms.creditcard.pay.own.ticketData.title"
                        defaultValue="Datos de tarjeta"
                    />
                </Box>
                <TicketData.Data
                    label="forms.creditcard.pay.own.ticketData.card.label"
                    value={`${creditCardDetailSelected?.label}`}
                />
                <TicketData.Data
                    label="forms.creditcard.pay.own.ticketData.holder.label"
                    value={creditCardDetailSelected?.nameOnCard}
                />
                <TicketData.Data
                    label="forms.creditcard.pay.own.ticketData.expirationDate.label"
                    value={format(creditCardDetailSelected?.expirationDate, i18n.get("datepicker.format"))}
                />

                <Box className="mt-5 mb-4">
                    <Text
                        size={5}
                        bold
                        labelKey="forms.creditcard.pay.own.ticketData.paymentData.title"
                        defaultValue="Datos de pago"
                    />
                </Box>
                <TicketData.Data
                    label="forms.creditcard.pay.own.ticketData.paymentData.account.label"
                    value={values.debitAccountLabel}
                />
                <TicketData.Data
                    label="forms.creditcard.pay.own.ticketData.paymentData.concept.label"
                    value={values.concept}
                />
                {renderModal()}
        </>
    );

    const renderDesktop = (values, setFieldValue) => (
        <>
            <Box className="full-width border-radius-lg pt-7 box-shadow-small background-white texture-header mb-7">
                <Box className="background-white p-7">
                    <Box className="border-radius-lg box-shadow-small mb-7 p-7">
                        {renderCreditCardInfoTitle()}
                        {renderCreditCardSelector(setFieldValue)}

                        {Object.keys(creditCardDetailSelected).length > 0 && (
                            <Box className="background-background-disabled border-radius-lg mt-7 px-5 display-flex flex-nowrap align-items-center gap-6">
                                <Box className="display-flex flex-2 align-items-center gap-6">
                                    <Box>{renderCreditCardImage()}</Box>
                                    <Box>{renderCreditCardLabel()}</Box>
                                </Box>
                                <Box className="flex">{renderCreditCardExpirationDate()}</Box>
                                <Box className="flex">{renderCreditCardMinimumPayment()}</Box>
                                <Box className="flex text-right">{renderCreditCardTotalPayment()}</Box>
                            </Box>
                        )}
                    </Box>

                    {/** payment options  */}
                    <Box className="border-radius-lg box-shadow-small p-7">
                        <Box className="mb-7">{renderPaymentOptionsTitle()}</Box>
                        <Box>
                            {renderPaymentOptionsPaymentTypeLabel()}
                            {renderPaymentOptionsPaymentTypeSelector(setFieldValue)}
                        </Box>
                        <Box className="display-flex gap-6">
                            <Box className="flex max-width-half with-ellipsis-container">{renderPaymentOptionsDebitAccountSelector(setFieldValue)}</Box>
                            <Box className="flex max-width-half with-ellipsis-container">{renderPaymentOptionsConceptTextField()}</Box>
                        </Box>
                        {values.amountPaymentOptions === PARTIAL_PAYMENT && (
                            <Box className="display-flex gap-6 mt-7">
                                <Box className="flex">{renderPaymentOptionsAmountField(setFieldValue, values)}</Box>
                                <Box className="flex" />
                            </Box>
                        )}
                    </Box>
                    {
                        renderModal()
                    }
                </Box>
            </Box>
        </>
    );

    const renderMobile = (values, setFieldValue) => (
        <>
            <Box className="pt-5">{renderCreditCardInfoTitle()}</Box>
            <Box className="mb-5">{renderCreditCardSelector(setFieldValue)}</Box>

            {Object.keys(creditCardDetailSelected).length > 0 && (
                <Box className="background-background-disabled display-flex flex-direction-column p-5 border-radius-lg gap-3 mb-5">
                    <Box className="display-flex align-items-center gap-6">
                        <Box className="">{renderCreditCardImage()}</Box>
                        <Box>{renderCreditCardLabel()}</Box>
                    </Box>

                    <Box className="display-flex align-items-center gap-6">
                        <Box>{renderCreditCardExpirationDate()}</Box>
                        <Box>{renderCreditCardMinimumPayment()}</Box>
                    </Box>
                    <Box className="display-flex align-items-center">
                        <Box>{renderCreditCardTotalPayment()}</Box>
                    </Box>
                </Box>
            )}

            {
                /** payment options  */
                <Box>
                    <Box className="mb-5">{renderPaymentOptionsTitle()}</Box>

                    <Box fullWidth>
                        <Box>{renderPaymentOptionsPaymentTypeLabel()}</Box>
                        <Box className="display-flex flex-direction-column" fullWidth>
                            {renderPaymentOptionsPaymentTypeSelector(setFieldValue)}
                        </Box>
                        <Box fullWidth className="display-flex flex-direction-column">
                            {renderPaymentOptionsDebitAccountSelector(setFieldValue)}
                            {renderPaymentOptionsConceptTextField()}
                        </Box>
                        {values.amountPaymentOptions === PARTIAL_PAYMENT && (
                            <Box className="mt-5">{renderPaymentOptionsAmountField(setFieldValue, values)}</Box>
                        )}
                    </Box>
                </Box>
            }
            {
                renderModal()
            }
        </>
    );

    const renderFields = (setFieldValue, values, _setValues, _scheduler, errors, setErrors) => {
        if (mode !== "view" && (!preDataForm || fetchingDetail || fetchingList || fetching)) {
            return <PageLoading loading classicStyle={false} />;
        }
        if (mode === "edit" && isValidForm(values)) {
            setEnabledSubmit(true);
        } else {
            setEnabledSubmit(false);
        }

        if (mode === "edit") {
            return <>{
                isDesktop? renderDesktop(values, setFieldValue) : renderMobile(values, setFieldValue)
            }</>

        }
        if (mode === "preview") {
            return <>{renderTicketConfirmation(values)}</>;
        }
    };

    const renderModal = () =>(
        <ExitModal
            modalShow={showExitModal}
            acceptFunction={() => dispatch(push("/desktop"))}
            cancelFunction={()=> setShowExitModal(false)}
            headingText={i18n.get("confirm.exit.title")}
            text={i18n.get("confirm.exit.info")}
        />
    )

    const formProps = {
        title: titleForm,
        data: {},
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        cancelAction: () => {
            setShowExitModal(true)
        },
        handleCancelPreview: () => {
            setShowExitModal(true)
        },
        textBack: "forms.creditcard.pay.own.textBack",
        textBackConfirmation: "global.return",
        preData: preDataForm,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        // validationSchema,
        //validate: validateForm,
        showSchedulerMessage: false,
        showFilterChips: false,
        submitDisabled: !enabledSubmit,
        ticketConfirmation: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

LocalCreditCardPaymentForm.propTypes = {
    idForm: string,
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    isDesktop: bool,
    postData: shape({}),
    creditCardDetail: shape({}),
    fetchingDetail: bool,
    fetchingList: bool,
    creditCardList: arrayOf(shape({})),
    data: arrayOf(shape({})),
};
LocalCreditCardPaymentForm.defaultProps = {
    idForm: ID_FORM,
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: null,
    transaction: null,
    location: {},
    fromTransaction: false,
    isDesktop: false,
    postData: {},
    creditCardDetail: {},
    fetchingDetail: false,
    fetchingList: false,
    creditCardList: [],
    data: {},
};
const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    postData: formSelectors.getData(state),
    creditCardDetail: creditCardSelectors.getCreditCardDetail(state),
    fetchingDetail: creditCardSelectors.getFetching(state),
    fetchingList: creditCardSelectors.isFetching(state),
    formProducts: formSelectors.getCustomProducts(state),
});

const areEqual = (prevProps, nextProps) =>
    // Compare props that are important for re-rendering
    prevProps.mode === nextProps.mode &&
    prevProps.transaction === nextProps.transaction &&
    prevProps.creditCardDetail === nextProps.creditCardDetail &&
    prevProps.fetchingDetail === nextProps.fetchingDetail &&
    prevProps.fetchingList === nextProps.fetchingList &&
    prevProps.location === nextProps.location &&
    // Only compare specific parts of preDataForm that you care about
    prevProps.preDataForm?.importantField === nextProps.preDataForm?.importantField &&
    prevProps.preDataForm?.anotherImportantField === nextProps.preDataForm?.anotherImportantField;
export default React.memo(
    compose(connect(mapStateToProps), withRouter)(resizableRoute(LocalCreditCardPaymentForm)),
    areEqual,
);

function LabelText({ labelKey, defaultValue, isDesktop }) {
    return (
        <Text labelKey={labelKey} defaultValue={defaultValue} className={classNames({
            "text-semibold": !isDesktop,
            "text-bold": isDesktop,
            "size-3": !isDesktop,
            "size-5": isDesktop
        })}/>
    );
}
