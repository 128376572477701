import React, { useEffect, useState } from "react";
import Box from "pages/_components/Box";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import FormTransition from "pages/forms/_components/FormTransition";
import { useGenericProps, useMetaData } from "pages/forms/customForms/hooks/TransferInternalHooks";
import { arrayOf, bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { compose } from "redux";
import * as i18n from "util/i18n";
import TicketData from "pages/_components/TicketData";
import { USD_CURRENCY } from "constants.js";
import { actions as checkServiceActions, selectors as checkServiceSelectors } from "reducers/checks";
import classNames from "classnames";
import Notification from "pages/_components/Notification";
import ExitModal from "pages/_components/modal/ExitModal";

import { Field } from "formik";
import Image from "pages/_components/Image";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { selectors as templateSelectors } from "reducers/template";
import Selector from "pages/_components/fields/formik/Selector";
import { dictionaryMiniatureCard } from "util/creditCards";
import Info from "pages/_components/Info";
import { format } from "date-fns";
import { formatNumber } from "util/format";
import TextField from "pages/_components/fields/TextField";
import AmountField from "pages/_components/fields/formik/AmountField";
import { actions as transactionsActions } from "reducers/transactions";
import { isDesktop, isMobile } from "react-device-detect";
import * as Yup from "yup";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import * as config from "util/config";
import CreditCardCorporateConsumeToPay from "pages/creditCards/_components/CreditCardCorporateConsumeToPay.jsx";

import {
    useCreditCardCorporatePaymentSelectorOptions,
    //PARTIAL_PAYMENT,
    useDebitAccountSelectorOptions,
    usePaymentTypeOptions,
    useCreditCardIdParam,
} from "./hooks/PayCreditCardHooks";
import { stubTrue } from "lodash";

const ID_FORM = "creditcard.corporate.pay";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
const TITLE_FORM = `${ID_FORM}.title`;

export const validationSchema = () => Yup.object().shape({});

export const validateForm = (values) => {
    let isValid = true;
    if (
        !values?.creditCard ||
        // !values?.amountPaymentOptions ||
        !values?.debitAccount ||
        !values?.amount ||
        !values?.concept ||
        values?.amount.quantity <= 0
    ) {
        isValid = false;
    }
    return isValid;
};

const LocalCreditCardsCorporatePaymentForm = (props) => {
    const {
        mode,
        dispatch,
        preDataForm,
        transaction,
        creditCardDetail,
        fetchingDetail,
        fetchingList,
        location,
        movementsConsumeScreen,
        movementsConsumeToPay,
        formProducts,
        fetchingForm,
    } = props;

    const accountsOptions = useDebitAccountSelectorOptions(formProducts||[]);
    const creditCardsOptions = useCreditCardCorporatePaymentSelectorOptions(preDataForm);
    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);

    const [creditCardIdParam] = useCreditCardIdParam(location);
    const [creditCardIdSelected, setCreditCardIdSelected] = useState(null);

    const [creditCardDetailSelected, setCreditCardDetailSelected] = useState({});
    // const amountPaymentOptions = usePaymentTypeOptions(creditCardDetailSelected);
    const [enabledSubmit, setEnabledSubmit] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);

    const titleForm = TITLE_FORM;

    useEffect(() => {
        dispatch(formActions.preCustomForm(["creditcard.payment.corparate"]));
    }, [dispatch, mode]);

    useEffect(() => {
        if (mode === "edit") {
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE }));
        }

        if (mode === "view") {
            if (transaction?.idTransactionStatus === "FINISHED") {
                dispatch(transactionsActions.setActionSecondaryByPath("global.goToHome", null, "/desktop"));
            }
        }
    }, [dispatch, mode]);

    useEffect(() => {
        if (mode === "edit") {
            if (preDataForm?.creditCardList?.length === 0) {
                dispatch(
                    notificationActions.showNotification(
                        "No tiene Tarjetas corporativas asociadas",
                        "warning",
                        ["desktop"],
                        null,
                        null,
                        null,
                        false,
                    ),
                );
                // dispatch(push("desktop"));
            }
        }
    }, [preDataForm]);

    useEffect(() => {
        const id = creditCardIdParam || creditCardIdSelected;
        if (id && preDataForm?.creditCardList?.length > 0) {
            setCreditCardDetailSelected(preDataForm?.creditCardList?.find((e) => e.internalNumber === id));
        }
    }, [creditCardIdParam, creditCardIdSelected, preDataForm]);

    useEffect(() => {
        if (
            creditCardDetail &&
            (creditCardDetail.internalNumber === creditCardIdParam ||
                creditCardDetail.internalNumber === creditCardIdSelected)
        ) {
            setCreditCardDetailSelected(creditCardDetail);
        }
    }, [creditCardDetail]);

    useEffect(
        () => () => {
            dispatch(transactionsActions.cleanActionSecondary());
            dispatch(creditCardActions.detailStopFetching());
        },
        [dispatch],
    );

    const handlerCancelAction = () => {
        setShowExitModal(true);
    };

    const handleCancel = () => {
        const { movementsConsumeScreen, creditCardDetail } = props;
        if (
            movementsConsumeScreen === "MOVIMIENTOS" ||
            movementsConsumeScreen === "CONSUMOS" ||
            movementsConsumeScreen === "CONSUMETOPAY"
        ) {
            dispatch(push("/CreditCardsCorporate/" + creditCardDetailSelected.internalNumber));
        } else {
            dispatch(push("/desktop"));
        }
    };

    const renderTicket = () => <></>;

    const renderCreditCardInfoTitle = () => (
        <Text
            className="size-5 text-bold"
            labelKey="forms.creditcard.pay.own.cardData.title"
            defaultValue="Datos de Tarjeta"
        />
    );

    const getErrorMessage = (fieldName, errors) => {
        switch (fieldName) {
            case "amount":
                return i18n.get(`${ID_FORM}.field.error.amount.invalid`, "Debe ser mayor a 0");
            default:
                return null;
        }
    };

    const renderCreditCardConsumeTitle = () => (
        <Text
            className="size-5 text-bold"
            labelKey="corporate.movements.filter.transactionType.1"
            defaultValue="Datos de Tarjeta"
        />
    );

    const renderCreditCardSelector = (setFieldValue) => (
        <Field
            component={Selector}
            idForm={ID_FORM}
            name="creditCard"
            options={creditCardsOptions}
            value={creditCardDetailSelected?.internalNumber || ""}
            renderAs="combo"
            labelClassName="text-bold"
            disabled={!!creditCardIdParam}
            handleChange={(creditCard) => {
                // debugger;
                setCreditCardIdSelected(creditCard);
                // setFieldValue("amountPaymentOptions", undefined);
                const acc = preDataForm?.creditCardList?.find((e) => e.internalNumber === creditCard);
                setFieldValue("creditCardNumberMask", acc.numberMask);
                setFieldValue("creditCardLastPaymentDate", acc.lastPaymentDate);
                setFieldValue("creditCardLastPaymentAmmount", acc.lastPaymentAmmount);
                setFieldValue("creditCardCardStyle", acc.cardStyle);
                setFieldValue("creditCardNameOnCard", acc.nameOnCard);
                setFieldValue("creditCardBalance", acc.balance);
                setFieldValue("creditCardOwner", acc.owner);
                setFieldValue("creditCardOfficial", acc.official);
            }}
            labelText={i18n.get("creditcard.corporate.pay.cardSelector.label", "Tarjeta corporativa")}
            placeholder={i18n.get("forms.creditcard.pay.own.cardData.cardSelector.placeholder", "Seleccionar tarjeta")}
        />
    );

    const renderCreditCardImage = () => (
        <Image
            src={`images/${creditCardDetailSelected?.cardStyle}-miniature-creditCardCorp.svg`}
            className="img-header-card fill-transparent"
        />
    );

    const renderCreditCardLabel = () => (
        <Info
            labelText={creditCardDetailSelected?.shortLabel || ""}
            text={creditCardDetailSelected?.nameOnCard || ""}
        />
    );

    const renderCreditCardStyle = () => (
        <Info
            labelText={
                creditCardDetailSelected?.cardStyle.charAt(0).toUpperCase() +
                creditCardDetailSelected?.cardStyle
                    .slice(1)
                    .toLowerCase()
                    .replaceAll("_", " ")
            }
            text={creditCardDetailSelected?.numberMask.replace(/(.{4})/g, "$1 ")}
        />
    );

    const renderCreditCardName = () => (
        <Info
            labelKey="creditcard.corporate.pay.ticketData.card.nameOwner"
            text={creditCardDetailSelected?.nameOnCard}
        />
    );

    const renderCreditCardTotalPayment = () => (
        <Info
            labelKey="creditcard.corporate.pay.ticketData.card.balance2pay"
            text={creditCardDetailSelected?.lastPaymentAmmount}
        />
    );

    const renderPaymentOptionsTitle = () => (
        <Text
            className="size-5 text-bold"
            labelKey="forms.creditcard.pay.own.paymentData.title"
            defaultValue="Datos de Pago"
        />
    );

    const renderPaymentOptionsDebitAccountSelector = (setFieldValue) => (
        <Field
            component={Selector}
            idForm={ID_FORM}
            name="debitAccount"
            options={accountsOptions}
            renderAs="combo"
            labelClassName="text-bold"
            handleChange={(debitAccount) => {
                setFieldValue("debitAccount", debitAccount);
                const acc = formProducts?.find((e) => e.idProduct === debitAccount);
                setFieldValue(
                    "debitAccountLabel",
                    `${acc.numberMask} - ${acc.client?.name} - ${acc.productTypeBackend} - ${acc.currency}`,
                );
                setFieldValue("debitAccountShortLabel", acc.shortLabel);
                setFieldValue("debitAccountIdClient", acc.client?.idClient);
                setFieldValue("debitAccountName", acc.client?.name);
                setFieldValue("debitAccountNumber", acc.number);
                setFieldValue("debitAccountOwnerName", acc.ownerName);
                setFieldValue("debitAccountCountableBalance", acc.countableBalance);
                setFieldValue("debitAccountNumberMask", acc.numberMask);
                setFieldValue("debitAccountType", acc.productTypeBackend);
            }}
            labelText={i18n.get("forms.creditcard.pay.own.paymentData.account.label", "Cuenta Ordenante")}
            placeholder={i18n.get("forms.creditcard.pay.own.paymentData.account.placeholder", "Seleccionar cuenta")}
        />
    );

    const renderPaymentOptionsConceptTextField = () => (
        <Field
            name="concept"
            idForm={ID_FORM}
            component={TextField}
            labelClassName="text-bold"
            maxLength={100}
            spellCheck="false"
            pattern="^[a-zA-Z0-9\s]*$"
            labelText={i18n.get("forms.creditcard.pay.own.paymentData.concept.label", "Concepto")}
            placeholder={i18n.get("forms.creditcard.pay.own.paymentData.concept.placeholder", "Ingresar un concepto")}
        />
    );

    const renderPaymentOptionsAmountField = (setFieldValue, values, errors) => (
        <Field
            name="amount"
            component={AmountField}
            maxLength={15}
            data={{
                options: [{ id: 1, label: "USD" }],
            }}
            clearable={false}
            labelText={i18n.get("forms.creditcard.pay.own.paymentData.amount.label", "Monto USD")}
            bold={false}
            hideCurrency
            fixedDecimalScale
            onInputChange={(value) => {
                setFieldValue("amount", { currency: "USD", quantity: value });
                setFieldValue("creditCard", creditCardDetailSelected?.internalNumber);
            }}
            amount={values.amount?.quantity}
            placeholder={i18n.get("creditcard.corporate.pay.send.amount.placeholder", "00.00")}
            labelNoMarginTop="true"
            hasError={values.amount?.quantity !== "" && values.amount?.quantity <= 0}
            error={getErrorMessage("amount", errors)}
        />
    );

    const renderPaymentOptionsAmountFieldConsume = (setFieldValue, values) => (
        <Field
            name="amount"
            component={AmountField}
            maxLength={15}
            data={{
                options: [{ id: 1, label: "USD" }],
            }}
            clearable={false}
            labelText={i18n.get("forms.creditcard.pay.own.paymentData.amount.label", "Monto USD")}
            bold={false}
            hideCurrency
            fixedDecimalScale
            amount={movementsConsumeToPay.reduce((sum, statement) => sum + statement.amount, 0)}
            placeholder={i18n.get("creditcard.corporate.pay.send.amount.placeholder", "00.00")}
            labelNoMarginTop="true"
            disabled={true}
        />
    );

    const renderTicketConfirmation = (values) => (
        <>
            <TicketData.Main
                label="creditcard.corporate.pay.send.amount.label"
                value={values.amount?.quantity}
                currency="USD"
                type="amount"
            />
            <Box>
                <Box className="mt-3 mb-4">
                    <Text
                        size="6"
                        bold
                        labelKey="forms.creditcard.pay.own.ticketData.title"
                        defaultValue="Datos de tarjeta"
                    />
                </Box>
                <TicketData.Data
                    label="creditcard.corporate.pay.ticketData.card.label"
                    value={`${creditCardDetailSelected?.cardStyle.charAt(0).toUpperCase() +
                        creditCardDetailSelected?.cardStyle
                            .slice(1)
                            .toLowerCase()
                            .replaceAll("_", " ") +
                        " " +
                        creditCardDetailSelected?.numberMask.replace(/(.{4})/g, "$1 ")}`}
                />
                <TicketData.Data
                    label="creditcard.corporate.pay.ticketData.card.nameOwner"
                    value={creditCardDetailSelected?.nameOnCard}
                />
                <TicketData.Data
                    label="creditcard.corporate.pay.ticketData.card.balance2pay"
                    value={creditCardDetailSelected?.lastPaymentAmmount}
                />

                <Box className="mt-5 mb-4">
                    <Text
                        size="6"
                        bold
                        labelKey="forms.creditcard.pay.own.ticketData.paymentData.title"
                        defaultValue="Datos de pago"
                    />
                </Box>
                <TicketData.Data
                    label="forms.creditcard.pay.own.ticketData.paymentData.account.label"
                    value={values?.debitAccountLabel}
                />
                <TicketData.Data
                    label="forms.creditcard.pay.own.ticketData.paymentData.concept.label"
                    value={values.concept}
                />
                <TicketData.Data label="creditcard.corporate.pay.service.cost.charge" value={0.0} />
            </Box>
        </>
    );

    const renderExitModal = () => (
        <ExitModal
            modalShow={showExitModal}
            acceptFunction={() => handleCancel()}
            cancelFunction={() => setShowExitModal(false)}
            headingText={i18n.get("confirm.exit.title")}
            text={i18n.get("confirm.exit.info")}
        />
    );

    const renderDesktop = (values, setFieldValue, errors) => (
        <>
            <Box className="full-width border-radius-lg pt-7 box-shadow-small background-white texture-header mb-7">
                <Box className="background-white p-7">
                    <Box className="border-radius-lg box-shadow-small mb-7 p-7">
                        {renderCreditCardInfoTitle()}
                        {movementsConsumeScreen !== "CONSUMETOPAY" && renderCreditCardSelector(setFieldValue)}
                        {Object.keys(creditCardDetailSelected).length > 0 && (
                            <Box className="background-background-disabled border-radius-lg mt-7 px-5 display-flex flex-nowrap align-items-center gap-6">
                                <Box className="align-items-center gap-6">
                                    <Box>{renderCreditCardImage()}</Box>
                                    {/* <Box>{renderCreditCardLabel()}</Box> */}
                                </Box>
                                <Box className="flex">{renderCreditCardStyle()}</Box>
                                <Box className="flex">{renderCreditCardName()}</Box>
                                <Box className="flex text-right">{renderCreditCardTotalPayment()}</Box>
                            </Box>
                        )}
                    </Box>

                    {/** payment options  */}
                    <Box className="border-radius-lg box-shadow-small p-7">
                        <Box className="mb-7">{renderPaymentOptionsTitle()}</Box>

                        <Box className="display-flex gap-6 align-items-end">
                            <Box className="flex max-width-half with-ellipsis-container">
                                {renderPaymentOptionsDebitAccountSelector(setFieldValue)}
                            </Box>
                            <Box className="flex max-width-half with-ellipsis-container">
                                {movementsConsumeScreen !== "CONSUMETOPAY"
                                    ? renderPaymentOptionsAmountField(setFieldValue, values, errors)
                                    : renderPaymentOptionsAmountFieldConsume(setFieldValue, values)}
                            </Box>
                        </Box>
                        <Box className="display-flex gap-6 mt-7">
                            <Box className="flex">{renderPaymentOptionsConceptTextField()}</Box>
                            <Box className="flex" />
                        </Box>
                    </Box>

                    {movementsConsumeScreen === "CONSUMETOPAY" && (
                        <Box className="border-radius-lg box-shadow-small mb-7 p-7">
                            <Box className="mb-7">{renderCreditCardConsumeTitle()}</Box>
                            <PaginatedDataTable
                                handleOpen={() => {
                                    setFieldValue("amount", {
                                        currency: "USD",
                                        quantity: movementsConsumeToPay.reduce(
                                            (sum, statement) => sum + statement.amount,
                                            0,
                                        ),
                                    });
                                    setFieldValue("creditCard", movementsConsumeToPay[0]?.idUsedCreditCard);
                                    setFieldValue("creditCardCardStyle", movementsConsumeToPay[0]?.cardStyle);
                                    setFieldValue("creditCardOwner", movementsConsumeToPay[0]?.owner);
                                    setFieldValue(
                                        "consumptions",
                                        movementsConsumeToPay.map((statement) => statement.idStatement).join(","),
                                    );
                                }}
                                nameList="movementsConsumeToPay"
                                data={movementsConsumeToPay || []}
                                rowsPerPage={config.getInteger("table.rowsPerPage", 10)}
                                component={CreditCardCorporateConsumeToPay}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );

    const renderMobile = (values, setFieldValue, errors) => (
        <>
            <Box className="mb-1 mt-1">{renderCreditCardInfoTitle()}</Box>
            <Box className="mb-5">
                {movementsConsumeScreen !== "CONSUMETOPAY" && renderCreditCardSelector(setFieldValue)}
            </Box>

            {Object.keys(creditCardDetailSelected).length > 0 && (
                <Box className="background-background-disabled display-flex flex-direction-column p-5 border-radius-lg gap-3 mb-5">
                    <Box className="display-flex align-items-center gap-6">
                        <Box className="">{renderCreditCardImage()}</Box>
                        <Box>{renderCreditCardStyle()}</Box>
                        {/* <Box>{renderCreditCardLabel()}</Box> */}
                    </Box>

                    <Box className="display-flex align-items-center gap-6">
                        <Box>{renderCreditCardName()}</Box>
                        <Box>{renderCreditCardTotalPayment()}</Box>
                    </Box>
                </Box>
            )}

            {
                /** payment options  */
                <Box>
                    <Box className="mb-1 mt-2">{renderPaymentOptionsTitle()}</Box>
                    <Box fullWidth>
                        <Box fullWidth className="display-flex flex-direction-column mb-3">
                            {renderPaymentOptionsDebitAccountSelector(setFieldValue)}
                        </Box>
                        <Box fullWidth className="display-flex flex-direction-column">
                            {movementsConsumeScreen !== "CONSUMETOPAY"
                                ? renderPaymentOptionsAmountField(setFieldValue, values, errors)
                                : renderPaymentOptionsAmountFieldConsume(setFieldValue, values)}
                        </Box>
                        <Box className="mt-5">{renderPaymentOptionsConceptTextField()}</Box>
                        {movementsConsumeScreen === "CONSUMETOPAY" && (
                            <Box className="border-radius-lg box-shadow-small mb-7 p-7">
                                <Box className="mb-7">{renderCreditCardConsumeTitle()}</Box>
                                <PaginatedDataTable
                                    handleOpen={() => {
                                        setFieldValue("amount", {
                                            currency: "USD",
                                            quantity: movementsConsumeToPay.reduce(
                                                (sum, statement) => sum + statement.amount,
                                                0,
                                            ),
                                        });
                                        setFieldValue("creditCard", movementsConsumeToPay[0]?.idUsedCreditCard);
                                        setFieldValue("creditCardCardStyle", movementsConsumeToPay[0]?.cardStyle);
                                        setFieldValue("creditCardOwner", movementsConsumeToPay[0]?.owner);
                                        setFieldValue(
                                            "consumptions",
                                            movementsConsumeToPay.map((statement) => statement.idStatement).join(","),
                                        );
                                    }}
                                    nameList="movementsConsumeToPay"
                                    data={movementsConsumeToPay || []}
                                    rowsPerPage={config.getInteger("table.rowsPerPage", 10)}
                                    component={CreditCardCorporateConsumeToPay}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            }
        </>
    );

    const renderFields = (setFieldValue, values, _setValues, _scheduler, errors, setErrors) => {
        if (mode !== "view" && (!preDataForm || fetchingDetail || fetchingList || fetchingForm )) {
            return <PageLoading loading classicStyle={false} />;
        }
        if (mode === "edit" && validateForm(values)) {
            setEnabledSubmit(true);
        } else {
            setEnabledSubmit(false);
        }

        if (mode === "edit") {
            if (isDesktop) {
                return (
                    <>
                        {renderDesktop(values, setFieldValue, errors)}
                        {renderExitModal()}
                    </>
                );
            }
            return (
                <>
                    {renderMobile(values, setFieldValue, errors)}
                    {renderExitModal()}
                </>
            );
        }
        if (mode === "preview") {
            return (
                <>
                    {renderTicketConfirmation(values)}
                    {renderExitModal()}
                </>
            );
        }
    };

    const formProps = {
        title: titleForm,
        data: {},
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        cancelAction: handlerCancelAction,
        handleCancelPreview: handlerCancelAction,
        textBack: "creditCard.corporate.send.back.button",
        textBackConfirmation: "global.return",
        preData: preDataForm,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema,
        // validate: validateCustom,
        showSchedulerMessage: false,
        // invalidateCache: true,
        // usesJointAccount,
        showFilterChips: false,
        submitDisabled: !enabledSubmit,
        ticketConfirmation: true,
        // submitActionParams
        submitButtonLabel: "creditCard.corporate.send.submit.button",
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    postData: formSelectors.getData(state),
    creditCardDetail: creditCardSelectors.getCreditCardDetail(state),
    fetchingDetail: creditCardSelectors.getFetching(state),
    fetchingList: creditCardSelectors.isFetching(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
    movementsConsumeScreen: creditCardSelectors.getMovementConsumeScreen(state),
    movementsConsumeToPay: creditCardSelectors.getMovementsConsumeToPay(state),
    fetchingForm: formSelectors.getFetching(state),
    formProducts: formSelectors.getCustomProducts(state),
});
LocalCreditCardsCorporatePaymentForm.propTypes = {
    idForm: string,
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    isDesktop: bool,
    postData: shape({}),
    creditCardDetail: shape({}),
    fetchingDetail: bool,
    fetchingList: bool,
    creditCardList: arrayOf(shape({})),
    usesJointAccount: bool,
    data: arrayOf(shape({})),

    history: shape({}).isRequired,
    emissionCost: shape({}),
    validateCIFullName: shape({}),
    listOffices: arrayOf(shape({})),
    formProducts: arrayOf(shape({})),
    subtitle: string,
    subtitlePrefix: string,
    fetching: bool,
    field: arrayOf(shape({})),
    fetchingForm: bool,
    isFastRecharge: bool,
    isLinkFastRecharge: bool,
    isFetchingFastRecharge: bool,
    listFastRecharge: arrayOf(shape({})),
    validatedCI: bool,
    fetchingOffices: bool,
    fetchingValidationCi: bool,
    movementsConsumeScreen: arrayOf(shape({})),
    movementsConsumeToPay: string,
};

LocalCreditCardsCorporatePaymentForm.defaultProps = {
    idForm: ID_FORM,
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    transaction: null,
    location: {},
    fromTransaction: false,
    isDesktop: false,
    postData: {},
    creditCardDetail: {},
    fetchingDetail: false,
    fetchingList: false,
    creditCardList: [],
    formProducts: [],
    movementsConsumeScreen: [],
    movementsConsumeToPay: "",
    usesJointAccount: false,
    data: {},
    previewData: {},
    subtitle: true,
    subtitlePrefix: true,
    fetching: false,
    field: [],
    fetchingForm: false,
    isFastRecharge: false,
    isLinkFastRecharge: false,
    isFetchingFastRecharge: false,
    listFastRecharge: [],
    listOffices: [],
    validatedCI: false,
    fetchingOffices: false,
    emissionCost: {},
    validateCIFullName: false,
    fetchingValidationCi: false,
};

const areEqual = (prevProps, nextProps) =>
    // Compare props that are important for re-rendering
    prevProps.mode === nextProps.mode &&
    prevProps.transaction === nextProps.transaction &&
    prevProps.creditCardDetail === nextProps.creditCardDetail &&
    prevProps.fetchingDetail === nextProps.fetchingDetail &&
    prevProps.fetchingList === nextProps.fetchingList &&
    prevProps.location === nextProps.location &&
    // Only compare specific parts of preDataForm that you care about
    prevProps.preDataForm?.importantField === nextProps.preDataForm?.importantField &&
    prevProps.preDataForm?.anotherImportantField === nextProps.preDataForm?.anotherImportantField;
export default React.memo(
    compose(connect(mapStateToProps), withRouter)(resizableRoute(LocalCreditCardsCorporatePaymentForm)),
    areEqual,
);

// export default compose(connect(mapStateToProps), withRouter)(resizableRoute(LocalCreditCardsCorporatePaymentForm));
