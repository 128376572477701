import { arrayOf, bool, func, node, number, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Button from "pages/_components/Button";
import Tooltip from "pages/_components/Tooltip";
import { isDesktop } from "react-device-detect";
import PaginatorButtonsBar from "./PaginatorButtonsBar";
import Box from "./Box";
import Text from "./Text";
import { noop } from "./utils";
import NoResults from "./NoResultsMessage";
import PageLoading from "./PageLoading";

const PaginatedDataTable = ({
    data,
    rowsPerPage,
    nameList,
    component: Component,
    componentProps,
    hasFilterApplied,
    rowActions: RowActionsComponent,
    rowActionsProps,
    emptyMessage,
    showFilterModal,
    hasFiltersApplied,
    filterAction,
    handleClearFilter,
    hasTooltip,
    textTooltip,
    renderDownloadDocs,
    isFetching,
    handleOpen,
    startPage,
    onChangePage,
    showNumbersResults,
}) => {
    const [currentPage, setCurrentPage] = useState(startPage || 1);

    useEffect(() => {
        if (currentPage > 1 && data?.length <= rowsPerPage) {
            goToFirstPage();
        }
    }, [data]);

    useEffect(() => {
        if (handleOpen) {
            handleOpen();
        }
    }, []);

    // Calculo de indice de inicio y final
    const indexOfLastItem = currentPage * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;

    // filas a mostrar actual pagina
    const currentRows = data.slice(indexOfFirstItem, indexOfLastItem);

    // Cambiar de página
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        onChangePage(pageNumber);
    };

    const goToFirstPage = () => {
        paginate(1);
    };

    const goToPreviousPage = () => {
        paginate(currentPage - 1);
    };

    const goToNextPage = () => {
        paginate(currentPage + 1);
    };

    const goToLastPage = () => {
        const lastPage = Math.ceil(data.length / rowsPerPage);
        paginate(lastPage);
    };

    // const poulateData = () => populateRows(currentRows);

    const listProps = {
        ...componentProps,
        [nameList]: currentRows,
    };

    return (
        <Box className="mb-7 paginated-table">
            <Box display="flex" alignY="center" className="my-5" fullWidth alignX="between">
                <Box
                    display="flex"
                    alignY="center"
                    alignX="left"
                    className={showFilterModal ? "full-width justify-content-between" : ""}
                    full>
                    {(isDesktop || showNumbersResults) && (
                        <Text
                            labelKey="global.pagination.results"
                            color="text"
                            size="5"
                            rows={
                                currentPage === 1
                                    ? currentRows.length
                                    : (currentPage - 1) * rowsPerPage + currentRows.length
                            }
                            totalRows={data.length}
                        />
                    )}

                    {showFilterModal && !hasFiltersApplied && (
                        <>
                            <Button
                                className={isDesktop ? "btn-filter" : "btn-filter p-0"}
                                image="images/util/filter.svg"
                                bsStyle="link"
                                label="global.filter"
                                onClick={filterAction}
                            />
                        </>
                    )}

                    {showFilterModal && hasFiltersApplied && (
                        <>
                            <Button
                                className="btn-clear-filter"
                                image="images/util/close.svg"
                                bsStyle="link"
                                label="global.clear.filter"
                                onClick={handleClearFilter}
                            />
                        </>
                    )}
                </Box>
                {hasTooltip && isDesktop && (
                    <Box display="flex" alignX="start">
                        <Tooltip
                            onHover
                            forEllipsis
                            inText={textTooltip}
                            position="top-right"
                            className="height-fit-content pos-relative">
                            <Button
                                image="images/icons/infoWhite.svg"
                                imageColor="active"
                                className="m-0 p-0 pagination-table-tooltip"
                            />
                        </Tooltip>
                    </Box>
                )}
                {renderDownloadDocs && <>{renderDownloadDocs()}</>}
                {hasTooltip && !isDesktop && (
                    <Box display="flex" alignX="start">
                        <Tooltip
                            onHover
                            forEllipsis
                            inText={textTooltip}
                            position="top-right"
                            className="height-fit-content pos-relative ml-3">
                            <Button
                                bsStyle="only-icon"
                                image="images/icons/infoWhite.svg"
                                imageMd
                                imageColor="active"
                                className="m-0"
                            />
                        </Tooltip>
                    </Box>
                )}
                {RowActionsComponent && (
                    <Box display="flex" alignY="center" alignX="rigth">
                        <RowActionsComponent {...rowActionsProps} />
                    </Box>
                )}
            </Box>

            <Box className="paginated-table-rows">
                <PageLoading relative loading={isFetching}>
                    {hasFilterApplied && currentRows.length === 0 ? (
                        <Box className="my-9">
                            <NoResults />
                        </Box>
                    ) : currentRows.length === 0 ? (
                        <>{emptyMessage()}</>
                    ) : (
                        <Component {...listProps} />
                    )}
                </PageLoading>
            </Box>

            <Box className="pagination-component mt-5">
                <PaginatorButtonsBar
                    page={data.length === 0 ? 0 : currentPage}
                    totalpages={Math.ceil(data.length / rowsPerPage)}
                    goToFirstPage={goToFirstPage}
                    goToPreviousPage={goToPreviousPage}
                    goToNextPage={goToNextPage}
                    goToLastPage={goToLastPage}
                />
            </Box>
        </Box>
    );
};

PaginatedDataTable.propTypes = {
    data: arrayOf(shape({})),
    nameList: string.isRequired,
    rowsPerPage: number,
    component: node.isRequired,
    componentProps: shape({}),
    onRowClick: func,
    hasFilterApplied: bool,
    rowActions: node,
    rowActionsProps: shape({}),
    emptyMessage: func,
    filterAction: func,
    handleClearFilter: func,
    showFilterModal: bool,
    hasFiltersApplied: bool,
    hasTooltip: bool,
    textTooltip: string,
    renderDownloadDocs: func,
    isFetching: bool,
    showNumbersResults: bool,
};

PaginatedDataTable.defaultProps = {
    data: [],
    rowsPerPage: 5,
    onRowClick: noop,
    componentProps: {},
    hasFilterApplied: false,
    rowActionsProps: {},
    rowActions: undefined,
    emptyMessage: () => <></>,
    showFilterModal: false,
    hasFiltersApplied: false,
    filterAction: noop,
    handleClearFilter: noop,
    hasTooltip: false,
    textTooltip: "",
    renderDownloadDocs: () => <></>,
    isFetching: false,
    showNumbersResults: false,
    startPage: undefined,
    onChangePage: noop,
};

export default PaginatedDataTable;
