import React, { useEffect, useState } from "react";
import Box from "pages/_components/Box";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import FormTransition from "pages/forms/_components/FormTransition";
import { useGenericProps, useMetaData } from "pages/forms/customForms/hooks/TransferInternalHooks";
import { arrayOf, bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { compose } from "redux";
import * as i18n from "util/i18n";
import TicketData from "pages/_components/TicketData";
import { USD_CURRENCY } from "constants.js";
import { actions as checkServiceActions, selectors as checkServiceSelectors } from "reducers/checks";
import classNames from "classnames";
import Notification from "pages/_components/Notification";
import RequestDataForm from "./_components/RequestDataForm";
import ExitModal from "pages/_components/modal/ExitModal";

import {
    initialValues,
    useAccountPaySelectorOptions,
    validateForm,
    validationSchema,
} from "./hooks/requestCheckFormHooks";

const ID_FORM = "check.request";
const ID_ACTIVITY = "check.request.send";
const ID_ACTIVITY_PRE = "check.request.pre";
const TITLE_FORM = `${ID_FORM}.title`;

const CheckRequestForm = (props) => {
    const {
        mode,
        preDataForm,
        emissionCost,
        validateCIFullName,
        validatedCI,
        listOffices,
        isDesktop,
        dispatch,
        fetchingForm,
        fetchingOffices,
        fetchingValidationCi,
        field,
        currentLang,
        transaction,
        fetchingList,
        formProducts,
        ...rest
    } = props;

    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);
    const [genericProps] = useGenericProps(props, ID_ACTIVITY);
    const [enabledSubmit, setEnabledSubmit] = useState(false);
    const [firstPre, setFirstPre] = useState(false);
    const [valuesF, setValuesF] = useState({});
    const [showExitModal, setShowExitModal] = useState(false);

    const accountOptions = useAccountPaySelectorOptions(formProducts || []);

    useEffect(() => () => dispatch(checkServiceActions.cleanCacheCheckRequest()), []);

    useEffect(() => {
        if (mode === "edit" && !firstPre) {
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE }));
            dispatch(formActions.preCustomForm(["check.request"]));
            setFirstPre(true);
        }
    }, [mode]);

    useEffect(() => {
        if (valuesF?.accountNumber && valuesF?.accountType && valuesF?.checkTypeCode && valuesF?.checkTypeDesc) {
            dispatch(
                checkServiceActions.getEmissionCost(
                    valuesF?.accountType,
                    valuesF?.accountNumber,
                    valuesF?.checkTypeCode,
                    valuesF?.checkTypeDesc,
                ),
            );
        }
    }, [valuesF?.accountNumber, valuesF?.accountType, valuesF?.checkTypeCode, valuesF?.checkTypeDesc]);

    useEffect(() => {
        if (valuesF?.document?.type == "CED" && valuesF?.document?.document?.length < 10) {
            dispatch(checkServiceActions.cleanCacheValidateCI());
        }
    }, [valuesF?.document?.document?.length, valuesF?.document?.type]);

    useEffect(() => {
        if (valuesF?.cityCode) {
            dispatch(checkServiceActions.getOfficesList(valuesF?.cityCode));
        }
    }, [valuesF?.cityCode]);

    useEffect(() => {
        if (fetchingForm) {
            return;
        }
        if (formProducts?.length === 0) {
            dispatch(push("/checksHome"));

            dispatch(
                notificationActions.showNotification(i18n.get("returnCode.API040E"), "warning", [
                    "settings",
                ]),
            );
        }
    }, [formProducts]);

    const onValidateCi = () => {
        dispatch(checkServiceActions.cleanCacheValidateCI());
        dispatch(checkServiceActions.getCiValidated(valuesF?.document?.type, valuesF?.document?.document));
    };

    const handleCancel = () => {
        dispatch(push("/desktop"));
    };

    const handlerCancelAction = () => {
        setShowExitModal(true);
    };

    const renderConfirmation = ({
        accountDesc,
        checkTypeDesc,
        cityCode,
        deliveryOfficeDesc,
        enableWithdraw,
        withdrawPersonIdentification,
        withdrawPersonIdentificationType,
        withdrawFullName,
    }) => (
        <>
            <TicketData.Main
                label={`${ID_FORM}.send.totalCost.label`}
                value={emissionCost.checkCost}
                currency={USD_CURRENCY}
                type="amount"
            />

            <Box>
                <Box className="mt-5 mb-4">
                    <Text size="6" bold labelKey={`${ID_FORM}.preview.sectionTitle`} />
                </Box>
                <TicketData.Data label={`${ID_FORM}.send.accountNumber.label`} value={accountDesc} />
                <TicketData.Data label={`${ID_FORM}.send.checkType.label`} value={checkTypeDesc} />
                <TicketData.Data label={`${ID_FORM}.send.checkSequential.label`} value={emissionCost.checkSequential} />
                <TicketData.Data label={`${ID_FORM}.send.deliveryOffice.label`} value={`${cityCode} - ${deliveryOfficeDesc}`} />
                <TicketData.Data label={`${ID_FORM}.send.enableWithdraw.label`} value={enableWithdraw ? i18n.get(`${ID_FORM}.send.enableWithdraw.yes`) : i18n.get(`${ID_FORM}.send.enableWithdraw.no`)} />
                {enableWithdraw && (
                    <>
                        <Box className="mt-5 mb-4">
                            <Text size="6" bold labelKey={`${ID_FORM}.send.withdrawTitle.label`} />
                        </Box>
                        <TicketData.Data
                            label={`${ID_FORM}.send.withdrawIdentificationAndType.label`}
                            value={`${withdrawPersonIdentificationType} ${withdrawPersonIdentification}`}
                        />
                        <TicketData.Data label={`${ID_FORM}.send.withdrawName.label`} value={withdrawFullName} />
                    </>
                )}
            </Box>
        </>
    );

    const renderTicket = () => <></>;

    const renderFields = (setFieldValue, values, _setValues, _scheduler, errors, setErrors) => {
        if (mode !== "view" && (!preDataForm || Object.keys(preDataForm).length === 0 || fetchingForm)) {
            return <PageLoading loading classicStyle={false} />;
        }
        if (mode === "edit" && validateForm(values)) {
            setEnabledSubmit(true);
        } else {
            setEnabledSubmit(false);
        }
        setValuesF(values);
        return (
            <>
                {mode === "edit" && (
                    <>
                        <Notification scopeToShow="checkRequest" />
                        <Box

                            background="white"
                            borderRadius="lg"
                            toBorderInMobile
                            className={classNames("texture-header px-0", {
                                "box-shadow-small mb-7 pt-5 pb-2": isDesktop,
                                "pt-5 pb-2": !isDesktop,
                            })}>

                            <Box
                                background="white"
                                className={classNames("px-0", {
                                    "px-7 pt-1 pb-4 mb-3": isDesktop,
                                    "pt-1 pb-4 px-5": !isDesktop,
                                })}>
                                    <RequestDataForm
                                        index={0}
                                        values={values}
                                        errors={errors}
                                        fetchingOffices={fetchingOffices}
                                        fetchingValidationCi={fetchingValidationCi}
                                        setErrors={setErrors}
                                        genericProps={genericProps}
                                        ID_FORM={ID_FORM}
                                        accountOptions={accountOptions}
                                        checkTypeOptions={preDataForm?.checkTypes}
                                        documentTypes={preDataForm?.documentTypes}
                                        citiesOptions={preDataForm?.cities}
                                        setFieldValue={setFieldValue}
                                        emissionCost={emissionCost}
                                        onValidateCi={() => onValidateCi()}
                                        validateCIFullName={validateCIFullName}
                                        validatedCI={validatedCI}
                                        listOffices={listOffices}
                                        isDesktop={isDesktop}
                                        showFieldAmount
                                    />
                            </Box>
                        </Box>
                    </>
                )}
                {(mode === "preview" || mode === "view") && <>{renderConfirmation(values)}</>}
                <ExitModal
                    modalShow={showExitModal}
                    acceptFunction={() => handleCancel()}
                    cancelFunction={() => setShowExitModal(false)}
                    headingText={i18n.get("confirm.exit.title")}
                    text={i18n.get("confirm.exit.info")}
                />
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        ticketConfirmation: true,
        cancelAction: handlerCancelAction,
        handleCancelPreview: handlerCancelAction,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        showSchedulerMessage: false,
        // validate: validateForm,
        titleFormConfirmation: "OTP CODE",
        data: initialValues(props),
        validationSchema,
        textBack: "check.request.send.back.button",
        textBackConfirmation: "global.return",
        submitDisabled: !enabledSubmit,
        submitButtonLabel: "check.request.send.submit.button",
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    fetchingForm: formSelectors.getFetching(state),
    fetching: checkServiceSelectors.getFetching(state),
    fetchingOffices: checkServiceSelectors.getFetchingOffices(state),
    fetchingValidationCi: checkServiceSelectors.getFetchingValidationCi(state),
    emissionCost: checkServiceSelectors.getEmissionCost(state),
    validateCIFullName: checkServiceSelectors.getValidateCi(state),
    validatedCI: checkServiceSelectors.getValidateCiStatus(state),
    listOffices: checkServiceSelectors.getListOffices(state),
    preDataForm: formSelectors.getPreData(state),
    currentLang: i18nSelectors.getLang(state),
    mode: formSelectors.getMode(state),
    previewData: formSelectors.getPreviewData(state),
    formProducts: formSelectors.getCustomProducts(state),
});

CheckRequestForm.propTypes = {
    history: shape({}).isRequired,
    isDesktop: bool,
    mode: string,
    currentLang: string,
    preDataForm: shape({}),
    previewData: shape({}),
    emissionCost: shape({}),
    validateCIFullName: shape({}),
    listOffices: arrayOf(shape({})),
    fromBackoffice: bool,
    subtitle: string,
    subtitlePrefix: string,
    fetching: bool,
    field: arrayOf(shape({})),
    fetchingForm: bool,
    dispatch: func.isRequired,
    transaction: shape({}),
    fetchingList: bool,
    formProducts: arrayOf(shape({})),
    validatedCI: bool,
    fetchingOffices: bool,
    fetchingValidationCi: bool,
};

CheckRequestForm.defaultProps = {
    fromBackoffice: false,
    mode: "edit",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    subtitle: true,
    subtitlePrefix: true,
    fetching: false,
    isDesktop: false,
    field: [],
    fetchingForm: false,
    transaction: null,
    fetchingList: false,
    listOffices: [],
    validatedCI: false,
    fetchingOffices: false,
    emissionCost: {},
    validateCIFullName: false,
    fetchingValidationCi: false,
    formProducts: [],
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(CheckRequestForm));
