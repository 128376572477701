import { call, put, takeLatest } from "redux-saga/effects";

import { types } from "reducers/creditCardOtherBank";
import { actions as notificationActions } from "reducers/notification";

import * as creditCardOtherBankMiddleware from "middleware/creditCardOtherBank";

import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.LIST_BANKS_REQUEST, getListBanks),
    takeLatest(types.LIST_OTHER_BANK_REQUEST, getListCreditCardOtherBanks),
];

export default sagas;

function* getListCreditCardOtherBanks({ pageNumber, searchWord }) {
    const response = yield call(creditCardOtherBankMiddleware.getListCreditCardOtherBanks, pageNumber, searchWord);
    if (response.type === "W") {
        yield put({ type: types.LIST_OTHER_BANK_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "creditcardsOtherBanks",
            ]),
        );
    } else {
        const { creditCards } = response.data.data;
        const { elementList, currentPage, totalPages } = creditCards;

        yield put({
            type: types.LIST_OTHER_BANK_SUCCESS,
            newList: elementList,
            currentPage,
            totalPages,
        });
    }
}

function* getListBanks({ codeBranch }) {
    const response = yield call(creditCardOtherBankMiddleware.getListBank, codeBranch);
    if (response.type === "W") {
        yield put({ type: types.LIST_BANKS_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "creditcardsOtherBanks",
            ]),
        );
    } else {
        const { banks } = response.data.data;

        yield put({
            type: types.LIST_BANKS_SUCCESS,
            listBanks: banks,
        });
    }
}
