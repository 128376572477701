import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, number, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import withRouter from "react-router-dom/withRouter";
import * as i18nUtils from "util/i18n";
import Text from "pages/_components/Text";

const ChipsButtonsGroup = ({ buttonsGroup, isDesktop, defaultSelect, onClickHandler, buttonsGap }) => {
    const [selectedIdButton, setSelectedIdButton] = useState(defaultSelect);

    const handlerSelectButton = (buttonId) => {
        setSelectedIdButton(buttonId);
        if (typeof onClickHandler === "function") {
            onClickHandler(buttonId);
        }
    };
    useEffect(() => {
        setSelectedIdButton(defaultSelect);
    }, [defaultSelect]);

    return (
        <Box
            component="nav"
            aria-label={i18nUtils.get("global.navTypes.a11y")}
            display="flex"
            gap={buttonsGap}
            // scrollable={!isDesktop}
            className="scroll"
            alignX={isDesktop ? "center" : "evenly"}>
            {buttonsGroup.map((button) => (
                <>
                    <Box
                        key={button.id}
                        display="flex"
                        background={
                            button?.id === selectedIdButton || button?.selected ? "primary-background-color" : ""
                        }
                        className="py-3 px-5 my-auto"
                        border="primary-focus-color"
                        borderRadius="xxl"
                        gap="3"
                        fitWidth
                        disabled={button?.permission}
                        onClick={() => {
                            handlerSelectButton(button.id);
                        }}>
                        <Text
                            color={button?.id === selectedIdButton || button?.selected ? "primary-active-color" : ""}
                            size="6"
                            labelKey={button?.label}
                            defaultValue={button?.labelDefault}
                            className="text-no-wrap"
                        />
                    </Box>
                </>
            ))}
        </Box>
    );
};

ChipsButtonsGroup.propTypes = {
    buttonsGroup: shape.isRequired,
    isDesktop: bool.isRequired,
    onClickHandler: func.isRequired,
    defaultSelect: number,
    buttonsGap: bool,
};
ChipsButtonsGroup.defaultProps = {
    defaultSelect: null,
    buttonsGap: "3",
};

export default resizableRoute(withRouter(ChipsButtonsGroup));
