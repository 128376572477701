export const NO_TRANSITION = "noTransition";

export const ACTIVITIES_WITHOUT_PREVIEW_STEP = [
    "administration.medium.modify.signature.send",
    "administration.medium.modify.contacts.send",
    "administration.restrictions.user.delete.send",
];

export const ACTIVITIES_WITH_SECOND_FACTOR_DATA = ["/token.entrust.activateUserToken"];

export const MAX_FAILED_TIMES = 2;

export const TIME_IN_MILLIS_TO_REFRESH = 15000;

export const TIME_CHANGE_OTP = 30;

export const MAX_LAST_LOGIN_TO_SHOW = 5;

export const CORPORATE_GROUP_ENVIRONMENT_TYPE = "corporateGroup";
export const RETAIL_ENVIRONMENT_TYPE = "retail";
export const CORPORATE_ENVIRONMENT_TYPE = "corporate";

export const SALARY_PAYMENT_ID_FORM = "salaryPayment";

export const REGION_USA = "US";
export const REGION_REST_OF_LATAM = "LATAM";
export const REGION_ARG = "AR";
export const REGION_CHI = "CL";
export const REGION_CAN = "CA";
export const REGION_UY = "UY";
export const REGION_COL = "CO";
export const REGION_MEX = "MX";
export const REGION_PER = "PE";
export const REGION_ECU = "EC";
export const REGION_PAR = "PY";
export const REGION_PAN = "PA";
export const REGION_CR = "CR";
export const REGION_VE = "VE";
export const AVAILABLE_REGIONS = [REGION_PAN];
export const ITBMS = "ITBMS";

export const COMPANY_NAME = "TekBNK";
export const GENERAL_CONDITIONS_CATEGORY_DEFAULT = "termsAndConditions";
export const ID_ACTIVITY_DOWNLOAD_STATE_PRE = "product.state.download.pre";
export const ID_ACTIVITY_DOWNLOAD_STATE = "product.state.download.send";
export const ID_ACTIVITY_EXCHANGE_POINTS = "product.exchange.points.send";

export const USD_CURRENCY = "USD";

export const STATUS_CHECK = {
    AVAILABLE: "AVAILABLE",
    CLEARED: "CLEARED",
    ISSUED: "ISSUED",
    STOPPED: "STOPPED",
    RETURNED: "RETURNED",
};

export const SPECIAL_CHARACTERS_REGEX = /^[a-zA-Z0-9 ]+$/i;

export const PRODUCT_TYPE = {
    ACCOUNT_CA: "CA",
    ACCOUNT_CC: "CC",
    CREDIT_CARD: "TC",
    DEPOSIT: "PF",
    LOAN: "PA",
};

export const TYPE_FILTER = {
    DATE: "DATE",
    AMOUNT: "AMOUNT",
    NUMBER: "NUMBER",
};

export const PERMISSION_ACCOUNT_STATEMENT = "accountStatement";
export const PERMISSION_CREDIT_CARD_STATEMENT = "creditCardStatement";
export const INSERT_SECURITY_QUESTION = "insertSecurityQuestions";
export const UPDATE_SECURITY_QUESTION = "updateSecurityQuestions";
export const PERMISSION_CREDIT_CARD_POINTS_INQUIRY = "creditcard.pointsInquiry";
export const PERMISSION_CREDITCARD_CORPORATE_PAYMENT = "creditcard.payment.corparate";
export const PERMISSION_CREDITCARD_CORPORATE_READ = "creditcard.read.corparate";

export const TYPE_FILTER_USERS = {
    STATUS: "STATUS",
    DOCUMENT: "DOCUMENT",
    SIGNATURE: "SIGNATURE",
    NAME: "NAME",
};

export const REMITTANCE_CHECK_STATUS= {
    CONFIRMED: "C",
    PENDING: "E",
    RETURNED: "T",
    ALL: "A",
};

export const REMITTANCE_CHECK_STATUS_LABEL= {
    CONFIRMED: "confirmed",
    PENDING: "pending",
    RETURNED: "returned",
};

export const SERVICE_REGISTRATION = {
    DEFAULT_VALUE: "Seleccionar",
};

export const IDENTIFIERS_MASK = {
    CATASTRO : {
        mask: '00-00-00-00-00-000',
        regex: '^([0-9]){2}(\\-)([0-9]){2}(\\-)([0-9]){2}(\\-)([0-9]){2}(\\-)([0-9]){2}(\\-)([0-9]){3}$',
        helpText: 'Ingrese clave de Catastro',
        value: 'CATASTRO'
    }
}

export const OPERATION_TRANSACTION = {
    ADMIN: "Admin",
    CHECKS: "Checks",
    CREDIT_CARDS: "CreditCards",
    SERVICE_PAYMENT: "ServicePayment",
};

export const OPERATIONS_TRANSACTION_ENABLED =
    [OPERATION_TRANSACTION.ADMIN, OPERATION_TRANSACTION.CHECKS, OPERATION_TRANSACTION.CREDIT_CARDS, OPERATION_TRANSACTION.SERVICE_PAYMENT];

export const FILTER_DATE_TRANSACTION = {
    TODAY: "today",
    LAST_7_DAYS: "last7Days",
    LAST_30_DAYS: "last30Days",
    OTHER: "other",
};    
export const BLOCK_CHECK_ACTION= {
    REVOCATION: "REVOCATORIA",
    ANNULMENT: "ANULACION",
    SUSPENSION: "SUSPENSION",
    DECLARATION: "DECLARATORIA",
};

export const BLOCK_CHECK_REASON_REVOCATION= {
    OTHER_REASON: "O",
};

export const BLOCK_CHECK_REASON_SUSPENSION= {
    LOSS: "PS",
};

export const BLOCK_CHECK_REASON_DECLARATION= {
    DETERIORATION: "DD",
    LOSS: "PD",
    THEFT: "SD",
    DESTRUCTION: "TD",
};

export const BLOCK_CHECK_REASON_ANNULMENT= {
    DETERIORATION: "D",
    LOSS: "P",
    THEFT: "S",
    DESTRUCTION: "T",
};

export const BLOCK_CHECK_DRAWNON= {
    ECUADOR: "P",
    EXTERIOR: "E",
};

export const BLOCK_CHECK_TYPE= {
    ONE: "1",
    MANY: "2",
};