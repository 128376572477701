import ConnectedAmountField from "pages/_components/fields/AmountField";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import { compose } from "redux";
import { numberFormat, toNumber } from "util/number";

class FormikAmountField extends Component {
    static propTypes = {
        field: shape({
            name: string.isRequired,
            value: string.isRequired,
        }).isRequired,
        form: shape({
            errors: shape({}).isRequired,
            touched: shape({}).isRequired,
            setFieldValue: func.isRequired,
            setFieldTouched: func.isRequired,
        }).isRequired,
        lang: string.isRequired,
        onCurrencyChange: func,
        onInputChange: func,
        error: string,
        hasError: bool,
    };

    static defaultProps = { onCurrencyChange: null, onInputChange: null, error: null, hasError: null,};

    getErrorMessage() {
        const { field, form } = this.props;
        const { name } = field;
        const { errors } = form;

        if (!this.hasErrorSeparation()) {
            return null;
        }

        const splittedName = name.split(".");
        if (splittedName.length <= 1) {
            return errors[name] && errors[name].amount;
        }

        let errorData = errors;
        splittedName.forEach((value) => {
            errorData = errorData[value];
        });

        return errorData.amount;
    }

    hasErrorSeparation = () => {
        const { field, form } = this.props;
        const { name } = field;
        const { touched, errors } = form;

        const splittedName = name.split(".");
        if (splittedName.length <= 1) {
            return touched[name] && errors[name];
        }

        if (Object.keys(touched).length === 0 || Object.keys(errors).length === 0) {
            return false;
        }

        let touchedData = touched;
        let errorData = errors;
        splittedName.forEach((value) => {
            if (touchedData && errorData) {
                touchedData = touchedData[value];
                errorData = errorData[value];
            }
        });

        if (touchedData && errorData && Object.keys(errorData).length !== 0) {
            return true;
        }
        return false;
    };

    handleInputChange = ({ target }) => {
        const { field, form, lang, onInputChange } = this.props;
        const { decimalSeparator } = numberFormat(lang);
        const number = toNumber(target.value, decimalSeparator);
        if (onInputChange) {
            onInputChange(number);
        } else {
            form.setFieldValue(`${field.name}.amount`, number);
        }
    };

    handleCurrencyChange = ({ value }) => {
        const { field, form, onCurrencyChange } = this.props;
        if (onCurrencyChange) {
            onCurrencyChange(value);
        } else {
            form.setFieldValue(`${field.name}.currency`, value);
        }
    };

    handleBlur = () => {
        const { field, form } = this.props;
        form.setFieldTouched(field.name);
    };

    render() {
        const { field, error, hasError } = this.props;
        return (
            <ConnectedAmountField
                {...field}
                {...this.props}
                hasError={hasError || this.hasErrorSeparation()}
                onCurrencyChange={this.handleCurrencyChange}
                onInputChange={this.handleInputChange}
                onBlur={this.handleBlur}
                error={error || this.getErrorMessage()}
                {...field.value}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
});

export default compose(connect(mapStateToProps))(FormikAmountField);
