import { bool, func, oneOfType, shape } from "prop-types";
import React, { Component } from "react";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import CreditCardsList from "pages/creditCards/List";
import Link from "react-router-dom/Link";
import I18n from "pages/_components/I18n";

class CreditCardList extends Component {
    static propTypes = {
        creditCards: oneOfType([shape({})]).isRequired,
        showProductIcon: bool,
        setFavoriteAccount: func,
        completeFavorites: bool,
        isOtherBanks: bool,
    };

    static defaultProps = {
        showProductIcon: true,
        setFavoriteAccount: () => {},
        completeFavorites: false,
        isOtherBanks: false,
    };

    renderList = () => {
        const { creditCards, isDesktop, isRequestAvailable, isOtherBanks } = this.props;
        if (!creditCards.length) {
            return null;
        }
        return (
            <>
                <CreditCardsList creditCards={creditCards} isDesktop={isDesktop} isOtherBanks={isOtherBanks} />
                {!isDesktop && isRequestAvailable && (
                    <Container className="container--layout items-center">
                        <Container.Column className="col-12">
                            <Link to="/form/creditCardRequest/" className="btn btn-outline btn-block">
                                <Image src="images/plus.svg" />
                                <I18n id="creditCard.new" />
                            </Link>
                        </Container.Column>
                    </Container>
                )}
            </>
        );
    };

    render() {
        return <>{this.renderList()}</>;
    }
}

export default CreditCardList;
