import React from "react";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip/Tooltip";

const CapsInfo = () => {
    return (
        <Box display="flex" fullWidth className="align-items-center">
            <Text bold size="4" labelKey={"administration.capForUserProduct.info.title"} />
            <Tooltip
                image="images/information.svg"
                tooltipInfo={
                    <>
                        <Text labelKey={"administration.capForUserProduct.info.text.1"} className="tooltip-text" />
                        <ul>
                            <li>
                                <Text labelKey={"administration.capForUserProduct.info.text.2"} className="tooltip-text" />
                            </li>
                            <li>
                                <Text labelKey={"administration.capForUserProduct.info.text.3"} className="tooltip-text" />
                            </li>
                            <li>
                                <Text labelKey={"administration.capForUserProduct.info.text.4"} className="tooltip-text" />
                            </li>
                            <li>
                                <Text labelKey={"administration.capForUserProduct.info.text.5"} className="tooltip-text" />
                            </li>
                            <li>
                                <Text labelKey={"administration.capForUserProduct.info.text.6"} className="tooltip-text" />
                            </li>
                        </ul>
                    </>
                }
                className="align-self-center height-fit-content"
            />
        </Box>
    );
};

export default CapsInfo;
