import React from "react";
import { bool, func, number, string } from "prop-types";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import { USD_CURRENCY } from "constants.js";
import { isMobile } from "react-device-detect";

const PREFIX = "servicePayments.massive.services";
const FooterMassivePay = ({ cant, totalPay, labelButton, typeButton, disabledButton, onClick }) => (
    <Box className={isMobile ? "footer-bar-payment py-5" : "footer-bar-payment py-8"}>
        <Box className="content-bar-payment">
            <Row>
                <Col xs="6">
                    <Box className="mb-3">
                        <Text labelKey={`${PREFIX}.count`} />
                    </Box>
                    <Box>
                        <Text size="3" bold defaultValue={cant} />
                    </Box>
                </Col>
                <Col xs="6" className="d-flex justify-content-end">
                    <Box display="flex">
                        <Box>
                            <Box className="mb-2 text-right">
                                <Text labelKey={`${PREFIX}.totalPay`} />
                            </Box>

                            <Box className="text-right">
                                <FormattedAmount
                                    quantity={totalPay}
                                    className="size-1 text-bold display-inline"
                                    noCurrency
                                />
                                &nbsp;
                                <Text size="1" color="text-disabled-color" light defaultValue={USD_CURRENCY} />
                            </Box>
                        </Box>
                        { !isMobile &&
                            <Box className="ml-5">
                                <Button
                                    type={typeButton}
                                    bsStyle="primary"
                                    className="btn-width-lg"
                                    label={labelButton}
                                    disabled={disabledButton}
                                    {...(onClick && { onClick })}
                                />
                            </Box>
                        }
                    </Box>
                </Col>
                { isMobile &&
                    <Box>
                        <Button
                            type={typeButton}
                            bsStyle="primary"
                            block
                            label={labelButton}
                            disabled={disabledButton}
                            {...(onClick && { onClick })}
                        />
                    </Box>
                }
            </Row>
        </Box>
    </Box>
);

FooterMassivePay.propTypes = {
    cant: number.isRequired,
    totalPay: number.isRequired,
    labelButton: string.isRequired,
    typeButton: string,
    disabledButton: bool,
    onClick: func,
};

FooterMassivePay.defaultProps = {
    typeButton: "button",
    disabledButton: false,
    onClick: undefined,
};

export default FooterMassivePay;
