import classNames from "classnames";
import Statements from "pages/loans/Statements";
import Action from "pages/loans/_components/Action";
import DetailHeadInfo from "pages/loans/_components/DetailHeadInfo";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";
import { bool, func, number, oneOfType, shape, string, instanceOf, array } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as loansActions, selectors as loansSelectors } from "reducers/loans";
import { selectors as i18nSelectors } from "reducers/i18n";
import DownloadAccoutDetailForm from "pages/forms/customForms/DownloadAccoutDetailForm";
import SideBarModal from "pages/_components/modal/SideBarModal";
import { getLocationBasePath } from "util/revelock";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import Box from "pages/_components/Box";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import * as config from "util/config";
import Head from "pages/_components/Head";
import { selectors as widgetSelectors } from "reducers/widgets";
import * as i18n from "util/i18n";

const PAGE_IDENTIFIER = "loans.read";
class Details extends Component {
    state = {
        showSideBarDownloadDetail: false,
        rowsPerPage: config.getInteger("table.rowsPerPage", 10),
        downloading: false,
    };

    componentDidMount() {
        const { dispatch, ...rest } = this.props;
        const loanSelected = this.getSelectedLoan(rest.match.params.id);
        const initialFilter = "pendingFees";

        dispatch(
            loansActions.readLoan(
                rest.match.params.id,
                loanSelected?.groupCode,
                loanSelected?.productTypeBackend,
                loanSelected?.currentBalance,
            ),
        );
        dispatch(loansActions.setSelectedFilter(initialFilter));
        this.setRevelockLocation();
    }

    getLoans() {
        const { loans, loansWidget } = this.props;
        return (loans?.length && loans) || (loansWidget?.length && loansWidget);
    }

    getSelectedLoan(idLoan) {
        const loansFinal = this.getLoans();
        const selectedLoan = loansFinal.find((e) => e.idProduct === idLoan);
        return selectedLoan;
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath, match } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(loansActions.toggleOptions());
    };

    handleFilterButtonsClick = (id) => {
        const { dispatch, ...rest } = this.props;
        dispatch(loansActions.setSelectedFilter(id));
        dispatch(loansActions.details(rest.match.params.id));
    };

    toolbarChildren = () => {
        const { isDesktop } = this.props;
        return (
            <>
                <Action isDesktop={isDesktop} labelKey="loans.request" to="/loansPayment/request/" />
            </>
        );
    };

    handleBack = () => {
        const { dispatch, backToDesktop, ...rest } = this.props;
        dispatch(loansActions.closeOptions());
        if (backToDesktop) {
            dispatch(push(`/desktop`));
        } else {
            dispatch(push(`/loans?id=${rest.match.params.id}`));
        }
    };

    handleClickDownload = (formats) => () => {
        const { dispatch, match } = this.props;
        dispatch(loansActions.downloadPayment(match.params.id, formats));
    };

    getSecondaryBtn = () => {
        const { loan, dispatch } = this.props;

        const secondaryButtons = [
            {
                bsStyle: "outline",
                label: `forms.product.state.download.title`,
                onClick: () => {
                    // dispatch(push("/websiteunderconstruction"));
                    this.setState({ showSideBarDownloadDetail: true });
                },
            },
        ];

        secondaryButtons.push({
            bsStyle: "primary",
            label: `loan.detail.pay.loan.button`,
            onClick: () => {
                dispatch(push(`/formCustom/payLoan?loan=${loan.idProduct}`));
            },
            disabled: loan?.overdueStatus === "NAB",
        });

        return secondaryButtons;
    };

    getMergedLoan() {
        const { loan } = this.props;
        const loansFinal = this.getLoans();
        let loanFromList = null;
        if (loan) {
            if (loansFinal && loansFinal.length > 0) {
                loanFromList = loansFinal?.find((a) => a.idProduct === loan.idProduct);
            }
        }
        let mergedLoan = null;
        if (loanFromList) {
            mergedLoan = {
                ...loan,
                alias: loanFromList.description,
                productAlias: loanFromList.description,
                groupCode: loanFromList.groupCode,
            };
        } else {
            mergedLoan = {
                ...loan,
            };
        }

        return mergedLoan;
    }

    onFinishDownload = () => {
        this.setState({
            downloading: false,
        });
    };

    handleClickStatementDownload(format) {
        const { dispatch, loan } = this.props;
        const mergedLoan = this.getMergedLoan();
        this.setState({
            downloading: true,
        });
        dispatch(
            loansActions.downloadStamentLoans(
                format,
                loan.idProduct,
                loan.groupCode,
                loan.type,
                loan.number,
                mergedLoan?.alias,
                this.onFinishDownload,
            ),
        );
    }

    renderHeader = () => {
        const {
            isRequestAvailable,
            isMobile,
            loans,
            // loggedUser
        } = this.props;

        const { downloading } = this.state;

        const mergedLoan = this.getMergedLoan();

        const documents = [
            {
                label: "global.pdfFile",
                onClick: () => this.handleClickStatementDownload("pdf"),
            },
            {
                label: "global.xlsFile",
                onClick: () => this.handleClickStatementDownload("xls"),
            },
            {
                label: "global.txtFile",
                onClick: () => this.handleClickStatementDownload("txt"),
            },
        ];

        if (isMobile) {
            return (
                <Head
                    onBack={this.handleBack}
                    titleText={mergedLoan?.alias}
                    {...{ exportList: documents }}
                    isFetchingExport={downloading}
                    // {...optionToCreatLoanMobile}
                />
            );
        }

        return (
            <Head
                textBack="loans.detail.header.returnToLoans"
                onBack={this.handleBack}
                titleText={mergedLoan?.alias}
                exportList={documents}
                exportListBsStyle="download"
                isFetchingExport={downloading}
            />
        );
    };

    render() {
        const { loan, isDesktop, dispatch, selectedFilter, fetching, currentLang, lastPaymentDay } = this.props;
        const { rowsPerPage, downloading } = this.state;
        const secondaryBtn = this.getSecondaryBtn();

        const contextOptions = [
            {
                label: "deposit.detail.button.edit.alias",
                onClick: () => {
                    dispatch(push(`/loans/${loan.idProduct}/setAlias`));
                },
            },
            {
                label: "forms.product.state.download.title",
                onClick: () => {
                    this.setState({ showSideBarDownloadDetail: true });
                    // dispatch(push("/websiteunderconstruction"));
                },
            },
        ];

        const filters = [
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "pendingFees",
                })}
                key="pendingFees"
                label="loans.statements.filters.pendingFees"
                onClick={() => this.handleFilterButtonsClick("pendingFees")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "paidFees",
                })}
                key="paidFees"
                label="loans.statements.filters.paidFees"
                onClick={() => this.handleFilterButtonsClick("paidFees")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "allFees",
                })}
                key="all"
                label="loans.statements.filters.all"
                onClick={() => this.handleFilterButtonsClick("allFees")}
            />,
        ];
        const mergedLoan = this.getMergedLoan();
        const productDetail = {
            ...mergedLoan,
            lastPaymentDay,
        };
        return (
            <>
                <PageLoading loading={fetching} classicStyle={false}>
                    <Notification scopeToShow="loans/details" />
                    {loan && (
                        <ProductDetail>
                            {/* <ProductDetail.Header
                                contextOptions={isDesktop ? null : contextOptions}
                                dispatch={dispatch}
                                isDesktop={isDesktop}
                                onBack={this.handleBack}
                                productId={loan.productId}
                                product={mergedLoan}
                                productKind="loan">
                                {getDropdown(documents, fetching, "download", fetching)}
                                </ProductDetail.Header> */}
                            {/* <Head
                                onBack={this.handleBack}
                                titleText={mergedLoan?.alias}
                                exportList={documents}
                                exportListBsStyle="download"
                                isFetchingExport={downloading}
                            /> */}
                            {this.renderHeader()}

                            <ProductDetail.Body
                                isDesktop={isDesktop}
                                filters={filters}
                                filtersKeyLabel="loans.details.paymentPlan"
                                product={mergedLoan}
                                productKind="loan"
                                moreFilters={false}
                                {...{
                                    detailHeadInfo: <DetailHeadInfo {...this.props} product={productDetail} />,
                                }}>
                                <Box>
                                    {(mergedLoan?.groupCode === "FACTORING" || mergedLoan?.groupCode === "CARTERA") && (
                                        <PaginatedDataTable
                                            nameList="fees"
                                            data={loan.fees}
                                            component={Statements}
                                            rowsPerPage={rowsPerPage}
                                            componentProps={{
                                                isDesktop,
                                            }}
                                            hasTooltip
                                            textTooltip={i18n.get("loan.detail.statement.header.tooltip", "")}
                                            isDesktop={isDesktop}
                                        />
                                    )}
                                </Box>
                            </ProductDetail.Body>
                        </ProductDetail>
                    )}
                </PageLoading>
                <SideBarModal
                    show={this.state.showSideBarDownloadDetail}
                    onClose={() => {
                        this.setState({ showSideBarDownloadDetail: false });
                    }}
                    title="forms.product.state.download.title">
                    <DownloadAccoutDetailForm
                        isDesktop={isDesktop}
                        account={loan}
                        currentLang={currentLang}
                        dispatch={dispatch}
                    />
                </SideBarModal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loan: loansSelectors.getSelectedLoan(state),
    loans: loansSelectors.getLoans(state),
    loansWidget: widgetSelectors.getWidget(state, "loans")?.data?.loans,
    fetching: loansSelectors.getFetching(state),
    fetchingDownload: loansSelectors.getFetchingDownload(state),
    totalCount: loansSelectors.getTotalCount(state),
    totalFavorites: loansSelectors.getTotalFavorites(state),
    selectedFilter: loansSelectors.getSelectedFilter(state),
    lastPaymentDay: loansSelectors.getLastPaymentDay(state),
    currentLang: i18nSelectors.getLang(state),
    backToDesktop: loansSelectors.getBackToDesktop(state),
});

Details.propTypes = {
    dispatch: func.isRequired,
    loan: shape({
        idProduct: string,
        currency: string,
    }),
    rest: shape(),
    match: shape().isRequired,
    isDesktop: bool.isRequired,
    fetching: bool.isRequired,
    fetchingDownload: bool.isRequired,
    totalCount: oneOfType([number, bool]).isRequired,
    totalFavorites: number.isRequired,
    selectedFilter: string.isRequired,
    lastPaymentDay: oneOfType([string, instanceOf(Date)]).isRequired,
    currentLang: string,
    setLocationCustomPath: func,
    backToDesktop: bool.isRequired,
    loans: array,
    loansWidget: array,
};

Details.defaultProps = {
    loan: {},
    loans: [],
    loansWidget: [],
    rest: {},
    currentLang: "",
    setLocationCustomPath: () => {},
};

export default connect(mapStateToProps)(withRevelockPositionContext(Details));
