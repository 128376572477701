import React, { Component } from "react";
import PropTypes from "prop-types";
import CapAmountField from "./CapAmountField";
import Box from "pages/_components/Box";
import Image from "../../Image/Image";
import Text from "../../Text";

class CapAmountListField extends Component {
    renderEmptyState = (isTC) => (
        <Box className="m-7">
            <Image src="images/empty.svg" />
            <Box className="text-center mt-5 mb-4">
                <Text
                    labelKey={`administration.capForUserProduct.emptyState.header.${isTC ? "TC" : "accounts"}`}
                    bold
                    size="3"
                />
            </Box>
            <Box className="text-center">
                <Text labelKey="administration.capForUserProduct.emptyState.footer" />
            </Box>
        </Box>
    );

    render() {
        const { products, fieldName, formId, currentProductTab } = this.props;

        const visibleProducts = products?.filter((product) => currentProductTab.includes(product.type)) || [];

        if (!visibleProducts.length) {
            return this.renderEmptyState(currentProductTab.includes("TC"));
        }

        return (
            <>
                {visibleProducts.map((product) => (
                    <CapAmountField
                        key={product.id}
                        product={product}
                        fieldName={`${fieldName}.cap_${product.id}`}
                        formId={formId}
                    />
                ))}
            </>
        );
    }
}

CapAmountListField.propTypes = {
    products: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        })
    ).isRequired,
    fieldName: PropTypes.string.isRequired,
    formId: PropTypes.string.isRequired,
    currentProductTab: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CapAmountListField;
