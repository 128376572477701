import { LOCATION_CHANGE } from "react-router-redux";

import types from "reducers/types/form";
import transactionLinesReducer from "reducers/form/transactionLines";
import { shouldKeepFormState } from "reducers/helpers/form";
import { createReducer, makeActionCreator } from "util/redux";
import { toggleParamsFromFalse, toggleParamsFromTrue } from "util/boolean";

export const INITIAL_STATE = {
    id: null,
    values: null,
    fetching: true,
    fetchingValidations: true,
    fetchingPre: false,
    fetchingDownloadTicket: false,
    metadata: { fieldList: [], formNameMap: {} },
    transaction: {},
    childrenTransactions: null,
    parentTransaction: null,
    data: {},
    credentialsGroups: [],
    name: "",
    submitAction: null,
    submitActionParams: null,
    mode: "edit",
    prevMode: "edit",
    previewData: null,
    isCancellingTransaction: false,
    prevRoute: { pathname: "" },
    bankDescriptionData: undefined,
    alertConfirmation: undefined,
    fetchingBank: undefined,
    submitDirectly: false,
    formikBagConfirmation: {},
    confirmationFetching: false,
    payload: {},
    isCanceledTransaction: false,
    formProducts: [],
    configurationParams: [],
    transactionData: undefined,
    emailUserCreator: undefined,
};

const setPreviewState = (state, action) => ({
    ...state,
    credentialsGroups: action.credentialsGroups,
    submitAction: action.submitAction,
    submitActionParams: {
        values: { scheduler: null },
        ...action.submitActionParams,
    },
    fetching: false,
    prevMode: state.mode,
    mode: action.mode,
    previewData: action.previewData,
});

const setPreState = (state, action) => ({
    ...state,
    submitActionParams: {
        values: { scheduler: null },
    },
    data: { ...state.data, ...action.formData },
    fetching: false,
    fetchingPre: false,
    preData: action.preData,
});

const formReducer = createReducer(INITIAL_STATE, {
    [LOCATION_CHANGE]: (state, action) => {
        if (shouldKeepFormState(action.payload, state.prevRoute)) {
            return { ...state, prevRoute: action.payload };
        }

        return { ...INITIAL_STATE, prevRoute: action.payload };
    },
    [types.READ_FORM_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        id: action.idForm,
        metadata: action.formMetadata,
        data: { ...state.data, ...action.formData },
        transaction: {},
        mode: "edit",
    }),
    [types.SET_MODE]: (state, action) => ({
        ...state,
        mode: action.mode,
        prevMode: action.prevMode,
    }),
    [types.SET_DATA]: (state, action) => ({
        ...state,
        data: { ...state.data, ...action.payload },
    }),
    [types.PREVIEW_FORM_REQUEST]: (state, action) => ({ ...state, values: action.payload.values }),
    [types.PREVIEW_FORM_SUCCESS]: setPreviewState,
    [types.ADD_EXTRA_PARAMS]: (state, action) => ({
        ...state,
        submitActionParams: {
            ...state.submitActionParams,
            values: {
                ...state.submitActionParams.values,
                ...action.values,
            },
        },
    }),
    [types.SEND_FORM_REQUEST]: (state, action) => ({
        ...state,
        confirmationFetching: action?.payload?.confirmationFetching || false,
    }),
    [types.SEND_FORM_SUCCESS]: (state, action) => ({
        ...state,
        credentialsGroups: [],
        idTransaction: action.idTransaction,
        fetching: false,
        confirmationFetching: true,
        transaction: action.transaction,
        data: action.transaction.data,
        usesJointAccount: action.usesJointAccount,
        mode: "view",
    }),
    [types.MODIFY_TRANSACTION_REQUEST]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.MODIFY_TRANSACTION_FAILURE]: (state) => toggleParamsFromTrue(state, "fetching"),
    [types.READ_TRANSACTION_REQUEST]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.READ_TRANSACTION_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        transaction: action.transaction,
        childrenTransactions: action.childrenTransactions,
        parentTransaction: action.parentTransaction,
        id: action.idForm,
        data: action.transaction.data,
        metadata: action.formMetadata,
        mode: action.transaction.idTransactionStatus === "DRAFT" ? "edit" : "view",
        usesJointAccount: action.usesJointAccount,
        emailUserCreator: action.emailUserCreator,
    }),
    [types.READ_TRANSACTION_TICKET_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        transaction: action.transaction,
        childrenTransactions: action.childrenTransactions,
        parentTransaction: action.parentTransaction,
    }),
    [types.READ_TRANSACTION_FAILURE]: (state) => toggleParamsFromTrue(state, "fetching"),
    [types.SIGN_TRANSACTION_PREVIEW_REQUEST]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.SIGN_TRANSACTION_PREVIEW_SUCCESS]: setPreviewState,
    [types.SIGN_TRANSACTION_PREVIEW_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        mode: "view",
    }),
    [types.CANCEL_TRANSACTION_PRE_REQUEST]: (state) => toggleParamsFromFalse(state, "isCancellingTransaction"),
    [types.CANCEL_TRANSACTION_PRE_SUCCESS]: (state, action) => ({
        ...state,
        credentialsGroups: action.credentialsGroups,
        fetching: false,
    }),
    [types.CANCEL_TRANSACTION_PRE_ERROR]: (state) => toggleParamsFromTrue(state, "fetching", "isCancellingTransaction"),
    [types.CANCEL_TRANSACTION_SUCCESS]: (state) => toggleParamsFromTrue(state, "isCancellingTransaction"),
    [types.SAVE_DRAFT_REQUEST]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.SAVE_DRAFT_SUCCESS]: (state) => toggleParamsFromTrue(state, "fetching"),
    [types.SAVE_DRAFT_FAILURE]: (state) => toggleParamsFromTrue(state, "fetching"),
    [types.SEND_FORM_DATA_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        mode: "edit",
    }),
    [types.CLOSE_TRANSACTION_PREVIEW]: (state) => ({
        ...state,
        mode: state.prevMode,
    }),
    [types.READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        transaction: action.transaction,
        id: action.idForm,
        data: action.transaction.data,
        metadata: action.formMetadata,
        mode: "view",
    }),
    [types.DOWNLOAD_TICKET_REQUEST]: (state) => ({
        ...state,
        fetchingDownloadTicket: true,
    }),
    [types.DOWNLOAD_TICKET_FAILURE]: (state) => ({
        ...state,
        fetchingDownloadTicket: false,
    }),
    [types.DOWNLOAD_TICKET_SUCCESS]: (state) => ({
        ...state,
        fetchingDownloadTicket: false,
    }),
    [types.SHARE_TICKET]: (state) => ({
        ...state,
        fetchingDownloadTicket: true,
    }),
    [types.SHARE_TICKET_FAILURE]: (state) => ({
        ...state,
        fetchingDownloadTicket: false,
    }),
    [types.SHARE_TICKET_SUCCESS]: (state) => ({
        ...state,
        fetchingDownloadTicket: false,
    }),
    [types.PRE_FORM_REQUEST]: (state, action) => ({ ...state, values: action.values, fetchingPre: true }),
    [types.PRE_FORM_SUCCESS]: (state, action) => setPreState(state, action),
    [types.PRE_FORM_FAILURE]: (state) => toggleParamsFromFalse(state, "fetching", "fetchingPre"),
    [types.GO_BACK_CLEAN_TRANSACTION]: (state) => ({
        ...state,
        mode: state.prevMode,
        // transaction: INITIAL_STATE.transaction,
    }),
    [types.CHANGE_TYPE_LOAN]: (state, action) => ({
        ...state,
        loanType: action.loanType,
    }),
    [types.CHANGE_TRANSACTION_HIDE]: (state, action) => ({
        ...state,
        isCancellingTransaction: action.cancelTransaction,
    }),
    [types.CHANGE_RADIO_BUTTON_OPTION]: (state, action) => ({
        ...state,
        radioButtonOption: action.radioButtonOption,
    }),
    [types.SET_FORM_FETCHING]: (state, action) => ({
        ...state,
        fetching: action.fetching,
    }),
    [types.BANK_DESCRIPTION_SUCCESS]: (state, action) => ({
        ...state,
        bankDescriptionData: action.payload,
        fetchingBank: false,
    }),
    [types.BANK_DESCRIPTION_FAILURE]: (state) => ({
        ...state,
        bankDescription: undefined,
        fetchingBank: false,
    }),
    [types.BANK_DESCRIPTION_REQUEST]: (state) => ({
        ...state,
        fetching: false,
        fetchingBank: true,
    }),
    [types.PREVIEW_FORM_CONFIRM]: (state, action) => {
        const updateState = setPreviewState(state, action);
        const alertConfirmation = action?.alertConfirmation;
        const submitDirectly = action?.submitDirectly;
        const submitAction = action?.submitAction;
        const submitActionParams = action?.submitActionParams;
        const formikBagConfirmation = action?.formikBagConfirmation;
        const credentialsGroupsConfirmation = action?.credentialsGroups;
        return {
            ...updateState,
            alertConfirmation: alertConfirmation || undefined,
            submitDirectly: submitDirectly || false,
            submitAction,
            submitActionParams,
            formikBagConfirmation,
            credentialsGroupsConfirmation,
        };
    },
    [types.VIEW_FORM_WALLY]: (state, action) => ({
        ...state,
        fetching: false,
        transaction: action.transaction,
        data: action.transaction.data,
        mode: "view",
    }),
    [types.SEND_FORM_DATA_INSUFFICIENT_FOUND]: (state) => ({
        ...state,
        fetching: false,
        alertConfirmation: undefined,
        confirmationFetching: false,
    }),
    [types.PRE_CUSTOM_FORM_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.PRE_CUSTOM_FORM_SUCCESS]: (state, action) => ({
        ...state,
        formProducts: action.formProducts,
        configurationParams: action.configurationParams,
        transactionData: action.transactionData,
        fetching: false,
    }),
    [types.PRE_CUSTOM_FORM_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.PRE_VALIDATIONS_FORM_REQUEST]: (state) => ({
        ...state,
        fetchingValidations: true,
        validationData: undefined,
    }),
    [types.PRE_VALIDATIONS_FORM_SUCCESS]: (state, action) => ({
        ...state,
        fetchingValidations: false,
        validationData: action.validationData,
    }),
    [types.PRE_VALIDATIONS_FORM_FAILURE]: (state) => ({
        ...state,
        fetchingValidations: false,
    }),
    [types.CLEAN_TRANSACTION]: (state) => ({
        ...state,
        transaction: {},
    }),
});

export const actions = {
    preForm: makeActionCreator(types.PRE_FORM_REQUEST, "values", "alertOpts"),
    preFormSuccess: makeActionCreator(types.PRE_FORM_SUCCESS, "formData", "preData"),
    preCustomForm: makeActionCreator(types.PRE_CUSTOM_FORM_REQUEST, "permissionList"),
    preCustomFormSuccess: makeActionCreator(
        types.PRE_CUSTOM_FORM_SUCCESS,
        "formProducts",
        "configurationParams",
        "transactionData",
    ),
    preCustomFormFailure: makeActionCreator(types.PRE_CUSTOM_FORM_FAILURE),
    preValidationsForm: makeActionCreator(types.PRE_VALIDATIONS_FORM_REQUEST, "activities"),
    preValidationsSuccess: makeActionCreator(types.PRE_VALIDATIONS_FORM_SUCCESS, "validationData"),
    preValidationsFailure: makeActionCreator(types.PRE_VALIDATIONS_FORM_FAILURE),
    previewForm: makeActionCreator(types.PREVIEW_FORM_REQUEST),
    sendForm: makeActionCreator(types.SEND_FORM_REQUEST),
    saveDraft: makeActionCreator(types.SAVE_DRAFT_REQUEST),
    cancelTransactionPre: makeActionCreator(types.CANCEL_TRANSACTION_PRE_REQUEST),
    cancelTransaction: makeActionCreator(types.CANCEL_TRANSACTION_REQUEST),
    modifyTransaction: makeActionCreator(types.MODIFY_TRANSACTION_REQUEST, "idTransaction"),
    signTransactionPreview: makeActionCreator(types.SIGN_TRANSACTION_PREVIEW_REQUEST, "payload"),
    signTransaction: makeActionCreator(types.SIGN_TRANSACTION_REQUEST),
    closeConfirmation: makeActionCreator(types.CLOSE_TRANSACTION_PREVIEW),
    setData: makeActionCreator(types.SET_DATA),
    readTransaction: makeActionCreator(types.READ_TRANSACTION_REQUEST, "payload", "isCanceledTransaction"),
    formClosed: makeActionCreator(types.FORM_CLOSED),
    downloadTicket: makeActionCreator(types.DOWNLOAD_TICKET_REQUEST, "idTicket", "format", "idForm"),
    downloadImageTicket: makeActionCreator(types.DOWNLOAD_IMG_TICKET_REQUEST, "idTicket", "image"),
    shareImageTicket: makeActionCreator(types.SHARE_IMG_TICKET, "idTicket", "image"),
    shareTicket: makeActionCreator(types.SHARE_TICKET, "idTicket", "format", "idForm"),
    setMode: makeActionCreator(types.SET_MODE, "mode", "prevMode"),
    addExtraParams: makeActionCreator(types.ADD_EXTRA_PARAMS, "values"),
    goBackCleanTransaction: makeActionCreator(types.GO_BACK_CLEAN_TRANSACTION),
    changeLoanType: makeActionCreator(types.CHANGE_TYPE_LOAN, "loanType"),
    changeCancelTransaction: makeActionCreator(types.CHANGE_TRANSACTION_HIDE, "cancelTransaction"),
    changeRadioButtom: makeActionCreator(types.CHANGE_RADIO_BUTTON_OPTION, "radioButtonOption"),
    viewFormWally: makeActionCreator(types.VIEW_FORM_WALLY, "transaction"),
    confirmMovementPendingWally: makeActionCreator(types.CONFIRM_MOVEMENT_PENDING_WALLY, "idActivity"),
    confirmSuspendCheck: makeActionCreator(types.CONFIRM_SUSPEND_CHECK, "idActivity"),
    deleteCreditCardOtherBank: makeActionCreator(types.DELETE_CREDIT_CARD_OTHER_BANK, "idActivity"),
    confirmPaymentFastRecharge: makeActionCreator(types.CONFIRM_PAYMENT_FAST_RECHARGE, "idActivity"),
    setFormFetching: makeActionCreator(types.SET_FORM_FETCHING, "fetching"),
    bankDescriptionRequest: makeActionCreator(
        types.BANK_DESCRIPTION_REQUEST,
        "codeBank",
        "codeBankType",
        "idComponent",
        "setFieldValue",
    ),
    cleanTransaction: makeActionCreator(types.CLEAN_TRANSACTION),
};

export default (state = INITIAL_STATE, action) => ({
    ...formReducer(state, action),
    transactionLines: transactionLinesReducer(state.transactionLines, action),
});

export const selectors = {
    getId: ({ form }) => form.id,
    getFetching: ({ form }) => form.fetching,
    getFetchingPre: ({ form }) => form.fetchingPre,
    getFetchingValidations: ({ form }) => form.fetchingValidations,
    isFetchingDownloadTicket: ({ form }) => form.fetchingDownloadTicket,
    isConfirmationFetching: ({ form }) => form.confirmationFetching,
    getMetadata: ({ form }) => form.metadata,
    getCredentialsGroups: ({ form }) => form.credentialsGroups,
    getTransaction: ({ form }) => form.transaction,
    getChildrenTransactions: ({ form }) => form.childrenTransactions,
    getParentTransaction: ({ form }) => form.parentTransaction,
    getTransactionFormMetadata: ({ form }) => form.metadata,
    getData: ({ form }) => form.data,
    getValues: ({ form }) => form.values,
    getName: ({ form }) => form.name,
    getSubmitAction: ({ form }) => form.submitAction,
    getSubmitActionParams: ({ form }) => form.submitActionParams,
    getMode: ({ form }) => form.mode,
    getPreviewData: ({ form }) => {
        if (form.previewData && form.previewData.notificationMails && form.previewData.notificationMails.length > 0) {
            const tags = [];
            form.previewData.notificationMails.forEach((item) => {
                tags.push({ id: item, text: item });
            });
            return { ...form.previewData, notificationMails: tags };
        }
        return form.previewData;
    },
    getField: ({ form }, id) => form.metadata.fieldList.find(({ idField }) => idField === id),
    getIsCancellingTransaction: ({ form }) => form.isCancellingTransaction,
    getPreData: ({ form }) => form.preData,
    getLoanType: ({ form }) => form.loanType,
    getRadioButtonOption: ({ form }) => form.radioButtonOption,
    getBankDescriptionData: ({ form }) => form.bankDescriptionData,
    getAlertConfirmation: ({ form }) => form.alertConfirmation,
    isFetchingBank: ({ form }) => form.fetchingBank,
    isSubmitDirectly: ({ form }) => form.submitDirectly,
    getFormikBagConfirmation: ({ form }) => form.formikBagConfirmation,
    getCredentialsGroupsConfirmation: ({ form }) => form.formikBagConfirmation,
    getUsesJointAccount: ({ form }) => form.usesJointAccount,
    getCustomTransactionData: ({ form }) => form.transactionData,
    getCustomConfigurationParams: ({ form }) => form.configurationParams,
    getCustomProducts: ({ form }) => form.formProducts,
    getEmailUserCreator: ({ form }) => form.emailUserCreator,
    getValidationData: ({ form }) => form.validationData,
};
