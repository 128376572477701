import { combineReducers } from "redux";

import administrationSimple from "reducers/administration/simple";
import administrationMedium from "reducers/administration/medium";
import administrationAdvanced from "reducers/administration/advanced";
import { selectors as fromChannels } from "reducers/administration/common/channels";
import { selectors as fromChannelsTicket } from "reducers/administration/common/channelsTicket";
import { selectors as fromDetails } from "reducers/administration/common/details";
import { selectors as fromGroup } from "reducers/administration/common/group";
import { selectors as fromGroupFormData } from "reducers/administration/common/groupFormData";
import { selectors as fromGroups } from "reducers/administration/common/groups";
import { selectors as fromPermissions } from "reducers/administration/common/permissions";
import { selectors as fromPermissionsTicket } from "reducers/administration/common/permissionsTicket";
import { selectors as fromSignaturesSchemes } from "reducers/administration/common/signaturesSchemes";
import { selectors as fromSignaturesSchemesTicket } from "reducers/administration/common/signaturesSchemesTicket";
import { selectors as fromSignatureTicket } from "reducers/administration/common/signatureTicket";
import { selectors as fromGroupsOfUserTicket } from "reducers/administration/common/groupsOfUserTicket";
import { selectors as navigation } from "reducers/administration/common/navigation";
import { selectors as sessionSelectors } from "reducers/session";

const getScheme = (state) => state.administration[sessionSelectors.getAdministrationScheme(state)];
const getUser = (state) => state.administration.user;

export const signaturesSchemesSelectors = {
    getCredentialGroups: (state) => fromSignaturesSchemes.getCredentialGroups(getScheme(state)),
    getIdTransaction: (state) => fromSignaturesSchemes.getIdTransaction(getScheme(state)),
    getIdActivity: (state) => fromSignaturesSchemes.getIdActivity(getScheme(state)),
    capFrequencyList: (state) => fromSignaturesSchemes.capFrequencyList(getScheme(state)),
    caps: (state) => fromSignaturesSchemes.caps(getScheme(state)),
    currentPage: (state) => fromSignaturesSchemes.currentPage(getScheme(state)),
    currentSignature: (state) => fromSignaturesSchemes.currentSignature(getScheme(state)),
    currentSignatureGroupMap: (state) => fromSignaturesSchemes.currentSignatureGroupMap(getScheme(state)),
    defaultSignatureCap: (state) => fromSignaturesSchemes.defaultSignatureCap(getScheme(state)),
    enabledChannels: (state) => fromSignaturesSchemes.enabledChannels(getScheme(state)),
    fetching: (state) => fromSignaturesSchemes.fetching(getScheme(state)),
    isFetchingExport: (state) => fromSignaturesSchemes.isFetchingExport(getScheme(state)),
    isFetchingMoreSignaturesSchemes: (state) => fromSignaturesSchemes.isFetchingMoreSignaturesSchemes(getScheme(state)),
    functionalGroups: (state) => fromSignaturesSchemes.functionalGroups(getScheme(state)),
    groups: (state) => fromSignaturesSchemes.groups(getScheme(state)),
    environmentProducts: (state) => fromSignaturesSchemes.environmentProducts(getScheme(state)),
    selectedProducts: (state) => fromSignaturesSchemes.selectedProducts(getScheme(state)),
    hasMoreData: (state) => fromSignaturesSchemes.hasMoreData(getScheme(state)),
    masterCurrency: (state) => fromSignaturesSchemes.masterCurrency(getScheme(state)),
    signatureLevelsCounts: (state) => fromSignaturesSchemes.signatureLevelsCounts(getScheme(state)),
    signaturesSchemes: (state) => fromSignaturesSchemes.signaturesSchemes(getScheme(state)),
    signatureType: (state) => fromSignaturesSchemes.signatureType(getScheme(state)),
    signatureTypeList: (state) => fromSignaturesSchemes.signatureTypeList(getScheme(state)),
    selectedFunctionalGroups: (state) => fromSignaturesSchemes.selectedFunctionalGroups(getScheme(state)),
    topAmount: (state) => fromSignaturesSchemes.topAmount(getScheme(state)),
    alias: (state) => fromSignaturesSchemes.alias(getScheme(state)),
    totalPages: (state) => fromSignaturesSchemes.totalPages(getScheme(state)),
    signatureDispatch: (state) => fromSignaturesSchemes.signatureDispatch(getScheme(state)),
};

export const signaturesSchemesTicketSelectors = {
    fetching: (state) => fromSignaturesSchemesTicket.isFetching(getScheme(state)),
    signatureAlias: (state) => fromSignaturesSchemesTicket.signatureAlias(getScheme(state)),
    functionalGroups: (state) => fromSignaturesSchemesTicket.functionalGroups(getScheme(state)),
    signatureLevelsCounts: (state) => fromSignaturesSchemesTicket.signatureLevelsCounts(getScheme(state)),
    signatureType: (state) => fromSignaturesSchemesTicket.signatureType(getScheme(state)),
    signatureDispatch: (state) => fromSignaturesSchemesTicket.signatureDispatch(getScheme(state)),
    topAmount: (state) => fromSignaturesSchemesTicket.topAmount(getScheme(state)),
};

export const permissionsSelectors = {
    getProducts: (state) => fromPermissions.getProducts(getScheme(state)),
    getMappedProducts: (state) =>
        fromPermissions.getProducts(getScheme(state)).map(({ idProduct, label, productType }) => ({
            value: idProduct,
            label,
            productType,
        })),
    getPermissions: (state) => fromPermissions.getPermissions(getScheme(state)),
    getFuturePermissions: (state) => fromPermissions.getFuturePermissions(getScheme(state)),
    getCredentialGroups: (state) => fromPermissions.getCredentialGroups(getScheme(state)),
    getGroups: (state) => fromPermissions.getGroups(getScheme(state)),
    getEnvData: (state) => fromPermissions.getEnvData(getScheme(state)),
    isFetching: (state) => fromPermissions.isFetching(getScheme(state)),
    getIdTransaction: (state) => fromPermissions.getIdTransaction(getScheme(state)),
    getIdActivity: (state) => fromPermissions.getIdActivity(getScheme(state)),
    getUser,
};

export const groupsSelectors = {
    getCredentialGroups: (state) => fromGroups.getCredentialGroups(getScheme(state)),
    getAvailableGroups: (state) => fromGroups.getAvailableGroups(getScheme(state)),
    getSelectedGroups: (state) => fromGroups.getSelectedGroups(getScheme(state)),
    getGroupsToSave: (state) => fromGroups.getGroupsToSave(getScheme(state)),
    isFetching: (state) => fromGroups.isFetching(getScheme(state)),
    getIdTransaction: (state) => fromGroups.getIdTransaction(getScheme(state)),
    getIdActivity: (state) => fromGroups.getIdActivity(getScheme(state)),
    getUser,
};

export const channelsSelectors = {
    getCaps: (state) => fromChannels.getCaps(getScheme(state)),
    getCurrencies: (state) => fromChannels.getCurrencies(getScheme(state)),
    getTopAmount: (state) => fromChannels.getTopAmount(getScheme(state)),
    getNonRemovableChannels: (state) => fromChannels.getNonRemovableChannels(getScheme(state)),
    getEnabledChannels: (state) => fromChannels.getEnabledChannels(getScheme(state)),
    getEnabledChannelsFrequencies: (state) => fromChannels.getEnabledChannelsFrequencies(getScheme(state)),
    isFetching: (state) => fromChannels.isFetching(getScheme(state)),
    getCredentialGroups: (state) => fromChannels.getCredentialGroups(getScheme(state)),
    getIdTransaction: (state) => fromChannels.getIdTransaction(getScheme(state)),
    getIdActivity: (state) => fromChannels.getIdActivity(getScheme(state)),
    getSignatureLevel: (state) => fromChannels.getSignatureLevel(getScheme(state)),
    getUserEnvStatus: (state) => fromDetails.getUserEnvStatus(getScheme(state)),
    getUser,
    getCapsProd: (state) => fromChannels.getCapsProd(getScheme(state)),
    getOldProducts: (state) => fromDetails.getOldProducts(getScheme(state)),
    getProducts: (state) => fromDetails.getProducts(getScheme(state)),
};

export const detailsSelectors = {
    ...permissionsSelectors,
    ...channelsSelectors,
    getCredentialGroups: (state) => fromDetails.getCredentialGroups(getScheme(state)),
    getIdTransaction: (state) => fromDetails.getIdTransaction(getScheme(state)),
    getIdActivity: (state) => fromDetails.getIdActivity(getScheme(state)),
    hasSignatureEnabled: (state) => fromDetails.hasSignatureEnabled(getScheme(state)),
    getSignatureLevel: (state) => fromDetails.getSignatureLevel(getScheme(state)),
    hasMassiveEnabled: (state) => fromDetails.hasMassiveEnabled(getScheme(state)),
    getUserEnvStatus: (state) => fromDetails.getUserEnvStatus(getScheme(state)),
    getUserTokenStatus: (state) => fromDetails.getUserTokenStatus(getScheme(state)),
    getUserSerialToken: (state) => fromDetails.getUserSerialToken(getScheme(state)),
    getUserDeviceType: (state) => fromDetails.getUserDeviceType(getScheme(state)),
    getAdminGroupsIds: (state) => fromDetails.getAdminGroupsIds(getScheme(state)),
    isFetching: (state) => fromDetails.isFetching(getScheme(state)),
};

export const groupSelectors = {
    getName: (state) => fromGroup.getName(getScheme(state)),
    getDescription: (state) => fromGroup.getDescription(getScheme(state)),
    isAdminGroup: (state) => fromGroup.isAdminGroup(getScheme(state)),
    getGroupStatus: (state) => fromGroup.getGroupStatus(getScheme(state)),
    getAdminUsers: (state) => fromGroup.getAdminUsers(getScheme(state)),
    getMembers: (state) => fromGroup.getMembers(getScheme(state)),
    isFetching: (state) => fromGroup.isFetching(getScheme(state)),
};

export const groupFormDataSelectors = {
    getAvailableUsers: (state) => fromGroupFormData.getAvailableUsers(getScheme(state)),
    getAdminUsers: (state) => fromGroupFormData.getAdminUsers(getScheme(state)),
    getFormValues: (state) => fromGroupFormData.getFormValues(getScheme(state)),
    isFetching: (state) => fromGroupFormData.isFetching(getScheme(state)),
    isAdminGroup: (state) => fromGroupFormData.isAdminGroup(getScheme(state)),
    getCredentialGroups: (state) => fromGroupFormData.getCredentialGroups(getScheme(state)),
    getIdTransaction: (state) => fromGroupFormData.getIdTransaction(getScheme(state)),
    getIdActivity: (state) => fromGroupFormData.getIdActivity(getScheme(state)),
    getCurrentStep: (state) => fromGroupFormData.getCurrentStep(getScheme(state)),
};

export const permissionsTicketSelectors = {
    isFetching: (state) => fromPermissionsTicket.isFetching(getScheme(state)),
};

export const channelsTicketSelectors = {
    isFetching: (state) => fromChannelsTicket.isFetching(getScheme(state)),
};

export const groupsOfUserTicketSelectors = {
    isFetching: (state) => fromGroupsOfUserTicket.isFetching(getScheme(state)),
    getGroups: (state) => fromGroupsOfUserTicket.getGroups(getScheme(state)),
    getUser: (state) => fromGroupsOfUserTicket.getUser(getScheme(state)),
};

export const signatureTicketSelectors = {
    isFetching: (state) => fromSignatureTicket.isFetching(getScheme(state)),
    getSignatureLevel: (state) => fromSignatureTicket.getSignatureLevel(getScheme(state)),
};

export const navigationSelectors = {
    isHasClose: (state) => navigation.isHasClose(getScheme(state)),
    isHasBack: (state) => navigation.isHasBack(getScheme(state)),
};

const administrationReducer = combineReducers({
    simple: administrationSimple,
    medium: administrationMedium,
    advanced: administrationAdvanced,
    user: (state = {}, { data = {} }) => data.user || state,
});

export default (state, action) => {
    if (
        action.type &&
        action.type.startsWith("administrationAdvanced") &&
        action.id &&
        action.id !== state.user.idUser
    ) {
        return administrationReducer(undefined, action);
    }

    return administrationReducer(state, action);
};
