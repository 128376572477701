import React from "react";
import classNames from "classnames";
import Box from "pages/_components/Box";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import InputMask from "react-input-mask";
import { bool, func, shape, string } from "prop-types";
import * as i18nUtils from "util/i18n";

const CatastroInputField = (props) => {
    const {
        className,
        disabled,
        error,
        field,
        form,
        isRequired,
        labelKey,
        name,
        mask,
        onChange,
        value,
        placeholder,
    } = props;

    const fieldHasError = (subfield) => {
        if (error) {
            return true;
        }
        if (Object.keys(form).length > 0) {
            const { errors } = form;
            return field.name === subfield ? errors[`${field.name}`] : errors[`${field.name}.${subfield}`];
        }
        return null;
    };

    const renderError = (subfield) => {
        if (error) {
            return <FieldError error={error} />;
        }
        if (Object.keys(form).length > 0) {
            const { errors } = form;

            if (fieldHasError(subfield)) {
                return (
                    <FieldError
                        error={field.name === subfield ? errors[`${field.name}`] : errors[`${field.name}.${subfield}`]}
                    />
                );
            }
        }
        return null;
    };

    return (
        <>
            <Box
                className={classNames("form-group", className, {
                    "has-error": fieldHasError(name),
                })}
            >
                {labelKey ? (
                    <FieldLabel
                        optional={isRequired ? "" : i18nUtils.get("forms.optional")}
                        idField={name}
                        labelKey={labelKey}
                    />
                ) : null}
                <Box className="input-group">
                    <InputMask
                        id={name}
                        className="form-control"                        
                        name={Object.keys(field).length > 0 ? `${field.name}_${name}` : name}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        mask={mask}
                        placeholder={placeholder}
                    />
                </Box>
                {renderError(name)}
            </Box>
        </>
    );
};

CatastroInputField.propTypes = {
    className: bool,
    disabled: bool,
    error: string,
    field: shape({}),
    form: shape({}),
    isRequired: bool,
    labelKey: string,
    name: string.isRequired,
    mask: string.isRequired,
    onChange: func.isRequired,
    placeholder: string,
    value: string.isRequired,
    onBlur: func,
};

CatastroInputField.defaultProps = {
    className: false,
    disabled: false,
    error: null,
    field: {},
    form: {},
    isRequired: true,
    labelKey: null,
    placeholder: "",
    onBlur: null,
};

export default CatastroInputField;
