import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { arrayOf, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import CapsListConfirm from "./CapsListConfirm";
import { renderAdditionalDataCaps, renderAdditionalDataChannelCaps, renderAdditionalDataLimits } from "../utils/users";

class ChannelsConfirm extends Component {
    static propTypes = {
        credentialGroups: arrayOf(
            shape({
                idCredentialGroup: string,
            }),
        ).isRequired,
        user: shape({
            idUser: string,
            idUserStatus: string,
            firstName: string,
            lastName: string,
        }).isRequired,
        routerActions: shape({
            goBack: func,
        }).isRequired,
        actions: shape({
            updateChannelsRequest: func,
        }).isRequired,
        formActions: shape({
            signTransaction: func,
        }).isRequired,
        caps: shape({}).isRequired,
        capsProd: arrayOf(shape({})).isRequired,
        idTransaction: string,
        idActivity: string,
    };

    static defaultProps = {
        idTransaction: "",
        idActivity: "",
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, caps, user, idTransaction, idActivity, capsProd } = this.props;
        const secondFactor = credentials;
        if (!idTransaction) {
            actions.updateChannelsRequest(
                {
                    caps: { ...caps },
                    idUser: user.idUser,
                    status: user.idUserStatus,
                    secondFactor,
                    fullName: user?.fullName,
                    documentType: user?.documentType,
                    documentNumber: user?.documentNumber,
                    capsProd,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const { user, credentialGroups, caps, capsProd, products } = this.props;
        return (
            <>
                <Head title="administration.limits.title" onBack={this.handleBack} textBack="administration.users.returnToDetail" />

                <MainContainer>
                    <Box className="border-radius-lg p-7 box-shadow-small background-white">
                        <div className="above-the-fold">
                            <Container
                                className="container--layout items-center flex-grow-1"
                                gridClassName="form-content">
                                <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2} className="line-height-125">
                                    <Box display="flex">
                                        <Text
                                            component="h4"
                                            semibold
                                            labelKey="administration.forms.confirm.credentials"
                                        />
                                    </Box>
                                    <Box display="flex" className="mt-7">
                                        <Text
                                            labelKey="administration.forms.confirm.credentials.description"
                                            className="m-0"
                                            size="5"
                                        />
                                    </Box>
                                    <Box>
                                        {renderAdditionalDataLimits(
                                            user?.fullName,
                                            user?.documentType,
                                            user?.documentNumber,
                                        )}
                                    </Box>
                                    {
                                        renderAdditionalDataChannelCaps(caps)
                                    }

                                    {(capsProd && capsProd?.length > 0) && (
                                        renderAdditionalDataCaps(capsProd, products)
                                    )}
                                </Col>
                            </Container>
                        </div>
                    </Box>
                    <Box>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            cancelUrl={`/administration/advanced/details/${user.idUser}`}
                            idActivity="administration.medium.modify.channels.send"
                        />
                    </Box>
                </MainContainer>
            </>
        );
    }
}

export default ChannelsConfirm;
