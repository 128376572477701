import { call, put, takeLatest, select } from "redux-saga/effects";
import { routerActions } from "react-router-redux";

import { ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE } from "util/responses.js";
import { types, actions } from "reducers/administration/common/groupFormData";
import { actions as notificationActions } from "reducers/notification";
import { permissionsActions } from "reducers/administration/advanced";
import * as administrationGroups from "middleware/administration/advanced";
import * as form from "middleware/form";
import { credentialsToUnderscoreFormat } from "util/form.js";
import * as i18n from "util/i18n";
import { actions as settingActions } from "reducers/settings";
import { types as secondFactorTypes } from "reducers/secondFactor";
import { actions as sessionActions } from "reducers/session";
import { steps } from "pages/administration/utils/groups";

const sagas = [
    takeLatest(types.LOAD_GROUP_FORM_DATA_REQUEST, loadGroupFormDataRequest),
    takeLatest(types.SUBMIT_GROUP_FORM_PREVIEW_REQUEST, submitGroupFormPreviewRequest),
    takeLatest(types.SUBMIT_GROUP_FORM_REQUEST, submitGroupFormRequest),
];

export default sagas;

const errorsKey = {
    noField: "NO_FIELD",
};

function* loadGroupFormDataRequest({ id }) {
    const response = yield call(administrationGroups.loadGroupFormDataRequest, id);

    if (response.type === "W") {
        yield put(actions.loadGroupFormDataFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
        );
    } else {
        const { group, permissions, isAdminGroup, availableUsers, adminUsers, envData } = response.data.data;

        yield put(actions.loadGroupFormDataSuccess(group, permissions, adminUsers, isAdminGroup, availableUsers, envData));
        yield put(permissionsActions.loadPermissionsSuccess(response.data.data));
    }
}

function* submitGroupFormPreviewRequest({ formData, formikBag, idGroup }) {

    const response = yield call(administrationGroups.submitGroupFormPreviewRequest, formData, idGroup, formikBag);
    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response.type === "W") {
        const errorMessage = response.data.data[errorsKey.noField] || response?.data?.data?.name || i18n.get("global.unexpectedError");
        // if (formikBag) {
        //     formikBag.setErrors(response.data.data);
        // }
        yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
    } else {
        // const pathname = yield select(({ router }) => router.location.pathname);

        // let idActivity = "";
        // if (idGroup) {
        //     idActivity = "administration.advanced.group.modify.send";
        //     yield put(routerActions.push(pathname.split(idGroup).join(`${idGroup}/confirm`)));
        // } else {
        //     idActivity = "administration.advanced.group.create.send";
        //     yield put(routerActions.push(pathname.split("create").join("create/confirm")));
        // }
        // const responseCredentials = yield call(
        //     form.listCredentialsGroups,
        //     null,
        //     "administration.advanced.group.create.send",
        // );
        // const credentialGroups = responseCredentials.data.data.groups;
        yield put(actions.setCurrentStep(steps.confirmation));
        // yield put(actions.submitGroupFormPreviewSuccess(credentialGroups, idActivity));
    }
}

function* submitGroupFormRequest({ formData, formikBag, idGroup }) {
    const { name, description, permissions, users, status, isModifyMember, ...secondFactor } = formData;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(secondFactor);
    const response = yield call(
        administrationGroups.submitGroupFormRequest,
        { name, description, permissions, users, status, ...credentialsWithUnderscore },
        idGroup,
    );

    formikBag.setSubmitting(false);

    if (response.type === "W") {
        const { code, data, message } = response.data;
        let showNotification = true;
        if (code === "COR020W" && data.secondFactor) {
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            showNotification = false;
        }

        if (code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }

        if (code === "API708W") {
            yield put(
                notificationActions.showNotification(i18n.get("secondFactor.credential.otp.expired", "Código OTP expirado, solicite un nuevo código OTP"), "warning", [
                    "administration",
                ]),
            );
            return;
        }

        const errorMessage = response.data.data[errorsKey.noField] || data.name || message || i18n.get("global.unexpectedError");

        formikBag.setErrors(response.data.data);
        if (showNotification) {
            yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
        }
    } else {
        const successMesage = response.data.message;
        if (response.data.code === ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE) {
            let message = "";
            if (idGroup) {
                if (isModifyMember) {
                    message = i18n.get("administration.groups.updateMembers.pending.approval", response.data.message);
                } else {
                    message = i18n.get("administration.groups.update.pending.messages", response.data.message);
                }
            } else {
                message = i18n.get("administration.groups.create.pending.messages", response.data.message);
            }
            yield put(notificationActions.showNotification(message, "warning", ["administration"]));
        } else {
            yield put(notificationActions.showNotification(successMesage, "success", ["administration"]));
            yield put(actions.submitGroupFormSuccess());
            yield put(settingActions.updatePermissionLocal(response?.data?.data?.permissions));
        }
        if (idGroup) {
            yield put(routerActions.replace(`/administration/advanced/group/${idGroup}/details`));
        } else {
            yield put(routerActions.replace(`/administration/groups`));
        }
    }
}
