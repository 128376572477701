/* eslint-disable no-nested-ternary */
import Box from "pages/_components/Box/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import { arrayOf, bool, func, number, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";

import * as stringUtils from "util/string";
import classNames from "classnames";
import DocumentsSwiftTabs from "./DocumentsSwiftTabs";
import * as Tabs from "./_components/tabs";

const DocumentsSwift = (props) => {
    const { dispatch, isDesktop, fetchingAccounts, accounts } = props;

    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    useEffect(() => {
        dispatch(formActions.preCustomForm(["documents.swift"]));
    }, []);

    const handleBack = () => {
        dispatch(push(`/documentsHome`));
    };

    const onChangeTab = (index) => {
        setCurrentTabIndex(index);
    };

    const renderItem = (item) => {
        const Tab = Tabs[stringUtils.capitalizeFirstLetter(item.id)];

        return (
            <Box
                className="swift-table-wrapper"
                key={item.id}
                showIconAllways
                isDesktop={isDesktop}
                keyLabel={item.label ? item.label : `documents.swift.widget.${item.id}`}>
                <Tab isDesktop={isDesktop} accounts={accounts} />
            </Box>
        );
    };

    const items = [
        { id: "importLettersCredit", label: "documents.swift.widget.importLettersCredit" },
        { id: "internationalTransfers", label: "documents.swift.widget.internationalTransfers" },
        { id: "standby", label: "documents.swift.widget.standby" },
    ];

    return (
        <>
            <Notification scopeToShow="documents" />
            <Head
                onBack={handleBack}
                title="documents.swift.header"
                textBack="documents.swift.header.returnToDocuments"
            />
            <MainContainer
                showLoader={fetchingAccounts}
                className="px-0 border-radius-lg box-shadow-small texture-header background-white mt-5">
                <Box
                    borderRadius="lg"
                    background="white"
                    className={classNames("mt-5", {
                        "p-7": isDesktop,
                        "p-5": !isDesktop,
                    })}>
                    <DocumentsSwiftTabs onSelect={onChangeTab}>
                        {items.map((item) => renderItem(item))}
                    </DocumentsSwiftTabs>
                </Box>
            </MainContainer>
        </>
    );
};

DocumentsSwift.propTypes = {
    dispatch: func.isRequired,
    match: shape({}).isRequired,
    isDesktop: bool.isRequired,
    checks: shape({}).isRequired,
    accounts: arrayOf(shape({})).isRequired,
    offset: number.isRequired,
    totalCount: number.isRequired,
    pageNumber: number.isRequired,
    fetchingAccounts: bool.isRequired,
};

const mapStateToProps = (state) => ({
    fetchingAccounts: formSelectors.getFetching(state),
    accounts: formSelectors.getCustomProducts(state),
});

export default connect(mapStateToProps)(resizableRoute(DocumentsSwift));
